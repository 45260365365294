import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@WRAP_COMP';
import { Tabs, Spin } from 'antd';

const defaultTabPaneArr = [
  { key: '1', tab: '11' },
  { key: '2', tab: '22' },
];

const TabsScrollBlock = ({
  children,
  loading = false,
  tabPaneArr = defaultTabPaneArr,
  onChangeTab = () => {},
  controlArea = null,
  wrapProps = {},
  ...props
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      bgColor='white'
      py='2'
      px='3'
      height='100%'
      {...wrapProps}>
      <div>
        <Tabs
          defaultActiveKey={tabPaneArr[0].key}
          onChange={onChangeTab}
          {...props}>
          {tabPaneArr.map(({ key, ...props }) => (
            <Tabs.TabPane key={key} {...props} disabled={loading} />
          ))}
        </Tabs>
      </div>
      <div>{controlArea}</div>
      <Box flex='1 1 auto' overflow='scroll'>
        <Spin spinning={loading}>{children}</Spin>
      </Box>
    </Box>
  );
};

TabsScrollBlock.propTypes = {
  tabPaneArr: PropTypes.array,
  controlArea: PropTypes.node,
};
export default TabsScrollBlock;
