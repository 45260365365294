import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Spin, Descriptions } from 'antd';
import { apiType } from '@/hook/useApi';
import { formatDateTime } from '@/utils';

function SpinachInfo({ userID = 0 }) {
  const [spinachData, setSpinachData] = useState(null);
  const dataHandle = ({ spinachCommonUserDetail, ...props }) => {
    setSpinachData({ ...spinachCommonUserDetail, ...props });
  };
  const { loading } = useQuery(apiType.GET_USER_SPINACH_INFO, {
    variables: { filter: { user: { id: userID } } },
    onCompleted: ({ getUser }) => dataHandle(getUser),
  });
  return (
    <>
      <Spin spinning={loading}>
        {spinachData && (
          <>
            <Descriptions column={1} labelStyle={{ fontWeight: 'bold' }}>
              <Descriptions.Item label='所屬平台'>
                {spinachData.platform}
              </Descriptions.Item>
              <Descriptions.Item label='使用者 ID'>
                {spinachData.spinachUserID}
              </Descriptions.Item>
              <Descriptions.Item label='使用者状态'>
                {spinachData.status}
              </Descriptions.Item>
              <Descriptions.Item label='支付等級 (VIP 等級)'>
                {spinachData.payLevel}
              </Descriptions.Item>
              <Descriptions.Item label='風控等級 (会员评级)'>
                {spinachData.riskControlLevel}
              </Descriptions.Item>
              <Descriptions.Item label='充值次數'>
                {spinachData.rechargeTime}
              </Descriptions.Item>
              <Descriptions.Item label='註冊時間'>
                {spinachData.registerAt
                  ? formatDateTime(spinachData.registerAt)
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label='本次登陸平台 IP'>
                {spinachData.nowLoginIP}
              </Descriptions.Item>
              <Descriptions.Item label='上次登陸平台時間'>
                {spinachData.lastLoginAt
                  ? formatDateTime(spinachData.lastLoginAt)
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label='上次登陸平台 IP'>
                {spinachData.lastLoginIP}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Spin>
    </>
  );
}

SpinachInfo.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default SpinachInfo;
