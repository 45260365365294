import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import useAlertMessage from '@/hook/useAlertMessage';
import useOrigin from '@/hook/useOrigin';
import useFile from '@/hook/useFile';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import {
  Card,
  Typography,
  Divider,
  Select,
  Button,
  Upload,
  Tooltip,
  Alert,
  Spin,
  Form,
  Input,
  Switch,
  Radio,
} from 'antd';
import { Row, Col } from '@/plugin/wrap-comp';
import RoomDemo from '@/components/CSRoomClient/RoomDemo';
import ColorBtn from './ColorBtn';

const { theme } = require('@/plugin/theme');

const handleInitEnabled = val => (val ? val === 'Yes' : false);

function CSRoomSettingMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const [originIDForm] = Form.useForm();
  const { onSuccessMsg, onWarningMsg } = useAlertMessage();
  const { getFileUrl, changeUrlToFile } = useFile();

  const { originSelectList, loading: originLoading } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });

  const [createSetting, { loading: cLdg }] = useMutationApi(
    apiType.CREATE_CS_ROOM_SETTING,
    {
      onCompleted: ({ createConsultingRoomClientSetting }) => {
        onSuccessMsg();
        fetchDataHandle(
          createConsultingRoomClientSetting.consultingRoomOriginID,
        );
      },
    },
  );

  const [updateSetting, { loading: uLdg }] = useMutationApi(
    apiType.UPDATE_CS_ROOM_SETTING,
    {
      onCompleted: ({ updateConsultingRoomClientSetting }) => {
        onSuccessMsg();
        fetchDataHandle(
          updateConsultingRoomClientSetting.consultingRoomOriginID,
        );
      },
    },
  );

  const [isExist, setIsExist] = useState(false);
  const [roomSettingData, setRoomSettingData] = useState(null);
  const [currCSRoomOriginID, setCurrCSRoomOriginID] = useState(1);

  const clickSubmitHandle = async () => {
    const { header, content, banner } = roomSettingData;

    const _contentLogoURL = [];
    for (const item of header.contentLogoURL) {
      const file = await changeUrlToFile(item);
      _contentLogoURL.push(file);
    }

    let postData = {
      header: {
        isEnabled: header.isEnabled ? 'Yes' : 'No',
        backgroundColor: header.backgroundColor,
        title: header.title,
        ...(header.avatar && { avatar: header.avatar }),
        ...(header.logo && { logo: header.logo }),
        contentLogoEnabled: header.contentLogoEnabled ? 'Yes' : 'No',
        contentLogo: _contentLogoURL,
        contentLogoPosition: header.contentLogoPosition,
        deleteContentLogo: !_contentLogoURL.length,
      },
      content: {
        backgroundColor: content.backgroundColor,
        ...(content.backgroundImage && {
          backgroundImage: content.backgroundImage,
        }),
        boxBackgroundColor: content?.boxBackgroundColor,
        ...(content.boxBackgroundImage && {
          boxBackgroundImage: content.boxBackgroundImage,
        }),
      },
      banner: {
        isEnabled: banner.isEnabled ? 'Yes' : 'No',
        linkUrl: banner.linkUrl,
        ...(banner.image && { image: banner.image }),
      },
    };

    if (!isExist) {
      createSetting({
        in: {
          consultingRoomOriginID: originIDForm.getFieldValue(
            'consultingRoomOriginID',
          ),
          ...postData,
        },
      });
    } else {
      updateSetting({
        filter: { consultingRoomClientSetting: { id: roomSettingData.id } },
        in: { ...postData },
      });
    }
  };

  const onChangeDataHeader = changeObj => {
    setRoomSettingData({
      ...roomSettingData,
      header: { ...roomSettingData.header, ...changeObj },
    });
  };
  const onChangeDataContent = changeObj => {
    setRoomSettingData({
      ...roomSettingData,
      content: { ...roomSettingData.content, ...changeObj },
    });
  };
  const onChangeDataBanner = changeObj => {
    setRoomSettingData({
      ...roomSettingData,
      banner: { ...roomSettingData.banner, ...changeObj },
    });
  };

  const deleteImg = async (postData = {}) => {
    const [err] = await updateSetting({
      filter: { consultingRoomClientSetting: { id: roomSettingData.id } },
      in: { ...postData },
    });
    if (err) return;
  };

  // 輪播處理
  const handleCarousel = file => {
    if (roomSettingData.header.contentLogoURL.length >= 3) {
      onWarningMsg('最多三张');
      return;
    }
    onChangeDataHeader({
      contentLogoURL: [...roomSettingData.header.contentLogoURL, file],
    });
  };
  const deleteCarouselImg = idx => {
    let _list = [...roomSettingData.header.contentLogoURL];
    _list.splice(idx, 1);
    onChangeDataHeader({ contentLogoURL: _list });
  };

  const initSettingData = (detail = {}) => {
    const { __typename, ...props } = detail;
    const { header = {}, content = {}, banner = {} } = props;

    setRoomSettingData({
      ...props,
      header: {
        ...header,
        avatarURL: header?.avatarURL || '',
        title: header?.title || '',
        backgroundColor: header?.backgroundColor || theme.secondary,
        logoURL: header?.logoURL || '',
        isEnabled: handleInitEnabled(header.isEnabled),
        contentLogoURL: header?.contentLogoURL || [],
        contentLogoEnabled: handleInitEnabled(header.contentLogoEnabled),
        contentLogoPosition: header.contentLogoPosition || 'Right',
      },
      content: {
        ...content,
        backgroundColor: content?.backgroundColor || theme.primary,
        backgroundImageURL: content?.backgroundImageURL || '',
        boxBackgroundColor: content?.boxBackgroundColor || theme['gray-1'],
        boxBackgroundImage: content?.boxBackgroundImage || '',
      },
      banner: {
        ...banner,
        imageURL: banner?.imageURL || '',
        isEnabled: handleInitEnabled(banner.isEnabled),
        linkUrl: banner?.linkUrl || '',
      },
    });
  };

  // get CSRoom setting
  const { fetchData, loading } = useQueryApi(apiType.GET_CS_ROOM_SETTING, {
    context: { isShowGeneralError: false },
  });
  const fetchDataHandle = async consultingRoomOriginID => {
    const { error, data } = await fetchData({
      filter: { consultingRoomClientSetting: { consultingRoomOriginID } },
    });
    if (error || error?.message === '404001') {
      initSettingData(); // 尚未建立 使用預設
      setIsExist(false);
      return;
    }
    const { getConsultingRoomClientSetting } = data;
    initSettingData(getConsultingRoomClientSetting);
    setIsExist(true);
  };

  const onChangeOriginID = ({ consultingRoomOriginID }) => {
    setCurrCSRoomOriginID(consultingRoomOriginID);
    fetchDataHandle(consultingRoomOriginID);
  };

  useEffect(() => {
    fetchDataHandle(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingList = roomSettingData => [
    {
      title: '浏览器标签设置',
      items: [
        {
          type: 'file',
          title: '商标icon(16x16)',
          showDelete: true,
          options: {
            deleteImgParams: { header: { deleteAvatar: true } },
            beforeUpload: file =>
              onChangeDataHeader({
                avatar: file,
                avatarURL: getFileUrl(file),
              }),
          },
          extra: ({ header }) => (
            <>
              {header?.avatarURL && (
                <img
                  src={header.avatarURL}
                  alt='avatarURL'
                  style={{ width: '16px', height: '16px' }}
                />
              )}
            </>
          ),
        },
        {
          type: 'inputStr',
          title: '标题(预设为当前域名)',
          options: {
            value: roomSettingData.header.title,
            onChange: val => onChangeDataHeader({ title: val }),
          },
        },
      ],
    },
    {
      title: '咨询室版头设置',
      items: [
        {
          type: 'switch',
          title: '启用状态',
          options: {
            showDelete: true,
            checked: roomSettingData.header.isEnabled,
            onChange: val => onChangeDataHeader({ isEnabled: val }),
          },
        },
        {
          type: 'color',
          title: '背景颜色',
          options: {
            color: roomSettingData.header.backgroundColor,
            onChange: v => onChangeDataHeader({ backgroundColor: v }),
          },
        },
        {
          type: 'file',
          title: '商标图片',
          options: {
            showDelete: false,
            beforeUpload: file =>
              onChangeDataHeader({ logo: file, logoURL: getFileUrl(file) }),
          },
        },
      ],
    },
    {
      title: '咨询室横幅设置',
      items: [
        {
          type: 'switch',
          title: '启用状态',
          options: {
            checked: roomSettingData.banner.isEnabled,
            onChange: val => onChangeDataBanner({ isEnabled: val }),
          },
        },
        {
          type: 'file',
          title: '图片',
          options: {
            showDelete: false,
            beforeUpload: file =>
              onChangeDataBanner({ image: file, imageURL: getFileUrl(file) }),
          },
        },
        {
          type: 'inputStr',
          title: '链结',
          options: {
            value: roomSettingData.banner.linkUrl,
            onChange: val => onChangeDataBanner({ linkUrl: val }),
          },
        },
      ],
    },
    {
      title: '咨询室背景',
      items: [
        {
          type: 'color',
          title: '背景颜色',
          options: {
            color: roomSettingData.content.boxBackgroundColor,
            onChange: v => onChangeDataContent({ boxBackgroundColor: v }),
          },
        },
        {
          type: 'file',
          title: '商标图片',
          options: {
            showDelete: true,
            deleteImgParams: { content: { deleteBoxBackgroundImage: true } },
            beforeUpload: file =>
              onChangeDataContent({
                boxBackgroundImage: file,
                boxBackgroundImageURL: getFileUrl(file),
              }),
          },
        },
      ],
    },
    {
      title: '咨询室左右设置',
      items: [
        {
          type: 'switch',
          title: '启用状态',
          options: {
            checked: roomSettingData.header.contentLogoEnabled,
            onChange: val => onChangeDataHeader({ contentLogoEnabled: val }),
          },
        },
        {
          type: 'radio',
          title: '显示位置',
          options: {
            data: [
              { value: 'Left', label: '左' },
              { value: 'Right', label: '右' },
            ],
            value: roomSettingData.header.contentLogoPosition,
            onChange: val => onChangeDataHeader({ contentLogoPosition: val }),
          },
        },
        {
          type: 'file',
          title: '轮播图片',
          tip: '只设定一张不做轮播动作',
          showDelete: false,
          options: {
            accept: 'image/png',
            beforeUpload: handleCarousel,
          },
          extra: ({ header }) => {
            return (
              <>
                [
                {header.contentLogoURL.map((url, idx) => (
                  <Button
                    key={Math.round(Math.random() * 100)}
                    danger
                    type='link'
                    size='small'
                    onClick={() => deleteCarouselImg(idx)}>
                    删除
                  </Button>
                ))}
                ]
              </>
            );
          },
        },
      ],
    },
    {
      title: '背景设置',
      items: [
        {
          type: 'color',
          title: '背景颜色',
          options: {
            color: roomSettingData.content.backgroundColor,
            onChange: v => onChangeDataContent({ backgroundColor: v }),
          },
        },
        {
          type: 'file',
          title: '背景图片',
          tip: '背景图片会覆盖背景色',
          options: {
            showDelete: true,
            deleteImgParams: { content: { deleteBackgroundImage: true } },
            beforeUpload: file =>
              onChangeDataContent({
                backgroundImage: file,
                backgroundImageURL: getFileUrl(file),
              }),
          },
        },
      ],
    },
  ];

  return (
    <Row gutter={20} style={{ height: '100%' }}>
      <Col span={8} style={{ height: '100%' }}>
        <Card style={{ height: '100%' }}>
          <Spin spinning={originLoading || loading}>
            <Typography.Title level={5}>所属域名</Typography.Title>
            <Form
              form={originIDForm}
              initialValues={{ consultingRoomOriginID: 1 }}
              onValuesChange={onChangeOriginID}>
              <Form.Item name='consultingRoomOriginID'>
                <Select style={{ width: '100%' }}>
                  {originSelectList.map(el => (
                    <Select.Option key={el.value} value={el.value}>
                      {el.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <Divider />

            {!isExist && (
              <Alert
                message='尚未新增任何样式'
                type='warning'
                showIcon
                closable
                style={{ marginBottom: '12px' }}
              />
            )}

            {roomSettingData && (
              <>
                {settingList(roomSettingData).map((setting, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Typography.Title
                        level={5}
                        style={{ marginBottom: '12px' }}>
                        {setting.title}
                      </Typography.Title>
                      {setting.items.map((item, idx) => {
                        const { extra, type, options, render, ...props } = item;
                        if (type === 'cus') return render();
                        return (
                          <SettingItemRender
                            key={idx}
                            headerTxt={item.title}
                            {...props}
                            contentNode={
                              <>
                                {extra && extra(roomSettingData)}
                                {type === 'file' && (
                                  <FileUpload
                                    {...options}
                                    deleteImg={deleteImg}
                                  />
                                )}
                                {type === 'inputStr' && (
                                  <Input
                                    size='small'
                                    {...options}
                                    onChange={e =>
                                      options.onChange(e.target.value)
                                    }
                                  />
                                )}
                                {type === 'switch' && (
                                  <Switch {...options} size='small' />
                                )}
                                {type === 'color' && <ColorBtn {...options} />}
                                {type === 'radio' && <RadioBtn {...options} />}
                              </>
                            }
                          />
                        );
                      })}
                      <Divider />
                    </React.Fragment>
                  );
                })}
                <div style={{ marginTop: '16px' }}>
                  <Button
                    block
                    type='primary'
                    loading={cLdg || uLdg}
                    disabled={
                      !isExist
                        ? !authRoutesKey.includes(
                            'API_ConsultingRoomClientSetting_Create',
                          )
                        : !authRoutesKey.includes(
                            'API_ConsultingRoomClientSetting_Update',
                          )
                    }
                    onClick={clickSubmitHandle}>
                    保存
                  </Button>
                </div>
              </>
            )}
          </Spin>
        </Card>
      </Col>
      <Col span={16}>
        {roomSettingData && (
          <RoomDemo
            key={currCSRoomOriginID}
            headerData={roomSettingData.header}
            contentData={roomSettingData.content}
            bannerData={roomSettingData.banner}
          />
        )}
      </Col>
    </Row>
  );
}

const SettingItemRender = ({
  tip = null,
  headerTxt = '',
  contentNode = null,
  ...props
}) => (
  <Row justify='space-between' mb='2' {...props}>
    <Col>
      {tip ? (
        <Tooltip title={tip}>
          <Typography.Text>{headerTxt}</Typography.Text>
          <QuestionCircleOutlined />
        </Tooltip>
      ) : (
        <Typography.Text>{headerTxt}</Typography.Text>
      )}
    </Col>
    <Col>{contentNode}</Col>
  </Row>
);

const FileUpload = ({
  showDelete = false,
  deleteImgParams,
  beforeUpload,
  deleteImg,
  ...props
}) => {
  return (
    <>
      {showDelete && (
        <Button
          danger
          type='link'
          size='small'
          onClick={() => deleteImg(deleteImgParams)}>
          删除
        </Button>
      )}

      <Upload
        name='file'
        beforeUpload={file => {
          beforeUpload(file);
          return false;
        }}
        showUploadList={false}
        listType='picture'
        maxCount={1}
        accept='image/*'
        {...props}>
        <Button size='small' icon={<UploadOutlined />}>
          上传
        </Button>
      </Upload>
    </>
  );
};

const RadioBtn = ({ data = [], onChange = () => {}, value = '' }) => {
  return (
    <Radio.Group onChange={e => onChange(e.target.value)} value={value}>
      {data.map((item, idx) => (
        <Radio key={idx} value={item.value}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default CSRoomSettingMgm;
