import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutationApi, apiType } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import { EnableKey } from '@/utils';
import DeviceList from '@/components/User/DeviceList/List';
import useAlertMessage from '@/hook/useAlertMessage';

/**
 * 使用者裝置列表, 更新&刪除 裝置
 */
const UserDeviceList = ({ userID }) => {
  const { onSuccessMsg } = useAlertMessage();
  const [deviceList, setDeviceList] = useState([]);
  const { loading, refetch } = useActiveList({
    api: 'LIST_USER_DEVICE',
    originFilter: { userDevice: { userID } },
    options: {
      onCompleted: ({ listUserDevice }) =>
        setDeviceList(() => listUserDevice?.userDevices || []),
    },
  });

  const completedConfig = {
    onCompleted: () => {
      refetch();
      onSuccessMsg();
    },
  };
  const [updateDevice, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_USER_DEVICE,
    completedConfig,
  );
  const updateDeviceHandle = (checked, id) => {
    const isBind = checked ? EnableKey['Yes'] : EnableKey['No'];
    updateDevice({
      filter: { userDevice: { userID, id } },
      in: { isBind },
    });
  };

  const [deleteDevice, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_USER_DEVICE,
    completedConfig,
  );

  return (
    <DeviceList
      loading={loading || updateLoading}
      deleteLoading={deleteLoading}
      dataSource={deviceList}
      updateDevice={updateDeviceHandle}
      deleteDevice={id =>
        deleteDevice({
          filter: { userDevice: { userID, id } },
        })
      }
    />
  );
};

UserDeviceList.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default UserDeviceList;
