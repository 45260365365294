import React, { useState } from 'react';
import { PlusOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import useMerchant from '@/hook/useMerchant';
import useMe from '@/hook/useMe';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { formatDateTime } from '@/utils';
import MerchantSettingDrawer from './MerchantSettingDrawer';
import useAlertMessage from '@/hook/useAlertMessage';
import { useSelector } from 'react-redux';

function MerchantSetting() {
  const { isSystemType } = useMe();
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const {
    loading,
    pagination,
    merchantTableList,
    merchantTableTotal,
    refetch,
    handleChangePage,
  } = useMerchant({ originPagination: { perPage: 10 } });

  const { onSuccessMsg } = useAlertMessage();
  const [visible, setVisible] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const openDrawer = (data = null) => {
    setDetailData(data);
    setVisible(true);
  };

  // 新增
  const [createMerchant, { loading: createdLoading }] = useMutationApi(
    apiType.CREATE_MERCHANT,
    {
      onCompleted: () => refetch() && onSuccessMsg(),
    },
  );

  // 更新
  const [updateMerchant, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_MERCHANT,
    {
      onCompleted: () => refetch() && onSuccessMsg(),
    },
  );

  // 刪除
  const [deleteMerchant, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_MERCHANT,
    {
      onCompleted: () => refetch() && onSuccessMsg(),
    },
  );

  const columns = () => {
    let columns = [
      {
        title: '#ID',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        fixed: 'left',
      },
      {
        title: '商户类型',
        dataIndex: 'merchantType',
        key: 'merchantType',
      },
      {
        title: '商戶号',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '商户名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '商戶域名',
        dataIndex: 'host',
        key: 'host',
      },
      {
        title: '商戶密钥',
        dataIndex: 'secretKey',
        key: 'secretKey',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '建立时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: h => formatDateTime(h),
      },
      {
        title: '更新时间',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: h => formatDateTime(h),
      },
    ];
      columns.push({
        title: '动作',
        key: 'action',
        width: '10%',
        fixed: 'right',
        render: record => (
          <Space>
            {(isSystemType ||authRoutesKey.includes('API_SpinachPlatformSetting_Update')) && ( 
              <Button
                onClick={() => openDrawer(record)}
                type='link'
                icon={<EditTwoTone />}>
                </Button>
              )}
            {(isSystemType ||authRoutesKey.includes('API_SpinachPlatformSetting_Delete')) && (
              <BtnDeleteConfirm
                handleOk={() =>
                  deleteMerchant({
                    filter: { merchant: { id: record.id } },
                  })
                }
                loading={deleteLoading}
              />
            )}
          </Space>
        ),
      });
    return columns;
  };

  return (
    <>
      <DataTable
        columns={columns()}
        dataSource={merchantTableList}
        loading={loading}
        bordered={false}
        bodyStyle={{ padding: '0px' }}
        pagination={{ ...pagination, total: merchantTableTotal }}
        onChangePage={handleChangePage}>
        {/* 新建 */}
        {(isSystemType || authRoutesKey.includes('API_SpinachPlatformSetting_Create')) && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openDrawer()}>
            新建商戶
          </Button>
        )}
      </DataTable>
      <MerchantSettingDrawer
        visible={visible}
        setVisible={setVisible}
        detailData={detailData}
        updateMerchant={updateMerchant}
        createMerchant={createMerchant}
        loading={createdLoading || updateLoading}
      />
    </>
  );
}

export default MerchantSetting;
