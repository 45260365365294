import React from 'react';
import { Box, Row, Col } from '@WRAP_COMP';

const PageWrap = ({ children, bgColor = '', ...props }) => {
  return (
    <Row height='100%'>
      <Col xs={24} md={24} lg={24} xl={24} xxl={16}>
        <Box
          height='100%'
          display='flex'
          flexDirection='column'
          bgColor={bgColor}
          {...props}>
          {children}
        </Box>
      </Col>
    </Row>
  );
};

export default PageWrap;
