import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { EditTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { useActiveList } from '@/hook/useList';
import { useMutationApi, apiType } from '@/hook/useApi';
import {
  Empty,
  Divider,
  Collapse,
  Button,
  Input,
  List,
  Space,
  Typography,
  Spin,
} from 'antd';
import { Box } from '@/plugin/wrap-comp';
import PageWrap from '@/components/PageWrap';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import useAlertMessage from '@/hook/useAlertMessage';
import { formatDateTime, cloneDeep } from '@/utils';
import CreatedForm from './CreatedForm';

const originFilter = { resCatList: {}, resList: {} };
export default function CannedResponseMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { onSuccessMsg } = useAlertMessage();
  const [dataSource, setDataSource] = useState([]);
  const dataHandle = ({ listCannedResponseCategory, listCannedResponse }) => {
    let tempCatList = cloneDeep(
      listCannedResponseCategory.cannedResponseCategories,
    );
    let tempList = cloneDeep(listCannedResponse.cannedResponses);
    const tempDataSource = tempList.reduce((catList, curr) => {
      catList.forEach(catItem => {
        catItem.list = catItem.list || [];
        catItem.id === curr.categoryID &&
          (catItem.list = [...catItem.list, curr]);
      });
      return catList;
    }, tempCatList);
    setDataSource(tempDataSource);
  };
  const { loading, refetch } = useActiveList({
    api: 'LIST_CANNED',
    cusFilter: { ...originFilter },
    options: { onCompleted: dataHandle },
  });

  // 新增
  const [createdCannedCat, { loading: createdCatLdg }] = useMutationApi(
    apiType.CREATE_CANNED_RES_CAT,
  );
  const [createdCanned, { loading: createdLdg }] = useMutationApi(
    apiType.CREATE_CANNED_RES,
  );
  const createdHandle = async ({ content }, categoryID, form) => {
    const createdCallback = () => {
      form.resetFields();
      onSuccessMsg('new');
      refetch();
    };
    if (categoryID) {
      const [err] = await createdCanned({ in: { categoryID, text: content } });
      !err && createdCallback();
    } else {
      const [err] = await createdCannedCat({ in: { name: content } });
      !err && createdCallback();
    }
  };

  // 更新
  const [curCatEditId, setCurCatEditId] = useState(0);
  const [curEditId, setCurEditId] = useState(0);
  const editInputEl = useRef(null);
  const updateCallback = () => {
    onSuccessMsg('update');
    refetch();
    setCurEditId(0);
    setCurCatEditId(0);
  };
  const [updateCannedCat] = useMutationApi(apiType.UPDATE_CANNED_RES_CAT, {
    onCompleted: () => updateCallback(),
  });
  const [updateCanned] = useMutationApi(apiType.UPDATE_CANNED_RES, {
    onCompleted: () => updateCallback(),
  });
  const updateHandle = ({ id }, isCat) => {
    isCat
      ? updateCannedCat({
          filter: { cannedResponseCategory: { id } },
          in: { name: editInputEl.current.state.value },
        })
      : updateCanned({
          filter: { cannedResponse: { id } },
          in: { text: editInputEl.current.state.value },
        });
  };
  const updateBtnRender = ({ id }, isCat) => {
    const isEdit = curCatEditId === id || curEditId === id;
    return (
      <Button
        key='edit'
        type='link'
        icon={isEdit ? <CheckCircleTwoTone /> : <EditTwoTone />}
        onClick={e => {
          e.stopPropagation();
          isCat ? setCurCatEditId(id) : setCurEditId(id);
          isEdit && updateHandle({ id }, isCat);
        }}
      />
    );
  };

  // 刪除
  const deleteCallback = () => {
    onSuccessMsg('delete');
    refetch();
  };
  const [deleteCannedCat, { loading: deleteCatLoading }] = useMutationApi(
    apiType.DELETE_CANNED_RES_CAT,
    {
      onCompleted: deleteCallback,
    },
  );
  const [deleteCanned, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_CANNED_RES,
    {
      onCompleted: deleteCallback,
    },
  );

  const deleteBtnRender = ({ id }, isCat) => {
    return (
      <BtnDeleteConfirm
        key='delete'
        handleOk={e => {
          e.stopPropagation();
          isCat
            ? deleteCannedCat({
                filter: { cannedResponseCategory: { id }, forceDelete: true },
              })
            : deleteCanned({
                filter: { cannedResponse: { id } },
              });
        }}
        loading={deleteCatLoading || deleteLoading}
        placement='topRight'
      />
    );
  };

  const titleRender = ({ id, createdAt, name, text }, editId) => {
    return (
      <Space direction='vertical'>
        {editId === id ? (
          <Input
            ref={editInputEl}
            type='text'
            defaultValue={name || text}
            onClick={e => e.stopPropagation()}
            onPressEnter={() => updateHandle({ id }, !!name)}
          />
        ) : (
          <Typography.Text strong>{name || text}</Typography.Text>
        )}
        <Typography.Text type='secondary'>
          建立時間：{formatDateTime(createdAt)}
        </Typography.Text>
      </Space>
    );
  };

  return (
    <PageWrap bgColor='white'>
      <Box p='4'>
        <CreatedForm createdHandle={createdHandle} loading={createdCatLdg} />
        <Divider />

        {/* <Input
          placeholder="搜寻"
          prefix={<SearchOutlined />}
          style={{ marginBottom: '16px' }}
        /> */}

        <Spin spinning={loading}>
          {dataSource.length ? (
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              style={{ backgroundColor: 'white' }}>
              {dataSource.map(cat => (
                <Collapse.Panel
                  header={titleRender(cat, curCatEditId)}
                  key={cat.id}
                  extra={
                    <>
                      {authRoutesKey.includes('API_CannedResponse_Update') &&
                        updateBtnRender({ id: cat.id }, true)}
                      <Divider type='vertical' />
                      {authRoutesKey.includes('API_CannedResponse_Delete') &&
                        deleteBtnRender({ id: cat.id }, true)}
                    </>
                  }>
                  <>
                    <CreatedForm
                      categoryID={cat.id}
                      createdHandle={createdHandle}
                      loading={createdLdg}
                    />
                    <List
                      dataSource={cat.list}
                      renderItem={item => (
                        <List.Item
                          actions={[
                            authRoutesKey.includes(
                              'API_CannedResponse_Update',
                            ) && updateBtnRender(item, false),
                            authRoutesKey.includes(
                              'API_CannedResponse_Delete',
                            ) && deleteBtnRender(item, false),
                          ]}>
                          {titleRender(item, curEditId)}
                        </List.Item>
                      )}
                    />
                  </>
                </Collapse.Panel>
              ))}
            </Collapse>
          ) : (
            <Empty description={false} style={{ paddingTop: '40px' }} />
          )}
        </Spin>
      </Box>
    </PageWrap>
  );
}
