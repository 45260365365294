import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  PieChartOutlined,
  MessageOutlined,
  UserOutlined,
  TeamOutlined,
  SettingOutlined,
  MailOutlined,
  LineChartOutlined,
  CustomerServiceOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Badge } from 'antd';
import { Box } from '@WRAP_COMP';
import { isDefaultKey, authMenu } from '@/hook/useAuthRoutes';
import WsStatusNotify from '@/components/WsStatusNotify';

const iconRender = {
  Dashboard: <PieChartOutlined />,
  Chatroom_System: <MessageOutlined />,
  Employee_System: <UserOutlined />,
  Member_System: <TeamOutlined />,
  CustomerService_System: <CustomerServiceOutlined />,
  Operating_System: <SettingOutlined />,
  Message_System: <MailOutlined />,
  Report_System: <LineChartOutlined />,
  Surveillance_System: <EyeOutlined />,
};
const menuItemRender = ({ path, title }, redPoint = null) => (
  <Menu.Item key={`/${path}`} icon={iconRender[path]}>
    <Link to={path}>{title}</Link>
    {redPoint}
  </Menu.Item>
);

const Sidebar = ({ collapsed = false, setCollapsed = () => {} }) => {
  const { redPoint } = useSelector(({ room }) => room);
  const redPointRender = useCallback(
    key =>
      redPoint[key]?.isShow && (
        <Badge color='red' dot style={{ marginLeft: '8px' }} />
      ),
    [redPoint],
  );

  const { pathname } = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = items => setOpenKeys(items);
  useEffect(() => {
    // 取得 menu item 父層 並展開
    setOpenKeys([`${pathname.substring(1, pathname.lastIndexOf('/'))}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const authMenuRender = useCallback(() => {
    return authMenu.map(menu => {
      if (isDefaultKey(menu.path)) return null;
      if (!authRoutesKey.includes(menu.path)) return null;
      return !menu.children ? (
        menuItemRender({ path: menu.path, title: menu.title })
      ) : (
        <Menu.SubMenu
          key={menu.path}
          title={
            <>
              {menu.title}
              {redPointRender(menu.path)}
            </>
          }
          icon={iconRender[menu.path]}>
          {menu.children.map(sub =>
            sub.path && authRoutesKey.includes(sub.path)
              ? menuItemRender(
                  { path: `${menu.path}/${sub.path}`, title: sub.title },
                  redPointRender(sub.key),
                )
              : null,
          )}
        </Menu.SubMenu>
      );
    });
  }, [redPointRender, authRoutesKey]);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint='xxl'
      onBreakpoint={broken => setCollapsed(broken)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}>
      <Box height='100%' display='flex' flexDirection='column'>
        <Box textAlign='center' fontSize={20} p='3' color='white'>
          Bochat Admin
        </Box>
        <Menu
          theme='dark'
          defaultSelectedKeys={['/']}
          selectedKeys={pathname}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode='inline'>
          {authMenuRender()}
        </Menu>
        <Box mt='auto' p='3'>
          <WsStatusNotify collapsed={collapsed} />
        </Box>
      </Box>
    </Layout.Sider>
  );
};

export default Sidebar;
