import React, { useEffect, useState } from 'react';
import { Drawer, Form, Input, Select } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import CSSelectTable from '@/components/Table/CSSelectTable';

const defaultForm = { remark: '' };
function OriginSettingDrawer({
  visible = false,
  merchantSelectList = [],
  detailData = null,
  setVisible = () => {},
  createOrigin = () => {},
  updateOrigin = () => {},
  loading = false,
}) {
  const [originForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  const [visibleServiceDrawer, setVisibleServiceDrawer] = useState(false);
  const visibleServiceDrawerHandle = () => setVisibleServiceDrawer(pre => !pre);

  const [selected, setSelected] = useState({ keys: [], rows: [] });
  const selectChangeHandle = (keys, rows) => setSelected({ keys, rows });
  const selectSubmit = (keys, rows) => {
    setSelected({ keys, rows });
    visibleServiceDrawerHandle();
  };

  const closeDrawer = async () => {
    await originForm.resetFields();
    setSelected({ keys: [], rows: [] });
    setVisible(false);
  };

  const submitHandle = async fields => {
    if (isEdit) {
      await updateOrigin({
        filter: { consultingRoomOrigin: { id: detailData.id } },
        in: { ...fields, customerServiceIDs: selected.keys },
      });
    } else {
      await createOrigin({
        in: { ...fields, customerServiceIDs: selected.keys },
      });
    }
    await closeDrawer();
  };

  useEffect(() => {
    const initDrawer = async () => {
      if (detailData) {
        const keys = detailData.customerServices.map(c => c.id) || [];
        const rows = detailData.customerServices || [];
        setSelected({ keys, rows });
        setIsEdit(true);
        await originForm.setFieldsValue({ ...detailData });
      } else {
        setIsEdit(false);
        await originForm.setFieldsValue({ ...defaultForm });
      }
    };
    visible && merchantSelectList.length && initDrawer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, merchantSelectList]);

  return (
    <Drawer
      visible={visible}
      title='新增域名'
      width={450}
      onClose={closeDrawer}>
      <Form form={originForm} layout='vertical' onFinish={submitHandle}>
        <Form.Item name='merchantID' label='平台号'>
          <Select placeholder='请选择'>
            {merchantSelectList.map(el => {
              return (
                <Select.Option key={el.value} value={el.value}>
                  {el.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='origin'
          label='域名'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='请输入' />
        </Form.Item>
        <Form.Item
          label='自动轮派客服'
          rules={[{ required: true, message: '必填' }]}>
          {selected.rows.length > 0 &&
            selected.rows.map(item => <p key={item.id}>{item.username}</p>)}
          <Button
            type='link'
            size='small'
            onClick={visibleServiceDrawerHandle}
            loading={loading}>
            + 新增客服
          </Button>
        </Form.Item>
        <Form.Item name='remark' label='备注'>
          <Input placeholder='请输入' />
        </Form.Item>
        <Button variant='primary' htmlType='submit' loading={loading}>
          提交
        </Button>
      </Form>
      <Drawer
        title='选择轮派客服'
        width={560}
        closable={false}
        visible={visibleServiceDrawer}
        onClose={visibleServiceDrawerHandle}>
        {visibleServiceDrawer && (
          <CSSelectTable
            selectedKeys={selected.keys}
            selectedRows={selected.rows}
            selectChangeHandle={selectChangeHandle}
            submit={selectSubmit}
          />
        )}
      </Drawer>
    </Drawer>
  );
}

export default OriginSettingDrawer;
