import React, { useEffect, useState, useCallback } from 'react';
import { Button } from '@/plugin/wrap-comp';
import { useQueryApi, apiType } from '@/hook/useApi';
import DataTable from '@/components/Table/DataTable';
import { AccountTypeKeys } from '@/utils';
const pagination = { page: 1, perPage: 9999 };

function SelectCSTable({
  selectedKeys = [],
  selectedRows = [],
  selectChangeHandle = () => {},
  submit = () => {},
  loading = false,
}) {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);

  const { fetchData, loading: listLoading } = useQueryApi(apiType.LIST_USER);
  const fetchDataHandle = useCallback(
    async (params = {}) => {
      const { data, error } = await fetchData({
        filter: {
          user: {
            accountType: AccountTypeKeys['CustomerService'],
            isDelete: 'No',
            ...params,
          },
          baseFilter: { sortField: 'id', sortType: 'DESC' },
        },
        pagination,
      });
      if (error) return;
      setDataSource(data.listUser?.users || []);
      setTotal(data.listUser?.meta?.total || 0);
    },
    [fetchData],
  );

  const submitHandle = () => {
    submit(selectedKeys, selectedRows);
  };

  useEffect(() => {
    fetchDataHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '帐号',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '別名',
      dataIndex: 'aliasName',
      key: 'aliasName',
      render: h => h || '-',
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        dataSource={dataSource}
        pagination={{ ...pagination, total }}
        loading={loading || listLoading}
        rowSelection={{
          type: 'checkbox',
          onChange: selectChangeHandle,
          selectedRowKeys: selectedKeys,
        }}
        bodyStyle={{ padding: '0px' }}
        bordered={false}
      />
      <Button variant='primary' onClick={submitHandle}>
        确认
      </Button>
    </>
  );
}

export default SelectCSTable;
