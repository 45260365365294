import React from 'react';
import { Row, Col, Spin } from 'antd';
import PageWrap from '@/components/PageWrap';
import { useActiveList } from '@/hook/useList';
import { PlatFormSettingKey } from '@/utils';

import BeepSetting from './BeepSetting';
import AutoDistribute from './AutoDistribute';
import AutoDeactivate from './AutoDeactivate';
import AutoCreatedCS from './AutoCreatedCS';
import QueueMaxCount from './QueueMaxCount';
import DialogueTimeout from './DialogueTimeout';
import NoResponse from './NoResponse';
import RedirectVerify from './RedirectVerify';

const {
  BEEP, // 提示音
  AUTO_DISTRIBUTE, // 自動輪派
  AUTO_DEACTIVATE_ENABLED, // 自動封存啟用
  AUTO_DEACTIVATE_MIN, // 自動封存分數
  DEACTIVATE_TIP_ENABLED, // 封存提示訊息 啟用
  DEACTIVATE_TIP_MANUAL, // 手動封存提示訊息
  DEACTIVATE_TIP_AUTO, // 自動封存提示訊息
  AUTO_CREATED_CS, // 自動進線
  QUEUE_MAX_COUNT, // 進線排隊
  DIALOGUE_TIMEOUT_SEC, // 對話超時提醒
  DIALOGUE_TIMEOUT_SEC_ENABLED, // 對話超時提醒 起用
  NO_RESPONSE_ENABLED, // 無回覆 自動發訊息 啟用
  NO_RESPONSE_SEC, // 無回覆 自動發訊息 秒數
  NO_RESPONSE_TIP, // 無回覆 自動發訊息
  REDIRECT_VERIFY_ENABLED, // 检测设置 啟用
} = PlatFormSettingKey;

function PlatformSettingMgm() {
  const {
    data: { list },
    loading,
  } = useActiveList({
    api: 'LIST_PLATFORM_SETTING',
    cusFilter: {},
    originPagination: { perPage: 999 },
    handleData: ({ listPlatformSetting }) => ({
      list: listPlatformSetting?.PlatformSettings || [],
    }),
  });
  const settingCardData = (settingList = []) => {
    if (!settingList.length) return;
    let itemCard = [];
    const itemCardsConfig = [
      {
        key: 'beep',
        title: '訊息提示音設置',
        configKeys: [BEEP],
        configKeysValue: {},
        content: props => <BeepSetting {...props} />,
      },
      {
        key: 'auto_deactivate',
        title: '封存咨询房',
        configKeys: [
          AUTO_DEACTIVATE_ENABLED,
          AUTO_DEACTIVATE_MIN,
          DEACTIVATE_TIP_ENABLED,
          DEACTIVATE_TIP_MANUAL,
          DEACTIVATE_TIP_AUTO,
        ],
        configKeysValue: {},
        content: props => (
          <AutoDeactivate
            {...props}
            AUTO_DEACTIVATE_MIN={AUTO_DEACTIVATE_MIN}
            AUTO_DEACTIVATE_ENABLED={AUTO_DEACTIVATE_ENABLED}
            DEACTIVATE_TIP_ENABLED={DEACTIVATE_TIP_ENABLED}
            DEACTIVATE_TIP_MANUAL={DEACTIVATE_TIP_MANUAL}
            DEACTIVATE_TIP_AUTO={DEACTIVATE_TIP_AUTO}
          />
        ),
      },
      {
        key: 'redirect_verify',
        title: '检测设置',
        configKeys: [REDIRECT_VERIFY_ENABLED],
        configKeysValue: {},
        content: props => (
          <RedirectVerify
            {...props}
            REDIRECT_VERIFY_ENABLED={REDIRECT_VERIFY_ENABLED}
          />
        ),
      },
      {
        key: 'auto_distribute',
        title: '自动轮派咨询单',
        configKeys: [AUTO_DISTRIBUTE],
        configKeysValue: {},
        content: props => (
          <AutoDistribute {...props} AUTO_DISTRIBUTE={AUTO_DISTRIBUTE} />
        ),
      },
      {
        key: 'enter_directly',
        title: '自动进线',
        configKeys: [AUTO_CREATED_CS],
        configKeysValue: {},
        content: props => (
          <AutoCreatedCS {...props} AUTO_CREATED_CS={AUTO_CREATED_CS} />
        ),
      },
      {
        key: 'dialogue_time_out',
        title: '对话超时提醒',
        configKeys: [DIALOGUE_TIMEOUT_SEC, DIALOGUE_TIMEOUT_SEC_ENABLED],
        configKeysValue: {},
        content: props => (
          <DialogueTimeout
            {...props}
            DIALOGUE_TIMEOUT_SEC={DIALOGUE_TIMEOUT_SEC}
            DIALOGUE_TIMEOUT_SEC_ENABLED={DIALOGUE_TIMEOUT_SEC_ENABLED}
          />
        ),
      },
      {
        key: 'no_response',
        title: '客服无应答时消息',
        configKeys: [NO_RESPONSE_ENABLED, NO_RESPONSE_SEC, NO_RESPONSE_TIP],
        configKeysValue: {},
        content: props => (
          <NoResponse
            {...props}
            NO_RESPONSE_ENABLED={NO_RESPONSE_ENABLED}
            NO_RESPONSE_SEC={NO_RESPONSE_SEC}
            NO_RESPONSE_TIP={NO_RESPONSE_TIP}
          />
        ),
      },
      {
        key: 'max_processing_count',
        title: '进线排队设定',
        configKeys: [QUEUE_MAX_COUNT],
        configKeysValue: {},
        content: props => (
          <QueueMaxCount {...props} QUEUE_MAX_COUNT={QUEUE_MAX_COUNT} />
        ),
      },
    ];
    itemCardsConfig.forEach(el => {
      el.configKeys.forEach(ck => {
        const idx = settingList.findIndex(s => s.key === ck);
        if (idx > 0) el.configKeysValue[ck] = settingList[idx];
      });
      itemCard.push({ ...el });
    });
    return itemCard;
  };

  return (
    <PageWrap>
      <Spin spinning={loading}>
        <Row gutter={20}>
          {!loading && (
            <>
              {settingCardData(list).map(item => (
                <Col span={24} key={item.key} style={{ marginBottom: '8px' }}>
                  {item.content({
                    title: item.title,
                    configKeysValue: { ...item.configKeysValue },
                  })}
                </Col>
              ))}
            </>
          )}
        </Row>
      </Spin>
    </PageWrap>
  );
}

export default PlatformSettingMgm;
