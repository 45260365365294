import React, { useState, useEffect } from 'react';
import { EnvironmentFilled, CompassFilled } from '@ant-design/icons';

import useOrigin from '@/hook/useOrigin';
import { Descriptions, Input } from 'antd';
import { formatDateTime, RoomStatusDisplay, deviceOSKeys } from '@/utils';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';

const { theme } = require('@/plugin/theme');
const iconStyle = { color: theme.primary, marginRight: '4px' };

function RoomDetailDesc({
  roomID = 0,
  status = '',
  createdAt = '',
  updatedAt = '',
  accessDomain = '',
  sourceDomain = '',
  ipAddress = '',
  country = '',
  administrativeArea = '',
  deviceOS = '',
  question = '',
  remark = '',
}) {
  const { accessDomainTextFormate } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });
  const { onSuccessMsg } = useAlertMessage();
  const [updateRemark] = useMutationApi(apiType.UPDATE_ROOM_REMARK);
  const [remarkVal, setRemarkVal] = useState(remark);
  useEffect(() => {
    setRemarkVal(remark);
  }, [remark]);

  const onBlurRemark = async () => {
    const [err] = await updateRemark({
      in: { roomID, remark: remarkVal },
    });
    if (err) return;
    onSuccessMsg();
  };

  return (
    <>
      <Descriptions size='small' column={1} style={{ marginTop: '8px' }}>
        <Descriptions.Item label='房间ID'>#{roomID || '-'}</Descriptions.Item>
        <Descriptions.Item label='房间IP'>
          <EnvironmentFilled style={iconStyle} />
          {ipAddress || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='房间地區'>
          <CompassFilled style={iconStyle} />
          {country || '-'} {administrativeArea || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='使用装置'>
          {deviceOSKeys[deviceOS]?.icon(iconStyle)}
          {deviceOSKeys[deviceOS]?.text || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='访问域名'>
          {accessDomainTextFormate(accessDomain)}
        </Descriptions.Item>
        <Descriptions.Item label='来源域名'>
          {sourceDomain || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='问题资讯'>
          {question || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='房间状态'>
          {status ? RoomStatusDisplay[status]?.text : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='建立时间'>
          {createdAt ? formatDateTime(createdAt) : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='更新时间'>
          {updatedAt ? formatDateTime(updatedAt) : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='备注'>
          <Input.TextArea
            value={remarkVal}
            onChange={e => setRemarkVal(e.target.value)}
            onBlur={onBlurRemark}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default RoomDetailDesc;
