import React from 'react';
import PropTypes from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
const QnsTooltip = ({ title = '', tip = '' }) => {
  return (
    <Tooltip title={tip}>
      {title}
      <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
    </Tooltip>
  );
};

QnsTooltip.propTypes = {
  title: PropTypes.string,
  tip: PropTypes.string,
};
export default QnsTooltip;
