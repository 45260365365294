import React, { useState } from 'react';
import { Layout, Spin } from 'antd';
import ReceiveMsgLogDrawer from '@/components/Drawer/ReceiveMsgLogDrawer';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
export default function Admin({ children, loading }) {
  // 控制 sideBar 收起
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = c => setCollapsed(c);

  return (
    <Spin spinning={loading}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
          <Navbar collapsed={collapsed} onCollapse={onCollapse} />
          <ReceiveMsgLogDrawer />
          <Layout>
            {children}
            <Layout.Footer
              style={{
                padding: '0px',
                textAlign: 'center',
                height: '30px',
              }}>
              BoChat-admin ©2021 Created by Miyo
            </Layout.Footer>
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
}
