import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import { Button, Drawer, Spin } from 'antd';
import { setVisibleDrawer } from '@/store/global';
import { setRoomDetail } from '@/store/room';
import { Box } from '@WRAP_COMP';
import useRoomMessage from '@/hook/useRoomMessage';
import MsgList from '@/components/MsgList';
import ChatRoomContent from '@/components/ChatRoomContent';
import InfoTabsBlock from './InfoTabsBlock';
const DRAWER_NAME = 'ViewRoomDrawer';

const ViewDrawer = ({
  title = '查核聊天內容',
  loading = false,
  onChangeRoom = () => {},
}) => {
  const dispatch = useDispatch();
  const { currentRoomID, roomDetail, roomUsersMap } = useSelector(
    ({ room }) => room,
  );
  const visibleData = useSelector(({ global }) => global.visibleDrawer);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    visibleData.name === DRAWER_NAME && setVisible(visibleData.visible);
  }, [visibleData]);

  const {
    roomMsg,
    setRoomMsgHandle,
    fetchRoomMsgHandle,
    loadMoreMsg,
    loadPreRoomMsg,
    lastReadID,
  } = useRoomMessage({
    currentRoomID,
    currUserRooms: roomDetail?.room.userRooms || [],
    currHistoryRooms: roomDetail?.room.historyRooms || [],
  });

  useEffect(() => {
    if (visible && roomDetail) fetchRoomMsgHandle(roomDetail.room.roomID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, roomDetail]);

  const closeDrawer = () => {
    setRoomMsgHandle('RESET');
    dispatch(setRoomDetail(null));
    dispatch(setVisibleDrawer({ name: DRAWER_NAME, visible: false }));
  };

  const onChangeRoomHandle = v => {
    setRoomMsgHandle('RESET');
    onChangeRoom(v);
  };

  return (
    <Drawer
      visible={visible}
      onClose={closeDrawer}
      title={title}
      width={1000}
      placement='right'
      footer={
        <Box display='flex' justifyContent='center'>
          <Button
            type='text'
            icon={
              <LeftCircleTwoTone
                style={{ fontSize: '20px' }}
                onClick={() => onChangeRoomHandle(false)}
              />
            }
          />
          <Button
            type='text'
            icon={
              <RightCircleTwoTone
                style={{ fontSize: '20px' }}
                onClick={() => onChangeRoomHandle(true)}
              />
            }
          />
        </Box>
      }>
      <Box height='100%' position='relative'>
        {loading && (
          <Box
            position='absolute'
            zIndex='3'
            top='0'
            bottom='0'
            right='0'
            left='0'
            display='flex'
            justifyContent='center'
            alignItems='center'
            bgColor='rgb(255 255 255 / 50%);'>
            <Spin spinning={true} />
          </Box>
        )}
        {roomDetail && (
          <Box display='flex' height='100%'>
            <Box flex='0 0 50%' display='flex'>
              <ChatRoomContent
                roomID={currentRoomID}
                roomMsgLength={roomMsg.length}
                loadMoreMsg={loadMoreMsg}
                loadPreRoomMsg={loadPreRoomMsg}>
                <MsgList
                  roomMsg={roomMsg}
                  roomID={currentRoomID}
                  lastReadID={lastReadID}
                  usersMap={roomUsersMap}
                  setRoomMsgHandle={setRoomMsgHandle}
                />
              </ChatRoomContent>
            </Box>
            <InfoTabsBlock
              room={roomDetail.room}
              ownerUserData={roomDetail.ownerUserData}
              roomUsersMap={roomUsersMap}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

ViewDrawer.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  roomDetail: PropTypes.object,
  roomUsers: PropTypes.object,
  title: PropTypes.string,
  footer: PropTypes.node,
};
export default ViewDrawer;
