import React from 'react';
import { Box } from '@WRAP_COMP';
import { Empty, Row, Col } from 'antd';
import RoomView from './RoomView';
function RoomViewList({
  isShowAll = false,
  merchantList = [],
  csRoomList = [],
  clickCurrRoom = () => {},
}) {
  const roomViewRender = ({ roomItem }) => {
    return (
      <Col
        xl={6}
        lg={8}
        key={roomItem.room.roomID}
        onClick={() => clickCurrRoom(roomItem)}
        style={{ cursor: 'pointer' }}>
        <RoomView
          roomID={roomItem.room.roomID}
          accessDomain={roomItem.room.accessDomain}
          userRooms={roomItem.userRooms}
          ownerUserData={roomItem.ownerUserData}
        />
      </Col>
    );
  };
  return (
    <>
      {csRoomList.length > 0 ? (
        <Row gutter={8}>
          {isShowAll &&
            merchantList.map(mr =>
              csRoomList.map(roomItem => {
                return roomItem.room.merchantID === mr.id
                  ? roomViewRender({ roomItem })
                  : null;
              }),
            )}
          {!isShowAll &&
            csRoomList.map(roomItem => roomViewRender({ roomItem }))}
        </Row>
      ) : (
        <Box
          height='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'>
          <Empty description={false} />
        </Box>
      )}
    </>
  );
}

export default RoomViewList;
