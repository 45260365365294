import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import BtnTooltip from './BtnTooltip';

/**
 * 刪除警告 button + Popconfirm
 * @param {Function} handleOk () => deleteFunc()
 */
const BtnDeleteConfirm = ({ handleOk, loading, btnProps, ...props }) => {
  const [visible, setVisible] = useState(false);
  const confirmHandle = e => {
    handleOk(e);
    setVisible(false);
  };

  return (
    <Popconfirm
      title='确认删除?'
      visible={visible}
      onConfirm={e => confirmHandle(e)}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading }}
      okText='确认'
      cancelText='取消'
      {...props}>
      <BtnTooltip
        onClick={e => {
          e.stopPropagation();
          setVisible(true);
        }}
        tipType='delete'
        {...btnProps}
      />
    </Popconfirm>
  );
};

BtnDeleteConfirm.propTypes = {
  handleOk: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
export default BtnDeleteConfirm;
