import React, { useState, useEffect, useRef } from 'react';
import { Typography } from 'antd';
import QRCode from 'qrcode-react';
import { IS_CLIENT } from '@/const';
import { Box, Divider, Button } from '@WRAP_COMP';
import {
  PayType,
  PayTypeImg,
  PayMethod,
  OrderStatusKeys,
  getNow,
} from '@UTILS';
import useRecMessage from '@/hook/useRecMessage';
import useOrder from '@/hook/useOrder';
import { CopyBtn } from '@/hook/useCopy';
import { fullScreenHandle } from '@/hook/useBrowser';
import ClientDrawerWrap from '@/components/Drawer/ClientDrawerWrap';
import ClientOrderDetailDrawer from '@/components/Drawer/ClientOrderDetailDrawer';

import alipayProcessUrl from '@/assets/alipay-process.jpg';
import wxProcessUrl from '@/assets/wx-process.jpg';
import { Icon } from '../PayList/Icons';

const InfoItem = ({ label = '', value = '', isBlue = false }) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      my='2'>
      <Box fontSize='14px' mr='1'>
        <Typography.Text style={{ color: '#7f7f7f' }}>
          {label}：
        </Typography.Text>
        <Typography.Text style={{ color: isBlue ? '#1591dc' : '#333333' }}>
          {value}
        </Typography.Text>
      </Box>
      <CopyBtn value={value} />
    </Box>
  );
};

function BankCardContent({
  bankName,
  bankCode,
  branchName,
  cardNo,
  accountName,
  amount,
  orderNo,
  onClickAlipay,
  onClickWx,
}) {
  const _cardNo = cardNo.replace(/\s*/g, '');
  return (
    <>
      <Box p='2' pt='0'>
        <InfoItem label='订单编号' value={orderNo} isBlue />
        <InfoItem label='收款姓名' value={accountName} isBlue />
        <InfoItem label='收款银行' value={`${bankName}(${bankCode})`} isBlue />
        <InfoItem label='支行名称' value={branchName} isBlue />
        <InfoItem label='收款卡号' value={_cardNo} isBlue />
        <InfoItem label='入款金额' value={amount} isBlue />
      </Box>
      <Divider style={{ margin: '12px 0' }} />
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Button
          size='small'
          type='link'
          style={{ fontSize: '12px', color: '#1591dc' }}
          onClick={onClickAlipay}>
          <img
            src={PayTypeImg['alipay'] || ''}
            alt={'alipay'}
            style={{ width: '15px', marginRight: '2px' }}
          />
          支付宝转银行卡教程
        </Button>
        <Divider type='vertical' style={{ margin: 0, height: '24px' }} />
        <Button
          size='small'
          type='link'
          style={{ fontSize: '12px', color: '#1591dc' }}
          onClick={onClickWx}>
          <img
            src={PayTypeImg['wx'] || ''}
            alt={'alipay'}
            style={{ width: '15px', marginRight: '2px' }}
          />
          微信转银行卡教程
        </Button>
      </Box>
    </>
  );
}
function AliCardContent({
  bankName,
  bankCode,
  branchName,
  cardNo,
  accountName,
  amount,
  orderNo,
  onClickAlipay,
  onClickWx,
}) {
  const _cardNo = cardNo.replace(/\s*/g, '');
  return (
    <>
      <Box p='2' pt='0'>
        <InfoItem label='订单编号' value={orderNo} isBlue />
        <InfoItem label='收款姓名' value={accountName} isBlue />
        <InfoItem label='收款账户' value={_cardNo} isBlue />
        <InfoItem label='入款金额' value={amount} isBlue />
      </Box>
      <Divider style={{ margin: '12px 0' }} />
    </>
  );
}

function QRcodeContent({
  url,
  orderNo,
  amount,
  qUrl,
  accountName,
  account,
  payType,
}) {
  return (
    <Box p='2' pt='0'>
      <InfoItem label='订单编号' value={orderNo} />
      {!!accountName && <InfoItem label='收款姓名' value={accountName} />}
      {!!account && <InfoItem label='收款账号' value={account} />}
      <InfoItem label='入款金额' value={amount} />
      <Box textAlign='center'>
        {!!url && <QRCode value={url} size={200} />}
        {!!qUrl && (
          <img src={qUrl} alt='WithdrawAddressUrl' style={{ width: '200px' }} />
        )}
        <Box mt='2'>
          <Typography.Text style={{ color: 'grey' }}>使用</Typography.Text>
          <Icon
            payType={payType}
            style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }}
          />
          <Typography.Text style={{ color: 'grey' }}>扫描支付</Typography.Text>
        </Box>
      </Box>
    </Box>
  );
}

function RedirectContent({ url, orderNo, amount, payType, status }) {
  return (
    <Box px='2' textAlign='center'>
      <InfoItem label='订单编号' value={orderNo} />
      <InfoItem label='入款金额' value={amount} isBlue />
      <Box textAlign='center' mt='3'>
        <img src={PayTypeImg[payType]} style={{ width: '60px' }} alt='' />
        <Box mt='2'>
          <Typography.Text style={{ color: 'black' }}>
            {PayType[payType].label}
          </Typography.Text>
        </Box>
      </Box>
      <Divider style={{ margin: '8px 0' }} />
      <Button
        type='link'
        onClick={() => fullScreenHandle(url)}
        disabled={
          !IS_CLIENT ||
          ![
            OrderStatusKeys['Pending'].key,
            OrderStatusKeys['Waiting'].key,
          ].includes(status)
        }>
        点击前往收银台
      </Button>
    </Box>
  );
}

function Oder({
  msgID = 0,
  payType = '', // 使用的支付
  payMethod = '', // 支付方式
  roomID = 0,

  orderStatus = '', // 訂單狀態
  orderNo = '',
  orderAmount = 0,
  expiredAt = 0,

  bank = {},
  qrcode = {},
  redirect = {},

  createdAt,
}) {
  const orderDetailDrawerWrapRef = useRef(null);
  const alipayDrawerWrapRef = useRef(null);
  const wxDrawerWrapRef = useRef(null);

  const [status, setStatus] = useState(orderStatus);

  const { cancelDepositConfirm, expireDeposit } = useOrder(); // 取消訂單

  const _cancelDepositConfirm = () => cancelDepositConfirm({ roomID, orderNo });

  const orderContentRender = {
    [PayMethod['bank']]: () => (
      <BankCardContent
        {...bank}
        amount={orderAmount}
        orderNo={orderNo}
        status={status}
        onClickAlipay={() => alipayDrawerWrapRef.current.setVisible(true)}
        onClickWx={() => wxDrawerWrapRef.current.setVisible(true)}
      />
    ),

    [PayMethod['sub_alipay']]: () => (
      <AliCardContent
        {...bank}
        amount={orderAmount}
        orderNo={orderNo}
        status={status}
        onClickAlipay={() => alipayDrawerWrapRef.current.setVisible(true)}
        onClickWx={() => wxDrawerWrapRef.current.setVisible(true)}
      />
    ),
    [PayMethod['qrcode']]: () => (
      <QRcodeContent
        {...qrcode}
        orderNo={orderNo}
        amount={orderAmount}
        payType={payType}
        status={status}
      />
    ),
    [PayMethod['redirect']]: () => (
      <RedirectContent
        {...redirect}
        orderNo={orderNo}
        amount={orderAmount}
        payType={payType}
        status={status}
      />
    ),
  };

  useRecMessage({
    msgID,
    orderStatusCancel: () => setStatus(OrderStatusKeys['Canceled'].key),
    orderStatusApproving: () => setStatus(OrderStatusKeys['Waiting'].key),
  });

  useEffect(() => {
    // 訂單倒數結束要打過期...
    if (
      orderStatus === OrderStatusKeys['Pending'].key &&
      getNow() > expiredAt
    ) {
      expireDeposit({ in: { roomID, orderNo } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredAt, orderStatus, roomID, orderNo]);

  return (
    <>
      <Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <div>收款信息</div>
          <Box display='flex' flex='1' justifyContent='space-between' ml='2'>
            <Button
              size='small'
              onClick={() => orderDetailDrawerWrapRef.current?.setVisible(true)}
              style={{
                background: '#E8F6FF',
                color: '#3392D1',
                border: 'none',
                borderRadius: '4px',
              }}>
              订单详情
            </Button>
            {[
              OrderStatusKeys['Canceled'].key,
              OrderStatusKeys['Expired'].key,
            ].includes(status) || (
              <Button
                size='small'
                type='text'
                onClick={_cancelDepositConfirm}
                style={{
                  color: '#b4b7ba',
                }}>
                取消订单
              </Button>
            )}
          </Box>
        </Box>
        <Divider style={{ margin: '4px 0' }} />

        {/* 訂單內容 */}
        {orderContentRender[payMethod.toLowerCase()]?.() || null}
      </Box>

      <ClientOrderDetailDrawer
        ref={orderDetailDrawerWrapRef}
        roomID={roomID}
        orderNo={orderNo}
        payType={payType}
        amount={orderAmount}
        status={status}
        createdAt={createdAt}
      />

      <ClientDrawerWrap
        ref={alipayDrawerWrapRef}
        title='支付宝转银行卡教程'
        height='80vh'>
        <img
          src={alipayProcessUrl}
          alt='支付宝转银行卡教程'
          style={{ width: '100%' }}
        />
      </ClientDrawerWrap>

      <ClientDrawerWrap
        ref={wxDrawerWrapRef}
        title='微信转银行卡教程'
        height='80vh'>
        <img
          src={wxProcessUrl}
          alt='微信转银行卡教程'
          style={{ width: '100%' }}
        />
      </ClientDrawerWrap>
    </>
  );
}

export default Oder;
