import React, { useEffect, useState } from 'react';
import { Drawer as ADrawer, Form, Select, Input, Spin, Radio } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import useOrigin from '@/hook/useOrigin';
import { AdTrackingType } from '@/utils';

function Drawer({
  consultingRoomOriginID = null,
  visible = false,
  detailData = null,
  loading = false,
  setVisible = () => {},
  create = () => {},
  update = () => {},
}) {
  const { originSelectList, loading: originListLoading } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const closeDrawer = async () => {
    await form.resetFields();
    setVisible(false);
  };

  const submitHandle = async fields => {
    fields.adTrackingCode = fields.adTrackingCode.trim();
    isEdit
      ? await update({
          filter: { adTracking: { id: detailData.id } },
          in: { ...fields },
        })
      : await create({ in: { ...fields } });
    await closeDrawer();
  };

  const adTrackingCodeValid = async (_, val) => {
    const adTrackingType = form.getFieldValue('adTrackingType');
    if (!/<[^>]+>/g.test(val)) throw new Error('不是正确的 HTML 文本');
    const validType = {
      [AdTrackingType['CNZZ']]: v => {
        if (v.includes('cnzz.com')) return;
        throw new Error('不是正确的 CNZZ 广告代码文本');
      },
      [AdTrackingType['Google']]: v => {
        if (v.includes('www.googletagmanager.com')) return;
        throw new Error('不是正确的 Google 广告代码文本');
      },
      [AdTrackingType['Baidu']]: v => {
        if (v.includes('baidu.com')) return;
        throw new Error('不是正确的 Baidu 广告代码文本');
      },
    };
    validType[adTrackingType]
      ? validType[adTrackingType](val)
      : console.log('缺少驗證Type');
  };

  useEffect(() => {
    const initDrawer = async () => {
      if (detailData) {
        setIsEdit(true);
        await form.setFieldsValue({
          ...detailData,
          isEnabled: detailData.isEnabled === 'Yes',
        });
      } else {
        setIsEdit(false);
        await form.setFieldsValue({
          consultingRoomOriginID,
          adTrackingType: AdTrackingType['CNZZ'],
        });
      }
    };
    visible && initDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, detailData]);

  return (
    <>
      <ADrawer
        visible={visible}
        onClose={closeDrawer}
        width={500}
        title={isEdit ? '编辑广告埋点' : '新增广告埋点'}>
        <Spin spinning={originListLoading}>
          <Form form={form} onFinish={submitHandle}>
            <Form.Item
              name='consultingRoomOriginID'
              label='所属域名'
              rules={[{ required: true, message: '必填' }]}>
              <Select placeholder='请选择'>
                {originSelectList.map(el => (
                  <Select.Option key={el.value} value={el.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='adTrackingType'
              label='广告类型'
              rules={[{ required: true, message: '必填' }]}>
              <Radio.Group onChange={() => form.validateFields()}>
                {Object.keys(AdTrackingType).map(el => (
                  <Radio key={el} value={AdTrackingType[el]}>
                    {el}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name='adTrackingCode'
              label='广告代码'
              validateFirst={true}
              rules={[
                { required: true, message: '必填' },
                { validator: adTrackingCodeValid },
              ]}>
              <Input.TextArea rows={10} placeholder='请输入' />
            </Form.Item>
            <Button variant='primary' htmlType='submit' loading={loading}>
              提交
            </Button>
          </Form>
        </Spin>
      </ADrawer>
    </>
  );
}

export default Drawer;
