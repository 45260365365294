/**
 * 自動輪派
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import SettingCard from './SettingCard';

function AutoDistribute({ title = '', AUTO_DISTRIBUTE, configKeysValue = {} }) {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const enable = configKeysValue[AUTO_DISTRIBUTE].value === 'Yes';
  const { onSuccessMsg } = useAlertMessage();
  const [updateData, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    { onCompleted: () => onSuccessMsg() },
  );
  return (
    <SettingCard
      title={title}
      subTitle='最后一位进线客服编号，往后由小到大分配客服(在线且未隐身)'
      hasEnable={true}
      defaultChecked={enable}
      loading={updateLoading}
      disabled={
        !authRoutesKey.includes('API_ConsultingRoomSetting_AutoDistribute')
      }
      onChange={checked =>
        updateData({
          filter: { PlatformSetting: { key: AUTO_DISTRIBUTE } },
          in: { value: checked ? 'Yes' : 'No' },
        })
      }
    />
  );
}

export default AutoDistribute;
