import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVisibleDrawer } from '@/store/global';
import { Button, Space, Tooltip, Badge, Typography } from 'antd';
import { EditTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import { useActiveList } from '@/hook/useList';
import useMerchant from '@/hook/useMerchant';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import AvatarWithText from '@/components/Avatar/AvatarWithText';
import UserDetailDrawer from '@/components/Drawer/UserDetailDrawer';
import {
  formatDateTime,
  AccountTypeKeys,
  UserStatusKey,
  UserStatusDisplay,
} from '@/utils';

const originFilter = {
  user: {
    username: '',
    email: '',
    status: UserStatusKey['Unknown'],
    accountType: AccountTypeKeys['CommonUser'],
  },
};

const merchantOptions = list => {
  let optionList = [];
  list.forEach(({ id, key }) => optionList.push({ value: id, label: key }));
  return optionList;
};

export default function EmployeeMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { merchantTableList, loading: merchantLoading } = useMerchant({
    options: { fetchPolicy: 'cache-first' },
  });
  const searchFields = useMemo(
    () => [
      { name: 'username', type: 'text', label: '帐号' },
      { name: 'email', type: 'text', label: '信箱' },
      {
        name: 'merchantID',
        type: 'select',
        label: '所属商户名称',
        options: merchantOptions(merchantTableList),
      },
      { name: 'spinachUserID', type: 'digit', label: '平台使用者ID' },
    ],
    [merchantTableList],
  );
  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
  } = useActiveList({
    api: 'LIST_USER',
    originFilter: { ...originFilter },
    handleData: ({ listUser }) => ({
      list: listUser.users || [],
      total: listUser?.meta.total || 0,
    }),
  });
  const onFinish = search => {
    handleSearchFilter({ user: { ...originFilter.user, ...search } });
  };

  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const openDrawer = useCallback(
    v => {
      v || setUser(() => null);
      dispatch(setVisibleDrawer({ name: 'UserDetailDrawer', visible: v }));
    },
    [dispatch],
  );

  const handleUser = user => {
    setUser(() => user);
    openDrawer(true);
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '会员',
      key: 'commonUser',
      render: ({ avatarURL, username }) => (
        <AvatarWithText avatarURL={avatarURL} username={username} />
      ),
    },
    {
      title: '商户资讯',
      key: 'spinach',
      width: '20%',
      render: ({ spinachUserID, spinachPlatformKey }) => (
        <>
          <Typography.Text>所属商户: {spinachPlatformKey}</Typography.Text>
          <br />
          <Typography.Text>平台使用者ID: #{spinachUserID}</Typography.Text>
        </>
      ),
    },
    {
      title: '咨询次数',
      dataIndex: 'consultingCount',
      key: 'consultingCount',
    },
    {
      title: () => (
        <Tooltip title='聊天室显示用'>
          別名
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'aliasName',
      key: 'aliasName',
      render: h => h || '-',
    },
    {
      title: '信箱',
      dataIndex: 'email',
      key: 'email',
      render: h => h || '-',
    },
    {
      title: '帐号状态',
      dataIndex: 'status',
      key: 'status',
      render: h => (
        <Badge
          color={UserStatusDisplay[h].color}
          text={UserStatusDisplay[h].text}
        />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: h => formatDateTime(h),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: user => (
        <Space>
          {authRoutesKey.includes('API_Member_Update') && (
            <Button
              onClick={() => handleUser(user)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={originFilter.user}
        onFinish={onFinish}
        loading={merchantLoading}
      />
      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}></DataTable>
      {/* 編輯 */}
      <UserDetailDrawer user={user}></UserDetailDrawer>
    </PageWrap>
  );
}
