import React, { useEffect, useState } from 'react';
import { Drawer, Form, Input, Select } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { MerchantTypeKeys } from '@/utils';

function MerchantSettingDrawer({
  visible = false,
  detailData = null,
  setVisible = () => {},
  createMerchant = () => {},
  updateMerchant = () => {},
  loading = false,
}) {
  const [merchantForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  const closeDrawer = async () => {
    await merchantForm.resetFields();
    setVisible(false);
  };

  const submitHandle = async fields => {
    const {
      deposit = '',
      paymentChannel = '',
      cancelDeposit = '',
      queryOrder = '',
      paidDeposit = '',
      expireDeposit = '',
      ...props
    } = fields;
    if (isEdit) {
      await updateMerchant({
        filter: { merchant: { id: detailData.id } },
        in: {
          ...props,
          endpoints: {
            deposit,
            paymentChannel,
            cancelDeposit,
            queryOrder,
            paidDeposit,
            expireDeposit,
          },
        },
      });
    } else {
      await createMerchant({
        in: {
          ...props,
          endpoints: {
            deposit,
            paymentChannel,
            cancelDeposit,
            queryOrder,
            paidDeposit,
            expireDeposit,
          },
        },
      });
    }
    await closeDrawer();
  };

  const [required, setRequired] = useState(false);
  const onChangeRequired = val => {
    setRequired(MerchantTypeKeys['Spinach'] === val);
  };

  useEffect(() => {
    const initDrawer = async () => {
      if (detailData) {
        onChangeRequired(detailData.merchantType);
        setIsEdit(true);
        await merchantForm.setFieldsValue({
          ...detailData,
          ...detailData.endpoints,
        });
      } else {
        setIsEdit(false);
        await merchantForm.setFieldsValue({
          merchantType: MerchantTypeKeys['Bochat'],
        });
      }
    };
    if (visible) initDrawer();
  }, [detailData, merchantForm, visible]);

  return (
    <Drawer
      visible={visible}
      title='新建商戶'
      width={450}
      onClose={closeDrawer}>
      <Form form={merchantForm} layout='vertical' onFinish={submitHandle}>
        <Form.Item
          name='merchantType'
          label='商户类型'
          initialValue='Bochat'
          rules={[{ required: true, message: '必填' }]}>
          <Select placeholder='请选择' onChange={onChangeRequired}>
            {[MerchantTypeKeys['Bochat'], MerchantTypeKeys['Spinach']].map(
              el => (
                <Select.Option key={el} value={el}>
                  {el}
                </Select.Option>
              ),
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name='name'
          label='商户名称'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='请输入' />
        </Form.Item>
        <Form.Item
          name='key'
          label='商戶号'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='host'
          label='商戶域名'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='secretKey'
          label='商戶密钥'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='deposit'
          label='存款网址接口'
          rules={[{ required, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='paymentChannel'
          label='支付类型接口'
          rules={[{ required, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='cancelDeposit'
          label='取消订单接口'
          rules={[{ required, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='queryOrder'
          label='查询订单接口'
          rules={[{ required, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='paidDeposit'
          label='完成存款接口'
          rules={[{ required, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='expireDeposit'
          label='超时订单接口'
          rules={[{ required, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item name='remark' label='备注'>
          <Input placeholder='请输入' />
        </Form.Item>
        <Button variant='primary' htmlType='submit' loading={loading}>
          提交
        </Button>
      </Form>
    </Drawer>
  );
}

export default MerchantSettingDrawer;
