import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';

const WsStatusNotify = ({ collapsed }) => {
  /**
   * ws 狀態顯示
   */
  const { wsStatusInfo, recMsg } = useSelector(({ subscribe }) => subscribe);

  const [currWsStatus, setCurrWsStatus] = useState({});
  const wsStatusShowInfo = {
    onError: () => ({
      type: 'error',
      message: 'socket 錯誤',
    }),
    onClose: ({ code, reason }) => ({
      type: 'warning',
      message: `socket 關閉 (${code}, ${reason})`,
    }),
    onConnecting: () => ({
      type: 'success',
      message: 'socket 连线中',
    }),
    // onConnected & Hi
    onConnected: () => ({
      type: 'success',
      message: 'socket 已连线',
    }),
    onReconnecting: () => ({
      type: 'warning',
      message: 'socket 重新連连线中',
    }),
    onReconnected: () => ({
      type: 'success',
      message: 'socket 重新连上线',
    }),
    onDisconnected: () => ({
      type: 'warning',
      message: 'socket 已断开连线',
    }),
    onElse: status => ({
      type: 'warning',
      message: `socket ${status}`,
    }),
  };

  const alertConfig = ({ status, data }) => {
    return wsStatusShowInfo[status]
      ? wsStatusShowInfo[status](data)
      : wsStatusShowInfo['onElse'](status);
  };

  useEffect(() => {
    setCurrWsStatus(wsStatusInfo);
  }, [wsStatusInfo]);

  useEffect(() => {
    // 顯示 Hi 代表已連線
    if (recMsg && recMsg.contentType === 'Hi') {
      setCurrWsStatus({ status: 'onConnected' });
    }
  }, [recMsg]);

  return (
    <Alert
      showIcon={true}
      type={alertConfig(currWsStatus).type}
      message={collapsed ? '' : alertConfig(currWsStatus).message}
    />
  );
};

export default WsStatusNotify;
