import React from 'react';
import { Tooltip, Space } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import {
  LockOutlined,
  UploadOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import useAlertMessage from '@/hook/useAlertMessage';

const HeaderBtnGroup = ({
  loading = false,
  roomID = 0,
  userRooms = [],
  pinRoom = () => {},
  leaveRoom = () => {},
  deactivateRoom = () => {},
}) => {
  const { onErrorMsg } = useAlertMessage();

  // 離開房間
  const checkCanLeave = userRooms =>
    userRooms.filter(u => u.isOperator === 'Yes' && !u.deletedAt).length > 1;
  const clickLeaveRoom = () => {
    // 最後一位 operator 不能離開房間
    checkCanLeave(userRooms)
      ? leaveRoom(roomID)
      : onErrorMsg('最后一位操作员不能离开');
  };

  return (
    <Space>
      <Tooltip title='钉选' placement='bottom'>
        <Button
          type='primary'
          shape='circle'
          size={'large'}
          icon={<PushpinOutlined />}
          onClick={pinRoom}
          loading={loading}
        />
      </Tooltip>
      <Tooltip title='離開' placement='bottom'>
        <Button
          type='primary'
          shape='circle'
          size={'large'}
          icon={<UploadOutlined />}
          onClick={clickLeaveRoom}
          loading={loading}
        />
      </Tooltip>
      <Tooltip title='封存' placement='bottom'>
        <Button
          variant='danger'
          shape='circle'
          size={'large'}
          icon={<LockOutlined />}
          onClick={() => deactivateRoom(roomID)}
          loading={loading}
        />
      </Tooltip>
    </Space>
  );
};

export default HeaderBtnGroup;
