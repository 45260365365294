import { Result, Button } from 'antd';
import useLogin from '@/hook/useLogout';

const Error403 = () => {
  const { fetchLogout } = useLogin();
  const routerPush = async () => {
    await fetchLogout();
  };
  return (
    <Result
      status='403'
      title='403'
      subTitle='抱歉，您没有权限进入此页面'
      extra={
        <Button type='primary' onClick={routerPush}>
          回到登入页
        </Button>
      }
    />
  );
};

export default Error403;
