import React, { useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import useOrigin from '@/hook/useOrigin';
import { PreviewModal } from '@/hook/useEditor';
import { cloneDeep } from '@/utils';

import SearchBar from '@/components/SearchBar';
import TreeCard from './TreeCard';
import DetailTable from './DetailTable';
import Drawer from './Drawer';

const initTreeData = [{ title: '主项目', key: 0, isLeaf: true }];
const defaultSearchBar = { consultingRoomOriginID: 1 };

const updateTreeData = (list, key, children) => {
  return list.map(item => {
    if (item.key === key) return { ...item, children };
    if (item.children)
      return {
        ...item,
        children: updateTreeData(item.children, key, children),
      };
    return item;
  });
};

function FAQMgm() {
  const { originSelectList, loading: originListLoading } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });
  const searchFields = useMemo(
    () => [
      {
        name: 'consultingRoomOriginID',
        type: 'select',
        label: '所属域名',
        options: originSelectList,
      },
    ],
    [originSelectList],
  );

  const [currOriginID, setCurrOriginID] = useState(
    defaultSearchBar.consultingRoomOriginID,
  );

  // table
  const filterSearch = (
    type = 'tree',
    { parentID = 0, faqSearch = {} } = {},
  ) => {
    let frequentlyQuestion = { ...defaultSearchBar, ...faqSearch };
    if (type === 'table') {
      const parent = parentID => (parentID ? { parentID } : { depth: 1 });
      frequentlyQuestion = { ...frequentlyQuestion, ...parent(parentID) };
    }
    return {
      frequentlyQuestion,
      baseFilter: { sortField: 'sequence', sortType: 'ASC' },
    };
  };

  const {
    data: { list, total },
    loading: tableLoading,
    pagination,
    handleSearchFilter: tableSearchFilter,
    handleChangePage,
    refetch: tableRefetch,
  } = useActiveList({
    api: 'LIST_FAQ_DETAIL',
    originFilter: filterSearch('table'),
    handleData: ({ listFrequentlyQuestion }) => ({
      list: listFrequentlyQuestion?.frequentlyQuestions || [],
      total: listFrequentlyQuestion.meta?.total || 0,
    }),
  });

  /**
   * treeData 處理所有的 FAQ
   *  */
  const [treeData, setTreeData] = useState(initTreeData);
  const [groupByParentID, setGroupByParentID] = useState({});
  const initTreeHandle = (listKeys = []) => {
    const tempList = cloneDeep(listKeys);
    const byParentIdData = tempList.reduce((acc, curr) => {
      curr.key = curr.id;
      curr.title = curr.question;
      acc[curr.parentID] =
        curr.parentID in acc ? [...acc[curr.parentID], curr] : [curr];
      return acc;
    }, {});
    const parentIdData = byParentIdData[0] || [];
    setGroupByParentID(byParentIdData);
    setTreeData([...initTreeData, ...parentIdData]);
  };
  const {
    loading: treeLoading,
    handleSearchFilter: treeSearchFilter,
    refetch: treeRefetch,
  } = useActiveList({
    api: 'LIST_FAQ_KEYS',
    originFilter: filterSearch('tree'),
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: ({ listFrequentlyQuestion }) =>
        initTreeHandle(listFrequentlyQuestion.frequentlyQuestions || []),
    },
  });
  const onLoadData = ({ key, children }) => {
    return new Promise(resolve => {
      if (children) {
        resolve();
        return;
      }
      setTreeData(origin => updateTreeData(origin, key, groupByParentID[key]));
      resolve();
    });
  };

  const [treeSelectedItem, setSelectedItem] = useState(initTreeData[0]);
  const setSelectedHandle = itemData => {
    tableSearchFilter(
      filterSearch('table', {
        parentID: itemData.id,
        faqSearch: { consultingRoomOriginID: currOriginID },
      }),
    );
    setSelectedItem(itemData);
  };

  // 搜尋Bar
  const searchBarOnFinish = faqSearch => {
    tableSearchFilter(filterSearch('table', { faqSearch }));
    treeSearchFilter(filterSearch('tree', { faqSearch }));
    setCurrOriginID(faqSearch.consultingRoomOriginID);
  };

  // 新增
  const [createFAQ, { loading: createdLdg }] = useMutationApi(
    apiType.CREATE_FAQ,
    {
      onCompleted: () => tableRefetch(),
    },
  );

  // 更新
  const [updateFAQ, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_FAQ,
    {
      onCompleted: () => tableRefetch(),
    },
  );

  const [editData, setEditData] = useState(null);
  const [visible, setVisible] = useState(false);
  const setVisibleHandle = ({ visible, editData = null }) => {
    setVisible(visible);
    setEditData(editData);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const previewVisible = (val, content) => {
    setModalVisible(val);
    setPreviewContent(() => (val ? content : null));
  };

  return (
    <>
      <SearchBar
        fields={searchFields}
        defaultSearch={defaultSearchBar}
        onFinish={searchBarOnFinish}
        loading={originListLoading}
      />
      <Row gutter={16}>
        <Col span={6}>
          <TreeCard
            treeData={treeData}
            onLoadData={onLoadData}
            setSelectedItem={setSelectedHandle}
            loading={treeLoading}
            refetch={treeRefetch}
          />
        </Col>
        <Col span={18}>
          <DetailTable
            loading={tableLoading || originListLoading}
            dataSource={list}
            pagination={pagination}
            handleChangePage={handleChangePage}
            total={total}
            refetch={tableRefetch}
            setVisible={setVisibleHandle}
            currentParentItem={treeSelectedItem}
            groupByParentID={groupByParentID}
            previewVisible={previewVisible}
          />
        </Col>
      </Row>
      <Drawer
        visible={visible}
        setVisible={setVisibleHandle}
        consultingRoomOriginID={currOriginID}
        parentList={groupByParentID[treeSelectedItem?.parentID] || []}
        currentParentItem={treeSelectedItem}
        editData={editData}
        refetch={treeRefetch}
        createFAQ={createFAQ}
        updateFAQ={updateFAQ}
        loading={createdLdg || updateLoading}
        previewVisible={previewVisible}
      />
      <PreviewModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        htmlStr={previewContent}
      />
    </>
  );
}

export default FAQMgm;
