import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useActiveList } from '@/hook/useList';
import { useMutationApi, apiType } from '@/hook/useApi';
import WhiteList from './List';
import useAlertMessage from '@/hook/useAlertMessage';

const UserWhiteList = ({ userID }) => {
  const { onSuccessMsg } = useAlertMessage();
  const [whiteList, setWhiteList] = useState([]);
  const { loading, refetch } = useActiveList({
    api: 'LIST_USER_WHITE',
    cusFilter: { filter: { userWhitelist: { userID } } },
    options: {
      onCompleted: ({ listUserWhitelist }) =>
        setWhiteList(() => listUserWhitelist?.userWhitelists || []),
    },
  });

  const [createWhite, { loading: createLoading }] = useMutationApi(
    apiType.CREATE_USER_WHITE,
  );
  const createWhiteHandle = async (filed, form) => {
    const [err] = await createWhite({ in: { ...filed, userID } });
    if (!err) {
      onSuccessMsg();
      await refetch();
      await form.resetFields();
    }
  };

  const completedConfig = {
    onCompleted: () => {
      refetch();
      onSuccessMsg();
    },
  };
  const [updateWhite, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_USER_WHITE,
    completedConfig,
  );
  const [deleteWhite, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_USER_WHITE,
    completedConfig,
  );

  return (
    <WhiteList
      loading={loading || updateLoading || createLoading}
      deleteLoading={deleteLoading}
      dataSource={whiteList}
      createWhite={createWhiteHandle}
      updateWhite={(id, ipAddress) =>
        updateWhite({
          filter: { userWhitelist: { id, userID } },
          in: { ipAddress },
        })
      }
      deleteWhite={id =>
        deleteWhite({ filter: { userWhitelist: { id, userID } } })
      }
    />
  );
};

UserWhiteList.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default UserWhiteList;
