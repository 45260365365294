import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@WRAP_COMP';
import { Empty, Tag, Checkbox } from 'antd';
import TabsScrollBlock from '@/components/TabsScrollBlock';
import RoomCard from '@/components/CSRoomAdmin/RoomCard';
import { AccountTypeDisplay, AccountTypeKeys, CSRoomTypeKey } from '@/utils';

const tagsRender = ({ isTourist, isProactive, isDeposit }) => {
  return isDeposit ? (
    <Tag color='success' style={{ marginRight: '0' }}>
      充值
    </Tag>
  ) : isTourist ? (
    <Tag
      color={AccountTypeDisplay['Tourist'].color}
      style={{ marginRight: '0' }}>
      {AccountTypeDisplay['Tourist'].text}
    </Tag>
  ) : isProactive ? (
    <Tag color='blue' style={{ marginRight: '0' }}>
      联系
    </Tag>
  ) : null;
};

const RoomTabList = ({
  loading = false,
  dataSource = [{ room: [], ownerUserData: {}, extraInfo: {} }],
  tabPaneArr = [],
  currTab = '',
  currRoomID = 0,
  onChangeTab = () => {},
  setCurrRoom = () => {},
  showIsOnline = false,
  showLastMsg = false,
  showUnReadCount = false,
  controlArea = null,
  checkedList = [],
  onCheckRoom = () => {},
}) => {
  return (
    <TabsScrollBlock
      activeKey={currTab}
      tabPaneArr={tabPaneArr}
      onChangeTab={onChangeTab}
      loading={loading}
      controlArea={controlArea}>
      {dataSource.length ? (
        <>
          <Checkbox.Group
            value={checkedList}
            onChange={onCheckRoom}
            style={{ width: '100%' }}>
            {dataSource.map(
              ({ room, ownerUserData, extraInfo, ...props }, idx) => (
                <div
                  key={room.roomID}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox value={room.roomID} />
                  <div
                    style={{ flex: 1 }}
                    onClick={() =>
                      setCurrRoom({ room, ownerUserData, extraInfo, ...props })
                    }>
                    <RoomCard
                      room={room}
                      roomID={room?.roomID}
                      userData={ownerUserData}
                      unRead={room.unRead || 0}
                      isTimeoutResponse={room.isTimeoutResponse === 'Yes'}
                      isWaitFirstResponse={room.isWaitFirstResponse === 'Yes'}
                      isActive={currRoomID === room.roomID}
                      isPinned={extraInfo.isPinned}
                      isNewActiveRoom={extraInfo.isNewActiveRoom}
                      showUnReadCount={showUnReadCount}
                      showIsOnline={showIsOnline}
                      showLastMsg={showLastMsg}
                      tags={tagsRender({
                        isTourist:
                          ownerUserData?.accountType ===
                          AccountTypeKeys['Tourist'],
                        isProactive: room.isProactive === 'Yes',
                        isDeposit: room.csType === CSRoomTypeKey['Deposit'],
                      })}
                    />
                  </div>
                </div>
              ),
            )}
          </Checkbox.Group>
        </>
      ) : (
        <Box
          height='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'>
          <Empty description={false} />
        </Box>
      )}
    </TabsScrollBlock>
  );
};

RoomTabList.propTypes = {
  dataSource: PropTypes.array.isRequired,
  tabPaneArr: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  controlArea: PropTypes.node,
};
export default RoomTabList;
