import { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import apiType from '@/services';

export const defaultFilter = {
  baseFilter: { sortField: 'id', sortType: 'DESC' },
};
export const defaultPagination = { perPage: 10, page: 1 };

/**
 * 一般表單 主動呼叫 fetchPolicy: 'cache-and-network'
 * @param {} param0
 * @returns
 */
export const useActiveList = ({
  api,
  originFilter = {},
  cusFilter = null,
  originPagination = {},
  options = {},
  handleData = null,
}) => {
  const [searchFilter, setSearchFilter] = useState(() =>
    cusFilter
      ? { ...cusFilter }
      : {
          filter: {
            ...defaultFilter,
            ...originFilter,
          },
        },
  );
  const [pagination, setPagination] = useState(() => ({
    ...defaultPagination,
    ...originPagination,
  }));

  const {
    loading,
    error,
    data: originData,
    refetch,
  } = useQuery(apiType[api], {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { ...searchFilter, pagination: { ...pagination } },
    ...options,
  });

  const handleSearchFilter = filter => {
    setSearchFilter(() =>
      cusFilter ? { ...filter } : { filter: { ...defaultFilter, ...filter } },
    );
    setPagination({ ...defaultPagination, ...originPagination }); // 分頁回到 default
  };

  const handleChangePage = p => {
    setPagination({ ...pagination, ...p });
  };

  const [refetchLoading, setRefetchLoading] = useState(false);
  const refetchHandle = async params => {
    setRefetchLoading(true);
    const data = await refetch(params);
    setRefetchLoading(false);
    return data;
  };

  useEffect(() => {
    error && console.log('useList error >>', JSON.stringify(error));
  }, [error]);

  const data = useMemo(() => {
    if (!originData) return {};
    if (!handleData) return originData;
    return handleData(originData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originData]);

  return {
    error,
    data,
    loading: loading || refetchLoading,
    pagination,
    refetch: refetchHandle,
    handleSearchFilter,
    handleChangePage,
  };
};
