import React from 'react';
import PropTypes from 'prop-types';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';

const BtnTooltip = ({
  tipType = 'edit', // edit delete
  tipTitle = '',
  placement = 'top',
  showBtnText = false,
  btnText = '',
  onClick,
  ...props
}) => {
  const toolkitTitle = {
    edit: { tipTitle: '編輯', icon: <EditTwoTone /> },
    delete: { tipTitle: '刪除', icon: <DeleteTwoTone /> },
  };

  return (
    <Tooltip
      placement={placement}
      title={tipTitle || toolkitTitle[tipType].tipTitle}>
      <Button
        icon={tipType ? toolkitTitle[tipType].icon : null}
        onClick={onClick}
        type='link'
        {...props}>
        {showBtnText ? btnText || toolkitTitle[tipType].btnText : null}
      </Button>
    </Tooltip>
  );
};

BtnTooltip.propTypes = {
  tipType: PropTypes.string,
  tipTitle: PropTypes.string,
  placement: PropTypes.string,
  showBtnText: PropTypes.bool,
  btnText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default BtnTooltip;
