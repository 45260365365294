import React, { useState } from 'react';
import { InputNumber, Button } from 'antd';
import { Box } from '@WRAP_COMP';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import SettingCard from './SettingCard';

function DialogueTimeout({
  title,
  DIALOGUE_TIMEOUT_SEC,
  DIALOGUE_TIMEOUT_SEC_ENABLED,
  configKeysValue = {},
}) {
  const enable = configKeysValue[DIALOGUE_TIMEOUT_SEC_ENABLED].value === 'Yes';
  const [sec, setSec] = useState(() =>
    Number(configKeysValue[DIALOGUE_TIMEOUT_SEC].value),
  );
  const { onSuccessMsg } = useAlertMessage();
  const [update, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    {
      onCompleted: ({ updatePlatformSetting }) => {
        updatePlatformSetting.key === DIALOGUE_TIMEOUT_SEC &&
          setSec(Number(updatePlatformSetting.value));
        onSuccessMsg();
      },
    },
  );

  return (
    <SettingCard
      title={title}
      subTitle='顾客发送消息后，客服在指定时间内未回复消息时进行超时提示（客服回复会员消息后提示消失）'
      hasEnable={true}
      checked={enable}
      defaultChecked={enable}
      loading={updateLoading}
      onChange={checked => {
        update({
          filter: { PlatformSetting: { key: DIALOGUE_TIMEOUT_SEC_ENABLED } },
          in: { value: checked ? 'Yes' : 'No' },
        });
      }}
      content={
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <div>
            超时提醒：当顾客发送消息后，客服超过
            <Box display='inline-block' mx='2'>
              <InputNumber value={sec} onChange={v => setSec(v)} size='small' />
            </Box>
            秒后无响应自动给出超时提示
          </div>
          <Button
            type='primary'
            disabled={updateLoading}
            onClick={() =>
              update({
                filter: { PlatformSetting: { key: DIALOGUE_TIMEOUT_SEC } },
                in: { value: String(sec) },
              })
            }>
            确认
          </Button>
        </Box>
      }
    />
  );
}

export default DialogueTimeout;
