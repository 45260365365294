import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Switch, Space } from 'antd';
import { PlusOutlined, EditTwoTone } from '@ant-design/icons';
import { useActiveList } from '@/hook/useList';
import { useMutationApi, apiType } from '@/hook/useApi';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { EnableKey, EnableDisplay, formatDateTime } from '@/utils';
import Drawer from './Drawer';

const originFilter = {
  hostsDeny: { isEnabled: EnableKey['Unknown'] },
};
const searchFields = [
  { name: 'ipAddress', type: 'text', label: 'IP' },
  {
    name: 'isEnabled',
    type: 'select',
    label: '启用状态',
    optionsObj: EnableDisplay,
  },
];

function HostsDenyMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
    refetch,
  } = useActiveList({
    api: 'LIST_HOSTS_DENY',
    originFilter: { ...originFilter },
    handleData: ({ listHostsDeny }) => ({
      list: listHostsDeny?.hostsDenys || [],
      total: listHostsDeny?.meta?.total || 0,
    }),
  });
  const onFinishSearch = search => {
    handleSearchFilter({
      hostsDeny: {
        ...originFilter.hostsDeny,
        ...search,
      },
    });
  };

  const onCompletedCallback = { onCompleted: () => refetch() };
  const [createHostsDeny, { loading: createLoading }] = useMutationApi(
    apiType.CREATE_HOSTS_DENY,
    onCompletedCallback,
  );
  const [updateHostsDeny, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_HOSTS_DENY,
    onCompletedCallback,
  );
  const [deleteHostsDeny, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_HOSTS_DENY,
    onCompletedCallback,
  );

  const [visible, setVisible] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const visibleDrawer = (v, data = null) => {
    setDetailData(data);
    setVisible(v);
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: 'IP',
      dataIndex: 'ipAddress',
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
    {
      title: '建立时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: h => formatDateTime(h),
    },
    {
      title: '启用状态',
      dataIndex: 'isEnabled',
      render: (h, { id }) => (
        <Switch
          checkedChildren='启用'
          unCheckedChildren='停用'
          checked={h === 'Yes'}
          disabled={
            h === 'Unknown' || !authRoutesKey.includes('API_HostsDeny_Update')
          }
          onChange={v =>
            updateHostsDeny({
              filter: { hostsDeny: { id } },
              in: { isEnabled: v ? 'Yes' : 'No' },
            })
          }
        />
      ),
    },
    {
      title: '動作',
      key: 'action',
      width: '15%',
      fixed: 'right',
      render: record => (
        <Space>
          {authRoutesKey.includes('API_HostsDeny_Update') && (
            <Button
              onClick={() => visibleDrawer(true, record)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {authRoutesKey.includes('API_HostsDeny_Delete') && (
            <BtnDeleteConfirm
              handleOk={() =>
                deleteHostsDeny({
                  filter: { hostsDeny: { id: record.id } },
                })
              }
              loading={deleteLoading}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={{ ...originFilter.user }}
        onFinish={onFinishSearch}
      />

      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        {authRoutesKey.includes('API_HostsDeny_Create') && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => visibleDrawer(true)}>
            新增黑名单
          </Button>
        )}
      </DataTable>
      <Drawer
        visible={visible}
        setVisible={visibleDrawer}
        detailData={detailData}
        create={createHostsDeny}
        update={updateHostsDeny}
        loading={createLoading || updateLoading}
      />
    </PageWrap>
  );
}

export default HostsDenyMgm;
