import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setVisibleDrawer } from '@/store/global';
import { Drawer } from 'antd';
import UserDetailTabs from '@/components/User/UserDetailTabs';

/**
 * 會員明細
 */
const DRAWER_NAME = 'UserDetailDrawer';

const UserDetailDrawer = ({
  children,
  user = {},
  closeCallback = () => {},
}) => {
  const dispatch = useDispatch();
  const visibleData = useSelector(({ global }) => global.visibleDrawer);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    visibleData.name === DRAWER_NAME && setVisible(visibleData.visible);
  }, [visibleData]);

  const closeDrawer = () => {
    dispatch(setVisibleDrawer({ name: DRAWER_NAME, visible: false }));
    closeCallback(false);
  };

  return (
    <Drawer
      visible={visible}
      title={user?.username || ''}
      width={'60%'}
      onClose={closeDrawer}
      bodyStyle={{ paddingBottom: 80 }}>
      {children}
      {visible && <UserDetailTabs user={user} />}
    </Drawer>
  );
};

UserDetailDrawer.propTypes = {
  user: PropTypes.object,
};

export default React.memo(UserDetailDrawer);
