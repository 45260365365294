import React, { useEffect, useState, useRef } from 'react';
import {
  Drawer as ADrawer,
  Form,
  Switch,
  Radio,
  Input,
  InputNumber,
} from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { Editor, useEditor } from '@/hook/useEditor';

const { theme } = require('@/plugin/theme');

const defaultForm = {
  afterSendSec: 0,
  source: '',
  isEnabled: false,
};

function Drawer({
  loading = false,
  consultingRoomOriginID,
  detailData = null,
  visible = false,
  setVisible = () => {},
  createGreeting = () => {},
  updateGreeting = () => {},
  previewVisible = () => {},
}) {
  const sourceInputEl = useRef(null);
  const [showSecInputEl, setShowSecInputEl] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [greetingForm] = Form.useForm();

  const {
    editorControls,
    extendControls,
    editorState,
    setHtmlToEditorState,
    changeEditorHandle,
    EditHtmlModal,
  } = useEditor({
    clickPreview: content => previewVisible(true, content),
  });

  const closeDrawer = async () => {
    await greetingForm.resetFields();
    setVisible(false);
  };

  const submitHandle = async fieldsObj => {
    let fields = {
      ...fieldsObj,
      isEnabled: fieldsObj.isEnabled ? 'Yes' : 'No',
      content: editorState.toHTML(),
    };
    if (isEdit) {
      await updateGreeting({
        filter: { consultingGreeting: { id: detailData.id } },
        in: { ...fields, consultingRoomOriginID },
      });
    } else {
      await createGreeting({ in: { ...fields, consultingRoomOriginID } });
    }
    await closeDrawer();
  };

  useEffect(() => {
    const initDrawer = async () => {
      if (detailData) {
        setIsEdit(true);
        setHtmlToEditorState(detailData.content);
        await greetingForm.setFieldsValue({
          ...detailData,
          isEnabled: detailData.isEnabled === 'Yes',
        });
      } else {
        setIsEdit(false);
        await greetingForm.setFieldsValue({ ...defaultForm });
      }
    };
    visible && initDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <ADrawer
        visible={visible}
        onClose={() => setVisible(false)}
        width={500}
        title={isEdit ? '编辑' : '新增'}>
        <Form form={greetingForm} onFinish={submitHandle}>
          <Form.Item
            name='title'
            label='备注'
            tooltip='只做纪录不显示于前台'
            rules={[{ required: true, message: '必填' }]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item
            name='afterSendSec'
            label='发送时间'
            tooltip='开启对话后执行发送秒数'
            rules={[{ required: true, message: '必填' }]}>
            <InputNumber
              max={6}
              placeholder='请输入秒数'
              style={{ width: '30%' }}
            />
          </Form.Item>
          <Form.Item name='source' label='发送范围'>
            <Radio.Group onChange={e => setShowSecInputEl(!!e.target.value)}>
              <Radio value={''}>统一发送</Radio>
              <Radio value={'1'}>
                定向发送
                {showSecInputEl ? (
                  <Input
                    placeholder='请输入'
                    ref={sourceInputEl}
                    style={{ marginLeft: '8px' }}
                  />
                ) : null}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='isEnabled' valuePropName='checked' label='启用状态'>
            <Switch checkedChildren='启用' unCheckedChildren='停用' />
          </Form.Item>
          <Form.Item>
            <Editor
              value={editorState}
              onChange={changeEditorHandle}
              controls={editorControls}
              extendControls={extendControls}
              style={{ border: `1px solid ${theme['gray-2']}` }}
            />
          </Form.Item>
          <Button variant='primary' htmlType='submit' loading={loading}>
            提交
          </Button>
        </Form>
        {EditHtmlModal()}
      </ADrawer>
    </>
  );
}

export default Drawer;
