import React, { useState, useEffect } from 'react';
import { Drawer, Space, Checkbox } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { useActiveList } from '@/hook/useList';
import DataTable from '@/components/Table/DataTable';
import OnlineStatusBadge from '@/components/User/OnlineStatusBadge';
import AntAlert from '@/components/Alert';
import { AccountTypeKeys, UserStatusKey } from '@/utils';

function JoinCustomerServiceDrawer({
  visible = false,
  loading = false,
  setVisible = () => {},
  submit = () => {},
}) {
  const [errMsg, setErrMsg] = useState('');
  const [selected, setSelected] = useState({ keys: [], rows: [] });
  const selectChangeHandle = (keys, rows) => {
    setSelected({ keys, rows });
  };
  const closeDrawer = () => {
    setSelected({ keys: [], rows: [] });
    setVisible(false);
  };
  useEffect(() => {
    visible || setSelected({ keys: [], rows: [] });
  }, [visible]);

  const [dataSource, setDataSource] = useState([]);
  const { loading: listLdg } = useActiveList({
    api: 'LIST_USER',
    originPagination: { page: 1, perPage: 9999 },
    cusFilter: {
      isOnline: true,
      filter: {
        user: {
          status: UserStatusKey['Unknown'],
          accountType: AccountTypeKeys['CustomerService'],
        },
      },
    },
    options: {
      skip: !visible,
      onCompleted: ({ listUser }) => {
        setDataSource(listUser.users || []);
      },
    },
  });

  const submitHandle = () => {
    if (!selected.keys.length) {
      setErrMsg(() => '請選擇人員');
      return;
    }
    submit(selected.keys);
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '帐号',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '別名',
      dataIndex: 'aliasName',
      key: 'aliasName',
      render: h => h || '-',
    },
    {
      title: '在线状态',
      key: 'isOnlineStatus',
      render: ({ isOnline, setting }) => (
        <OnlineStatusBadge
          isOnline={isOnline}
          isStealth={setting ? setting.isStealth : 'No'}
        />
      ),
    },
  ];

  const [showOnlyOnline, setShowOnlyOnline] = useState(true);
  const onChange = e => setShowOnlyOnline(!e.target.checked);

  const filterData = dataSource =>
    dataSource.filter(data =>
      showOnlyOnline ? data.isOnline === 'Yes' : data,
    );

  return (
    <Drawer
      visible={visible}
      title='加入客服'
      width={760}
      onClose={closeDrawer}>
      <AntAlert message={errMsg} />
      <Space
        style={{
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}>
        <Button variant='primary' onClick={submitHandle}>
          提交
        </Button>
        <Checkbox onChange={onChange}>显示所有客服</Checkbox>
      </Space>
      <DataTable
        columns={columns}
        dataSource={filterData(dataSource)}
        loading={loading || listLdg}
        rowSelection={{
          type: 'checkbox',
          onChange: selectChangeHandle,
          selectedRowKeys: selected.keys,
        }}
        tableProps={{ size: 'middle' }}
        bodyStyle={{ padding: '0px' }}
        bordered={false}
      />
    </Drawer>
  );
}

export default JoinCustomerServiceDrawer;
