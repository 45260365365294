import React, { useState, useCallback } from 'react';
import {
  EditTwoTone,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Box } from '@WRAP_COMP';
import { useDispatch, useSelector } from 'react-redux';
import { setVisibleDrawer } from '@/store/global';
import { Button, Space, Tooltip, Badge, Tabs } from 'antd';
import { useActiveList, defaultFilter } from '@/hook/useList';
import { useMutationApi, apiType } from '@/hook/useApi';
import useMerchant from '@/hook/useMerchant';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import AvatarWithText from '@/components/Avatar/AvatarWithText';
import UserDetailDrawer from '@/components/Drawer/UserDetailDrawer';
import CreateUserDrawer from '@/components/Drawer/CreateUserDrawer';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import {
  formatDateTime,
  AccountTypeKeys,
  AccountTypeDisplay,
  UserStatusKey,
  UserStatusDisplay,
} from '@/utils';

const originFilter = {
  user: {
    username: '',
    email: '',
    status: UserStatusKey['Unknown'],
    accountType: AccountTypeKeys['Manager'],
    isDelete: 'No',
  },
};

const searchFields = [
  { name: 'username', type: 'text', label: '管理员帐号' },
  { name: 'email', type: 'text', label: '信箱' },
  {
    name: 'status',
    type: 'select',
    label: '帐号狀態',
    optionsObj: UserStatusDisplay,
  },
];

export default function EmployeeMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);

  // 商戶列表
  const { merchantTableList } = useMerchant();
  const merchantRender = id => {
    const merchant = merchantTableList.find(el => el.id === id);
    return (
      <>
        {merchant?.key || '-'}
        {merchant?.merchantType ? ` (${merchant.merchantType})` : null}
      </>
    );
  };

  // 使用者列表
  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
    refetch,
  } = useActiveList({
    api: 'LIST_USER',
    originFilter: { ...originFilter },
    handleData: ({ listUser }) => ({
      list: listUser.users || [],
      total: listUser?.meta?.total || 0,
    }),
  });

  const onFinish = search => {
    handleSearchFilter({
      ...originFilter,
      user: { ...originFilter.user, ...search },
    });
  };

  /**
   * created user drawer
   */
  const [createDrawer, setCreateDrawer] = useState(false);
  const openCreateUserDrawer = v => {
    setCreateDrawer(v);
  };

  /**
   * view&edit user
   */
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const openDrawer = useCallback(
    v => {
      v || setUser(() => null);
      dispatch(setVisibleDrawer({ name: 'UserDetailDrawer', visible: v }));
    },
    [dispatch],
  );

  const handleUser = user => {
    setUser(() => user);
    openDrawer(true);
  };

  /**
   * delete user
   */
  const [deleteUser, { loading: deleteLdg }] = useMutationApi(
    apiType.DELETE_USER,
    {
      onCompleted: () => refetch(),
    },
  );

  /**
   * table
   */
  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '管理员',
      key: 'manager',
      fixed: 'left',
      render: ({ username, avatarURL }) => (
        <AvatarWithText avatarURL={avatarURL} username={username} />
      ),
    },
    {
      title: () => (
        <Tooltip title='聊天室顯示用'>
          別名
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'aliasName',
      key: 'aliasName',
      render: h => h || '-',
    },
    {
      title: '所属商户号(类型)',
      dataIndex: 'merchantID',
      key: 'merchantID',
      render: merchantID => merchantRender(merchantID),
    },
    {
      title: '信箱',
      dataIndex: 'email',
      key: 'email',
      render: h => h || '-',
    },
    {
      title: '帐号狀態',
      dataIndex: 'status',
      key: 'status',
      render: h => (
        <Badge
          color={UserStatusDisplay[h].color}
          text={UserStatusDisplay[h].text}
        />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: h => formatDateTime(h),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: user => (
        <Space>
          {authRoutesKey.includes('API_Employee_Update') && (
            <Button
              onClick={() => handleUser(user)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {authRoutesKey.includes('API_Employee_Delete') && (
            <BtnDeleteConfirm
              handleOk={() => deleteUser({ filter: { user: { id: user.id } } })}
              loading={deleteLdg}
            />
          )}
        </Space>
      ),
    },
  ];
  const tableRender = () => {
    return (
      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}
        bordered={false}
        bodyStyle={{ padding: '0px' }}
      />
    );
  };

  const onChangeTab = key => {
    refetch({
      filter: {
        ...defaultFilter,
        user: { ...originFilter.user, accountType: key },
      },
    });
  };

  const tabTableRender = () => {
    const tabs = [
      {
        key: AccountTypeKeys['Manager'],
        tab: AccountTypeDisplay[AccountTypeKeys['Manager']].text,
      },
      {
        key: AccountTypeKeys['Monitor'],
        tab: AccountTypeDisplay[AccountTypeKeys['Monitor']].text,
      },
    ];

    return (
      <Box bgColor='white' p='3'>
        <Tabs
          defaultActiveKey='1'
          type='card'
          tabBarGutter={5}
          onChange={onChangeTab}
          tabBarExtraContent={
            authRoutesKey.includes('API_Employee_Create') && (
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => openCreateUserDrawer(true)}>
                新增员工 / 监察员
              </Button>
            )
          }>
          {tabs.map(tt => (
            <Tabs.TabPane tab={tt.tab} key={tt.key}>
              {tableRender()}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Box>
    );
  };

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={originFilter.user}
        onFinish={onFinish}
      />

      {tabTableRender()}

      {/* 編輯 */}
      <UserDetailDrawer user={user} />

      {/* 新增 */}
      <CreateUserDrawer
        title='新增员工 / 监察员'
        visible={createDrawer}
        setVisible={v => setCreateDrawer(v)}
        callback={refetch}
        accountTypes={[AccountTypeKeys['Manager'], AccountTypeKeys['Monitor']]}
      />
    </PageWrap>
  );
}
