import React from 'react';
import QnsTooltip from '@/components/QnsTooltip';
import DataTable from '@/components/Table/DataTable';
import { commaFormat, secondsToHms } from '@/utils';

const CustomerServiceTable = ({
  dataSource,
  loading,
  pagination,
  onChangePage,
  ...props
}) => {
  const columns = [
    {
      title: '客服ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
    },
    {
      title: '客服帐号',
      dataIndex: 'username',
      key: 'username',
      fixed: 'left',
    },
    {
      title: () => <QnsTooltip title='首位客服' tip='為首位進線客服的次數' />,
      dataIndex: 'firstEntryCount',
      key: 'firstEntryCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='进入房间数'
          tip='有进入过的咨询房数(首位+非首位客服)'
        />
      ),
      dataIndex: 'entryCount',
      key: 'entryCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='有效咨询数'
          tip='该客服在进入房间内有进行有效咨询​​的房间数 (参与咨询房且有对话的数量)'
        />
      ),
      dataIndex: 'validConsultationCount',
      key: 'validConsultationCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip title='结案数' tip='该客服进行结案(封存)的数字' />
      ),
      dataIndex: 'casesClosedCount',
      key: 'casesClosedCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='平均回应时间'
          tip='首位客服平均回应时间(只计算首位回应,若是第二个客服进线则不计算)'
        />
      ),
      dataIndex: 'averageResponseSecond',
      key: 'averageResponseSecond',
      render: r => secondsToHms(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='平均对话时间'
          tip='首次发出对话,到客服封存的平均时间,(只记算给结案客服)'
        />
      ),
      dataIndex: 'averageConversationSecond',
      key: 'averageConversationSecond',
      render: r => secondsToHms(r),
    },
    {
      title: () => (
        <QnsTooltip title='逐条回应时间' tip='每则讯息平均回复时间' />
      ),
      dataIndex: 'averageResponseByItemSecond',
      key: 'averageResponseByItemSecond',
      render: r => secondsToHms(r),
    },
    {
      title: '登录时长',
      dataIndex: 'loginSecond',
      key: 'loginSecond',
      render: r => secondsToHms(r),
    },
    {
      title: '在线时长',
      dataIndex: 'onlineSecond',
      key: 'onlineSecond',
      render: r => secondsToHms(r),
    },
    {
      title: '隐身时长',
      dataIndex: 'stealthSecond',
      key: 'stealthSecond',
      render: r => secondsToHms(r),
    },
  ];
  return (
    <DataTable
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={pagination}
      onChangePage={onChangePage}
      {...props}
    />
  );
};

export default CustomerServiceTable;
