import { useState, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { Input, Form, Select, Button, Drawer as ADrawer } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import { EnableDisplay } from '@/utils';

const Drawer = ({ openDrawer, visible, isEdit, refetch, detailData = {} }) => {
  const [newTag] = useMutationApi(apiType.CREATE_TAG);
  const [updateTag] = useMutationApi(apiType.UPDATE_TAG);
  const [loading, setLoading] = useState(false);
  const [colorPicker, setColorPicker] = useState('');
  const [tagData, setTagData] = useState({});
  const [tagForm] = Form.useForm();

  // 提交表單
  const submitHandle = async () => {
    const fields = await tagForm.validateFields();
    setLoading(true);
    const submitFunc = isEdit ? updateTag : newTag;
    let variables = { in: { name: fields.name, RGBHex: fields.RGBHex.hex } };

    if (isEdit) {
      variables.in.isEnable = fields.isEnable;
      variables = { ...variables, filter: { tag: { id: tagData.id } } };
    }
    const [err] = await submitFunc(variables);
    setLoading(false);
    if (!err) {
      refetch();
      await closeDrawer();
    }
  };

  const closeDrawer = async () => {
    await tagForm.resetFields();
    await setTagData({});
    setColorPicker('');
    openDrawer(false);
  };

  // detail 處理
  useEffect(() => {
    const detailDataHandle = async getTag => {
      await setTagData({ ...getTag });
      await setColorPicker(getTag.RGBHex);
      await tagForm.setFieldsValue(getTag);
    };
    if (isEdit && detailData?.getTag) detailDataHandle(detailData.getTag);
  }, [isEdit, detailData, tagForm]);

  useEffect(() => {
    if (visible) tagForm.resetFields();
  }, [visible, tagForm]);

  return (
    <ADrawer
      visible={visible}
      title={isEdit ? '編輯' : '新增'}
      onClose={closeDrawer}
      width={450}
      bodyStyle={{ paddingBottom: 80 }}>
      <Form form={tagForm} onFinish={submitHandle} layout='vertical'>
        <Form.Item
          name='name'
          label='標籤名稱'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>

        {isEdit && (
          <Form.Item
            name='isEnable'
            label='启用状态'
            rules={[{ required: true, message: '必填' }]}>
            <Select placeholder='請選擇'>
              {Object.keys(EnableDisplay).map(kk => (
                <Select.Option key={kk} value={kk} disabled={kk === 'Unknown'}>
                  {EnableDisplay[kk].text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name='RGBHex'
          label='標籤顏色'
          rules={[{ required: true, message: '必填' }]}>
          <CirclePicker
            color={colorPicker}
            onChangeComplete={({ hex }) => setColorPicker(hex)}
            width='100%'
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' loading={loading} type='primary'>
            提交
          </Button>
        </Form.Item>
      </Form>
    </ADrawer>
  );
};

export default Drawer;
