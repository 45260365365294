import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Badge } from 'antd';
import { Box } from '@WRAP_COMP';
import useCrypto from '@HOOK/useCrypto';
import useRecMessage from '@HOOK/useRecMessage';
import useRedPoint from '@HOOK/useRedPoint';
import { formatTime, MsgContentTypeKeys } from '@/utils';

function MsgStatus({
  roomID = 0,
  isWaitFirst = false,
  isTimeout = false,
  showLastMsg = false,
  showUnReadCount = true,
  unRead = 0,
  lastMessage = null,
}) {
  const { currentInRoomID, csTotalUnRead } = useSelector(({ room }) => room);
  const { meInfo, loginInfo } = useSelector(({ auth, global }) => ({
    ...auth,
    ...global,
  }));
  const { setCsTotalUnReadHandle } = useRedPoint();

  /**
   * unRead 狀態
   */
  const [unReadCount, setUnReadCount] = useState(() => unRead);
  useEffect(() => {
    // 進到當前房間 取消 unRead 並減少 totalUnRead
    if (currentInRoomID === roomID && unReadCount > 0) {
      setUnReadCount(0);
      setCsTotalUnReadHandle(csTotalUnRead - unReadCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInRoomID]);

  // 驗證 新增unRead
  const verifyAddUnRead = ({ roomID, from }) => {
    if (roomID === currentInRoomID) return;
    if (from.userID === meInfo.id) return;
    setUnReadCount(pre => pre + 1);
  };

  /**
   * 待首次響應 & 超時 狀態處理
   */
  const [isWaitFirstState, setIsWaitFirstState] = useState(() => isWaitFirst);
  const [isTimeoutState, setIsTimeout] = useState(() => isTimeout);
  const recRoomStatusProcessing = ({ roomUpdate }) => {
    const isWaitFirstResponse =
      roomUpdate.consultingDetail.isWaitFirstResponse === 'Yes';
    const isTimeoutResponse =
      roomUpdate.consultingDetail.isTimeoutResponse === 'Yes';
    isWaitFirstResponse !== isWaitFirstState &&
      setIsWaitFirstState(isWaitFirstResponse);
    isTimeoutResponse !== isTimeoutState && setIsTimeout(isTimeoutResponse);
  };

  /**
   * 最後一筆 msg
   */
  const [lastMsgState, setLastMsgState] = useState(() => lastMessage);
  const { decryptStrHandle } = useCrypto(loginInfo.token);
  const { lastMsg = '', lastMsgTime = 0 } = useMemo(() => {
    if (!lastMsgState || !showLastMsg) return {};
    const typeShow = {
      [MsgContentTypeKeys['Text']]: ({ text }) =>
        text.isEncrypt ? decryptStrHandle(text.text) : text.text,
      [MsgContentTypeKeys['File']]: () => '图片',
      [MsgContentTypeKeys['Join']]: () => '加入房間',
      [MsgContentTypeKeys['Leave']]: () => '离开房间',
      [MsgContentTypeKeys['Voice']]: () => '音讯',
    };
    return {
      lastMsg: typeShow[lastMsgState.contentType]
        ? typeShow[lastMsgState.contentType](lastMsgState)
        : '',
      lastMsgTime: formatTime(lastMsgState.timestamp),
    };
  }, [decryptStrHandle, lastMsgState, showLastMsg]);

  /**
   * 監聽
   *  */
  const isFirstRender = useRef(true);
  useRecMessage({
    roomID,
    msgCommonType: recMsg => {
      if (!isFirstRender.current) {
        verifyAddUnRead(recMsg);
        setLastMsgState(recMsg);
      }
    },
    csRoomStatusProcessing: recMsg => {
      if (recMsg.roomID === roomID) recRoomStatusProcessing(recMsg);
    },
  });

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return (
    <Box display='flex' justifyContent='space-between' alignItems='end' pt='2'>
      <Box display='flex' flexDirection='column' width='90%'>
        {/* msg status */}
        {(isWaitFirstState || isTimeoutState) && (
          <Typography.Text type='danger'>
            {isWaitFirstState
              ? '[待首次响应]' // 優先顯示
              : '[超時]'}
          </Typography.Text>
        )}
        {lastMsg && (
          <Box display='flex' justifyContent='space-between'>
            <Box
              overflow='hidden'
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {lastMsg}
            </Box>
            <Typography.Text type='secondary'>{lastMsgTime}</Typography.Text>
          </Box>
        )}
      </Box>
      <Box display='flex' flexDirection='column'>
        {showUnReadCount && unReadCount > 0 && (
          <Badge count={unReadCount} style={{ background: 'red' }} />
        )}
      </Box>
    </Box>
  );
}

MsgStatus.propTypes = {
  roomID: PropTypes.number,
  isWaitFirst: PropTypes.bool,
  isTimeout: PropTypes.bool,
  showLastMsg: PropTypes.bool,
  showUnReadCount: PropTypes.bool,
  unRead: PropTypes.number,
  lastMessage: PropTypes.object,
};

export default MsgStatus;
