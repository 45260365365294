/**
 * 自動進線
 */
import React from 'react';
import SettingCard from './SettingCard';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';

function AutoCreatedCS({ title = '', AUTO_CREATED_CS, configKeysValue = {} }) {
  const enable = configKeysValue[AUTO_CREATED_CS].value === 'Yes';
  const { onSuccessMsg } = useAlertMessage();
  const [update, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    { onCompleted: () => onSuccessMsg() },
  );
  return (
    <SettingCard
      title={title}
      subTitle='启动后客服前台将自动建立咨询单，客户无需点击「联系客服」按钮'
      hasEnable={true}
      defaultChecked={enable}
      loading={updateLoading}
      onChange={checked =>
        update({
          filter: { PlatformSetting: { key: AUTO_CREATED_CS } },
          in: { value: checked ? 'Yes' : 'No' },
        })
      }
    />
  );
}

export default AutoCreatedCS;
