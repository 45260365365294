import React, { useEffect, useState } from 'react';
import { Drawer as ADrawer, Input, Select, Form } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { Editor, useEditor } from '@/hook/useEditor';
const { theme } = require('@/plugin/theme');

export default function Drawer({
  visible = false,
  setVisible = () => {},
  consultingRoomOriginID,
  parentList = [],
  currentParentItem = {},
  editData = null,
  createFAQ = () => {},
  updateFAQ = () => {},
  loading = false,
  previewVisible = () => {},
}) {
  const { id = 0, title = '主項目' } = currentParentItem;
  const [isEdit, setIsEdit] = useState(false);
  const [faqForm] = Form.useForm();
  const closeDrawer = () => setVisible({ visible: false });

  const {
    editorControls,
    extendControls,
    editorState,
    setHtmlToEditorState,
    changeEditorHandle,
    EditHtmlModal,
  } = useEditor({
    clickPreview: content => previewVisible(true, content),
  });

  const submitHandle = async fields => {
    if (isEdit) {
      updateFAQ({
        filter: { frequentlyQuestion: { id: editData.id } },
        in: { ...fields, asked: editorState.toHTML() },
      });
    } else {
      await createFAQ({
        in: {
          consultingRoomOriginID,
          ...fields,
          parentID: fields?.parentID || id,
          source: fields?.source || '',
          asked: editorState.toHTML(),
        },
      });
    }
    await closeDrawer();
  };
  useEffect(() => {
    const initDrawer = async () => {
      if (editData) {
        setIsEdit(true);
        setHtmlToEditorState(editData.asked);
        await faqForm.setFieldsValue({ ...editData });
      } else {
        setIsEdit(false);
        await faqForm.resetFields();
        await faqForm.setFieldsValue({ parentID: id });
      }
    };
    visible && initDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editData, faqForm, id]);

  return (
    <ADrawer
      visible={visible}
      onClose={closeDrawer}
      width={520}
      title={`新建${title}`}>
      <Form form={faqForm} layout='vertical' onFinish={submitHandle}>
        {id > 0 && (
          <Form.Item name='parentID' label='所属主项目'>
            <Select placeholder='请选择'>
              {parentList.map(p => {
                return (
                  <Select.Option key={p.id} value={p.id}>
                    {p.question}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name='question'
          label='项目标题'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='请输入' />
        </Form.Item>
        {id === 0 && (
          <Form.Item
            name='source'
            label='来源判断'
            rules={[{ required: true, message: '必填' }]}>
            <Input placeholder='请输入' />
          </Form.Item>
        )}
        {/* <Form.Item
          name='asked'
          label='项目内文'
          rules={[{ required: true, message: '必填' }]}>
          <Input.TextArea rows={4} placeholder='请输入' />
        </Form.Item> */}
        <Form.Item
          label={
            <>
              <span
                style={{
                  marginRight: '4px',
                  color: '#ff4d4f',
                  fontSize: '16px',
                  display: 'inline-block',
                }}>
                *
              </span>
              项目内文
            </>
          }>
          <Editor
            value={editorState}
            onChange={changeEditorHandle}
            controls={editorControls}
            extendControls={extendControls}
            style={{ border: `1px solid ${theme['gray-2']}` }}
          />
        </Form.Item>
        <Button variant='primary' htmlType='submit' loading={loading}>
          提交
        </Button>
      </Form>
      {EditHtmlModal()}
    </ADrawer>
  );
}
