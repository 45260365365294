import { filterSubDomain } from '@/utils';
const currDomain = () => {
  return ['localhost', 'bochat.dev.web'].includes(document.domain)
    ? 'dev.api'
    : document.domain;
};

const fullDomain = () => {
  return ['localhost', 'bochat.dev.web'].includes(window.location.host)
    ? 'dev.api'
    : window.location.host;
};

// 愈發測試用
// const MAIN_DOMAIN = 'iyes.dev'; // 只保留一級域名
const MAIN_DOMAIN = filterSubDomain(currDomain()); // 只保留一級域名

export const IS_CLIENT = process.env.REACT_APP_NAME === 'CLIENT';
export const IS_MOBILE = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); // P判斷是PC , Mobile ?



// client
export const CLIENT_API_DOMAIN = process.env.REACT_APP_DOMAIN_ENABLE?`https://${fullDomain()}/bgql`:process.env.REACT_APP_NEW_DOMAIN?`https://${process.env.REACT_APP_NEW_DOMAIN}/bgql`:`${process.env.REACT_APP_API_URL_PREFIX}${MAIN_DOMAIN}`;
export const CLIENT_API_URL = process.env.REACT_APP_DOMAIN_ENABLE?`https://${fullDomain()}/bgql${process.env.REACT_APP_API_FILE}`:process.env.REACT_APP_NEW_DOMAIN?`https://${process.env.REACT_APP_NEW_DOMAIN}/bgql${process.env.REACT_APP_API_FILE}`:`${process.env.REACT_APP_API_URL_PREFIX}${MAIN_DOMAIN}${process.env.REACT_APP_API_FILE}`; // 配置的二級域名 + 一級域名
export const CLIENT_WS_URL = process.env.REACT_APP_DOMAIN_ENABLE?`wss://${fullDomain()}/bws${process.env.REACT_APP_API_FILE}`:process.env.REACT_APP_NEW_DOMAIN?`wss://${process.env.REACT_APP_NEW_DOMAIN}/bws${process.env.REACT_APP_API_FILE}`:`${process.env.REACT_APP_WS_URL_PREFIX}${MAIN_DOMAIN}${process.env.REACT_APP_API_FILE}`; // 配置的二級域名 + 一級域名
// admin
export const ADMIN_API_URL = `${process.env.REACT_APP_API_ORIGIN}${process.env.REACT_APP_API_FILE}`;
export const ADMIN_WS_URL = `${process.env.REACT_APP_WS_ORIGIN}${process.env.REACT_APP_API_FILE}`;

// connect
export const HTTP_URI = IS_CLIENT ? CLIENT_API_URL : ADMIN_API_URL;
export const WS_URI = IS_CLIENT ? CLIENT_WS_URL : ADMIN_WS_URL;

// api
export const API_JUMP_URL = process.env.REACT_APP_DOMAIN_ENABLE?`https://${fullDomain()}/bj/apis/v1/cs_url`:process.env.REACT_APP_NEW_DOMAIN?`https://${process.env.REACT_APP_NEW_DOMAIN}/bj/apis/v1/cs_url`:`${process.env.REACT_APP_API_URL_PREFIX}${MAIN_DOMAIN}/bj/apis/v1/cs_url`;

// other
