import {
  AndroidFilled,
  AppleFilled,
  WindowsOutlined,
  NumberOutlined,
  MobileFilled,
} from '@ant-design/icons';

import alipayUrl from '@/assets/alipay.svg';
import wxUrl from '@/assets/wxUrl.svg';
import bankUrl from '@/assets/bank.png';
import ecnyUrl from '@/assets/ecnyUrl.svg';

const { theme } = require('@/plugin/theme');

export const EnableKey = { Unknown: 'Unknown', No: 'No', Yes: 'Yes' };

export const EnableDisplay = {
  Unknown: { text: '不明', searchText: '全部狀態', color: '' },
  No: { text: '停用', searchText: '停用状态', color: theme['gray-2'] },
  Yes: { text: '啟用', searchText: '启用状态', color: theme['primary'] },
};

// ------- User ---------------------------------------------
/**
 * 帐号類型
 */
export const AccountTypeKeys = {
  Unknown: 'Unknown',
  Manager: 'Manager', // 管理員
  CustomerService: 'CustomerService', // 客服
  CommonUser: 'CommonUser', // 一般會員
  Tourist: 'Tourist', // 遊客
  System: 'System', // 系統
  Monitor: 'Monitor', // 監察人
};

export const AccountTypeDisplay = {
  Unknown: { text: '不明', searchText: '全部类型', color: theme['gray-0'] },
  Manager: { text: '管理員', searchText: '管理員', color: 'blue' },
  CommonUser: { text: '會員', searchText: '會員', color: 'green' },
  CustomerService: { text: '客服', searchText: '客服', color: 'purple' },
  Tourist: { text: '遊客', searchText: '遊客', color: 'orange' },
  System: { text: '系統', searchText: '系統', color: theme['danger'] },
  Monitor: { text: '监察员', searchText: '监察员', color: theme['danger'] },
};

/**
 * User status key
 */
export const UserStatusKey = {
  Unknown: 'Unknown',
  Active: 'Active',
  Lock: 'Lock',
  Delete: 'Delete',
};

export const UserStatusDisplay = {
  Unknown: { text: '不明', searchText: '全部狀態', color: '' },
  Active: { text: '啟用', searchText: '启用状态', color: theme.success },
  Lock: { text: '停用', searchText: '停用狀態', color: theme.warning },
  Delete: { text: '刪除', searchText: '刪除狀態', color: theme['gray-2'] },
};

// ------- Room ---------------------------------------------

export const RoomTypeKey = {
  Unknown: 'Unknown',
  Direct: 'Direct', // 私聊
  Group: 'Group', // 群組
  Consulting: 'Consulting', // 諮詢
  Invite: 'Invite', // 邀請
};

// 房間型態
export const RoomTypeDisplay = {
  Unknown: { text: '不明', searchText: '全部型態', color: '' },
  Direct: { text: '私聊', searchText: '私聊型態', color: 'purple' },
  Group: { text: '群組', searchText: '群組型態', color: 'blue' },
  Consulting: { text: '諮詢', searchText: '諮詢型態', color: 'warning' },
};

// 加入的層級
export const InviteLevel = {
  Unknown: { key: 'Unknown', label: '不明' },
  Member: { key: 'Member', label: '群员' },
  Admin: { key: 'Admin', label: '管理员' },
  Owner: { key: 'Owner', label: '拥有者' },
};

// 陌生人的等級
export const StrangerLevel = {
  Unknown: { key: 'Unknown', label: '不明' },
  All: { key: 'All', label: '所有人' },
  Password: { key: 'Password', label: '需要密码' },
  Close: { key: 'Close', label: '关闭' },
};

// Room Status
export const RoomStatusKeys = {
  Unknown: 'Unknown',
  Active: 'Active', // 開啟
  Deactivated: 'Deactivated', // 封存
  Processing: 'Processing', // 處理中
};

export const RoomStatusDisplay = {
  Unknown: { text: '不明' },
  Active: { text: '開啟' },
  Deactivated: { text: '封存' },
  Processing: { text: '處理中' },
};

export const CSRoomTypeKey = {
  Consulting: 'Consulting',
  Deposit: 'Deposit', // 充值
};

// ------- message ---------------------------------------------
// post message
export const MsgContentTypeKeys = {
  Unknown: 'Unknown',
  Text: 'Text', // 文字
  File: 'File', // 檔案
  Voice: 'Voice', // 錄音
  Join: 'Join', // 加入
  Leave: 'Leave', // 離開
  Invite: 'Invite', // 邀請
  LastRead: 'LastRead', // 已讀
  System: 'System', // 系統訊息
  Bubble: 'Bubble', // 客制組合訊息
};

// post message
export const ReceiveMsgContentType = {
  Unknown: 'Unknown',
  Hi: 'Hi', // 確認連線
  Text: 'Text', // 文字
  File: 'File', // 檔案
  Voice: 'Voice', // 錄音
  Invite: 'Invite', // 邀請
  Join: 'Join', // 加入
  Leave: 'Leave', // 離開
  LastRead: 'LastRead', // 最後讀取訊息
  Consulting: 'Consulting', // 有新的諮詢聊天室
  Geo: 'Geo', // 位置資訊
  Typing: 'Typing', // 正在輸入
  RoomUpdate: 'RoomUpdate', // 房間 detail 更新
  UserOnlineInfo: 'UserOnlineInfo', // 使用者上下線
  BusinessSystemNotification: 'BusinessSystemNotification', // 業務系統通知
  System: 'System', // 系統訊息
  Bubble: 'Bubble', // 客制組合訊息
};

export const MsgStatusKeys = {
  Unknown: 'Unknown',
  Initial: 'Initial', // 初始狀態
  Retract: 'Retract', // 收回
  Edit: 'Edit', // 修改
};

export const MsgSystemType = {
  PayList: 'PayList',
  UserOrder: 'UserOrder',
  Text: 'Text',
};

export const MsgFromTags = {
  AutoReplay: 'AutoReplay',
};

// 前端自定義訊息
export const CusMsgContentType = 'CusMsgContentType';

// ------- order ---------------------------------------------

// 支付方式
export const PayType = {
  wx: { key: 'wx', label: '微信' },
  alipay: { key: 'alipay', label: '支付宝', describe: '' },
  bank: { key: 'bank', label: '银行卡' },
  ecny: { key: 'ecny', label: '数字人民币' },
  sub_alipay: { key: 'sub_alipay', label: '支付宝' },
  // union: { key: 'union', label: '云闪付' },
  // qq: { key: 'qq', label: 'QQ支付' },
  // jd: { key: 'jd', label: '京东支付' },
  // crypto: { key: 'crypto', label: '加密货币' },
  // xlpay: { key: 'xlpay', label: '闲聊支付' },
};

export const PayTypeImg = {
  [PayType['wx'].key]: wxUrl, // 微信
  [PayType['alipay'].key]: alipayUrl, // 支付寶
  [PayType['bank'].key]: bankUrl, // 銀行卡
  [PayType['ecny'].key]: ecnyUrl, // 數字人民幣
};

// 支付方法
export const PayMethod = {
  redirect: 'redirect',
  qrcode: 'qrcode',
  bank: 'bank',
  sub_alipay: 'sub_alipay',
};

export const OrderStatusKeys = {
  Unknown: { key: 'Unknown', label: '全部', color: '' },
  // New: { key: 'New', label: '等待支付', color: 'orange' },
  Pending: { key: 'Pending', label: '等待支付', color: '#ed9c23' },
  Waiting: { key: 'Waiting', label: '等待到账', color: '#e43737' },
  Canceled: { key: 'Canceled', label: '已取消', color: '#7f7f7f' },
  Succeeded: { key: 'Succeeded', label: '成功', color: '#37cb69' },
  Failed: { key: 'Failed', label: '拒绝', color: '#7f7f7f' },
  Denied: { key: 'Denied', label: '审核拒绝', color: '#7f7f7f' },
  Expired: { key: 'Expired', label: '超时', color: '#7837cb' },
};

export const ConsultingOrderTypeKeys = {
  Consulting: 'Consulting',
  Urging: 'Urging',
};

// ------- PushNotifications ---------------------------------------------

export const PushStatusKeys = {
  PENDING: { text: '待發送', variant: 'outline-warning' },
  SUCCESS: { text: '已發送', variant: 'outline-success' },
  FAIL: { text: '失敗', variant: 'outline-danger' },
  CANCELED: { text: '已撤回', variant: 'outline-secondary' },
};

export const PushTargetKey = {
  ALL: { text: '所有用戶' },
  SINGLE_USER: { text: '單個用戶' },
};

export const IsScheduledKeys = {
  Yes: { text: '定時推送', color: theme.primary },
  No: { text: '立即推送', color: theme.success },
};

// ------- PushNotifications ---------------------------------------------

export const MoreInformationTypeKey = {
  Unknown: 'Unknown',
  HTML: 'HTML',
  URL: 'URL',
};

// ------- deviceOS ---------------------------------------------

export const deviceOSKeys = {
  Unknown: {
    text: '不明',
    icon: style => <NumberOutlined style={style} />,
  },
  IOS: {
    text: 'IOS',
    icon: style => <MobileFilled style={style} />,
  },
  Mac: {
    text: 'Mac',
    icon: style => <AppleFilled style={style} />,
  },
  Android: {
    text: 'Android',
    icon: style => <AndroidFilled style={style} />,
  },
  Windows: {
    text: 'Windows',
    icon: style => <WindowsOutlined style={style} />,
  },
  Other: {
    text: '其他',
    icon: style => <NumberOutlined style={style} />,
  },
};

// ------- CSForm ---------------------------------------------

export const CSFormFieldType = {
  Input: 'Input',
  Radio: 'Radio',
  Checkbox: 'Checkbox',
};

export const CSFormFieldVerifyType = {
  Text: 'Text', // 不驗證
  Email: 'Email',
  Age: 'Age',
  QQ: 'QQ',
  WeChat: 'WeChat',
};

// ------- Merchant ---------------------------------------------

export const MerchantTypeKeys = {
  Unknown: 'Unknown',
  Bochat: 'Bochat',
  Spinach: 'Spinach',
};

// ------- PlatFormSettingKey ---------------------------------------------

export const PlatFormSettingKey = {
  BEEP: 'bochat.platform.consulting.beep',
  AUTO_DISTRIBUTE: 'bochat.platform.consulting.auto_distribute',
  AUTO_DEACTIVATE_MIN: 'bochat.platform.consulting.deactivate',
  AUTO_DEACTIVATE_ENABLED: 'bochat.platform.consulting.deactivate.enabled',
  AUTO_CREATED_CS: 'bochat.platform.consulting.client.enter_directly',
  QUEUE_MAX_COUNT: 'bochat.platform.consulting.max_processing_count',
  DIALOGUE_TIMEOUT_SEC: 'bochat.platform.consulting.time_out.sec',
  DIALOGUE_TIMEOUT_SEC_ENABLED: 'bochat.platform.consulting.time_out.enabled',
  NO_RESPONSE_TIP: 'bochat.platform.consulting.no_response.tip',
  NO_RESPONSE_SEC: 'bochat.platform.consulting.no_response.sec',
  NO_RESPONSE_ENABLED: 'bochat.platform.consulting.no_response.enabled',
  DEACTIVATE_TIP_MANUAL: 'bochat.platform.consulting.deactivate.tip.manual',
  DEACTIVATE_TIP_AUTO: 'bochat.platform.consulting.deactivate.tip.auto',
  DEACTIVATE_TIP_ENABLED: 'bochat.platform.consulting.deactivate.tip.enabled',
  REDIRECT_VERIFY_ENABLED:
    'bochat.platform.consulting.client.redirect_verify.enabled',
};

// ------- Other ---------------------------------------------

/**
 * pagination
 */
export const Pagination = {
  page: 1,
  perPage: 10,
  total: 0,
};

/**
 * 瀏覽器
 */
export const BrowserType = {
  IE: 'MSIE',
  Edge: 'Edg',
  Firefox: 'Firefox',
  Sogou: 'MetaSr',
  Opera: 'OPR',
  Chrome: 'Chrome',
  Safari: 'Safari',
  Netscape: 'Netscape',
  UC: 'UCBrowser',
};

export const Currency = {
  RMB: '¥',
};

/**
 * 追蹤碼類型
 */
export const AdTrackingType = {
  CNZZ: 'CNZZ',
  Google: 'Google',
  Baidu: 'Baidu',
};
