import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import { Row, Col } from 'antd';
import BasicForm from '@/components/User/BasicForm/Form';
import AvatarUpload from '@/components/Avatar/AvatarUpload';

const UserBasicForm = ({
  userID,
  formColProps,
  avatarColProps,
  isEdit = true,
}) => {
  const { onSuccessMsg } = useAlertMessage();
  const [detailData, setDetailData] = useState(null);
  const { loading, refetch } = useQuery(apiType.GET_USER, {
    variables: { filter: { user: { id: userID } } },
    onCompleted: ({ getUser }) => setDetailData(() => getUser || null),
  });
  const [file, setFile] = useState(null);
  const [updateUser, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_USER,
  );
  const updateUserHandle = async fields => {
    let formFields = { ...fields };
    if (file) formFields.avatarContent = file;
    const [err] = await updateUser({
      filter: { user: { id: userID } },
      in: { ...formFields },
    });
    if (err) return;
    refetch();
    onSuccessMsg('update');
  };

  const [updateUserPass, { loading: updatePassLoading }] = useMutationApi(
    apiType.UPDATE_USER_PASS,
  );
  const updateUserPassHandle = async ({ password, userID }) => {
    const [err] = await updateUserPass({ in: { userID, password } });
    if (err) return;
    onSuccessMsg('update');
  };

  return (
    <Row gutter={60}>
      <Col span={14} order={1} {...formColProps}>
        <BasicForm
          user={detailData}
          loading={loading}
          btnUserLoading={updateLoading}
          updatePassLoading={updatePassLoading}
          updateUser={updateUserHandle}
          updatePass={updateUserPassHandle}
          isEdit={isEdit}
        />
      </Col>
      <Col span={10} order={2} {...avatarColProps}>
        <AvatarUpload
          avatarURL={detailData?.avatarURL || ''}
          setUploadFile={file => setFile(file)}
          isEdit={isEdit}
        />
      </Col>
    </Row>
  );
};

UserBasicForm.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default UserBasicForm;
