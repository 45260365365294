import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Box } from '@WRAP_COMP';
import { Tabs, Button } from 'antd';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import { AccountTypeKeys } from '@/utils';
import Drawer from './Drawer';
import TabTable from './TabTable';

const initSearch = { name: '' };
const searchFields = [{ name: 'name', type: 'text', label: '角色名称' }];

const EmployeeRoleMgm = () => {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const [loadingObj, setLoadingObj] = useState({ list: false, delete: false });
  const [defaultSearch, setDefaultSearchData] = useState({ ...initSearch });

  const [managerData, setManagerData] = useState({}); // 管理員角色
  const [customerServiceData, setCustomerServiceData] = useState({}); // 客服角色
  const [monitorData, setMonitorData] = useState({}); // 監察角色

  const [detailSource, setDetailSource] = useState({});
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [reload, setReload] = useState(false);

  const { onErrorMsg } = useAlertMessage();
  const { fetchData: fetchList } = useQueryApi(apiType.LIST_ROLE);
  const { fetchData: fetchDetail } = useQueryApi(apiType.GET_ROLE);
  const [deleteRole] = useMutationApi(apiType.DELETE_ROLE); // 刪除

  const tabsData = [
    {
      key: AccountTypeKeys['Manager'],
      title: '管理員角色',
      defaultRoleID: 1,
      data: managerData,
      setData: setManagerData,
    },
    {
      key: AccountTypeKeys['CustomerService'],
      title: '客服角色',
      defaultRoleID: 2,
      data: customerServiceData,
      setData: setCustomerServiceData,
    },
    {
      key: AccountTypeKeys['Monitor'],
      title: '监察角色',
      defaultRoleID: 4,
      data: monitorData,
      setData: setMonitorData,
    },
  ];

  // data 處理
  const tableDataHandle = ({ roles = [], meta = {} }, key) => {
    try {
      tabsData.forEach(
        t => t.key === key && t.setData(() => ({ list: roles, meta })),
      );
    } catch (err) {
      onErrorMsg(err);
    }
  };

  const fetchListHandle = async (searchData, { page, perPage }) => {
    const { supportAccountType } = searchData;
    const searchParams = { ...defaultSearch, ...searchData };
    setLoadingObj(pre => ({ ...pre, list: true }));
    const { data, error } = await fetchList({
      filter: {
        role: { ...searchParams },
        baseFilter: { sortField: 'id', sortType: 'DESC' },
      },
      pagination: { page, perPage },
    });
    setLoadingObj(pre => ({ ...pre, list: false }));
    if (error) return;
    tableDataHandle(data?.listRole || {}, supportAccountType);
    setReload(false);
  };

  const fetchDetailHandel = async id => {
    setLoadingObj(pre => ({ ...pre, list: true }));
    const { data, error } = await fetchDetail({ filter: { role: { id } } });
    setLoadingObj(pre => ({ ...pre, list: false }));
    if (error) {
      onErrorMsg(error);
    } else {
      setDetailSource({ ...data });
      openDrawer(true, true);
    }
  };

  const deleteRoleHandler = async id => {
    setLoadingObj(pre => ({ ...pre, delete: true }));
    const [err] = await deleteRole({ filter: { role: { id } } });
    setLoadingObj(pre => ({ ...pre, delete: false }));
    return err;
  };

  const openDrawer = (open, openType) => {
    setVisible(open);
    setIsEdit(() => (open ? openType : false));
  };

  const tableRender = ({ key, data, defaultRoleID }) => {
    return (
      <TabTable
        supportAccountType={key}
        defaultSearch={defaultSearch}
        dataSource={data.list}
        meta={data.meta}
        defaultRoleID={defaultRoleID}
        loadingObj={loadingObj}
        fetchListHandle={fetchListHandle}
        fetchDetailHandel={fetchDetailHandel}
        deleteRoleHandler={deleteRoleHandler}
        openDrawer={openDrawer}
        reload={reload}
        authRoutesKey={authRoutesKey}
      />
    );
  };

  return (
    <>
      <PageWrap>
        <SearchBar
          fields={searchFields}
          defaultSearch={initSearch}
          onFinish={setDefaultSearchData}
        />
        <Box bgColor='white' p='3'>
          <Tabs
            defaultActiveKey='1'
            type='card'
            tabBarGutter={5}
            tabBarExtraContent={
              authRoutesKey.includes('API_Employee_Role_Create') && (
                <Button
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => openDrawer(true, false)}>
                  新建角色
                </Button>
              )
            }>
            {tabsData.map(tab => (
              <Tabs.TabPane
                tab={<span>{tab.title}</span>}
                key={tab.key}
                forceRender>
                {/* 列表 */}
                {tableRender(tab)}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Box>
      </PageWrap>
      <Drawer
        visible={visible}
        setVisible={openDrawer}
        isEdit={isEdit}
        refetch={() => setReload(true)}
        detailData={detailSource}
      />
    </>
  );
};

export default EmployeeRoleMgm;
