import React from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input } from 'antd';
import { Button } from '@/plugin/wrap-comp';

function CreatedForm({ categoryID = null, createdHandle, loading }) {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  return (
    <Row justify='end'>
      <Col>
        <Form
          form={form}
          onFinish={field => createdHandle(field, categoryID, form)}
          layout='inline'>
          <Form.Item
            name='content'
            rules={[{ required: true, message: '必填' }]}>
            <Input
              placeholder={categoryID ? '快捷內容' : '快捷类别名称'}
              size={categoryID ? 'small' : 'default'}
            />
          </Form.Item>
          <Form.Item>
            {authRoutesKey.includes('API_CannedResponse_Create') && (
              <Button
                variant={categoryID ? 'outline-primary' : 'primary'}
                htmlType='submit'
                loading={loading}
                size={categoryID ? 'small' : 'default'}
                icon={<PlusOutlined />}>
                新建
              </Button>
            )}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default CreatedForm;
