import React from 'react';
import { formatDateTime, secondsToHms } from '@/utils';
import { Badge, Tooltip } from 'antd';

function ConversationTime({ createdAt, deactivatedAt }) {
  const items = [
    { status: 'success', title: '建立时间', text: formatDateTime(createdAt) },
    {
      status: 'success',
      title: '封存时间',
      text: formatDateTime(deactivatedAt),
    },
    {
      status: 'blue',
      title: '对话时间',
      text: secondsToHms(deactivatedAt - createdAt),
    },
  ];
  return (
    <>
      {items.map(({ title, status, text }, idx) => (
        <div key={idx}>
          <Tooltip placement='left' title={title}>
            <Badge status={status} text={text} />
          </Tooltip>
          <br />
        </div>
      ))}
    </>
  );
}

export default ConversationTime;
