import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SoundOutlined } from '@ant-design/icons';
import { Switch, Upload, Space, Card, Typography, Divider } from 'antd';
import { Box, Button } from '@WRAP_COMP';
import useBeep from '@/hook/useBeep';

function BeepSetting({ title = '' }) {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const {
    beepAudioSrc,
    beepEnabled,
    beepClientEnabled,
    loading,
    updateLoading,
    keys,
    updateBeepEnabled,
    updateBeepFile,
    fetchBeepFile,
    fetchBeepEnabled,
  } = useBeep();

  const onChangeHandle = (checked, key) => {
    const value = checked ? 'Yes' : 'No';
    updateBeepEnabled({ data: { value } }, key);
  };

  const [uploadSrc, setUploadSrc] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const beforeAudioUpload = async file => {
    setUploadSrc(() => window.URL.createObjectURL(file));
    setUploadFile(file);
    return false;
  };

  const audioUploadHandle = file => {
    updateBeepFile({ data: { file } });
  };

  useEffect(() => {
    fetchBeepFile();
    fetchBeepEnabled();
    fetchBeepEnabled(keys.BEEP_CLIENT_ENABLED_KEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const audioRender = audioSrc =>
    React.createElement('audio', { controls: true, src: audioSrc });

  return (
    <Card title={title}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        {audioRender(uploadSrc || beepAudioSrc)}
        <Space>
          <Upload
            name='file'
            beforeUpload={beforeAudioUpload}
            showUploadList={false}
            accept='.mp3,audio/*'>
            <Button variant='outline-primary' icon={<SoundOutlined />}>
              选择音频挡案
            </Button>
          </Upload>
          <Button
            type='primary'
            disabled={
              !uploadFile ||
              updateLoading ||
              !authRoutesKey.includes('API_ConsultingRoomSetting_Beep')
            }
            onClick={() => audioUploadHandle(uploadFile)}>
            确认上传
          </Button>
        </Space>
      </Box>
      <Divider />
      <Box display='flex' justifyContent='space-between' mb='3'>
        <div>
          <Typography.Title level={5}>后台提示音提醒</Typography.Title>
          <span>后台回复咨询信息时播放提示音提醒</span>
        </div>
        <Switch
          checked={beepEnabled}
          loading={loading || updateLoading}
          disabled={!authRoutesKey.includes('API_ConsultingRoomSetting_Beep')}
          checkedChildren='开启'
          unCheckedChildren='关闭'
          onChange={checked => onChangeHandle(checked, keys.BEEP_ENABLED_KEY)}
        />
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <div>
          <Typography.Title level={5}>用户端提示音提醒</Typography.Title>
          <span>客服用户端收到新讯息提示音</span>
        </div>
        <Switch
          checked={beepClientEnabled}
          loading={loading || updateLoading}
          disabled={!authRoutesKey.includes('API_ConsultingRoomSetting_Beep')}
          checkedChildren='开启'
          unCheckedChildren='关闭'
          onChange={checked =>
            onChangeHandle(checked, keys.BEEP_CLIENT_ENABLED_KEY)
          }
        />
      </Box>
    </Card>
  );
}

export default BeepSetting;
