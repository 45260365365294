import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Select, Spin, Form, Divider } from 'antd';
import { UserStatusDisplay, AccountTypeKeys, MerchantTypeKeys } from '@/utils';

const BasicForm = ({
  user = null,
  loading = false,
  btnUserLoading = false,
  updatePassLoading = false,
  isEdit = true,
  editType = 'normal', // normal, self
  updateUser = () => {},
  updatePass = () => {},
}) => {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const [isFirst, setIsFirst] = useState(true);
  const [inputValue, setInputValue] = useState('*******');
  const onChangeInputPass = e => {
    if (isFirst) {
      setInputValue(e.nativeEvent.data);
      setIsFirst(false);
    } else {
      setInputValue(e.target.value);
    }
  };

  const [userForm] = Form.useForm();
  useEffect(() => {
    const userDataHandle = async d => {
      await userForm.setFieldsValue(d);
    };
    userDataHandle(user);
  }, [user, userForm]);

  const accountTypeSelect = accType => {
    const selectObj = {
      [AccountTypeKeys['Manager']]: [
        { value: 'Manager', label: '管理员' },
        { value: 'CustomerService', label: '客服' },
      ],
      [AccountTypeKeys['CustomerService']]: [
        { value: 'CustomerService', label: '客服' },
        { value: 'Manager', label: '管理员' },
      ],
      [AccountTypeKeys['CommonUser']]: [
        { value: 'CommonUser', label: '一般会员' },
      ],
      [AccountTypeKeys['Tourist']]: [{ value: 'Tourist', label: '游客' }],
    };
    return selectObj[accType] || selectObj[AccountTypeKeys['Tourist']];
  };

  const disabledPassInput = () => {
    // 只能修改 Bochat 裡的用戶密碼
    if (user?.merchantType !== MerchantTypeKeys['Bochat']) return true;
    // 有修改客服密碼的權限
    if (
      user.accountType === AccountTypeKeys['CustomerService'] &&
      !authRoutesKey.includes('API_CustomerService_Password_Update')
    )
      return true;
    // 有修改員工密碼的權限
    if (
      user.accountType === AccountTypeKeys['Manager'] &&
      !authRoutesKey.includes('API_Employee_Password_Update')
    )
      return true;
    return false;
  };

  return (
    <Spin spinning={loading}>
      {editType !== 'self' && (
        <Form layout='vertical'>
          <Form.Item label='帐号'>
            <Input value={user?.username} disabled />
          </Form.Item>
          <Form.Item label='密码'>
            <Input.Group compact>
              <Input
                value={inputValue}
                onChange={onChangeInputPass}
                disabled={disabledPassInput()}
                style={{ width: 'calc(100% - 88px)' }}
                placeholder='请输入'
              />
              <Button
                type='primary'
                onClick={() =>
                  updatePass({ userID: user.id, password: inputValue })
                }
                disabled={isFirst}
                loading={updatePassLoading}>
                更新密码
              </Button>
            </Input.Group>
          </Form.Item>
        </Form>
      )}

      <Divider />
      <Form form={userForm} layout='vertical' onFinish={updateUser}>
        <Form.Item name='aliasName' label='別名' tooltip='聊天室显示用'>
          <Input placeholder='请输入' disabled={!isEdit} autoComplete='off' />
        </Form.Item>
        {editType !== 'self' && user?.accountType && (
          <Form.Item name='accountType' label='帐户类型'>
            <Select
              placeholder='请选择'
              disabled={
                !isEdit ||
                user.accountType === AccountTypeKeys['Tourist'] ||
                user.accountType === AccountTypeKeys['CommonUser']
              }>
              {accountTypeSelect(user.accountType).map(item => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {editType !== 'self' && (
          <>
            <Form.Item
              name='status'
              label='启用状态'
              rules={[{ required: true, message: '必填' }]}>
              <Select placeholder='请选择' disabled={!isEdit}>
                {Object.keys(UserStatusDisplay).map(kk => (
                  <Select.Option
                    key={kk}
                    value={kk}
                    disabled={kk === 'Unknown'}>
                    {UserStatusDisplay[kk].text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='area' label='居住地区'>
              <Input placeholder='请输入' disabled={!isEdit} />
            </Form.Item>
            <Form.Item name='notes' label='备注'>
              <Input.TextArea
                placeholder='请输入'
                allowClear
                disabled={!isEdit}
              />
            </Form.Item>
          </>
        )}
        {!isEdit || (
          <Button loading={btnUserLoading} type='primary' htmlType='submit'>
            更新
          </Button>
        )}
      </Form>
    </Spin>
  );
};

export default BasicForm;
