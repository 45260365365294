import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

const { theme } = require('@/plugin/theme');

const status = {
  online: { text: '在线', status: 'processing', color: theme.success },
  offline: { text: '离线', status: 'default', color: theme.default },
  stealth: { text: '隐身', status: 'warning', color: theme.warning },
};

function OnlineStatusBadge({ isOnline = '', isStealth = '' }) {
  const type =
    isOnline === 'Yes'
      ? isStealth === 'Yes'
        ? 'stealth'
        : 'online'
      : 'offline';

  return (
    <Badge
      status={status[type].status}
      text={status[type].text}
      color={status[type].color}
    />
  );
}

OnlineStatusBadge.propTypes = {
  isOnline: PropTypes.string,
  isStealth: PropTypes.string,
};
export default OnlineStatusBadge;
