/**
 * 聊天室管理
 */

import React, { useState } from 'react';
import { PlusOutlined, StopTwoTone } from '@ant-design/icons';
import { Button, Space, Badge } from 'antd';
import { Tag } from '@/plugin/wrap-comp';
import { useSelector } from 'react-redux';
import { apiType, useMutationApi } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import useAlertMessage from '@/hook/useAlertMessage';
import PageWrap from '@/components/PageWrap';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import {
  PushStatusKeys,
  PushTargetKey,
  formatDateTime,
  IsScheduledKeys,
} from '@/utils';
import CreateDrawer from './CreateDrawer';

export default function PushMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { onSuccessMsg } = useAlertMessage();
  const {
    data: { list, total },
    loading,
    pagination,
    refetch,
    handleChangePage,
  } = useActiveList({
    api: 'LIST_PUSH',
    handleData: ({ listPushNotification }) => ({
      list: listPushNotification.pushNotifications || [],
      total: listPushNotification?.meta?.total || 0,
    }),
  });

  // 新增
  const [createVisible, setCreateVisible] = useState(false);
  const [cancelFetch, { loading: cancelLdg }] = useMutationApi(
    apiType.CANCEL_PUSH,
  );
  const cancelFetchHandle = async id => {
    const [err] = await cancelFetch({
      filter: { pushNotification: { id } },
    });
    if (err) return;
    onSuccessMsg();
    refetch();
  };

  const pushTimeRender = ({
    isScheduled,
    createdAt,
    scheduledTime,
    status,
  }) => {
    let pushTime = formatDateTime(createdAt);
    if (isScheduled === 'Yes') {
      pushTime = status === 'Success' ? formatDateTime(scheduledTime) : '-';
    }
    return <>{pushTime}</>;
  };

  const scheduledTimeRender = (time, row) => {
    return (
      <>
        <Badge
          color={IsScheduledKeys[row.isScheduled].color}
          text={IsScheduledKeys[row.isScheduled].text}></Badge>
        {row.isScheduled === 'Yes' ? <p>{formatDateTime(time)}</p> : null}
      </>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
    },
    {
      title: '推送时间',
      key: 'pushTime',
      width: '20%',
      render: row => <>{pushTimeRender(row)}</>,
    },
    {
      title: '推送标题',
      dataIndex: 'title',
      key: 'title',
      width: '10%',
      render: h => <>{h}</>,
    },
    {
      title: '推送内容',
      dataIndex: 'content',
      key: 'content',
      render: h => <span style={{ wordBreak: 'break-all' }}>{h}</span>,
    },
    {
      title: '目标人群',
      dataIndex: 'target',
      key: 'target',
      width: '10%',
      render: h => <>{PushTargetKey[h].text}</>,
    },
    {
      title: '推送时间设定',
      dataIndex: 'scheduledTime',
      key: 'scheduledTime',
      width: '20%',
      render: (h, row) => <>{scheduledTimeRender(h, row)}</>,
    },
    {
      title: '发送状态',
      dataIndex: 'status',
      key: 'status',
      render: h => (
        <Tag variant={PushStatusKeys[h].variant}>{PushStatusKeys[h].text}</Tag>
      ),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: ({ id, status }) => (
        <Space>
          <BtnDeleteConfirm
            loading={cancelLdg}
            title='确认撤回？'
            btnProps={{
              tipTitle: '撤回',
              icon: <StopTwoTone />,
              disabled: status !== 'PENDING',
            }}
            handleOk={() => cancelFetchHandle(id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        {authRoutesKey.includes('API_Push_Create') && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setCreateVisible(true)}>
            新建推送
          </Button>
        )}
      </DataTable>
      <CreateDrawer
        visible={createVisible}
        setVisible={setCreateVisible}
        refetch={refetch}
      />
    </PageWrap>
  );
}
