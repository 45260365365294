/**
 * 房間成員 + 待加入成員
 * - useRooms
 * - userInvitations
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { List, Space, Typography, Tag, Divider, Row, Col } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import AvatarFetch from '@/components/Avatar/AvatarFetch';
import { AccountTypeDisplay, RoomTypeKey } from '@/utils';

function RoomUserList({
  roomType = '',
  userRooms = [],
  userInvitations = [],
  inviteBtnText = '加入客服',
  roomUsersMap = {},
  openInviteDrawer = () => {},
}) {
  // 顯示房間成員 (濾掉離開的)
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const showUserInRoom = (useRooms = []) => {
    return useRooms.filter(u => u.deletedAt === 0 && roomUsersMap[u.userID]);
  };
  return (
    <>
      <Divider orientation='right'>房间成员</Divider>
      <List
        dataSource={showUserInRoom(userRooms)}
        renderItem={u => (
          <List.Item
            size='small'
            style={{ borderBottom: '0px', padding: '8px 0' }}>
            {roomUsersMap[u.userID] && (
              <>
                <Space>
                  <AvatarFetch avatarURL={roomUsersMap[u.userID].avatarURL} />
                  <Typography.Text>
                    {roomUsersMap[u.userID]?.username} #
                    {roomUsersMap[u.userID]?.id}
                  </Typography.Text>
                </Space>
                {AccountTypeDisplay[roomUsersMap[u.userID].accountType] ? (
                  <Tag
                    color={
                      AccountTypeDisplay[roomUsersMap[u.userID].accountType]
                        .color
                    }>
                    {
                      AccountTypeDisplay[roomUsersMap[u.userID].accountType]
                        .text
                    }
                  </Tag>
                ) : null}
              </>
            )}
          </List.Item>
        )}
      />

      {/* 正在邀請 */}
      {userInvitations.length > 0 && (
        <>
          <Divider orientation='right'>正在邀请</Divider>
          <List
            dataSource={userInvitations}
            renderItem={u => (
              <List.Item
                size='small'
                style={{ borderBottom: '0px', padding: '8px 0' }}>
                <Space>
                  <AvatarFetch avatarURL={u.inviteeUser.avatarURL} />
                  <Typography.Text>
                    {u.inviteeUser.username} #{u.inviteeUser.id}
                  </Typography.Text>
                </Space>
              </List.Item>
            )}
          />
        </>
      )}
      {roomType !== RoomTypeKey['Direct'] && (
        <Row justify='end' mt={4}>
          <Col>
            <Button
              variant='outline-primary'
              onClick={() => openInviteDrawer(true)}
              disabled={!authRoutesKey.includes('API_PullInConsultingRoom')}>
              {inviteBtnText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}

export default RoomUserList;
