import React from 'react';
import PropTypes from 'prop-types';
import { PushpinTwoTone } from '@ant-design/icons';
import { Typography } from 'antd';
import { Box } from '@WRAP_COMP';

function RoomInfo({
  roomID = 0,
  ipAddress = '',
  country = '',
  administrativeArea = '',
  isPinned = false,
}) {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='start'
      pt='1'>
      <Box display='flex' flexDirection='column' width='90%'>
        <Typography.Text type='secondary'>房間ID: #{roomID}</Typography.Text>
        <Typography.Text type='secondary' style={{ overflowWrap: 'anywhere' }}>
          房間IP: {country}
          {administrativeArea}({ipAddress})
        </Typography.Text>
      </Box>
      {isPinned && <PushpinTwoTone style={{ fontSize: '16px' }} />}
    </Box>
  );
}

RoomInfo.propTypes = {
  roomID: PropTypes.number,
  ipAddress: PropTypes.string,
  country: PropTypes.string,
  administrativeArea: PropTypes.string,
  isPinned: PropTypes.bool,
};
export default RoomInfo;
