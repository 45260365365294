import { gql } from '@apollo/client';
import { MSG_REQUIRED } from './message';
import {
  CS_DETAIL_FIELD,
  CS_SETTING_FIELD,
  CS_FORM_FIELD,
  CS_GREETING_FIELD,
  MSG_SYSTEM,
  COMMENT_USER_ROOMS,
} from './Fragment';

/**
 * client Ｈ5 相關
 * - 諮詢房設定 + 詢前表單
 * - 諮詢房設定 + 詢前表單 + 房間資訊
 * - 行銷問候語 + FAQ depth 1
 * - 建立諮詢室
 * - 房間資訊 + 房間訊息
 */

// 諮詢房設定 + 詢前表單
export const GET_CS_ROOM_SETTING_AND_FORM = gql`
  ${CS_SETTING_FIELD}
  ${CS_FORM_FIELD}
  query csRoomSettingAndForm {
    listConsultingRoomQuestion {
      consultingRoomQuestions {
        id
        question
        maxProcessingCount
        createdAt
      }
    }
    getConsultingRoomClientSetting(filter: { isClient: true }) {
      ...CSSetting
    }
    getConsultingRoomForm(filter: { isClient: true }) {
      ...CSForm
    }
  }
`;

// 行銷問候語 + FAQ depth 1
export const GET_GREETING_AND_FAQ = gql`
  ${CS_GREETING_FIELD}
  query greetingAndFaq($source: String) {
    listConsultingGreeting(
      filter: { consultingGreeting: { isEnabled: Yes }, isClient: true }
    ) {
      ConsultingGreetings {
        ...CSGreeting
      }
    }
    listFrequentlyQuestion(
      filter: {
        frequentlyQuestion: { depth: 1, source: $source }
        isClient: true
      }
    ) {
      frequentlyQuestions {
        id
        consultingRoomOriginID
        question
        asked
        parentID
        sequence
        source
        depth
      }
    }
  }
`;

// 房間資訊 + 房間訊息
export const GET_ROOM_DETAIL = gql`
  ${CS_DETAIL_FIELD}
  ${COMMENT_USER_ROOMS}
  ${MSG_REQUIRED}
  ${MSG_SYSTEM}
  query getRoomDetail($roomID: Uint64) {
    getRoom(filter: { room: { id: $roomID } }) {
      id
      type
      createdAt
      updatedAt
      consultingDetail {
        ...consultingDetailField
      }
      userRooms {
        ...userRoomsFields
      }
    }
    listMessage(
      filter: { roomID: $roomID }
      pagination: { offsetType: New, limit: 20 }
    ) {
      messages {
        ...messageFields
        text {
          text
          isEncrypt
        }
        file {
          text
          fileURL
          type
        }
        invite {
          id
          type
          inviteeUserIDs
          inviterUserID
          roomID
        }
        join {
          userID
        }
        leave {
          userID
        }
        system {
          ...MsgSystemField
        }
      }
      meta {
        total
      }
    }
  }
`;

// 建立 一般諮詢室
export const CREATED_CS_ROOM = gql`
  mutation createConsultationRoom($in: createConsultationRoomInput) {
    createConsultationRoom(in: $in) {
      room {
        id
        consultingDetail {
          roomID
          ownerUserID
          status
          type
        }
      }
      token
      deviceUID
    }
  }
`;

// 建立 充值諮詢室
export const CREATED_CS_DEPOSIT_ROOM = gql`
  mutation createUserDepositRoom($in: createUserDepositRoomInput) {
    createUserDepositRoom(in: $in) {
      room {
        id
        consultingDetail {
          roomID
          ownerUserID
          status
          type
        }
      }
      token
      deviceUID
    }
  }
`;

// 代客充值取得密碼及token
export const GET_WEB_DEPOSIT_TOKEN = gql`
  query getWebToken($token: String) {
    getWebToken(token: $token) {
      code
    }
  }
`;

// 查詢可支付渠道
export const listPaymentChannel = gql`
  query listPaymentChannel($in: ListPaymentChannelInput!) {
    listPaymentChannel(in: $in) {
      channels {
        payeeID
        alias
        name
        payType
        payMethod
        account
        bank
        bankCode
        subBank
        amount
      }
    }
  }
`;

// 生成訂單
export const deposit = gql`
  mutation deposit($in: DepositInput!) {
    deposit(in: $in) {
      payType
      payMethod
      orderID
      orderNo
      amount
      currency
      expiredAt
      bank {
        bankName
        branchName
        bankCode
        accountName
        cardNo
      }
      qrcode {
        url
        qUrl
      }
      redirect {
        url
      }
    }
  }
`;

// 過期訂單
export const expireDeposit = gql`
  mutation expireDeposit($in: ExpireDepositInput!) {
    expireDeposit(in: $in) {
      Result
    }
  }
`;

// 取消訂單
export const cancelDeposit = gql`
  mutation cancelDeposit($in: CancelDepositInput!) {
    cancelDeposit(in: $in) {
      Result
    }
  }
`;

// 審核訂單
export const paidDeposit = gql`
  mutation paidDeposit($in: PaidDepositInput!) {
    paidDeposit(in: $in) {
      Result
    }
  }
`;

// 諮詢/催單
export const consultingOrder = gql`
  mutation consultingOrder($in: ConsultingOrderInput!) {
    consultingOrder(in: $in) {
      orderNo
      amount
      payType
      currency
      createdAt
      status
    }
  }
`;

// 訂單列表
export const listOrder = gql`
  query listOrder($pagination: PaginationInput!, $in: ListOrderInput!) {
    listOrder(pagination: $pagination, in: $in) {
      meta {
        total
        page
        perPage
        totalPage
      }
      orders {
        orderNo
        amount
        payType
        currency
        createdAt
        status
      }
    }
  }
`;

// 訂單列表 棄用
export const listPendingOrder = gql`
  query listPendingOrder($pagination: PaginationInput!) {
    listPendingOrder(pagination: $pagination) {
      meta {
        total
        page
        perPage
        totalPage
      }
      orders {
        orderNo
        amount
        payType
        currency
        createdAt
        status
      }
    }
  }
`;

export const FAQ_COUNT = gql`
  mutation addFrequentlyQuestionClick($roomID: Uint64!) {
    addFrequentlyQuestionClick(roomID: $roomID) {
      faqCount
      result
    }
  }
`;
