import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useMutationApi, apiType } from '@/hook/useApi';
import SettingForm from '@/components/User/SettingForm/Form';

function UserSettingForm({ userID }) {
  const [settingData, setSettingData] = useState(null);
  const dataHandle = async getUserSetting => {
    let settingData = {
      ...getUserSetting,
      allowStrangeMessageEnabled:
        getUserSetting.allowStrangeMessageEnabled === 'Yes',
      autoAddFriendEnabled: getUserSetting.autoAddFriendEnabled === 'Yes',
    };
    setSettingData(() => ({ ...settingData }));
  };
  const { loading, refetch } = useQuery(apiType.GET_USER_SETTING, {
    variables: { filter: { userSetting: { userID } } },
    onCompleted: ({ getUserSetting }) => dataHandle(getUserSetting),
  });
  const [updateUserSetting, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_USER_SETTING,
    {
      onCompleted: () => refetch(),
    },
  );

  return (
    <SettingForm
      loading={loading || updateLoading}
      settingData={settingData}
      updateUserSetting={fields =>
        updateUserSetting({
          filter: { userSetting: { userID } },
          in: { ...fields },
        })
      }
    />
  );
}

UserSettingForm.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default UserSettingForm;
