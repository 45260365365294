import React from 'react';
import { Empty, Tag, Collapse, Typography } from 'antd';
import RoomCard from '@/components/CSRoomAdmin/RoomCard';
import { AccountTypeDisplay, AccountTypeKeys } from '@/utils';

function CSRoomList({
  activeKey = '',
  merchantList = [],
  csRoomList = [],
  currRoomID = 0,
  clickCurrRoom = () => {},
  onChangePanel = () => {},
}) {
  const tagsRender = ({ isTourist, isProactive }) => {
    if (isTourist) {
      return (
        <Tag color={AccountTypeDisplay['Tourist'].color}>
          {AccountTypeDisplay['Tourist'].text}
        </Tag>
      );
    }
    if (isProactive) return <Tag color='blue'>联系</Tag>;
    return null;
  };
  const csRoomListRender = list => {
    if (!list.length) return <Empty description={false} />;
    return csRoomList.map(({ room, ownerUserData, extraInfo, ...props }) => (
      <div
        key={room.roomID}
        onClick={() =>
          clickCurrRoom({ room, ownerUserData, extraInfo, ...props })
        }>
        <RoomCard
          room={room}
          roomID={room?.roomID}
          userData={ownerUserData}
          isActive={currRoomID === room.roomID}
          showUnReadCount={false}
          tags={tagsRender({
            isTourist:
              ownerUserData?.accountType === AccountTypeKeys['Tourist'],
            isProactive: room.isProactive === 'Yes',
          })}
        />
      </div>
    ));
  };

  return (
    <Collapse
      accordion
      activeKey={activeKey}
      destroyInactivePanel={true}
      bordered={false}
      onChange={onChangePanel}>
      {merchantList.map(mr => (
        <Collapse.Panel
          key={mr.id}
          header={
            <>
              <Typography.Title level={5}>{mr.name}</Typography.Title>
              商户号：{mr.key}
            </>
          }
          extra={
            <Typography.Text strong type='danger'>
              {mr.csRoomTotal}
            </Typography.Text>
          }>
          {csRoomListRender(csRoomList)}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}

export default CSRoomList;
