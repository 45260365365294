import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Spin, List, Typography, Space } from 'antd';
import AntAvatar from '@/components/Avatar/Avatar';
import BtnTooltip from '@/components/Button/BtnTooltip';

const FriendList = ({ loading = false, dataSource = [], deleteFriend }) => {
  return (
    <Spin spinning={loading}>
      <List
        dataSource={dataSource}
        renderItem={f => (
          <List.Item
            size='small'
            actions={[
              <BtnTooltip
                key='delete'
                tipType='delete'
                onClick={() => deleteFriend(f.id)}
                icon={<CloseOutlined />}
              />,
            ]}>
            <Space>
              <AntAvatar src={f.friend.avatarURL} />
              <Typography.Text>{f.friend.username}</Typography.Text>
            </Space>
          </List.Item>
        )}></List>
    </Spin>
  );
};

FriendList.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.array,
  deleteFriend: PropTypes.func.isRequired,
};

export default FriendList;
