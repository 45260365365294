import { useState, useEffect } from 'react';
import moment from 'dayjs';
import { apiType, useMutationApi } from '@/hook/useApi';
import {
  Input,
  Radio,
  Button,
  DatePicker,
  Form,
  Drawer as ADrawer,
} from 'antd';
import useAlertMessage from '@/hook/useAlertMessage';

const CreateDrawer = ({ visible, setVisible, refetch }) => {
  const [isScheduled, setIsScheduled] = useState(false);

  const [pushForm] = Form.useForm();
  const { onSuccessMsg } = useAlertMessage();
  const [cerateFetch, { loading }] = useMutationApi(apiType.CREATE_PUSH); // 新增

  // 提交表單
  const submitHandle = async () => {
    const fields = await pushForm.validateFields();
    if (fields.scheduledTime) {
      fields.scheduledTime = fields.scheduledTime.unix();
    }
    const [err] = await cerateFetch({ in: { ...fields } });
    if (!err) {
      onSuccessMsg();
      refetch();
      await closeDrawer(false);
    }
  };

  const closeDrawer = async () => {
    await pushForm.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    const detailDataHandle = async () => {
      await pushForm.setFieldsValue({
        target: 'ALL',
        isScheduled: 'No',
      });
    };
    if (visible) detailDataHandle();
  }, [visible, pushForm]);

  const changeScheduled = e => {
    setIsScheduled(() => e.target.value === 'Yes');
  };

  function disabledDate(current) {
    return current < moment().startOf('day');
  }

  return (
    <ADrawer visible={visible} onClose={closeDrawer} width={450}>
      <Form form={pushForm} onFinish={submitHandle} layout='vertical'>
        <Form.Item
          name='title'
          label='推送標題'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='content'
          label='推送內容'
          rules={[{ required: true, message: '必填' }]}>
          <Input.TextArea placeholder='請輸入' />
        </Form.Item>
        <Form.Item name='target' label='目標人群'>
          <Radio.Group>
            <Radio value='ALL'>所有用戶</Radio>
            <Radio value='SINGLE_USER' disabled>
              單個用戶
            </Radio>
            <Radio value='SPECIAL_USER' disabled>
              特定用戶
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name='isScheduled' label='推送時間'>
          <Radio.Group onChange={changeScheduled}>
            <Radio value='No'>立即推送</Radio>
            <Radio value='Yes'>定時推送</Radio>
          </Radio.Group>
        </Form.Item>
        {isScheduled && (
          <Form.Item
            name='scheduledTime'
            label='推送時間'
            rules={[{ required: true, message: '必填' }]}>
            <DatePicker
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabledDate={disabledDate}
              style={{ width: '100%' }}
              placeholder='請輸入時間'
            />
          </Form.Item>
        )}
        <Form.Item>
          <Button htmlType='submit' loading={loading} type='primary'>
            提交
          </Button>
        </Form.Item>
      </Form>
    </ADrawer>
  );
};

export default CreateDrawer;
