import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin, Switch, Form } from 'antd';

const SettingForm = ({
  loading = false,
  settingData = null,
  updateUserSetting,
}) => {
  const [settingForm] = Form.useForm();
  useEffect(() => {
    const formHandle = async d => {
      await settingForm.setFieldsValue(d);
    };
    settingData && formHandle(settingData);
  }, [settingData, settingForm]);

  const onChangeHandle = (checked, fieldName) => {
    let fields = {};
    fields[fieldName] = checked ? 'Yes' : 'No';
    updateUserSetting(fields);
  };

  return (
    <Spin spinning={loading}>
      <Form form={settingForm} layout='horizontal'>
        <Form.Item
          name='allowStrangeMessageEnabled'
          label='允許陌生訊息'
          valuePropName='checked'>
          <Switch
            checkedChildren='开启'
            unCheckedChildren='关闭'
            onChange={c => onChangeHandle(c, 'allowStrangeMessageEnabled')}
          />
        </Form.Item>
        <Form.Item
          name='autoAddFriendEnabled'
          label='自动加为好友'
          valuePropName='checked'>
          <Switch
            checkedChildren='开启'
            unCheckedChildren='关闭'
            onChange={c => onChangeHandle(c, 'autoAddFriendEnabled')}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

SettingForm.propTypes = {
  loading: PropTypes.bool,
  settingData: PropTypes.object,
  updateUserSetting: PropTypes.func.isRequired,
};

export default SettingForm;
