import React, { useEffect, useState } from 'react';
import { EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { formatDateTime, Pagination } from '@/utils';

export default function TabTable({
  defaultSearch,
  supportAccountType,
  defaultRoleID,
  meta = {},
  loadingObj = {},
  dataSource = [],
  fetchListHandle = () => {},
  fetchDetailHandel = () => {},
  deleteRoleHandler = () => {},
  reload = false,
  authRoutesKey = [],
}) {
  const [pagination, setPagination] = useState({
    ...Pagination,
  });
  const [searchData] = useState(() => ({ supportAccountType }));

  const clickDeleteRole = async id => {
    const err = await deleteRoleHandler(id);
    if (!err) fetchListHandle(searchData, pagination);
  };

  const handleChangePage = pagination => {
    setPagination({ ...pagination });
    fetchListHandle(searchData, pagination);
  };

  useEffect(() => {
    if (meta?.total) {
      setPagination(pre => ({ ...pre, total: meta.total }));
    }
  }, [meta]);

  useEffect(() => {
    fetchListHandle(searchData, pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearch, reload]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '角色名稱',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: h => formatDateTime(h),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: ({ id }) => (
        <Space>
          {authRoutesKey.includes('API_Employee_Role_Update') && (
            <Button
              onClick={() => fetchDetailHandel(id)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {/* 預設角色不能刪除 */}
          {defaultRoleID !== id &&
            authRoutesKey.includes('API_Employee_Role_Delete') && (
              <BtnDeleteConfirm
                handleOk={() => clickDeleteRole(id)}
                loading={loadingObj.delete}
              />
            )}
        </Space>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      dataSource={dataSource}
      loading={loadingObj.list}
      pagination={pagination}
      onChangePage={handleChangePage}
      bordered={false}
      bodyStyle={{ padding: '0px' }}
    />
  );
}
