/**
 *  客服個人報表
 */
import React from 'react';
import { useActiveList } from '@/hook/useList';
import SearchBar from '@/components/SearchBar';
import CustomerServiceTable from '@/components/Table/CSReportTable';
import {
  setDateRanges,
  formatSearchDate,
  UserStatusKey,
  AccountTypeKeys,
} from '@/utils';

const defaultDateTime = setDateRanges(1);
const defaultSearch = {
  date: [defaultDateTime.start, defaultDateTime.end], // 日期套件要用的預設格式
};
const originFilter = {
  greaterThanEqual: formatSearchDate(defaultSearch.date)[0],
  lessThanEqual: formatSearchDate(defaultSearch.date)[1],
  user: {
    status: UserStatusKey['Active'],
    accountType: AccountTypeKeys['CustomerService'],
    isDelete: 'No',
  },
};

const searchFields = [{ name: 'date', type: 'dateRange', label: '时间范围' }];

export default function ReportCustomerServiceMgm() {
  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
  } = useActiveList({
    api: 'REPORT_CUSTOMER_SERVICE',
    originFilter: { ...originFilter },
    handleData: ({ listCustomerServiceSummary }) => {
      const { customerServiceSummaries, meta } = listCustomerServiceSummary;
      return {
        list: customerServiceSummaries.map(el => ({
          ...el,
          id: el.user.id,
          username: el.user.username,
        })),
        total: meta?.total || 0,
      };
    },
  });

  const onFinish = search => {
    handleSearchFilter({
      ...originFilter,
      greaterThanEqual: formatSearchDate(search.date)[0],
      lessThanEqual: formatSearchDate(search.date)[1],
    });
  };

  return (
    <>
      <SearchBar
        fields={searchFields}
        defaultSearch={defaultSearch}
        onFinish={onFinish}
        defaultCollapsed={false}
      />

      {/* 列表 */}
      <CustomerServiceTable
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}></CustomerServiceTable>
    </>
  );
}
