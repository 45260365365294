import React from 'react';
import { Box } from '@WRAP_COMP';
import { Button, Form, Input, InputNumber } from 'antd';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';

function QusListForm({
  qusData = null,
  updateQsHandle = () => {},
  deleteQsHandle = () => {},
  deleteLoading = false,
}) {
  const [updateForm] = Form.useForm();
  const deleteBtnRender = (id = 0) => {
    return (
      <BtnDeleteConfirm
        key='delete'
        handleOk={e => {
          e.stopPropagation();
          deleteQsHandle(id);
        }}
        loading={deleteLoading}
        placement='topRight'
      />
    );
  };

  return (
    <Form
      form={updateForm}
      onFinish={updateQsHandle}
      layout='inline'
      size='small'
      initialValues={{ ...qusData }}
      style={{ width: '100%' }}>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='question'>
        <Input
          placeholder='请输入咨询问题'
          rules={[{ required: true, message: '必填' }]}
        />
      </Form.Item>
      <Form.Item
        name='maxProcessingCount'
        rules={[{ required: true, message: '必填' }]}>
        <InputNumber
          placeholder='请输入问题最大数量'
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Box flex='1' display='flex' justifyContent='flex-end'>
        <Button key='edit' type='link' size='small' htmlType='submit'>
          更新
        </Button>
        {deleteBtnRender(qusData?.id)}
      </Box>
    </Form>
  );
}

export default QusListForm;
