import { useState } from 'react';
import 'braft-editor/dist/index.css';
import Editor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { Upload, Button, Input, Modal } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import ResetHtmlWrap from '@/components/ResetHtmlWrap';

const defaultState = Editor.createEditorState('<p>Hello <b>World!</b></p>');

const useEditor = ({ clickPreview = () => {} } = {}) => {
  const [editorState, setEditorState] = useState(defaultState);
  const changeEditorHandle = value => {
    setEditorState(value);
    // console.log('toHTML', value.toHTML());
  };

  const setHtmlToEditorState = htmlStr => {
    setEditorState(() => Editor.createEditorState(htmlStr));
    // console.log('htmlStr', htmlStr);
  };

  /**
   * 上傳圖片
   */
  const [putImageLoading, setPutImageLoading] = useState(false);
  const [newCreateUpload] = useMutationApi(apiType.NEW_CREATE_UPLOAD);
  const uploadHandler = async param => {
    console.log('uploadHandler param', param);
    if (!param.file) return false;

    // 开启加载状态
    setPutImageLoading(true);

    try {
      const [err, { data }] = await newCreateUpload({
        in: param.file,
      });

      if (err) throw new Error('Failed to create upload URL');

      const url = data.newCreateUpload;
      const s3_domain = process.env.REACT_APP_S3_URL;
      const imgUrl = `${s3_domain}/${url}`;

      console.log(s3_domain, imgUrl);

      // 图片插入编辑器
      setEditorState(() =>
        ContentUtils.insertMedias(editorState, [
          { type: 'IMAGE', url: imgUrl, width: '100%' },
        ]),
      );
    } catch (error) {
      console.error('uploadHandler error:', error);
    } finally {
      setPutImageLoading(false);
    }
  };

  const uploadBtnRender = () => (
    <Upload
      accept='image/*'
      showUploadList={false}
      customRequest={uploadHandler}>
      <Button
        className='control-item button upload-button'
        loading={putImageLoading}>
        插入图片
      </Button>
    </Upload>
  );

  /**
   * 編輯 html
   */
  const [editHtmlModalVisible, setEditHtmlModalVisible] = useState(false);
  const [editHtml, setEditHtml] = useState('');
  const clickEditHTML = htmlStr => {
    setEditHtml(htmlStr);
    setEditHtmlModalVisible(true);
  };
  const onChangeHtml = e => setEditHtml(e.target.value);
  const clickSubmitHtml = () => {
    setHtmlToEditorState(editHtml);
    setEditHtmlModalVisible(false);
  };

  const EditHtmlModal = () => (
    <Modal
      title='编辑HTML'
      visible={editHtmlModalVisible}
      centered
      onCancel={() => setEditHtmlModalVisible(false)}
      footer={
        <Button variant='primary' onClick={clickSubmitHtml}>
          确认
        </Button>
      }>
      <Input.TextArea value={editHtml} onChange={onChangeHtml} />
    </Modal>
  );

  const extendControls = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: uploadBtnRender(),
    },
    {
      key: 'editor-html',
      type: 'button',
      text: '编辑HTML',
      onClick: () => clickEditHTML(editorState.toHTML()),
    },
    {
      key: 'preview',
      type: 'button',
      text: '预览',
      onClick: () => clickPreview(editorState.toHTML()),
    },
  ];
  const editorControls = [
    'undo',
    'redo',
    'headings',
    'font-size',
    'line-height',
    'letter-spacing',
    'bold',
    'italic',
    'underline',
    'text-color',
    'remove-styles',
    'blockquote',
    'code',
    'hr',
    'separator',
    'link',
    'separator',
  ];

  return {
    editorControls,
    extendControls,
    editorState,
    setHtmlToEditorState,
    changeEditorHandle,
    EditHtmlModal,
  };
};

const PreviewModal = ({
  visible = false,
  onCancel = () => {},
  htmlStr = '',
}) => {
  return (
    <Modal
      title='预览项目内文'
      zIndex={2000}
      visible={visible}
      centered
      onCancel={onCancel}
      footer={null}>
      <ResetHtmlWrap html={htmlStr} />
    </Modal>
  );
};

export { useEditor, Editor, PreviewModal };
