import React from 'react';
import { useSelector } from 'react-redux';
import {
  UpCircleTwoTone,
  DownCircleTwoTone,
  PlusOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Space, Switch } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import DataTable from '@/components/Table/DataTable';
import BtnTooltip from '@/components/Button/BtnTooltip';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { formatDate } from '@/utils';

function DetailTable({
  loading,
  dataSource,
  pagination,
  total,
  handleChangePage,
  refetch,
  setVisible,
  currentParentItem = {},
  groupByParentID = {},
  previewVisible = () => {},
}) {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { id = 0, title = '主项目' } = currentParentItem;
  const { onSuccessMsg, onWarningMsg, onErrorMsg } = useAlertMessage();
  const completed = () => {
    onSuccessMsg();
    refetch();
  };

  // 更新
  const [updateFetch] = useMutationApi(apiType.UPDATE_FAQ, {
    onCompleted: completed,
  });
  const updateFetchHandle = (isUp, { id, idx }) => {
    const changeIdx = isUp ? idx - 1 : idx + 1;
    const refer = dataSource[changeIdx];
    if (!refer) {
      onWarningMsg(isUp ? '最上面一筆了' : '最下面一筆了');
      return;
    }
    updateFetch({
      filter: { frequentlyQuestion: { id } },
      in: { sequence: refer.sequence },
    });
  };

  // 刪除
  const [deleteFetch] = useMutationApi(apiType.DELETE_FAQ, {
    onCompleted: completed,
  });
  const deleteFetchHandle = ({ id }) => {
    // 檢查 子項目
    if (groupByParentID[id]) {
      onErrorMsg('有子項目不能刪除');
      return;
    }
    deleteFetch({ filter: { frequentlyQuestion: { id } } });
  };

  const columns = currID => {
    let columns = [
      {
        title: '排序',
        key: 'index',
        width: '10%',
        fixed: 'left',
        render: (txt, row, idx) => (
          <>
            {idx + 1}
            {/* {idx + 1}--id: {row.id}/ sequence:{row.sequence} */}
          </>
        ),
      },
      {
        title: '项目标题',
        dataIndex: 'question',
        key: 'question',
        width: '15%',
      },
      {
        title: '项目内文',
        dataIndex: 'asked',
        key: 'asked',
        render: v => (
          <Button
            onClick={() => previewVisible(true, v)}
            type='link'
            icon={<EyeTwoTone />}
          />
        ),
      },
      { title: '来源判断', dataIndex: 'source', key: 'source' },
      {
        title: '建立日期',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '15%',
        render: c => formatDate(c),
      },
      {
        title: '启用状态',
        dataIndex: 'isEnabled',
        render: (h, { id }) => (
          <Switch
            checkedChildren='启用'
            unCheckedChildren='停用'
            checked={h === 'Yes'}
            disabled={
              h === 'Unknown' || !authRoutesKey.includes('API_HostsDeny_Update')
            }
            onChange={v =>
              updateFetch({
                filter: { frequentlyQuestion: { id } },
                in: { isEnabled: v ? 'Yes' : 'No' },
              })
            }
          />
        ),
      },
      {
        title: '操作',
        key: 'action',
        width: '12%',
        fixed: 'right',
        render: (t, row, idx) => (
          <Space>
            <BtnTooltip
              tipTitle='上移'
              icon={<UpCircleTwoTone />}
              onClick={() => updateFetchHandle(true, { ...row, idx })}
            />
            <BtnTooltip
              tipTitle='下移'
              icon={<DownCircleTwoTone />}
              onClick={() => updateFetchHandle(false, { ...row, idx })}
            />
            <BtnTooltip
              onClick={() => setVisible({ visible: true, editData: row })}
            />
            <BtnDeleteConfirm
              key='delete'
              handleOk={() => deleteFetchHandle(row)}
              placement='topRight'
            />
          </Space>
        ),
      },
    ];
    currID && columns.splice(3, 1);
    return columns;
  };

  return (
    <>
      <DataTable
        columns={columns(id)}
        dataSource={dataSource}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        {authRoutesKey.includes('API_FAQ_Create') && (
          <Button
            variant='primary'
            icon={<PlusOutlined />}
            onClick={() => setVisible({ visible: true })}>
            新建{title}
          </Button>
        )}
      </DataTable>
    </>
  );
}

export default DetailTable;
