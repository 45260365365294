import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

/**
 * 用於表單錯誤
 */
const AntAlert = ({ message = '', ...props }) => {
  return (
    <>
      {message && (
        <Alert
          message={message}
          type='error'
          showIcon={true}
          style={{ marginBottom: '20px' }}
          {...props}></Alert>
      )}
    </>
  );
};

AntAlert.propTypes = {
  message: PropTypes.string,
};

export default AntAlert;
