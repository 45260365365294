import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { Button, Col, Row } from '@/plugin/wrap-comp';
import useMerchant from '@/hook/useMerchant';
import { useQueryApi, apiType } from '@/hook/useApi';
import { roomFormate } from '@/hook/useRoom';
import useOrigin from '@/hook/useOrigin';
import { cloneDeep } from '@/utils';

import RoomList from './RoomList';
import RoomViewList from './RoomViewList';
import RoomView from './RoomView';
import useRecMessage from '@/hook/useRecMessage';

function MonitorMgm() {
  const { meInfo } = useSelector(({ auth }) => auth);

  /**
   * 域名列表
   */
  const { accessDomainTextFormate } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });

  /**
   * 待諮詢列表
   *  */
  const [csRoomList, setCsRoomList] = useState([]);
  const { fetchData: fetchCSRoomList } = useQueryApi(
    apiType.LIST_MONITOR_CS_ROOM,
  );
  const fetchCSRoomListHandle = async merchantID => {
    const { error, data } = await fetchCSRoomList({
      filter: {
        baseFilter: { sortField: 'room_id', sortType: 'DESC' },
        consultingRoom: { merchantID, status: 'Processing' },
      },
    });
    if (error) return;
    let tempListSource = [];
    data.listConsultingRoom.consultingRooms.forEach(
      ({ userRooms, ...room }) => {
        const formateRoom = roomFormate.consulting({
          room: {
            ...room,
            accessDomain: accessDomainTextFormate(room.accessDomain),
          },
          ownerUserID: room.ownerUserID,
          userRooms: userRooms,
          meID: meInfo.id,
        });
        tempListSource.push({ ...formateRoom });
      },
    );
    setMerchantList(pre => {
      pre.forEach(el => {
        el.id === Number(merchantID) &&
          (el.csRoomTotal = tempListSource.length);
      });
      return pre;
    });

    setCsRoomList(tempListSource);
    return tempListSource;
  };
  const [currMerchantID, setCurrMerchantID] = useState(0);
  const onChangeMerchant = async (mrID = 0) => {
    mrID ? await fetchCSRoomListHandle(mrID) : setCsRoomList([]);
    setCurrMerchantID(Number(mrID));
    setIsShowAll(false);
  };

  /**
   * 商戶列表
   */
  const [merchantList, setMerchantList] = useState([]);
  const { merchantTableList } = useMerchant({
    originPagination: { perPage: 999, page: 1 },
  });
  const mrRoomTotalHandle = (recMrID, isAdd = true) => {
    const idx = merchantList.findIndex(mr => mr.id === recMrID);
    if (idx < 0) return;
    let tempList = cloneDeep(merchantList);
    isAdd ? tempList[idx].csRoomTotal++ : tempList[idx].csRoomTotal--;
    setMerchantList(tempList);
  };
  const mrListRoomTotalHandel = async list => {
    const { error, data } = await fetchCSRoomList({
      filter: {
        baseFilter: { sortField: 'room_id', sortType: 'DESC' },
        consultingRoom: { status: 'Processing' },
      },
    });
    let temp = cloneDeep(list);
    temp.forEach(ll => {
      ll.csRoomTotal = error
        ? 0
        : data.listConsultingRoom.consultingRooms.filter(
            cc => cc.merchantID === ll.id,
          ).length;
    });
    setMerchantList(temp);
  };
  useEffect(() => {
    merchantTableList.length > 0 && mrListRoomTotalHandel(merchantTableList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantTableList]);

  /**
   * 展開所有房間
   */
  const [isShowAll, setIsShowAll] = useState(false);
  const openAllCSRoom = show => {
    setIsShowAll(show);
    setCurrMerchantID(0);
    show ? fetchCSRoomListHandle(0) : setCsRoomList([]);
  };

  /**
   * 當前房間處理
   */
  const [currRoom, setCurrRoom] = useState({});
  const [visible, setVisible] = useState(false);
  const openCurrRoomModal = (isOpen = false, roomData = {}) => {
    setCurrRoom(roomData);
    setVisible(isOpen);
  };

  /**
   * 監聽
   */
  const recRoomUpdateHandle = ({ merchantID, roomID }) => {
    if (!isShowAll && !currMerchantID) return;
    if (currRoom.room && currRoom.room.roomID === roomID) {
      openCurrRoomModal(false);
    }
    if (isShowAll) {
      fetchCSRoomListHandle(0);
      return;
    }
    if (currMerchantID === merchantID) fetchCSRoomListHandle(currMerchantID);
  };
  useRecMessage({
    csRoomStatusProcessing: ({ roomUpdate }) => {
      mrRoomTotalHandle(roomUpdate.consultingDetail.merchantID, true);
      recRoomUpdateHandle(roomUpdate.consultingDetail);
    },
    csRoomStatusDeactivated: ({ roomUpdate }) => {
      recRoomUpdateHandle(roomUpdate.consultingDetail);
    },
  });

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>
          <Button
            variant='outline-primary'
            onClick={() => openAllCSRoom(!isShowAll)}
            block
            mb='2'>
            {isShowAll ? '收合所有咨询房' : '展开所有咨询房'}
          </Button>
          <RoomList
            activeKey={currMerchantID}
            merchantList={merchantList}
            csRoomList={csRoomList}
            currRoomID={currRoom.room?.roomID || 0}
            clickCurrRoom={data => openCurrRoomModal(true, data)}
            setCurrRoom={setCurrRoom}
            onChangePanel={onChangeMerchant}
          />
        </Col>
        <Col span={20}>
          <RoomViewList
            isShowAll={isShowAll}
            merchantList={merchantList}
            csRoomList={csRoomList}
            clickCurrRoom={data => openCurrRoomModal(true, data)}
          />
        </Col>
      </Row>
      <Modal
        visible={visible}
        closable={false}
        onCancel={() => setVisible(false)}
        centered
        destroyOnClose
        footer={null}
        bodyStyle={{ padding: '0px', height: '50%' }}
        width='50%'>
        {currRoom.room && (
          <div style={{ height: '50vh' }}>
            <RoomView
              roomID={currRoom.room.roomID}
              accessDomain={currRoom.room.accessDomain}
              userRooms={currRoom.userRooms}
              ownerUserData={currRoom.ownerUserData}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default MonitorMgm;
