import React from 'react';
import { Divider, Space, Collapse } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { CSFormFieldType, CSFormFieldVerifyType } from '@/utils';
// import { CloseOutlined } from '@ant-design/icons';

const unVerifyRule = {
  require: false,
  verifyType: CSFormFieldVerifyType['Text'],
};
const inputContent = { value: '', items: [] };
const defaultQsData = [
  {
    id: 1,
    type: CSFormFieldType['Input'],
    label: '会员账号',
    content: { ...inputContent },
    rule: { ...unVerifyRule },
  },
  {
    id: 2,
    type: CSFormFieldType['Input'],
    label: '性别',
    content: { ...inputContent },
    rule: { ...unVerifyRule },
  },
  {
    id: 3,
    type: CSFormFieldType['Input'],
    label: '年龄',
    content: { ...inputContent },
    rule: { require: true, verifyType: CSFormFieldVerifyType['Age'] },
  },
  {
    id: 4,
    type: CSFormFieldType['Input'],
    label: 'QQ',
    content: { ...inputContent },
    rule: { require: true, verifyType: CSFormFieldVerifyType['QQ'] },
  },
  {
    id: 5,
    type: CSFormFieldType['Input'],
    label: '微信',
    content: { ...inputContent },
    rule: { require: true, verifyType: CSFormFieldVerifyType['WeChat'] },
  },
  {
    id: 6,
    type: CSFormFieldType['Input'],
    label: '地址',
    content: { ...inputContent },
    rule: { ...unVerifyRule },
  },
  {
    id: 7,
    type: CSFormFieldType['Input'],
    label: '邮箱',
    content: { ...inputContent },
    rule: { require: true, verifyType: CSFormFieldVerifyType['Email'] },
  },
  {
    id: 8,
    type: CSFormFieldType['Input'],
    label: '备注',
    content: { ...inputContent },
    rule: { ...unVerifyRule },
  },
];
const defaultCusQsData = [
  {
    id: 9,
    type: CSFormFieldType['Input'],
    label: '自定义问题',
    content: { ...inputContent },
    rule: { ...unVerifyRule },
  },
  {
    id: 10,
    type: CSFormFieldType['Radio'],
    label: '自定义单选',
    content: { value: '', items: [{ label: '选项', value: false }] },
    rule: { ...unVerifyRule },
  },
  {
    id: 11,
    type: CSFormFieldType['Checkbox'],
    label: '自定义多选',
    content: { value: '', items: [{ label: '选项', value: false }] },
    rule: { ...unVerifyRule },
  },
];

function AddQsBtn({ selectQsIDs, setSelectQsIDs, selectItem = () => {} }) {
  const clickQsHandle = qsItem => {
    setSelectQsIDs(pre => [...pre, qsItem.id]);
    selectItem(qsItem);
  };

  const clickCusQsHandle = qsItem => {
    // 客制按鈕需定義 id
    let tempQsItem = { ...qsItem };
    tempQsItem.id = Date.now();
    selectItem(tempQsItem);
  };

  const BtnGroupRender = () => {
    return (
      <>
        <Space>
          {defaultQsData.length === selectQsIDs.length && (
            <div>已选完预设问题</div>
          )}
          {defaultQsData.map(el => {
            return selectQsIDs.includes(el.id) ? null : (
              <Button
                size='small'
                key={el.id}
                onClick={() => clickQsHandle(el)}>
                {el.label}
              </Button>
            );
          })}
        </Space>
        <Divider style={{ margin: '12px 0' }} />
        <Space>
          {defaultCusQsData.map(el => (
            <Button
              size='small'
              key={el.id}
              onClick={() => clickCusQsHandle(el)}>
              {el.label}
            </Button>
          ))}
        </Space>
      </>
    );
  };

  return (
    <Collapse bordered={false}>
      <Collapse.Panel
        key='1'
        showArrow={false}
        header={
          <Button variant='outline-primary' block title='Title' type='dashed'>
            + 添加问题
          </Button>
        }>
        {BtnGroupRender()}
      </Collapse.Panel>
    </Collapse>
  );
}

export default AddQsBtn;
