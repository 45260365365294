import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box } from '@WRAP_COMP';

function ColorBtn({ color = '', onChange = () => {} }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const clickColorBtn = () => setShowColorPicker(pre => !pre);

  return (
    <>
      <Box
        p='1'
        bgColor='white'
        borderRadius='1px'
        border='1px solid'
        borderCr='gray-2'
        display='inline-block'
        style={{ cursor: 'pointer' }}
        onClick={clickColorBtn}>
        <Box bgColor={color} width='36px' height='14px' borderRadius='1px' />
        {showColorPicker && (
          <Box position='absolute' top='0' left='52px' zIndex='2'>
            <SketchPicker
              color={color}
              onChangeComplete={({ hex }) => onChange(hex)}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ColorBtn;
