import React from 'react';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import CenterLayout from '@/components/Layout/CenterLayout';
import AdminContainer from '@/components/Layout/Admin/Container';

import Login from '@/views/Login';
import Error403 from '@/views/Error403';
import Error404 from '@/views/Error404';
import Dashboard from '@/views/Dashboard';

import ReportCommonUserMgm from '@/views/ReportCommonUserMgm';
import ReportConsultingRoomMgm from '@/views/ReportConsultingRoomMgm';
import ReportCustomerServiceMgm from '@/views/ReportCustomerServiceMgm';

import CSRoom from '@/views/CSRoom';
import CSRoomDeactivated from '@/views/CSRoomDeactivated';
import ContactCustomerService from '@/views/ContactCustomerService';

import PushMgm from '@/views/PushMgm';
import MemberMgm from '@/views/MemberMgm';
import MemberTagMgm from '@/views/MemberTagMgm';

import CSGreeting from '@/views/CSGreeting';
import FAQMgm from '@/views/FAQMgm';
import CSFormMgm from '@/views/CSFormMgm';
import CannedResponseMgm from '@/views/CannedResponseMgm';
import CSRoomSettingMgm from '@/views/CSRoomSettingMgm';
import OriginMgm from '@/views/OriginMgm';
import PlatformSettingMgm from '@/views/PlatformSettingMgm';
import HostsDenyMgm from '@/views/HostsDenyMgm';
import AdTrackingMgm from '@/views/AdTrackingMgm';

import EmployeeMgm from '@/views/EmployeeMgm';
import EmployeeCSMgm from '@/views/EmployeeCSMgm';
import EmployeeRoleMgm from '@/views/EmployeeRoleMgm';

import MonitorMgm from '@/views/MonitorMgm';

export const adminLogin = [
  {
    key: 'Main',
    path: '/',
    element: <CenterLayout />,
    children: [
      { index: true, element: <Login /> },
      { path: '*', element: <Navigate to='/' /> },
    ],
  },
];

export const adminContainer = [
  {
    path: '/',
    element: <AdminContainer />,
    children: [
      { path: '403', element: <Error403 /> },

      {
        path: 'Dashboard',
        title: '儀表板',
        element: <Dashboard />,
      },

      // 報表系統
      {
        path: 'Report_System',
        title: '报表系统',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='CommonUserReport_Management' />,
          },
          {
            path: 'CommonUserReport_Management',
            title: '会员统计数据',
            element: <ReportCommonUserMgm />,
          },
          {
            path: 'ConsultingRoomReport_Management',
            title: '客服统计数据',
            element: <ReportConsultingRoomMgm />,
          },
          {
            path: 'CustomerServiceReport_Management',
            title: '客服个人数据',
            element: <ReportCustomerServiceMgm />,
          },
        ],
      },

      // 客服系統
      {
        path: 'CustomerService_System',
        title: '客服系統',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='CommonUserReport_Management' />,
          },
          {
            path: 'ConsultationChat_Management',
            title: '咨询聊天室',
            element: <CSRoom />,
          },
          {
            path: 'MothballConsultationChat',
            title: '历史咨询纪录',
            element: <CSRoomDeactivated />,
          },
          {
            path: 'ContactCustomerService_Management',
            title: '联系人客服设定',
            element: <ContactCustomerService />,
          },
        ],
      },

      // 訊息系統
      {
        path: 'Message_System',
        title: '讯息系统',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='Push_Management' />,
          },
          {
            path: 'Push_Management',
            title: '推送管理',
            element: <PushMgm />,
          },
        ],
      },

      // 會員系統
      {
        path: 'Member_System',
        title: '会员系统',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='Member_Management' />,
          },
          {
            path: 'Member_Management',
            title: '会员管理',
            element: <MemberMgm />,
          },
          {
            path: 'Member_Role_Management',
            title: '標籤管理',
            element: <MemberTagMgm />,
          },
        ],
      },

      // 運營系統
      {
        path: 'Operating_System',
        title: '运营系统',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='ConsultingGreeting_Management' />,
          },
          {
            path: 'ConsultingGreeting_Management',
            title: '行銷问候语管理',
            element: <CSGreeting />,
          },
          {
            path: 'FAQ_Management',
            title: 'FAQ管理',
            element: <FAQMgm />,
          },
          {
            path: 'ConsultingRoomForm_Management',
            title: '询前表单管理',
            element: <CSFormMgm />,
          },
          {
            path: 'ConsultingRoomClient_Management',
            title: '咨询窗口管理',
            element: <CSRoomSettingMgm />,
          },
          {
            path: 'CannedResponse_Management',
            title: '快捷回复管理',
            element: <CannedResponseMgm />,
          },
          {
            path: 'ConsultingRoomOrigin_Management',
            title: '域名设定管理',
            element: <OriginMgm />,
          },
          {
            path: 'PlatformSetting_Management',
            title: '后台设定管理',
            element: <PlatformSettingMgm />,
          },
          {
            path: 'HostsDeny_Management',
            title: '黑名单管理',
            element: <HostsDenyMgm />,
          },
          {
            path: 'AdTracking_Management',
            title: '广告代码管理',
            element: <AdTrackingMgm />,
          },
        ],
      },

      // 員工系統
      {
        path: 'Employee_System',
        title: '员工系统',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='Employee_Management' />,
          },
          {
            path: 'Employee_Management',
            title: '员工管理',
            element: <EmployeeMgm />,
          },
          {
            path: 'CustomerService_Management',
            title: '客服管理',
            element: <EmployeeCSMgm />,
          },
          {
            path: 'Employee_Role_Management',
            title: '角色管理',
            element: <EmployeeRoleMgm />,
          },
        ],
      },

      // 監聽系統
      {
        path: 'Surveillance_System',
        title: '监听系统',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to='Surveillance_Management' />,
          },
          {
            path: 'Surveillance_Management',
            title: '监听管理',
            element: <MonitorMgm />,
          },
        ],
      },

      { path: '*', element: <Error404 /> },
    ],
  },
];
