import React from 'react';
import PropTypes from 'prop-types';
import { Typography, List, Switch, Space, Spin } from 'antd';
import { formatDateTime, EnableKey } from '@/utils';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';

const DeviceList = ({
  loading = false,
  deleteLoading = false,
  dataSource = [],
  updateDevice,
  deleteDevice,
}) => {
  return (
    <Spin spinning={loading}>
      <List
        dataSource={dataSource}
        renderItem={item => (
          <List.Item
            actions={[
              <Switch
                key='isBind'
                checkedChildren='开启'
                unCheckedChildren='关闭'
                checked={item.isBind === EnableKey['Yes']}
                onChange={e => updateDevice(e, item.id)}
              />,
              <BtnDeleteConfirm
                key='delete'
                handleOk={() => deleteDevice(item.id)}
                loading={deleteLoading}
                placement='topRight'
              />,
            ]}>
            <Space direction='vertical'>
              <Typography.Text strong>{item.deviceUID}</Typography.Text>
              <Typography.Text type='secondary'>
                建立时间：{formatDateTime(item.createdAt)}
              </Typography.Text>
              <Typography.Text type='secondary'>
                更新时间：{formatDateTime(item.updatedAt)}
              </Typography.Text>
            </Space>
          </List.Item>
        )}
      />
    </Spin>
  );
};

DeviceList.propTypes = {
  loading: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  dataSource: PropTypes.array,
  updateDevice: PropTypes.func.isRequired,
  deleteDevice: PropTypes.func.isRequired,
};

export default DeviceList;
