import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { Box, Button } from '@WRAP_COMP';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import InputTextArea from '@/components/InputTextArea';
import SettingCard from './SettingCard';

function NoResponse({
  title = '',
  NO_RESPONSE_ENABLED,
  NO_RESPONSE_SEC,
  NO_RESPONSE_TIP,
  configKeysValue = {},
}) {
  const enable = configKeysValue[NO_RESPONSE_ENABLED].value === 'Yes';
  const [sec, setSec] = useState(() =>
    Number(configKeysValue[NO_RESPONSE_SEC].value),
  );
  const [tip, setTip] = useState(() => configKeysValue[NO_RESPONSE_TIP].value);

  const { onSuccessMsg } = useAlertMessage();
  const [update, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    {
      onCompleted: ({ updatePlatformSetting }) => {
        updatePlatformSetting.key === NO_RESPONSE_SEC &&
          setSec(Number(updatePlatformSetting.value));
        updatePlatformSetting.key === NO_RESPONSE_ENABLED && onSuccessMsg();
      },
    },
  );

  const onClickConfirm = () => {
    Promise.all(
      [
        { key: NO_RESPONSE_SEC, value: String(sec) },
        { key: NO_RESPONSE_TIP, value: tip },
      ].map(({ key, value }) =>
        update({
          filter: { PlatformSetting: { key } },
          in: { value },
        }),
      ),
    ).finally(() => onSuccessMsg());
  };

  return (
    <SettingCard
      title={title}
      subTitle='客服一段时间没有响应顾客消息时，系统自动向他发送一段消息'
      hasEnable={true}
      checked={enable}
      defaultChecked={enable}
      loading={updateLoading}
      onChange={checked => {
        update({
          filter: { PlatformSetting: { key: NO_RESPONSE_ENABLED } },
          in: { value: checked ? 'Yes' : 'No' },
        });
      }}
      content={
        <>
          <Box mt='2'>
            客服超过
            <Box display='inline-block' mx='2'>
              <InputNumber value={sec} onChange={v => setSec(v)} size='small' />
            </Box>
            秒后无响应自动发送
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-end'
            mt='2'>
            <InputTextArea
              value={tip}
              setValue={setTip}
              onChange={e => setTip(e.target.value)}
              rows={2}
            />
            <Button
              disabled={updateLoading}
              type='primary'
              ml='3'
              onClick={onClickConfirm}>
              确认
            </Button>
          </Box>
        </>
      }
    />
  );
}

export default NoResponse;
