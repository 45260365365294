/**
 * 歷史諮詢紀錄
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EyeTwoTone, DownloadOutlined } from '@ant-design/icons';
import { setVisibleDrawer } from '@/store/global';
import { setRoomDetail } from '@/store/room';
import { Space, Tag, Typography } from 'antd';
import { Button } from '@/plugin/wrap-comp';

import { useActiveList } from '@/hook/useList';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import useRoom from '@/hook/useRoom';

import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import BtnTooltip from '@/components/Button/BtnTooltip';
import ViewRoomDrawer from '@/views/CSRoomDeactivated/ViewRoomDrawer';
import UserDetailDrawer from '@/components/Drawer/UserDetailDrawer';
import {
  setDateRanges,
  formatSearchDateTime,
  RoomStatusKeys,
  AccountTypeKeys,
  AccountTypeDisplay,
  EnableKey,
} from '@/utils';
import ConversationTime from './ConversationTime';

const defaultDateTime = setDateRanges(0);
const defaultSearch = {
  accessDomain: '',
  ownerUsername: '', // 會員帐号
  deactivatedUsername: '', // 封存客服
  accountType: AccountTypeKeys['Unknown'],
  isEffective: EnableKey['Unknown'],
  date: [defaultDateTime.start, defaultDateTime.end], // 日期套件要用的預設格式
};
const defaultSearchDataFormat = formatSearchDateTime([
  defaultDateTime.start,
  defaultDateTime.end,
]);
const originFilter = {
  statusIn: [RoomStatusKeys['Deactivated']],
  baseFilter: {
    sortField: 'room_id',
    sortType: 'DESC',
    rangeField: 'deactivated_at',
    rangeType: 'ByDateTime',
    greaterThanEqual: defaultSearchDataFormat[0],
    lessThanEqual: defaultSearchDataFormat[1],
  },
};

export default function ConsultationChatDeactivated() {
  const { onErrorMsg, onWarningMsg } = useAlertMessage();
  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
    refetch,
  } = useActiveList({
    api: 'LIST_DEACTIVATED_CS_ROOM',
    originFilter: { ...originFilter },
    handleData: ({ listConsultingRoom }) => {
      const { consultingRooms = [], meta = {} } = listConsultingRoom;
      let list = [];
      let total = 0;
      try {
        list = consultingRooms.map(item => {
          let obj = { ...item };
          // 封存客服
          obj.deactivatedUsername = obj.deactivatedUserID
            ? item.userRooms.find(u => u.userID === obj.deactivatedUserID)?.user
                .username || '-'
            : '待咨询自动封存';
          // 會員帳號
          obj.username =
            item.userRooms.find(u => u.userID === item.ownerUserID)?.user
              .username || '';
          return obj;
        });
        total = meta?.total || 0;
      } catch (err) {
        onErrorMsg(err);
      }
      return { list, total };
    },
  });

  const [currSearch, setCurrSearch] = useState({ ...originFilter });
  const onSearchHandle = ({
    accessDomain,
    isEffective,
    roomID,
    date,
    createdAt,
    ...props
  }) => {
    const formatDate = formatSearchDateTime(date);
    const formatCreatedAt = formatSearchDateTime(createdAt);
    const searchData = {
      statusIn: originFilter.statusIn,
      consultingRoom: { accessDomain, isEffective, roomID },
      ...props,
      baseFilter: {
        ...originFilter.baseFilter,
        greaterThanEqual: formatDate[0],
        lessThanEqual: formatDate[1],
        ...(createdAt && {
          createdAtGTE: formatCreatedAt[0],
          createdAtLTE: formatCreatedAt[1],
        }),
      },
    };
    setCurrSearch({ ...searchData });
    handleSearchFilter({ ...searchData });
  };

  const dispatch = useDispatch();
  const setVisibleHandle = visible => {
    if (!visible) dispatch(setRoomDetail(null));
    dispatch(setVisibleDrawer({ name: 'ViewRoomDrawer', visible }));
  };

  const { roomDetail } = useSelector(({ room }) => room);
  const [currIdx, setCurrIdx] = useState(0);
  const { getRoomLoading, fetchRoomInfo } = useRoom();
  const fetchRoomInfoHandle = (id, extraInfo) => {
    fetchRoomInfo({ id, extraInfo });
  };

  const clickControl = async (data, idx) => {
    setCurrIdx(idx);
    fetchRoomInfoHandle(data.roomID);
    setVisibleHandle(true);
  };

  /**
   * 報表下載
   * - 下載限制最多 3 天量
   */
  const [currSearchData, setCurrSearchData] = useState([
    ...defaultSearchDataFormat,
  ]);
  const onChangeData = m => {
    setCurrSearchData([...formatSearchDateTime(m)]);
  };
  const isAllowDownloadDayRange = () => {
    const limitRangeSec = 24 * 60 * 60 * 3;
    const searchRange = currSearchData[1] - currSearchData[0];
    return searchRange <= limitRangeSec;
  };
  const [downloadReport, { loading: downLoading }] = useMutationApi(
    apiType.DOWNLOAD_CS_ROOM_REPORT,
  );

  const downloadReportHandle = async () => {
    if (!isAllowDownloadDayRange()) {
      onWarningMsg('下载限制最多三天');
      return;
    }
    const [error, data] = await downloadReport({ filter: { ...currSearch } });
    if (error) return;
    if (data?.data?.downloadConsultingRoomReport) {
      let a = document.createElement('a');
      a.href = data.data.downloadConsultingRoomReport;
      a.click();
    } else {
      onErrorMsg('报表下载失败');
    }
  };

  const changePageSpecifyDetail = async ({ Idx, page }) => {
    await handleChangePage({ perPage: pagination.perPage, page });
    const { error, data } = await refetch({
      pagination: { perPage: pagination.perPage, page },
    });
    if (error) return;
    return {
      roomID: data.listConsultingRoom.consultingRooms[Idx]?.roomID || 0,
    };
  };

  const onChangeDetailRoom = async (isNext = true) => {
    let Idx = isNext ? currIdx + 1 : currIdx + -1;
    let roomID = list[Idx]?.roomID || 0;
    let page = pagination.page;

    // 上一頁
    if (Idx < 0) {
      if (page === 1) {
        onWarningMsg('已是第一笔纪录');
        return;
      }
      Idx = pagination.perPage - 1;
      page = page - 1;
      const data = await changePageSpecifyDetail({ Idx, page });
      roomID = data.roomID;
    }

    // 下一頁
    if (Idx === list.length) {
      if (pagination.page > total / pagination.perPage) {
        onWarningMsg('已是最後ㄧ笔纪录');
        return;
      }
      Idx = 0;
      page = page + 1;
      const data = await changePageSpecifyDetail({ Idx, page });
      roomID = data.roomID;
    }
    setCurrIdx(Idx);
    fetchRoomInfoHandle(roomID);
  };

  const searchFields = [
    {
      name: 'date',
      type: 'dateRange',
      label: '封存时间',
      showTime: true,
      onChange: onChangeData,
    },
    {
      name: 'createdAt',
      type: 'dateRange',
      label: '创建时间',
      showTime: true,
      allowClear: true,
    },
    { name: 'roomID', type: 'text', label: '房間ID' },
    { name: 'deactivatedUsername', type: 'text', label: '封存客服帐号' },
    { name: 'firstJoinUsername', type: 'text', label: '进线客服帐号' },
    { name: 'ownerUsername', type: 'text', label: '會員帐号' },
    {
      name: 'accountType',
      type: 'select',
      label: '會員帐号类型',
      optionsObj: {
        Unknown: { ...AccountTypeDisplay['Unknown'] },
        CommonUser: { ...AccountTypeDisplay['CommonUser'] },
        Tourist: { ...AccountTypeDisplay['Tourist'] },
      },
    },
    {
      name: 'isEffective',
      type: 'select',
      label: '对话状态',
      optionsObj: {
        Unknown: { searchText: '全部类型' },
        Yes: { searchText: '有效对话' },
        No: { searchText: '无效对话' },
      },
    },
    { name: 'accessDomain', type: 'text', label: '访问域名' },
  ];
  const columns = [
    {
      title: '房間 #ID',
      dataIndex: 'roomID',
      key: 'roomID',
      width: '10%',
      fixed: 'left',
    },
    {
      title: '客戶信息',
      key: 'userInfo',
      width: '15%',
      render: r => (
        <>
          {r.username ? (
            `${r.username} #${r.ownerUserID}`
          ) : (
            <Tag color='orange'>遊客 #{r.ownerUserID}</Tag>
          )}
        </>
      ),
    },
    {
      title: '封存客服',
      dataIndex: 'deactivatedUsername',
      key: 'deactivatedUsername',
      width: '10%',
      render: (n, { deactivatedUserID }) => (
        <>
          {n}
          {deactivatedUserID && ` #${deactivatedUserID}`}
        </>
      ),
    },
    {
      title: '对话时间',
      key: 'conversation',
      render: ({ createdAt, deactivatedAt }) => (
        <ConversationTime createdAt={createdAt} deactivatedAt={deactivatedAt} />
      ),
    },
    {
      title: '访问域名',
      dataIndex: 'accessDomain',
      key: 'accessDomain',
    },
    {
      title: '房间资讯',
      key: 'roomInfo',
      render: r => (
        <>
          <Typography.Text>裝置: {r.deviceOS}</Typography.Text>
          <br />
          <Typography.Text>IP: {r.ipAddress}</Typography.Text>
          <br />
          <Typography.Text>
            地區: {r.country} {r.administrativeArea}
          </Typography.Text>
        </>
      ),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: (record, d, idx) => (
        <Space>
          <BtnTooltip
            onClick={() => clickControl(record, idx)}
            tipTitle='查核'
            icon={<EyeTwoTone />}></BtnTooltip>
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={defaultSearch}
        onFinish={onSearchHandle}
        defaultCollapsed={false}
      />
      <DataTable
        columns={columns}
        rowKey='roomID'
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        <Button
          variant='secondary'
          icon={<DownloadOutlined />}
          onClick={downloadReportHandle}
          loading={downLoading}>
          報表下載
        </Button>
      </DataTable>
      <ViewRoomDrawer
        loading={loading || getRoomLoading}
        onChangeRoom={onChangeDetailRoom}
      />
      <UserDetailDrawer
        user={roomDetail?.ownerUserData || {}}></UserDetailDrawer>
    </PageWrap>
  );
}
