import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined, EditTwoTone, EyeTwoTone } from '@ant-design/icons';
import { Button, Switch, Space } from 'antd';
import { useActiveList } from '@/hook/useList';
import useOrigin from '@/hook/useOrigin';
import { useMutationApi, apiType } from '@/hook/useApi';
import { PreviewModal } from '@/hook/useEditor';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { EnableKey } from '@/utils';
import Drawer from './Drawer';

const originFilter = {
  consultingGreeting: {
    consultingRoomOriginID: 1,
    isEnabled: EnableKey['Unknown'],
  },
};

export default function ConsultingGreeting() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { originSelectList, loading: originListLoading } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });

  const searchFields = useMemo(
    () => [
      {
        name: 'consultingRoomOriginID',
        type: 'select',
        label: '所属域名',
        options: originSelectList,
      },
    ],
    [originSelectList],
  );

  const [currOriginID, setCurrOriginID] = useState(
    originFilter.consultingGreeting.consultingRoomOriginID,
  );

  // table
  const {
    data: { list },
    loading,
    handleSearchFilter,
    refetch,
  } = useActiveList({
    api: 'LIST_CS_GREETING',
    originFilter: { ...originFilter },
    handleData: ({ listConsultingGreeting }) => ({
      list: listConsultingGreeting?.ConsultingGreetings || [],
    }),
  });

  // 搜尋Bar
  const searchBarOnFinish = greetingSearch => {
    setCurrOriginID(greetingSearch.consultingRoomOriginID);
    handleSearchFilter({
      ...originFilter,
      consultingGreeting: {
        ...originFilter.consultingGreeting,
        ...greetingSearch,
      },
    });
  };

  const onCompletedCallback = { onCompleted: () => refetch() };

  // 新增
  const [createGreeting, { loading: createLoading }] = useMutationApi(
    apiType.CREATE_CS_GREETING,
    onCompletedCallback,
  );

  // 編輯
  const [updateGreeting, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_CS_GREETING,
    onCompletedCallback,
  );

  const onChangeEnabled = (checked, record) => {
    updateGreeting({
      filter: { consultingGreeting: { id: record.id } },
      in: { isEnabled: checked ? 'Yes' : 'No' },
    });
  };

  // 刪除
  const [deleteGreeting, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_CS_GREETING,
    onCompletedCallback,
  );

  const [visible, setVisible] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const openDrawer = (data = null) => {
    setDetailData(data);
    setVisible(true);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const previewVisible = (val, content) => {
    setModalVisible(val);
    setPreviewContent(() => (val ? content : null));
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '备注',
      dataIndex: 'title',
      key: 'title',
      render: title => <>{title}</>,
    },
    {
      title: '发送时间',
      dataIndex: 'afterSendSec',
      key: 'afterSendSec',
      render: mins => <>{mins} 秒後</>,
    },
    {
      title: '发送范围',
      dataIndex: 'source',
      key: 'source',
      render: source => <>{source}</>,
    },
    {
      title: '启用状态',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (h, record) => (
        <Switch
          checkedChildren='启用'
          unCheckedChildren='停用'
          checked={h === 'Yes'}
          disabled={
            h === 'Unknown' ||
            !authRoutesKey.includes('API_ConsultingGreeting_Update')
          }
          onChange={checked => onChangeEnabled(checked, record)}
        />
      ),
    },
    {
      title: '動作',
      key: 'action',
      width: '15%',
      fixed: 'right',
      render: record => (
        <Space>
          <Button
            onClick={() => previewVisible(true, record.content)}
            type='link'
            icon={<EyeTwoTone />}
          />
          {authRoutesKey.includes('API_ConsultingGreeting_Update') && (
            <Button
              onClick={() => openDrawer(record)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          <BtnDeleteConfirm
            handleOk={() =>
              deleteGreeting({
                filter: { consultingGreeting: { id: record.id } },
              })
            }
            loading={deleteLoading}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageWrap>
        <SearchBar
          fields={searchFields}
          defaultSearch={originFilter.consultingGreeting}
          onFinish={searchBarOnFinish}
          loading={originListLoading}
        />
        <DataTable
          columns={columns}
          dataSource={list}
          loading={loading || originListLoading}>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openDrawer()}>
            新建行销用语
          </Button>
        </DataTable>
      </PageWrap>
      <Drawer
        visible={visible}
        setVisible={setVisible}
        consultingRoomOriginID={currOriginID}
        detailData={detailData}
        createGreeting={createGreeting}
        updateGreeting={updateGreeting}
        loading={createLoading || updateLoading}
        previewVisible={previewVisible}
      />
      <PreviewModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        htmlStr={previewContent}
      />
    </>
  );
}
