/**
 * 自動輪派
 */
import React from 'react';
// import { useSelector } from 'react-redux';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import SettingCard from './SettingCard';

function RedirectVerify({
  title = '',
  REDIRECT_VERIFY_ENABLED,
  configKeysValue = {},
}) {
  // const { authRoutesKey } = useSelector(({ auth }) => auth);
  const enable = configKeysValue[REDIRECT_VERIFY_ENABLED].value === 'Yes';
  const { onSuccessMsg } = useAlertMessage();
  const [updateData, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    { onCompleted: () => onSuccessMsg() },
  );
  return (
    <SettingCard
      title={title}
      subTitle='可设定是否呈现检测过程。默认不显示'
      hasEnable={true}
      defaultChecked={enable}
      loading={updateLoading}
      // disabled={
      //   !authRoutesKey.includes('API_ConsultingRoomSetting_AutoDistribute')
      // }
      onChange={checked =>
        updateData({
          filter: { PlatformSetting: { key: REDIRECT_VERIFY_ENABLED } },
          in: { value: checked ? 'Yes' : 'No' },
        })
      }
    />
  );
}

export default RedirectVerify;
