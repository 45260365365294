import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@WRAP_COMP';
import ProForm, {
  QueryFilter,
  ProFormText,
  ProFormSelect,
  ProFormDigit,
} from '@ant-design/pro-form';
import { Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import DateRangePicker from '@/components/DateRangePicker';

// 物件轉成select 需要的 option 欄位
const objToSelectOptionArr = (obj = {}) => {
  let arr = [];
  for (const key in obj) {
    arr.push({
      value: key,
      label: obj[key].searchText,
      disabled: !!obj[key].disabled,
    });
  }
  return arr;
};
const SearchBar = ({
  children,
  fields,
  defaultSearch = {},
  onFinish,
  loading = false,
  ...props
}) => {
  const [initialValues] = useState(() => ({ ...defaultSearch }));
  const fieldType = {
    dateRange: p => <DateRangePicker key={p.name} {...p} />,
    text: p => (
      <ProFormText key={p.name} width='md' placeholder='请输入' {...p} />
    ),
    digit: p => (
      <ProFormDigit key={p.name} width='md' placeholder='请输入' {...p} />
    ),
    select: p => (
      <ProFormSelect
        key={p.name}
        width='md'
        allowClear={false}
        options={objToSelectOptionArr(p.optionsObj || {})}
        {...p}
      />
    ), // 自定義傳 option
  };
  return (
    <>
      <Box p='24px' pb='0' mb='3' bgColor='white'>
        <Spin spinning={loading}>
          <QueryFilter
            labelWidth='auto'
            onFinish={async values => {
              await onFinish({ ...defaultSearch, ...values });
            }}
            initialValues={initialValues}
            submitter={{ submitButtonProps: { icon: <SearchOutlined /> } }}
            {...props}>
            <ProForm.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
              {fields.map(f => {
                return fieldType[f.type] ? fieldType[f.type](f) : null;
              })}
            </ProForm.Group>
            {/* {children} */}
          </QueryFilter>
        </Spin>
      </Box>
    </>
  );
};

SearchBar.propTypes = {
  // fields: PropTypes.array.isRequired,
  defaultSearch: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default SearchBar;
