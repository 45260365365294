import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Badge } from 'antd';
import { Box } from '@WRAP_COMP';
import useRecMessage from '@HOOK/useRecMessage';
import AvatarFetch from '@/components/Avatar/AvatarFetch';
const { theme } = require('@/plugin/theme');

function Header({
  roomID = 0,
  showIsOnline = false,
  isOnline = false,
  avatarURL = '',
  username = '',
  userID = 0,
  tags = null,
}) {
  /**
   * isOnlineState 上下線狀態
   */
  const [isOnlineState, setIsOnlineState] = useState(() => isOnline);
  const userOnlineInfoHandle = ({ userOnlineInfo }) => {
    userOnlineInfo.userID === userID &&
      setIsOnlineState(userOnlineInfo.isOnline === 'Yes');
  };
  /**
   * 監聽
   *  */
  useRecMessage({
    roomID,
    userOnlineInfo: userOnlineInfoHandle,
  });

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Badge
          dot={showIsOnline}
          offset={[0, 4]}
          style={{
            background: isOnlineState ? theme.success : theme['gray-2'],
            border: `5px solid ${
              isOnlineState ? theme.success : theme['gray-2']
            }`,
          }}>
          <AvatarFetch avatarURL={avatarURL} size={'default'} />
        </Badge>
        <Typography.Title
          level={5}
          style={{ marginBottom: '0px', marginLeft: '8px' }}>
          {username} #{userID}
        </Typography.Title>
      </Box>
      {tags}
    </Box>
  );
}

Header.propTypes = {
  roomID: PropTypes.number,
  showIsOnline: PropTypes.bool,
  isOnline: PropTypes.bool,
  avatarURL: PropTypes.string,
  username: PropTypes.string,
  userID: PropTypes.number,
  tags: PropTypes.node,
};
export default Header;
