/**
 * 排隊機制
 */
import React, { useState } from 'react';
import { Box } from '@WRAP_COMP';
import { InputNumber, Button, Typography } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import SettingCard from '../SettingCard';
import QusMgm from './QusMgm';

function QueueMaxCount({ title = '', QUEUE_MAX_COUNT, configKeysValue = {} }) {
  const [count, setCount] = useState(() =>
    Number(configKeysValue[QUEUE_MAX_COUNT].value),
  );
  const { onSuccessMsg } = useAlertMessage();
  const [update, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    {
      onCompleted: ({ updatePlatformSetting }) => {
        setCount(Number(updatePlatformSetting.value));
        onSuccessMsg();
      },
    },
  );
  return (
    <SettingCard
      title={title}
      content={
        <>
          <Box display='flex' justifyContent='space-between' mb='2'>
            <div>
              最大容许进行中数量
              <InputNumber
                max={20}
                min={0}
                value={count}
                onChange={v => setCount(v)}
                size='small'
                style={{ marginLeft: '8px' }}
              />
              <Typography.Text type='secondary'> (设定0为关闭)</Typography.Text>
            </div>
            <Button
              type='primary'
              loading={updateLoading}
              onClick={() =>
                update({
                  filter: { PlatformSetting: { key: QUEUE_MAX_COUNT } },
                  in: { value: String(count) },
                })
              }>
              确认
            </Button>
          </Box>
          <QusMgm />
        </>
      }
    />
  );
}

export default QueueMaxCount;
