import { useState, useEffect } from 'react';
import { Input, Button, Select, Form, Drawer as ADrawer } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import useMenuList from '@/hook/useMenuList';
import AntMenuTree from '@/components/AntMenuTree';
import { AccountTypeKeys } from '@/utils';
import useMe from '@/hook/useMe';

const accTypeArray = [
  { value: AccountTypeKeys['Manager'], label: '管理员角色' },
  { value: AccountTypeKeys['CustomerService'], label: '客服角色' },
  { value: AccountTypeKeys['Monitor'], label: '监察角色' },
];

const Drawer = ({ visible, setVisible, isEdit, detailData = {}, refetch }) => {
  const {
    treeData,
    authorityDataHandle,
    authorityKeysForTreeHandle,
  } = useMenuList();
  const { authorityKeysHandle } = useMe();
  const [roleForm] = Form.useForm();

  // 提交表單
  const [checkedMenuKeysResult, setCheckedMenuKeysResult] = useState([]); // 送出樹狀 checked, 包含父層 keys
  const [expandedKeys, setExpandedKeys] = useState([]); // 控制樹狀 checked
  const [checkedMenuKeys, setCheckedMenuKeys] = useState([]); // 控制樹狀 checked

  // 樹狀處理
  const onCheckMenuTree = (checkedKeysValue, info) => {
    setCheckedMenuKeys([...checkedKeysValue]);
    setCheckedMenuKeysResult([...checkedKeysValue, ...info.halfCheckedKeys]);
  };

  // detail 處理
  const [roleData, setRoleData] = useState({});
  const detailDataHandle = async getRole => {
    await setRoleData({ ...getRole });
    await roleForm.setFieldsValue(getRole);
    setCheckedMenuKeys(authorityKeysForTreeHandle(getRole.authority));
    setCheckedMenuKeysResult(authorityKeysHandle(getRole.authority));
  };

  const mutationCallBack = {
    onCompleted: () => {
      refetch();
      closeDrawer();
    },
  };
  const [createdRole, { loading: createdLoading }] = useMutationApi(
    apiType.CREATED_ROLE,
    mutationCallBack,
  );
  const [updateRole, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_ROLE,
    mutationCallBack,
  );

  const submitHandle = async fields => {
    const authority = authorityDataHandle(checkedMenuKeysResult); // 回傳 menu 送出格式
    let variables = { in: { ...fields, authority } };
    if (isEdit) {
      variables = { ...variables, filter: { role: { id: roleData.id } } };
      updateRole(variables);
    } else {
      createdRole(variables);
    }
  };

  const closeDrawer = async () => {
    await roleForm.resetFields();
    setExpandedKeys(() => []);
    setCheckedMenuKeys([]);
    setVisible(false);
  };

  useEffect(() => {
    if (isEdit && detailData?.getRole) detailDataHandle(detailData.getRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, detailData]);

  return (
    <ADrawer
      visible={visible}
      title={isEdit ? '編輯' : '新增'}
      onClose={closeDrawer}
      width={520}
      bodyStyle={{ paddingBottom: 80 }}>
      <Form form={roleForm} onFinish={submitHandle} layout='vertical'>
        <Form.Item
          name='supportAccountType'
          label='角色所属帐号'
          rules={[{ required: true, message: '必填' }]}>
          <Select placeholder='請選擇'>
            {accTypeArray.map(item => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name='name'
          label='角色名稱'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>

        <Form.Item label='Menu 權限'>
          <AntMenuTree
            onCheck={onCheckMenuTree}
            checkedKeys={checkedMenuKeys}
            treeData={treeData}
            onExpand={keys => setExpandedKeys(() => [...keys])}
            expandedKeys={expandedKeys}
          />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType='submit'
            loading={createdLoading || updateLoading}
            type='primary'>
            提交
          </Button>
        </Form.Item>
      </Form>
    </ADrawer>
  );
};

export default Drawer;
