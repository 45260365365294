import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { initRoutes } from '@/hook/useAuthRoutes';
import DelayedFallback from '@/components/DelayedFallback';
import GlobalAdmin from '@/views/GlobalAdmin';
import AdminLayout from '@/components/Layout/Admin';

const AdminApp = () => {
  const { isLogin, loading } = useSelector(({ global }) => global);
  const routing = useRoutes(initRoutes(isLogin));
  if (!isLogin) return <>{routing}</>;
  return (
    <GlobalAdmin>
      <AdminLayout loading={loading}>
        <Suspense fallback={<DelayedFallback />}>{routing}</Suspense>
      </AdminLayout>
    </GlobalAdmin>
  );
};

export default AdminApp;
