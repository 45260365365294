import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@WRAP_COMP';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Button,
  Typography,
  Dropdown,
  Menu,
  Space,
  Badge,
  Drawer,
} from 'antd';
import AvatarFetch from '@/components/Avatar/AvatarFetch';
import useAlertMessage from '@/hook/useAlertMessage';
import { useMutationApi, apiType } from '@/hook/useApi';
import useLogin from '@/hook/useLogout';
import useMe from '@/hook/useMe';
import BasicForm from '@/components/User/BasicForm/Form';
import AvatarUpload from '@/components/Avatar/AvatarUpload';

const { theme } = require('@/plugin/theme');

const ContainerNavbar = ({ collapsed, onCollapse }) => {
  const { meInfo } = useSelector(({ auth }) => auth);
  const { onSuccessMsg } = useAlertMessage();
  const { fetchLogout } = useLogin();
  const logoutHandle = async () => await fetchLogout();

  // 上下線
  const [isStealth, setIsStealth] = useState('Yes');
  useEffect(() => {
    meInfo?.isStealth && setIsStealth(meInfo.isStealth);
  }, [meInfo]);

  const { fetchMeHandler } = useMe();
  const [updateUserSetting, { loading }] = useMutationApi(
    apiType.UPDATE_USER_SETTING,
    {
      onCompleted: ({ updateUserSetting }) => {
        setIsStealth(updateUserSetting.isStealth);
        fetchMeHandler();
      },
    },
  );
  const onlineHandle = () => {
    if (loading) return;
    updateUserSetting({
      filter: { userSetting: { userID: meInfo.id } },
      in: { isStealth: isStealth === 'Yes' ? 'No' : 'Yes' },
    });
  };

  // 編輯個人資訊
  const [profiledVisible, setProfiledVisible] = useState(false);
  const profiledVisibleHandel = (v = true) => {
    setProfiledVisible(v);
  };
  const [file, setFile] = useState(null);
  const [updateUser, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_USER,
  );
  const updateUserHandle = async fields => {
    let formFields = { ...fields };
    if (file) formFields.avatarContent = file;
    const [err] = await updateUser({
      filter: { user: { id: meInfo.id } },
      in: { ...formFields },
    });
    if (err) return;
    fetchMeHandler();
    onSuccessMsg('update');
  };

  const dotColor = isStealth =>
    isStealth === 'Yes' ? theme.warning : theme.success;

  const menu = (
    <Menu>
      <Menu.Item key='onlineStatus'>
        <Space onClick={onlineHandle} style={{ width: '100%' }}>
          {isStealth === 'Yes' ? (
            <Badge status='success' text='上線' />
          ) : (
            <Badge status='warning' text='隐身' />
          )}
        </Space>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='profile'>
        <Space onClick={profiledVisibleHandel}>
          <UserOutlined />
          个人资讯
        </Space>
      </Menu.Item>
      <Menu.Item key='logout'>
        <Space onClick={logoutHandle}>
          <LogoutOutlined />
          退出登入
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout.Header style={{ padding: 0, height: '48px' }}>
        <Box
          bgColor='white'
          px='1'
          height='100%'
          lineHeight='0'
          display='flex'
          justifyContent='space-between'
          alignItems='center'>
          <Button
            type='link'
            icon={
              collapsed ? (
                <MenuUnfoldOutlined style={{ color: 'grayText' }} />
              ) : (
                <MenuFoldOutlined style={{ color: 'grayText' }} />
              )
            }
            onClick={() => onCollapse(!collapsed)}
          />
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            lineHeight='30px'>
            <Dropdown overlay={menu} trigger={['hover']}>
              <Box px='3' style={{ cursor: 'pointer' }}>
                <Badge color={dotColor(isStealth)} dot={true} offset={[0, 4]}>
                  <AvatarFetch avatarURL={meInfo.avatarURL} />
                </Badge>
                <Typography.Text strong level={5} style={{ marginLeft: '8px' }}>
                  {meInfo.username}
                </Typography.Text>
              </Box>
            </Dropdown>
          </Box>
        </Box>
      </Layout.Header>
      <Drawer
        visible={profiledVisible}
        title={meInfo.username || ''}
        width={'30%'}
        onClose={() => profiledVisibleHandel(false)}>
        <AvatarUpload
          avatarURL={meInfo?.avatarURL || ''}
          setUploadFile={file => setFile(file)}
          isEdit={true}
        />
        <BasicForm
          user={meInfo}
          btnUserLoading={updateLoading}
          updateUser={updateUserHandle}
          isEdit={true}
          editType='self'
        />
      </Drawer>
    </>
  );
};

export default ContainerNavbar;
