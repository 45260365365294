import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@WRAP_COMP';
import { setVisibleDrawer } from '@/store/global';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import { Empty, Tree, Divider, Button } from 'antd';
import TabsScrollBlock from '@/components/TabsScrollBlock';
import SpinachInfo from '@/components/User/SpinachInfo';
import RoomDetailDesc from '@/components/CSRoomAdmin/RoomDetailDesc';
import RoomUserList from '@/components/CSRoomAdmin/RoomUserList';
import CSForm from '@/components/ChatRoomContent/CSForm';
import { AccountTypeKeys, MsgContentTypeKeys, RoomTypeKey } from '@/utils';

const tabPaneArr = [
  { key: 'roomInfo', tab: '房间资讯' },
  { key: 'csForm', tab: '询前表单' },
  { key: 'cannedMsg', tab: '快捷讯息' },
  { key: 'functionMsg', tab: '功能话术' },
];

function InfoTabsBlock({
  room = null,
  ownerUserData = null,
  roomUsersMap = {},
  setCannedText = () => {},
  visibleJoinDrawer = () => {},
}) {
  const dispatch = useDispatch();
  const openUserDetailDrawer = () => {
    dispatch(setVisibleDrawer({ name: 'UserDetailDrawer', visible: true }));
  };

  const [currTabKey, setCurrInfoTabKey] = useState(tabPaneArr[0].key);
  const onChangeTab = tab => {
    if (tab === 'cannedMsg') fetchCannedListHandle();
    setCurrInfoTabKey(tab);
  };

  /**
   * 取得快捷回覆
   */
  const { fetchData: fetchCannedList, loading } = useQueryApi(
    apiType.LIST_CANNED,
  );
  const [cannedTreeData, setCannedTreeData] = useState([]);
  const fetchCannedListHandle = async () => {
    const { data } = await fetchCannedList();
    const {
      listCannedResponseCategory: { cannedResponseCategories },
      listCannedResponse: { cannedResponses },
    } = data;
    let tempData = [];
    cannedResponseCategories.forEach(category => {
      let children = [];
      cannedResponses.forEach(resItem => {
        resItem.categoryID === category.id &&
          children.push({ title: resItem.text, key: resItem.id });
      });
      tempData.push({ title: category.name, key: category.id, children });
    });
    setCannedTreeData(tempData);
  };

  const onSelectCannedMsg = (selectedKeys, info) => {
    if (info.node?.children) return;
    setCannedText(info.node.title);
  };

  /**
   * 功能話術
   */
  const [postMsg] = useMutationApi(apiType.POST_MSG);

  const onSelectFunctionMsg = (selectedKeys, info) => {
    if (info.node?.children) return;
    const templateID = info.node.key;

    postMsg({
      in: {
        roomID: room.roomID,
        type: RoomTypeKey['Consulting'],
        contentType: MsgContentTypeKeys['Bubble'],
        templateID,
      },
    });
  };

  const tabContentRender = {
    roomInfo: () => (
      <>
        <RoomDetailDesc {...room} />
        {ownerUserData?.accountType !== AccountTypeKeys['Tourist'] && (
          <>
            <Divider orientation='right'>会员资讯</Divider>
            <SpinachInfo userID={ownerUserData?.id || 0}></SpinachInfo>
            <Button
              type='link'
              onClick={openUserDetailDrawer}
              style={{ padding: '0px' }}>
              更多資訊
            </Button>
          </>
        )}
        <RoomUserList
          roomType={room.type}
          userRooms={room.userRooms}
          userInvitations={room.userInvitations}
          roomUsersMap={roomUsersMap}
          openInviteDrawer={visibleJoinDrawer}
        />
      </>
    ),
    csForm: () => (
      <CSForm formDataList={room?.formData || []} isReadType={true} />
    ),
    cannedMsg: ({ cannedTreeData }) =>
      cannedTreeData.length ? (
        <Tree treeData={cannedTreeData} onSelect={onSelectCannedMsg} />
      ) : (
        <Empty description={false} />
      ),

    functionMsg: () => {
      return (
        <Tree
          defaultExpandedKeys={[11]}
          treeData={[
            {
              key: 11,
              title: '充值话术',
              children: [
                { key: 1, title: '确认充值金额' },
                { key: 2, title: '完成充值提示' },
                { key: 3, title: '订单问题' },
                { key: 4, title: '请稍候' },
              ],
            },
          ]}
          onSelect={onSelectFunctionMsg}
        />
      );
    },
  };

  return (
    <Box height='100%' bgColor='white'>
      <TabsScrollBlock
        tabPaneArr={tabPaneArr}
        onChangeTab={onChangeTab}
        loading={loading}>
        {room &&
          ownerUserData &&
          tabContentRender[currTabKey]({ cannedTreeData })}
      </TabsScrollBlock>
    </Box>
  );
}

export default InfoTabsBlock;
