import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import useFile from '@/hook/useFile';
import { Button, Upload, Skeleton, Space, Avatar } from 'antd';
const { theme } = require('@/plugin/theme');

/**
 * 顯示 & 新增 & 更新 大頭貼
 * 顯示：丟入 avatarURL 獲取有效頭像 src
 * 新增&更新：setUploadFile 傳出上傳 File
 */
const AvatarUpload = ({
  avatarURL = '',
  setUploadFile,
  size = 120,
  isEdit = false,
}) => {
  const [src, setSrc] = useState('');
  const { getDownloadFile, getBase64, loading } = useFile();
  const beforeUploadHandle = async file => {
    const baseUrl = await getBase64(file);
    setSrc(baseUrl);
    setUploadFile(file);
    return false;
  };

  const getDownloadFileHandle = useCallback(
    async url => {
      const { error, data } = await getDownloadFile(url);
      if (error) return;
      setSrc(data);
    },
    [getDownloadFile],
  );

  useEffect(() => {
    avatarURL && getDownloadFileHandle(avatarURL);
    return () => {
      setSrc('');
    };
  }, [avatarURL, getDownloadFileHandle]);

  return (
    <Space direction='vertical' align='center'>
      {loading ? (
        <Skeleton.Avatar active={loading} size={size} shape={'circle'} />
      ) : (
        <Avatar
          src={src}
          size={size}
          icon={<UserOutlined />}
          style={{ background: theme['gray-2'] }}
        />
      )}
      {isEdit && (
        <Upload
          name='file'
          beforeUpload={beforeUploadHandle}
          showUploadList={false}
          accept='image/*'>
          <Button icon={<UploadOutlined />}>上传大头贴</Button>
        </Upload>
      )}
    </Space>
  );
};

AvatarUpload.propTypes = {
  avatarURL: PropTypes.string,
  size: PropTypes.number,
  setUploadFile: PropTypes.func.isRequired,
};
export default AvatarUpload;
