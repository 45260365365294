/**
 * 客服管理
 */
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  EditTwoTone,
  QuestionCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { setVisibleDrawer } from '@/store/global';
import { Button, Space, Tooltip, Typography } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import useAlertMessage from '@/hook/useAlertMessage';
import useMerchant from '@/hook/useMerchant';
import useMe from '@/hook/useMe';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import AvatarWithText from '@/components/Avatar/AvatarWithText';
import UserDetailDrawer from '@/components/Drawer/UserDetailDrawer';
import CreateUserDrawer from '@/components/Drawer/CreateUserDrawer';
import OnlineStatusBadge from '@/components/User/OnlineStatusBadge';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import {
  formatDateTime,
  AccountTypeKeys,
  UserStatusKey,
  UserStatusDisplay,
} from '@/utils';
import AllowTimeForm from './AllowTimeForm';

const originFilter = {
  user: {
    username: '',
    email: '',
    status: UserStatusKey['Unknown'],
    accountType: AccountTypeKeys['CustomerService'],
    isDelete: 'No',
  },
};

const searchFields = [
  { name: 'username', type: 'text', label: '帐号' },
  { name: 'email', type: 'text', label: '信箱' },
  {
    name: 'status',
    type: 'select',
    label: '帐号狀態',
    optionsObj: UserStatusDisplay,
  },
];

export default function EmployeeCSMgm() {
  const { meInfo, authRoutesKey, isSystemType } = useMe();
  const { onSuccessMsg } = useAlertMessage();

  // 商戶列表
  const { merchantTableList } = useMerchant();
  const merchantRender = id => {
    const merchant = merchantTableList.find(el => el.id === id);
    return (
      <>
        {merchant?.key || '-'}
        {merchant?.merchantType ? ` (${merchant.merchantType})` : null}
      </>
    );
  };

  // 使用者列表
  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
    refetch,
  } = useActiveList({
    api: 'LIST_USER',
    originFilter: { ...originFilter },
    handleData: ({ listUser }) => ({
      list: listUser?.users || [],
      total: listUser?.meta?.total || 0,
    }),
  });

  const [updateSetting, { loading: updateSettingLdg }] = useMutationApi(
    apiType.UPDATE_USER_SETTING,
    {
      onCompleted: () => {
        refetch();
        onSuccessMsg('update');
      },
    },
  );

  const onFinishSearch = search => {
    handleSearchFilter({
      ...originFilter,
      user: { ...originFilter.user, ...search },
    });
  };

  /**
   * created user drawer
   */
  const [createDrawer, setCreateDrawer] = useState(false);
  const openCreateUserDrawer = v => setCreateDrawer(v);

  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const openDrawer = useCallback(
    v => {
      v || setUser(null);
      dispatch(setVisibleDrawer({ name: 'UserDetailDrawer', visible: v }));
    },
    [dispatch],
  );

  const handleUser = user => {
    setUser(() => user);
    openDrawer(true);
  };

  /**
   * delete user
   */
  const [deleteUser, { loading: deleteLdg }] = useMutationApi(
    apiType.DELETE_USER,
    {
      onCompleted: () => refetch(),
    },
  );
  const systemAccCols = () => {
    if (!isSystemType) return [];
    return [
      {
        title: '授权时间狀態',
        dataIndex: 'setting',
        key: 'allowStatus',
        render: record => (
          <Typography.Text type={record?.allowLoginEndAt ? 'success' : null}>
            {record?.allowLoginEndAt ? '啟用' : '停用'}
          </Typography.Text>
        ),
      },
      {
        title: '授权时间起讫',
        dataIndex: 'setting',
        key: 'allowTime',
        render: record => {
          if (!record || !record?.allowLoginEndAt) return <>-</>;
          return `${formatDateTime(
            record.allowLoginStartAt,
          )} ~ ${formatDateTime(record.allowLoginEndAt)}`;
        },
      },
    ];
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '客服',
      key: 'manager',
      fixed: 'left',
      render: ({ username, avatarURL }) => (
        <AvatarWithText avatarURL={avatarURL} username={username} />
      ),
    },
    {
      title: () => (
        <Tooltip title='聊天室顯示用'>
          別名
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'aliasName',
      key: 'aliasName',
      render: h => h || '-',
    },
    {
      title: '在线状态',
      key: 'isOnlineStatus',
      render: ({ isOnline, setting }) => {
        const isStealth = setting ? setting.isStealth : 'No';
        return (
          <OnlineStatusBadge
            isOnline={isOnline}
            isStealth={isStealth}></OnlineStatusBadge>
        );
      },
    },
    {
      title: '所属商户号(类型)',
      dataIndex: 'merchantID',
      key: 'merchantID',
      render: merchantID => merchantRender(merchantID),
    },
    {
      title: '信箱',
      dataIndex: 'email',
      key: 'email',
      render: h => h || '-',
    },
    {
      title: '帐号狀態',
      dataIndex: 'status',
      key: 'status',
      render: h => (
        <span style={{ color: UserStatusDisplay[h].color }}>
          {UserStatusDisplay[h].text}
        </span>
      ),
    },
    ...systemAccCols(meInfo.accountType),
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: h => formatDateTime(h),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: user => (
        <Space>
          {authRoutesKey.includes('API_Employee_Update') && (
            <Button
              onClick={() => handleUser(user)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {authRoutesKey.includes('API_Employee_Delete') && (
            <BtnDeleteConfirm
              handleOk={() => deleteUser({ filter: { user: { id: user.id } } })}
              loading={deleteLdg}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={{ ...originFilter.user }}
        onFinish={onFinishSearch}></SearchBar>

      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        {authRoutesKey.includes('API_Employee_Create') && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openCreateUserDrawer(true)}>
            新增客服
          </Button>
        )}
      </DataTable>

      {/* 編輯 */}
      <UserDetailDrawer user={user} closeCallback={() => openDrawer(false)}>
        {user && isSystemType && (
          <AllowTimeForm
            setting={{ ...user?.setting, userID: user?.id }} // setting 會有 null 的情況
            loading={updateSettingLdg}
            updateSetting={updateSetting}
          />
        )}
      </UserDetailDrawer>

      {/* 新增 */}
      <CreateUserDrawer
        title='新增客服'
        visible={createDrawer}
        setVisible={v => setCreateDrawer(v)}
        callback={refetch}
        accountTypes={AccountTypeKeys['CustomerService']}
      />
    </PageWrap>
  );
}
