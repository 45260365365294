import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { setIsLogin, setLoginInfo } from '@/store/global';
import { Input, Button, Typography, Form } from 'antd';
import { Card, Space } from '@/plugin/wrap-comp';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import AntAlert from '@/components/Alert';
import { getErrorMessage } from '@/utils';

const Login = () => {
  const { isLogin } = useSelector(({ global }) => global);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onSuccessMsg } = useAlertMessage();
  const [errMsg, setErrMsg] = useState('');
  const [loginForm] = Form.useForm();

  // 登入成功處理
  const loginProcess = async ({ Login }) => {
    await dispatch(setLoginInfo(Login));
    await dispatch(setIsLogin(true));
    onSuccessMsg('登入');
  };

  // 登入
  const [loginFetch, { loading }] = useMutationApi(apiType.POST_LOGIN, {
    context: { isShowGeneralError: false },
  });
  const onFinish = async fields => {
    ['username', 'password'].forEach(k => (fields[k] = fields[k].trim()));
    const [err, res] = await loginFetch({ ...fields });
    if (!err) {
      loginProcess(res.data || {});
      return;
    }
    err?.message === '403001'
      ? setErrMsg('授权时间到期,请联系窗口')
      : setErrMsg(() => getErrorMessage(err?.message || 0));
  };

  useEffect(() => {
    isLogin && navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Space direction='vertical' pb={4} align='center'>
      <Typography.Title>Bochat Admin</Typography.Title>
      <Card variant='shadow-lg' p={16}>
        <Typography.Title level={4} style={{ marginBottom: '20px' }}>
          Login
        </Typography.Title>
        <Form form={loginForm} size='large' onFinish={onFinish}>
          <AntAlert message={errMsg} />
          <Form.Item
            name='username'
            rules={[{ required: true, message: '請輸入帐号' }]}>
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='輸入帐号'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: '请输入密码' }]}>
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='输入密码'
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button htmlType='submit' type='primary' block loading={loading}>
              登入
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Typography.Text type='secondary'>
        {process.env.REACT_APP_VERSION}
      </Typography.Text>
    </Space>
  );
};

export default Login;
