/**
 * 自動封存
 */
import React, { useState } from 'react';
import { InputNumber, Card, Typography, Divider } from 'antd';
import { Box, Button } from '@WRAP_COMP';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import InputTextArea from '@/components/InputTextArea';
import SettingCard from './SettingCard';

function AutoDeactivate({
  title = '',
  AUTO_DEACTIVATE_ENABLED, // 自動封存啟用
  AUTO_DEACTIVATE_MIN, // 自動封存分數
  DEACTIVATE_TIP_ENABLED, // 封存提示訊息 啟用
  DEACTIVATE_TIP_MANUAL, // 手動封存提示訊息
  DEACTIVATE_TIP_AUTO, // 自動封存提示訊息
  configKeysValue = {},
}) {
  const [min, setMin] = useState(() =>
    Number(configKeysValue[AUTO_DEACTIVATE_MIN].value),
  );
  const [tipManual, setTipManual] = useState(
    () => configKeysValue[DEACTIVATE_TIP_MANUAL].value,
  );
  const [tipAuto, setTipAuto] = useState(
    () => configKeysValue[DEACTIVATE_TIP_AUTO].value,
  );
  const isEnabled = key => configKeysValue[key].value === 'Yes';

  const { onSuccessMsg } = useAlertMessage();
  const [update, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
    {
      onCompleted: ({ updatePlatformSetting }) => {
        if (updatePlatformSetting.key === AUTO_DEACTIVATE_MIN) {
          setMin(Number(updatePlatformSetting.value));
          onSuccessMsg();
        }
      },
    },
  );

  const onChangeEnable = key => async checked => {
    const [err] = await update({
      filter: { PlatformSetting: { key } },
      in: { value: checked ? 'Yes' : 'No' },
    });
    err || onSuccessMsg();
  };

  const onClickTipsConfirm = () => {
    Promise.all(
      [
        { key: DEACTIVATE_TIP_MANUAL, value: tipManual },
        { key: DEACTIVATE_TIP_AUTO, value: tipAuto },
      ].map(({ key, value }) =>
        update({
          filter: { PlatformSetting: { key } },
          in: { value },
        }),
      ),
    ).finally(() => onSuccessMsg());
  };

  return (
    <Card title={title}>
      <SettingCard
        title='自动封存咨询房'
        subTitle='客服前台设定时间内未产生新对话，则自动封存该咨询房'
        hasEnable={true}
        defaultChecked={isEnabled(AUTO_DEACTIVATE_ENABLED)}
        loading={updateLoading}
        bodyStyle={{ padding: 0 }}
        bordered={false}
        onChange={onChangeEnable(AUTO_DEACTIVATE_ENABLED)}
        content={
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'>
            <div>
              <Box display='inline-block' mr={2}>
                <InputNumber
                  value={min}
                  onChange={v => setMin(v)}
                  size='small'
                />
              </Box>
              分钟未产生新对话，自动封存该咨询房
            </div>
            <Button
              disabled={updateLoading}
              type='primary'
              onClick={() =>
                update({
                  filter: { PlatformSetting: { key: AUTO_DEACTIVATE_MIN } },
                  in: { value: String(min) },
                })
              }>
              确认
            </Button>
          </Box>
        }
      />

      <Divider style={{ margin: '16px 0' }} />

      <SettingCard
        title='封存提示讯息'
        subTitle='对话结束后，系统自动向顾客发送一段结束语'
        hasEnable={true}
        defaultChecked={isEnabled(DEACTIVATE_TIP_ENABLED)}
        loading={updateLoading}
        bodyStyle={{ padding: 0 }}
        bordered={false}
        onChange={onChangeEnable(DEACTIVATE_TIP_ENABLED)}
        content={
          <>
            <Box
              mt={3}
              display='flex'
              alignItems='flex-end'
              justifyContent='space-between'>
              <Box width='100%'>
                <Box mb='3'>
                  <Typography.Text strong>
                    客服手动结束时对话提示内容
                  </Typography.Text>
                  <InputTextArea
                    value={tipManual}
                    setValue={setTipManual}
                    onChange={e => setTipManual(e.target.value)}
                    rows={2}
                  />
                </Box>
                <Typography.Text strong>
                  系统自动关闭时对话提示内容
                </Typography.Text>
                <InputTextArea
                  value={tipAuto}
                  setValue={setTipAuto}
                  onChange={e => setTipAuto(e.target.value)}
                  rows={2}
                />
              </Box>
              <Button
                disabled={updateLoading}
                type='primary'
                ml='3'
                onClick={onClickTipsConfirm}>
                确认
              </Button>
            </Box>
          </>
        }
      />
    </Card>
  );
}

export default AutoDeactivate;
