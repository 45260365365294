import React, { useEffect } from 'react';
import { Drawer, Form, Input, Select, Button } from 'antd';
import { useMutationApi, apiType } from '@/hook/useApi';
import useMerchant from '@/hook/useMerchant';
import { AccountTypeKeys, MerchantTypeKeys, AccountTypeDisplay } from '@/utils';
import useAlertMessage from '@/hook/useAlertMessage';

const accTypeConfig = [
  {
    value: AccountTypeKeys['Manager'],
    label: AccountTypeDisplay[AccountTypeKeys['Manager']].text,
  },
  {
    value: AccountTypeKeys['CustomerService'],
    label: AccountTypeDisplay[AccountTypeKeys['CustomerService']].text,
  },
  {
    value: AccountTypeKeys['Monitor'],
    label: AccountTypeDisplay[AccountTypeKeys['Monitor']].text,
  },
];

function CreateUserDrawer({
  title = '新增',
  accountTypes = [AccountTypeKeys['Manager']], // 指定新增的 accountTypes
  visible = false,
  setVisible = () => {},
  callback = () => {},
}) {
  const [userForm] = Form.useForm();
  const { onSuccessMsg } = useAlertMessage();
  const { merchantSelectList, loading: merchantLoading } = useMerchant({
    options: { fetchPolicy: 'cache-first' },
  });
  const closeDrawer = async () => {
    await userForm.resetFields();
    setVisible(false);
  };
  const [createUser] = useMutationApi(apiType.CREATED_USER);
  const onFinish = async fields => {
    const [err] = await createUser({ in: { ...fields } });
    if (err) return;
    onSuccessMsg();
    callback();
    await closeDrawer();
  };

  useEffect(() => {
    const initDrawer = async () => await userForm.setFieldsValue({});
    if (visible) initDrawer();
  }, [userForm, visible]);

  return (
    <Drawer title={title} onClose={closeDrawer} visible={visible} width={'30%'}>
      <Form form={userForm} onFinish={onFinish} layout='vertical'>
        <Form.Item
          label='帐号'
          name='username'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='请输入' />
        </Form.Item>
        <Form.Item
          label='别名'
          name='aliasName'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='请输入' />
        </Form.Item>
        <Form.Item
          label='密码'
          name='password'
          rules={[{ required: true, message: '必填' }]}>
          <Input.Password placeholder='请输入' />
        </Form.Item>
        <Form.Item
          label='帐户类型'
          name='accountType'
          rules={[{ required: true, message: '必填' }]}>
          <Select placeholder='請選擇'>
            {accTypeConfig.map(acc => {
              if (!accountTypes.includes(acc.value)) return null;
              return (
                <Select.Option key={acc.value} value={acc.value}>
                  {acc.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label='所属商户号'
          name='merchantID'
          rules={[{ required: true, message: '必填' }]}>
          <Select placeholder='请选择' loading={merchantLoading}>
            {merchantSelectList.map(el => {
              if (el.merchantType === MerchantTypeKeys['Spinach']) return null;
              return (
                <Select.Option key={el.value} value={el.value}>
                  {el.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            提交
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default CreateUserDrawer;
