import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutationApi, apiType } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import useFile from '@/hook/useFile';
import { cloneDeep } from '@/utils';
import FriendList from './List';

const UserFriendList = ({ userID = 0 }) => {
  const [friendList, setFriendList] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const dataHandle = async ({ listUserFriend }) => {
    const userFriends = listUserFriend?.userFriends || [];
    let avatars = [];
    userFriends.forEach(f => {
      f.friend.avatarURL &&
        avatars.push({ userID: f.friend.id, avatarURL: f.friend.avatarURL });
    });
    setFriendList(() => userFriends || []);
    setAvatars(() => avatars);
  };

  const { loading, refetch } = useActiveList({
    api: 'LIST_USER_FRIEND',
    cusFilter: { filter: { userFriend: { userID } } },
    options: {
      onCompleted: dataHandle,
    },
  });

  const { getDownloadFiles } = useFile();
  const handleFetchAvatar = () => {
    getDownloadFiles(avatars).then(res => {
      let tempList = cloneDeep(friendList);
      res.forEach(r => {
        tempList.forEach(
          f => f.friend.id === r.userID && (f.friend.avatarURL = r.objectURL),
        );
      });
      setFriendList(() => [...tempList]);
    });
  };

  useEffect(() => {
    avatars.length && handleFetchAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatars]);

  // 刪除
  const [deleteFriend, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_USER_FRIEND,
    {
      onCompleted: () => refetch(),
    },
  );

  return (
    <FriendList
      loading={loading || deleteLoading}
      dataSource={friendList}
      deleteFriend={id => deleteFriend({ filter: { userFriend: { id } } })}
    />
  );
};

UserFriendList.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default UserFriendList;
