import React, { useState, useRef } from 'react';
import { EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Input, Button, Space } from 'antd';
import { Box } from '@WRAP_COMP';
import { cloneDeep } from '@/utils';

function QsOption({
  originData,
  updateData = () => {},
  deleteData = () => {},
}) {
  const [isEdit, setIsEdit] = useState(false);
  const labelEl = useRef(null);

  const onChangeLabel = originData => {
    let tempData = cloneDeep(originData);
    tempData.label = labelEl.current.state.value;
    setIsEdit(false);
    updateData(tempData);
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      borderBottom='1px solid'
      borderCr='secondary'>
      {isEdit ? (
        <Input
          ref={labelEl}
          placeholder='請輸入標題'
          size='small'
          defaultValue={originData.label}
          onPressEnter={() => onChangeLabel(originData)}
          onBlur={() => onChangeLabel(originData)}
        />
      ) : (
        <>{originData.label}</>
      )}
      <Space size={0}>
        {isEdit ? (
          <Button
            type='text'
            icon={<SaveOutlined />}
            onClick={() => onChangeLabel(originData)}
          />
        ) : (
          <Button
            type='text'
            icon={<EditOutlined />}
            onClick={() => setIsEdit(pre => !pre)}
          />
        )}
        <Button type='text' icon={<DeleteOutlined />} onClick={deleteData} />
      </Space>
    </Box>
  );
}

export default QsOption;
