import React, { useState, useEffect } from 'react';
import { Button, Typography, Divider, Form, Switch } from 'antd';
import DateRangePicker from 'src/components/DateRangePicker';
import { setDateRanges, formatSearchDateTime, toMoment } from 'src/utils';

const defaultDateTime = setDateRanges(0);
const defaultForm = {
  allowStatus: false,
  date: [defaultDateTime.start, defaultDateTime.end],
};

function AllowTimeForm({
  setting = null,
  loading = false,
  updateSetting = () => {},
}) {
  const [allowForm] = Form.useForm();
  const [dateRequire, setRateRequire] = useState(false);
  const onFinish = ({ allowStatus = false, date = [0, 0] }) => {
    const unixData = formatSearchDateTime(date);
    updateSetting({
      filter: { userSetting: { userID: setting.userID } },
      in: {
        allowLoginStartAt: allowStatus ? unixData[0] : 0,
        allowLoginEndAt: allowStatus ? unixData[1] : 0,
      },
    });
  };

  const onChangeStatus = v => {
    setRateRequire(v);
    v && allowForm.validateFields(['date']);
  };

  useEffect(() => {
    if (setting) {
      const { allowLoginEndAt, allowLoginStartAt } = setting;
      let initForm = { ...defaultForm };
      initForm.allowStatus = !!(allowLoginEndAt && allowLoginStartAt); // 只要有一個為0就為停用
      initForm.allowStatus &&
        (initForm.date = [
          toMoment(allowLoginStartAt),
          toMoment(allowLoginEndAt),
        ]);
      setRateRequire(initForm.allowStatus);
      allowForm.setFieldsValue({ ...initForm });
    } else {
      setRateRequire(false);
      allowForm.setFieldsValue({ ...defaultForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography.Title level={5}>授权时间</Typography.Title>
      <Form form={allowForm} onFinish={onFinish}>
        <Form.Item
          name='allowStatus'
          valuePropName='checked'
          label='启用狀態'
          style={{ marginBottom: '12px' }}>
          <Switch
            checkedChildren='启用'
            unCheckedChildren='停用'
            onChange={onChangeStatus}
          />
        </Form.Item>
        {dateRequire && (
          <DateRangePicker
            label='时间起讫'
            showTime={true}
            isAddDate={true}
            formProps={{ rules: [{ required: true, message: '请输入' }] }}
          />
        )}
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            size='small'
            loading={loading}>
            确认
          </Button>
        </Form.Item>
      </Form>

      <Divider />
    </div>
  );
}

export default AllowTimeForm;
