import React from 'react';
import { Card, Tree, Button } from 'antd';

function TreeCard({ treeData, onLoadData, loading, setSelectedItem, refetch }) {
  return (
    <Card
      size='small'
      title='目录结构'
      loading={loading}
      extra={
        <Button
          type='link'
          onClick={() => refetch()}
          style={{ padding: '0px' }}>
          重新整理
        </Button>
      }>
      <Tree
        // defaultExpandedKeys={[selectedID]}
        loadData={onLoadData}
        treeData={treeData}
        showLine={{
          showLeafIcon: false,
        }}
        onSelect={(arr, row) => setSelectedItem(row.node)}
      />
    </Card>
  );
}

export default TreeCard;
