import React, { useEffect } from 'react';
import { Box } from '@WRAP_COMP';
import { Typography } from 'antd';
import ChatRoomHeader from '@/components/ChatRoomHeader/AdminHeader';
import ChatRoomContent from '@/components/ChatRoomContent';
import MsgList from '@/components/MsgList';
import useRoomMessage from '@/hook/useRoomMessage';
import { createdUsersMap } from '@/hook/useRoom';
function RoomView({
  roomID = 0,
  accessDomain = '',
  userRooms = [],
  ownerUserData = {},
}) {
  const { roomMsg, fetchRoomMsgHandle, loadMoreMsg } = useRoomMessage({
    currentRoomID: roomID,
  });

  useEffect(() => {
    roomID && fetchRoomMsgHandle(roomID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomID]);

  return (
    <Box
      minHeight='400px'
      display='flex'
      flexDirection='column'
      border='1px solid'
      borderCr='gray-2'>
      <ChatRoomHeader
        title={`${ownerUserData?.username} #${ownerUserData?.userID}`}
        titleLevel={5}
        cusSubtitle={
          <>
            <Typography.Text style={{ fontSize: '12px' }}>
              {`房間ID #${roomID}`}
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontSize: '12px' }}>
              {`来源域名 ${accessDomain}`}
            </Typography.Text>
          </>
        }
      />
      <ChatRoomContent
        roomID={roomID}
        roomMsgLength={roomMsg.length}
        loadMoreMsg={loadMoreMsg}
        showEndMsg={false}>
        <MsgList
          roomMsg={roomMsg}
          roomID={roomID}
          usersMap={createdUsersMap(userRooms)}
        />
      </ChatRoomContent>
    </Box>
  );
}

export default RoomView;
