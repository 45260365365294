/**
 *  會員統計報表
 */
import React from 'react';
import {
  UserAddOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Statistic, Card, Skeleton } from 'antd';
import { Row, Col } from '@/plugin/wrap-comp';
import { useActiveList } from '@/hook/useList';
import QnsTooltip from '@/components/QnsTooltip';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import {
  setDateRanges,
  formatSearchDate,
  formatDate,
  commaFormat,
} from '@/utils';

const { theme } = require('@/plugin/theme');
const defaultDateTime = setDateRanges(2);
const defaultSearch = {
  date: [defaultDateTime.start, defaultDateTime.end], // 日期套件要用的預設格式
};

const originFilter = {
  baseFilter: {
    sortField: 'date',
    sortType: 'DESC',
    rangeField: 'date',
    rangeType: 'ByDateTime',
    greaterThanEqual: formatSearchDate(defaultSearch.date)[0],
    lessThanEqual: formatSearchDate(defaultSearch.date)[1],
  },
};

const searchFields = [{ name: 'date', type: 'dateRange', label: '统计时间' }];
const totalDataFields = [
  {
    key: 'registerCountTotalOfCommonUser',
    title: () => <QnsTooltip title='会员注册总数' tip='筛选日期范围进行加总' />,
    value: 0,
    prefix: () => <UserAddOutlined />,
  },
  {
    key: 'onlineCountTotalOfCommonUser',
    title: () => (
      <QnsTooltip title='会员在线总数' tip='筛选日期内不重复的登录会员数' />
    ),
    value: 0,
    prefix: () => <UserSwitchOutlined />,
  },
  {
    key: 'createGroupCountTotal',
    title: () => <QnsTooltip title='新增群总数' tip='每日新增群数相加之值' />,
    value: 0,
    prefix: () => <UsergroupAddOutlined />,
  },
];

export default function ReportCommonUserMgm() {
  const {
    data: { list, total, totalData },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
  } = useActiveList({
    api: 'REPORT_COMMON_USER',
    cusFilter: { ...originFilter },
    handleData: ({
      listUserDailySummary = {}, // 使用者每日統計
      listRoomDailySummary = {}, // 房間每日統計
    }) => {
      const { userDailySummaries, meta: userTotal } = listUserDailySummary;
      const { roomDailySummaries, meta: roomTotal } = listRoomDailySummary;
      const metaData = { ...userTotal, ...roomTotal };
      const list = userDailySummaries.map(el => {
        let obj = { ...el };
        obj.createGroupCount = roomDailySummaries.find(
          r => r.date === obj.date,
        )?.createGroupCount;
        return obj;
      });
      return {
        list,
        total: userTotal?.total || 0,
        totalData: totalDataFields.map(el => {
          el.value = metaData[el.key];
          return el;
        }),
      };
    },
  });

  const onFinish = search => {
    handleSearchFilter({
      baseFilter: {
        ...originFilter.baseFilter,
        greaterThanEqual: formatSearchDate(search.date)[0],
        lessThanEqual: formatSearchDate(search.date)[1],
      },
    });
  };

  const columns = [
    {
      title: '日期',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      render: c => formatDate(c),
    },
    {
      title: () => (
        <QnsTooltip
          title='注册数'
          tip='当日的注册会员数 (平台会员首次加入博聊以及博聊自身注册会员)'
        />
      ),
      dataIndex: 'registerCountOfCommonUser',
      key: 'registerCountOfCommonUser',
      render: r => commaFormat(r),
    },
    {
      title: () => <QnsTooltip title='在线人数' tip='当日的不重复登入会员数' />,
      dataIndex: 'onlineCountOfCommonUser',
      key: 'onlineCountOfCommonUser',
      render: r => commaFormat(r),
    },
    {
      title: () => <QnsTooltip title='新增群数' tip='当日的新增群组数' />,
      dataIndex: 'createGroupCount',
      key: 'createGroupCount',
      render: r => commaFormat(r),
    },
  ];

  return (
    <>
      <SearchBar
        fields={searchFields}
        defaultSearch={defaultSearch}
        onFinish={onFinish}
        defaultCollapsed={false}
      />

      {/* 統計 */}
      <Row gutter={12}>
        {loading && <Skeleton />}
        {loading ||
          totalData.map(el => (
            <Col key={el.key} span={8} mb={3}>
              <Card>
                <Statistic
                  valueStyle={{ color: theme.primary }}
                  title={el.title()}
                  value={el.value}
                  prefix={el.prefix()}
                />
              </Card>
            </Col>
          ))}
      </Row>

      <DataTable
        columns={columns}
        rowKey='date'
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}></DataTable>
    </>
  );
}
