import React, { useState } from 'react';
import { Box } from '@WRAP_COMP';
import { Popover, Form, AutoComplete, Input } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import useMerchant from '@/hook/useMerchant';
import { MerchantTypeKeys } from '@/utils';

const ControlArea = ({ contactToMember, contactLdg }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = v => {
    v || form.resetFields();
    setVisible(v);
  };

  const { merchantTableList } = useMerchant({
    options: { fetchPolicy: 'cache-first' },
  });

  const filterOption = (inputValue, option) =>
    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  const optionsSpinachKey = list => {
    let optionsList = [];
    list.forEach(
      ll =>
        ll.merchantType === MerchantTypeKeys['Spinach'] &&
        optionsList.push({ value: ll.key, label: ll.key }),
    );
    return optionsList;
  };

  const onFinish = async fields => {
    const [err] = await contactToMember({ in: { ...fields } });
    if (err) return;
    handleVisibleChange(false);
  };

  const connectFormRender = () => {
    return (
      <>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name='spinachPlatformKey'
            rules={[{ required: true, message: '必填' }]}
            style={{ marginBottom: '12px' }}>
            <AutoComplete
              style={{ width: 200 }}
              placeholder='请输入平台号'
              options={optionsSpinachKey(merchantTableList)}
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item
            name='username'
            rules={[{ required: true, message: '必填' }]}
            style={{ marginBottom: '12px' }}>
            <Input placeholder='请输入会员帐号' />
          </Form.Item>
          <Button
            htmlType='submit'
            variant='outline-primary'
            size='small'
            loading={contactLdg}>
            确认
          </Button>
        </Form>
      </>
    );
  };

  return (
    <Box mb='2'>
      <Popover
        content={connectFormRender}
        trigger='click'
        visible={visible}
        onVisibleChange={handleVisibleChange}>
        <Button type='primary' size='small'>
          联系会员
        </Button>
      </Popover>
    </Box>
  );
};

export default ControlArea;
