import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
function Container() {
  return (
    <Layout.Content style={{ margin: '12px' }}>
      <Outlet />
    </Layout.Content>
  );
}

export default Container;
