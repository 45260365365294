import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Space, Modal, Input } from 'antd';
import { PlusOutlined, EditTwoTone, EyeTwoTone } from '@ant-design/icons';
import { useActiveList } from '@/hook/useList';
import useOrigin from '@/hook/useOrigin';
import { useMutationApi, apiType } from '@/hook/useApi';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { formatDateTime, AdTrackingType } from '@/utils';
import Drawer from './Drawer';

function AdTrackingMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { originSelectList, loading: originListLoading } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });
  const searchFields = useMemo(
    () => [
      {
        name: 'consultingRoomOriginID',
        type: 'select',
        label: '所属域名',
        options: originSelectList,
      },
    ],
    [originSelectList],
  );

  const {
    data: { list, total },
    loading,
    pagination,
    handleSearchFilter,
    handleChangePage,
    refetch,
  } = useActiveList({
    api: 'LIST_AD_TRACKING',
    handleData: ({ listAdTracking }) => ({
      list: listAdTracking?.adTrackings || [],
      total: listAdTracking?.meta?.total || 0,
    }),
  });

  const [currOriginID, setCurrOriginID] = useState(null);
  const onFinishSearch = search => {
    setCurrOriginID(search.consultingRoomOriginID);
    handleSearchFilter({ adTracking: { ...search } });
  };

  const onCompletedCallback = { onCompleted: () => refetch() };
  const [createAdTracking, { loading: createLoading }] = useMutationApi(
    apiType.CREATE_AD_TRACKING,
    onCompletedCallback,
  );
  const [updateAdTracking, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_AD_TRACKING,
    onCompletedCallback,
  );
  const [deleteAdTracking, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_AD_TRACKING,
    onCompletedCallback,
  );

  const [visible, setVisible] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const visibleDrawer = (v, data = null) => {
    setDetailData(data);
    setVisible(v);
  };

  const [previewVisible, setPreviewVisible] = useState(false);
  const setPreviewVisibleHandel = (v, data = null) => {
    setDetailData(data);
    setPreviewVisible(v);
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '所属域名',
      dataIndex: 'origin',
    },
    {
      title: '广告类型',
      dataIndex: 'adTrackingType',
      render: t => AdTrackingType[t],
    },
    {
      title: '广告代码',
      dataIndex: 'adTrackingCode',
      render: (v, record) => (
        <Button
          onClick={() => setPreviewVisibleHandel(true, record)}
          type='link'
          icon={<EyeTwoTone />}
        />
      ),
    },
    {
      title: '建立时间',
      dataIndex: 'createdAt',
      render: h => formatDateTime(h),
    },
    {
      title: '動作',
      key: 'action',
      width: '15%',
      fixed: 'right',
      render: record => (
        <Space>
          {authRoutesKey.includes('API_HostsDeny_Update') && (
            <Button
              onClick={() => visibleDrawer(true, record)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {authRoutesKey.includes('API_HostsDeny_Delete') && (
            <BtnDeleteConfirm
              handleOk={() =>
                deleteAdTracking({
                  filter: { adTracking: { id: record.id } },
                })
              }
              loading={deleteLoading}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={{}}
        onFinish={onFinishSearch}
        loading={originListLoading}
      />

      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        {authRoutesKey.includes('API_Employee_Create') && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => visibleDrawer(true)}>
            新增广告埋点
          </Button>
        )}
      </DataTable>
      <Drawer
        visible={visible}
        setVisible={visibleDrawer}
        consultingRoomOriginID={currOriginID}
        detailData={detailData}
        create={createAdTracking}
        update={updateAdTracking}
        loading={createLoading || updateLoading}
      />
      <Modal
        title='预览广告代码'
        visible={previewVisible}
        onCancel={() => setPreviewVisibleHandel(false)}
        footer={null}>
        <Input.TextArea rows={10} value={detailData?.adTrackingCode} disabled />
      </Modal>
    </PageWrap>
  );
}

export default AdTrackingMgm;
