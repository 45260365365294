import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@WRAP_COMP';
import {
  Row,
  Col,
  Card,
  Typography,
  Select,
  Spin,
  Divider,
  Alert,
  Switch,
  Input,
  Form,
} from 'antd';
import useOrigin from '@/hook/useOrigin';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import RoomDemo from '@/components/CSRoomClient/RoomDemo';
import CSRoomForm from '@/components/ChatRoomContent/CSForm';
import AddQsBtn from './AddQsBtn';
import QsItem from './QsItem';
import { omitTypenameHandle, cloneDeep } from '@/utils';

function CSFormMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { originSelectList, loading: originLoading } = useOrigin({
    options: { fetchPolicy: 'cache-first' },
  });
  const [originID, setOriginID] = useState(1);
  const [qsList, setQsList] = useState([]);
  const [selectQsIDs, setSelectQsIDs] = useState([]);
  const setQsHandle = item => {
    setQsList(pre => [...pre, item]);
  };

  const updateQsHandel = (idx, data) => {
    let tempList = cloneDeep(qsList);
    tempList[idx] = data;
    setQsList(tempList);
  };

  const deleteQsHandle = (idx, deleteId) => {
    const tempList = qsList.filter((q, ii) => ii !== idx);
    setSelectQsIDs(pre => pre.filter(id => id !== deleteId));
    setQsList(tempList);
  };

  /**
   * 問題項目渲染
   */
  const qsListRender = (list = []) => {
    if (!list.length) return null;
    return (
      <>
        {list.map((el, idx) => (
          <QsItem
            key={el.id}
            originData={el}
            updateData={data => updateQsHandel(idx, data)}
            deleteData={() => deleteQsHandle(idx, el.id)}
          />
        ))}
      </>
    );
  };

  /**
   * CSForm handle
   */
  const [CSForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);

  // 表單 title
  const [title, setTitle] = useState('');
  const onChangeTitle = () => {
    setTitle(CSForm.getFieldValue('title'));
  };

  // 表單 isEnable
  const [isEnabled, setIsEnabled] = useState(false);
  const onChangeEnable = () => {
    setIsEnabled(CSForm.getFieldValue('isEnabled'));
  };

  // 表單 驗證碼 isVerificationCode
  const [isVerificationCode, setIsVerificationCode] = useState(false);
  const onChangeVerificationCode = () => {
    setIsVerificationCode(CSForm.getFieldValue('isVerificationCode'));
  };

  const initHandle = (detail = null) => {
    let initialValues = {};
    if (detail) {
      setSelectQsIDs(() => detail.formData.map(el => el.id));
      initialValues = {
        ...detail,
        isEnabled: detail?.isEnabled === 'Yes',
        isVerificationCode: detail?.isVerificationCode === 'Yes',
      };
    } else {
      setSelectQsIDs([]);
      initialValues = {
        title: '',
        isEnabled: false,
        isVerificationCode: false,
      };
    }
    CSForm.setFieldsValue(initialValues);
    setIsEdit(!!detail);
    setTitle(initialValues.title);
    setIsEnabled(initialValues.isEnabled);
    setIsVerificationCode(initialValues.isVerificationCode);
    setQsList(detail?.formData || []);
  };

  // get CSForm
  const { fetchData, loading: CSFormLoading } = useQueryApi(
    apiType.GET_CS_FORM,
    {
      context: { isShowGeneralError: false },
    },
  );
  const fetchDataHandle = async (consultingRoomOriginID = originID) => {
    const { error, data } = await fetchData({
      filter: { consultingRoomForm: { consultingRoomOriginID } },
    });
    if (error || error?.message === '404001') {
      initHandle(); // 尚未建立 使用預設
      return;
    }
    const { getConsultingRoomForm } = data;
    initHandle(getConsultingRoomForm);
  };

  const handleChange = value => {
    setOriginID(value);
    fetchDataHandle(value);
  };

  useEffect(() => {
    fetchDataHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onSuccessMsg } = useAlertMessage();

  // 新增
  const [createdForm, { loading: createLoading }] = useMutationApi(
    apiType.CREATE_CS_FORM,
    {
      onCompleted: () => {
        onSuccessMsg('new');
        fetchDataHandle();
      },
    },
  );

  // 編輯
  const [updateForm, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_CS_FORM,
    {
      onCompleted: () => {
        onSuccessMsg('update');
        fetchDataHandle();
      },
    },
  );

  const submitFormHandle = async () => {
    let fields = await CSForm.validateFields();
    fields.isEnabled = fields.isEnabled ? 'Yes' : 'No';
    fields.isVerificationCode = fields.isVerificationCode ? 'Yes' : 'No';
    let postData = { ...fields, formData: qsList };

    if (isEdit) {
      updateForm({
        filter: { consultingRoomForm: { consultingRoomOriginID: originID } },
        in: omitTypenameHandle(postData),
      });
    } else {
      postData.consultingRoomOriginID = originID;
      createdForm({ in: postData });
    }
  };

  return (
    <Row gutter={20} style={{ height: '100%' }}>
      <Col xl={10} xxl={8}>
        <Card style={{ height: '100%' }}>
          <Spin spinning={originLoading || CSFormLoading}>
            <Typography.Title level={5}>所属域名</Typography.Title>
            <Select
              defaultValue={originID}
              style={{ width: '100%' }}
              onChange={handleChange}>
              {originSelectList.map(el => (
                <Select.Option key={el.value} value={el.value}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
            <Divider />

            {!isEdit && (
              <Alert
                message='尚未新增任何表单'
                type='warning'
                showIcon
                closable
                style={{ marginBottom: '12px' }}
              />
            )}

            <Form form={CSForm}>
              <Form.Item
                tooltip='再咨询前让用户填写表单，咨询时可以查看用户填写的内容'
                name='isEnabled'
                valuePropName='checked'
                label={
                  <Typography.Title level={5} style={{ marginBottom: '0px' }}>
                    啟用询前表单
                  </Typography.Title>
                }>
                <Switch
                  checkedChildren='开启'
                  unCheckedChildren='关闭'
                  onChange={onChangeEnable}
                />
              </Form.Item>
              <Form.Item name='title' label='提示文案'>
                <Input placeholder='请输入' onChange={onChangeTitle} />
              </Form.Item>
              <Form.Item
                name='isVerificationCode'
                label='验证码'
                valuePropName='checked'>
                <Switch
                  checkedChildren='开启'
                  unCheckedChildren='关闭'
                  onChange={onChangeVerificationCode}
                />
              </Form.Item>
            </Form>
            {qsListRender(qsList)}
            <AddQsBtn
              selectQsIDs={selectQsIDs}
              setSelectQsIDs={setSelectQsIDs}
              selectItem={setQsHandle}
            />
            <Button
              variant='primary'
              block
              onClick={submitFormHandle}
              loading={createLoading || updateLoading}
              style={{ marginTop: '8px' }}
              disabled={
                isEdit
                  ? !authRoutesKey.includes('API_ConsultingRoomForm_Update')
                  : !authRoutesKey.includes('API_ConsultingRoomForm_Create')
              }>
              {isEdit ? '更新表单' : '建立表单'}
            </Button>
          </Spin>
        </Card>
      </Col>
      <Col xl={14} xxl={16}>
        <RoomDemo
          contentNode={
            isEnabled ? (
              <Box p='3'>
                <CSRoomForm
                  formTitle={title}
                  formDataList={qsList}
                  updateQsHandel={updateQsHandel}
                  showVerificationCode={isVerificationCode}
                />
              </Box>
            ) : null
          }
        />
      </Col>
    </Row>
  );
}

export default CSFormMgm;
