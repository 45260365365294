import React from 'react';
import { Box } from '@WRAP_COMP';
import { Card, Typography, Switch } from 'antd';
function SettingCardStyle({
  title = '',
  subTitle = '',
  content = null,
  hasEnable = false,
  defaultChecked = false,
  loading = false,
  disabled = false,
  onChange = () => {},
  ...props
}) {
  return (
    <Card {...props}>
      <Box display='flex' justifyContent='space-between'>
        {title && (
          <div>
            <Typography.Title level={5}>{title}</Typography.Title>
            {subTitle}
          </div>
        )}
        {hasEnable && (
          <Switch
            defaultChecked={defaultChecked}
            loading={loading}
            disabled={disabled}
            checkedChildren='开启'
            unCheckedChildren='关闭'
            onChange={onChange}
          />
        )}
      </Box>
      <>{content}</>
    </Card>
  );
}

export default SettingCardStyle;
