import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRedPoint, setCsTotalUnRead } from '@/store/room';
import { apiType, useQueryApi } from '@/hook/useApi';

const SHOW_RED_POINT_MENU_KEYS = [
  'CustomerService_System',
  'ConsultationChat_Management',
];

function useRedPoint() {
  const dispatch = useDispatch();
  const { meInfo } = useSelector(({ auth }) => auth);

  const setRedPointHandle = useCallback(
    data => {
      dispatch(setRedPoint(data));
    },
    [dispatch],
  );

  const setCsTotalUnReadHandle = useCallback(
    total => {
      total >= 0 && dispatch(setCsTotalUnRead(total));
    },
    [dispatch],
  );

  //  通知紅點 (目前要show的key 都要顯示)
  const { fetchData: getRedPointData } = useQueryApi(apiType.GET_RED_POINT);
  const initRedPointNotify = useCallback(async () => {
    const { error, data } = await getRedPointData({ userID: meInfo.id });
    if (error) return;
    // const { listConsultingRoom, listUserRoom } = data;
    const { listUserRoom } = data;
    const totalUnRead = listUserRoom?.meta.totalUnRead;
    // const total =
    //   listConsultingRoom?.meta.total || 0 + listUserRoom?.meta.total || 0;
    const total = 0 + listUserRoom?.meta.total || 0;
    const tempData = SHOW_RED_POINT_MENU_KEYS.reduce((acc, pre) => {
      acc[pre] = { isShow: total > 0 };
      return acc;
    }, {});

    setCsTotalUnReadHandle(totalUnRead);
    setRedPointHandle(tempData);
  }, [getRedPointData, setCsTotalUnReadHandle, setRedPointHandle, meInfo.id]);

  return { initRedPointNotify, setCsTotalUnReadHandle };
}

export default useRedPoint;
