import React from 'react';
import { useActiveList } from '@/hook/useList';
import DataTable from '@/components/Table/DataTable';
import { formatDateTime, deviceOSKeys } from '@/utils';

function LoginInfoList({ userID }) {
  const {
    data: { list, total },
    loading,
    pagination,
    handleChangePage,
  } = useActiveList({
    api: 'LIST_USER_LOGIN_INFO',
    originFilter: { userLoginHistory: { userID } },
    handleData: ({ listUserLoginHistory }) => ({
      list: listUserLoginHistory?.userLoginHistories || [],
      total: listUserLoginHistory?.meta.total || 0,
    }),
  });
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: 'IP',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },
    {
      title: '地區',
      key: 'place',
      render: ({ country, administrativeArea }) => (
        <>
          {country}
          {administrativeArea}
        </>
      ),
    },
    {
      title: '客端',
      dataIndex: 'deviceOS',
      key: 'deviceOS',
      render: key => deviceOSKeys[key]?.text || '-',
    },
    {
      title: '建立时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: h => formatDateTime(h),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rowKey='id'
      size='small'
      loading={loading}
      dataSource={list}
      pagination={{ ...pagination, total }}
      onChangePage={handleChangePage}
      bordered={false}
      bodyStyle={{ padding: '0px' }}
    />
  );
}

export default LoginInfoList;
