import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuList } from '@/store/global';
import { useQueryApi, apiType } from '@/hook/useApi';
import { cloneDeep } from '@/utils';

/**
 * 取得全部 menu 用於新增角色選擇 menu
 * @param {String} accType
 * @returns {Object}
 * {Array} treeData: 組出樹狀 arr 給新增元件用
 * {Function} refetch: 重新取得 menuList
 * {Function} authorityDataHandle: 根據選完的樹狀 keys 組出提交 arr 給新增用
 * {Function} authorityKeysForTreeHandle: 根據 detail arr 組出 API key (樹狀元件吃最後一層 key 值)
 */
const useMenuList = () => {
  const { menuList } = useSelector(({ global }) => global);

  /**
   * 組出樹狀 arr 給新增元件用
   * @param {Array} listMenu
   * @returns {Array} 樹狀 arr
   */
  const treeDataHandle = useCallback(list => {
    if (!Array.isArray(list)) return;
    return list.map(item => {
      return {
        title: `${item.name}`,
        key: item.key,
        children: treeDataHandle(item.next),
      };
    });
  }, []);

  /**
   * 根據 keys 組出 arr
   * @param {Array} keys 選中的 key 值
   * @param {Array} array 預設為 data.listMenu
   * @returns {Array} array
   */
  const authorityDataHandle = (keys = [], array) => {
    if (!array) array = cloneDeep(menuList);
    return array.filter(item => {
      if (keys.includes(item.key)) {
        delete item.__typename;
        delete item.name;
        if (Array.isArray(item.next)) {
          return (item.next = authorityDataHandle(keys, item.next));
        } else {
          return (item.next = []);
        }
      }
      return false;
    });
  };

  /**
   * 根據 arr 組出最後的 lastKey
   * @param {Array} arr
   * @return {Array} [{lastKey}, {lastKey}, {lastKey}]
   */
  const authorityKeysForTreeHandle = useCallback(arr => {
    return arr.reduce((acc, cur) => {
      return acc.concat(
        cur.next.length > 0 ? authorityKeysForTreeHandle(cur.next) : cur.key,
      );
    }, []);
  }, []);

  const [treeData, setTreeData] = useState([]);
  useEffect(() => {
    if (menuList.length > 0) {
      const tree = treeDataHandle(menuList);
      setTreeData(tree);
    }
  }, [menuList, treeDataHandle]);

  /**
   * menuList 存放
   * @param {Object} meData
   */
  const dispatch = useDispatch();

  const { fetchData } = useQueryApi(apiType.GET_MENU_LIST);
  const fetchMenuHandle = useCallback(async () => {
    const { data, error } = await fetchData();
    if (!error) {
      await dispatch(setMenuList(data.listMenu));
    }
    return { data, error };
  }, [dispatch, fetchData]);

  return {
    listMenu: menuList,
    treeData,
    fetchMenuHandle,
    authorityDataHandle,
    authorityKeysForTreeHandle,
  };
};
export default useMenuList;
