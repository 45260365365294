import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge } from 'antd';
import { Row, Col } from '@WRAP_COMP';
import { setCurrentInRoomID } from '@/store/room';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import useRoom, { roomFormate } from '@/hook/useRoom';
import useRedPoint from '@/hook/useRedPoint';
import useRecMessage from '@/hook/useRecMessage';
import useMe from '@/hook/useMe';
import { RoomStatusKeys, cloneDeep, AccountTypeKeys } from '@/utils';
import ContactToMember from './components/ContactToMember';
import BatchDeactivated from './components/BatchDeactivated';
import RoomTabList from './RoomTabList';
import RoomDetailSection from './RoomDetailSection';

const TabMyCSHeader = ({ csTotalUnRead }) => (
  <>
    我的咨询中
    <Badge
      count={csTotalUnRead}
      style={{ background: 'red', marginLeft: '2px' }}
    />
  </>
);
const sorUserRoomListIsPinedHandle = list => {
  list.sort((a, b) => {
    if (a.extraInfo.isPinned) return -1;
    return a.room.createdAt > b.room.createdAt ? 0 : 1;
  });
  return list;
};

const ConsultationChat = () => {
  const { meInfo } = useSelector(({ auth }) => auth);
  const { currentInRoomID, csTotalUnRead } = useSelector(({ room }) => room);
  const dispatch = useDispatch();

  const { isMonitorType } = useMe();
  const { initRedPointNotify, setCsTotalUnReadHandle } = useRedPoint();
  const { updateInRoomHandle, fetchRoom } = useRoom();

  /**
   * 房間列表
   */
  const [roomList, setRoomList] = useState([]);
  const [pinRoomNum, setPinRoomNum] = useState(0);
  const myConsRoomList = useRef([]);

  // 我的諮詢單列表
  const { fetchData: fetchMyCons, loading: myRoomLoading } = useQueryApi(
    apiType.LIST_MY_CS_ROOM,
  );
  const fetchMyConsHandle = useCallback(
    async (isReserve = false) => {
      try {
        const {
          data: {
            listUserRoom: { userRooms },
          },
        } = await fetchMyCons({ userID: meInfo.id });
        let pinnedRoomNum = 0; // 紀錄 pinRoom 數量
        let tempDataSource = []; // 組出 format roomList
        userRooms.forEach(({ room, userRooms, ...props }) => {
          const data = roomFormate.consulting({
            room: { ...room, ...props },
            ownerUserID: room.consultingDetail.ownerUserID,
            userRooms,
            meID: meInfo.id,
          });
          if (data.extraInfo.isPinned) pinnedRoomNum++;
          tempDataSource.push({ ...data });
        });
        // if (tempDataSource.length === 0) initRedPointNotify();
        setPinRoomNum(pinnedRoomNum);
        const tempList = sorUserRoomListIsPinedHandle(tempDataSource);
        isReserve || setRoomList(tempList);
        myConsRoomList.current = tempList;
      } catch (error) {
        console.log('fetchMyConsHandle', error);
      }
    },
    [fetchMyCons, meInfo.id],
  );

  // 待諮詢 & 其他諮詢中列表
  const { fetchData: fetchCons, loading: roomLoading } = useQueryApi(
    apiType.LIST_CS_ROOM,
  );
  const fetchConsHandle = useCallback(
    async ({ status }) => {
      const statusType = {
        0: RoomStatusKeys['Active'],
        2: RoomStatusKeys['Processing'],
      };
      try {
        const { data } = await fetchCons({
          filter: {
            baseFilter: { sortField: 'room_id', sortType: 'DESC' },
            statusIn: [statusType[status]],
          },
        });
        const {
          listConsultingRoom: { consultingRooms },
        } = data;

        // 組出 roomList format
        let tempDataSource = [];
        consultingRooms.forEach(({ userRooms, ...room }) => {
          const data = roomFormate.consulting({
            room,
            ownerUserID: room.ownerUserID,
            userRooms: userRooms,
            meID: meInfo.id,
          });

          // 其他諮詢中濾掉有我在的房間
          if (status === RoomStatusKeys['Processing']) {
            !data.extraInfo.isExistInRoom && tempDataSource.push({ ...data });
          } else {
            tempDataSource.push({ ...data });
          }
        });
        if (tempDataSource.length === 0) initRedPointNotify();
        setRoomList(tempDataSource);
      } catch (error) {
        console.log('fetchConsError', error);
      }
    },
    [fetchCons, initRedPointNotify, meInfo.id],
  );

  /**
   * RoomList Tab 處理
   */
  const tabPaneArr = () => {
    const tabs = [
      { key: '0', tab: '待咨询' },
      { key: '1', tab: <TabMyCSHeader csTotalUnRead={csTotalUnRead} /> },
      { key: '2', tab: '其他咨询中' },
    ];
    if (isMonitorType) tabs.splice(1, 1);
    return tabs;
  };
  const [currTab, setCurrTab] = useState(tabPaneArr()[0].key);
  const [currRoom, setCurrRoom] = useState(null);
  const onChangeTab = useCallback(
    key => {
      key === '1' ? fetchMyConsHandle() : fetchConsHandle({ status: key });
      setCurrTab(key);
    },
    [fetchConsHandle, fetchMyConsHandle],
  );

  /**
   * 處理指定進入房
   * - 列表完成後進進入指定房間
   */
  const [specifyRoomID, setSpecifyRoomID] = useState(0); //
  useEffect(() => {
    if (specifyRoomID) {
      const room = roomList.find(rm => rm.room.roomID === specifyRoomID);
      setCurrRoom(room);
      setSpecifyRoomID(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomList]);

  /**
   * 主動聯繫會員
   */
  const [contactToMember, { loading: contactLdg }] = useMutationApi(
    apiType.CREATED_CS_CONTACT,
    {
      onCompleted: ({ createConsultingRoomWithContact }) => {
        onChangeTab('1'); // 到我的諮詢單
        // 設定指定進入房
        setSpecifyRoomID(
          createConsultingRoomWithContact.consultingDetail.roomID,
        );
      },
    },
  );

  // sort 房間排序 處理最新訊息排序
  const sortRoomToTop = useCallback(
    roomID => {
      const roomIdx = roomList.findIndex(({ room }) => room.roomID === roomID); // 找到 room index
      if (roomIdx < 0) return;
      if (roomList[roomIdx].extraInfo.isPinned) return; // pinRoom 不再處理最高排序
      let tempList = cloneDeep(roomList);
      tempList.splice(roomIdx, 1);
      tempList.splice(pinRoomNum, 0, roomList[roomIdx]);
      setRoomList(tempList);
    },
    [pinRoomNum, roomList],
  );

  // totalUnRead: 處理新訊息 tab totalUnRead
  const setUnReadHandle = useCallback(
    ({ roomID, from, ...props }) => {
      // 當前房 && 自己的訊息不處理
      if (roomID === currentInRoomID || from.userID === meInfo.id) return;
      if (meInfo.accountType === AccountTypeKeys['CustomerService']) {
        setCsTotalUnReadHandle(csTotalUnRead + 1);
        sortRoomToTop(roomID);
      } else {
        // 其他帳號類型都會收到 ws, 判斷是否在我的諮詢中 是的話才處理未讀數量
        const isMyConsRoom = myConsRoomList.current.some(
          rr => rr.room.roomID === roomID,
        );
        if (!isMyConsRoom) return;
        setCsTotalUnReadHandle(csTotalUnRead + 1);
        sortRoomToTop(roomID);
      }
    },
    [
      csTotalUnRead,
      currentInRoomID,
      meInfo.id,
      meInfo.accountType,
      setCsTotalUnReadHandle,
      sortRoomToTop,
    ],
  );

  // 有新的諮詢室
  const getActiveRoomHandle = async id => {
    const { data, error } = await fetchRoom({ filter: { room: { id } } });
    if (error) {
      onChangeTab(currTab);
      return;
    }
    const roomDetail = roomFormate.consulting({
      room: { ...data.getRoom },
      ownerUserID: data.getRoom.consultingDetail.ownerUserID,
      userRooms: data.getRoom.userRooms,
      meID: meInfo.id,
      extraInfo: { isNewActiveRoom: true },
    });
    setRoomList(pre => {
      return [roomDetail, ...pre];
    });
  };

  /**
   * ws 監聽
   */
  useRecMessage({
    msgCommonType: useCallback(
      recMsg => setUnReadHandle(recMsg),
      [setUnReadHandle],
    ),
    orderStatusPending: useCallback(
      recMsg => setUnReadHandle(recMsg),
      [setUnReadHandle],
    ),
    csRoomStatusActive: data => {
      // 有新的諮詢室
      currTab === '0' && getActiveRoomHandle(data.roomID);
    },
    csRoomStatusProcessing: ({ from }) => {
      // 被自動分到諮詢室
      if (from.userID !== meInfo.id && currTab === '1') {
        onChangeTab(currTab);
      }
    },
  });

  useEffect(() => {
    fetchConsHandle({ status: currTab });
    fetchMyConsHandle(true);
    initRedPointNotify();
    return () => {
      // 離開 inRoom 恢復預設
      updateInRoomHandle(0, roomID => dispatch(setCurrentInRoomID(roomID)));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 待諮詢 批量封存
   */
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  const [batchDeactivated] = useMutationApi(
    apiType.BATCH_DEACTIVATED_CS_ROOM,
    {
      onCompleted: () => {
        onChangeTab('0'); // 到我的諮詢單
        setIndeterminate(false)
        setCheckAll(false)
        setCheckedList([])
      },
    },
  );

  const onCheckAllChange = e => {
    const list = e.target.checked ? roomList.map(r => r.room.roomID) : [];
    setCheckedList(list);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onCheckRoom = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < roomList.length);
    setCheckAll(list.length === roomList.length);
  };

  return (
    <>
      <Row>
        <Col height='90vh' span={7}>
          <RoomTabList
            loading={myRoomLoading || roomLoading}
            dataSource={roomList}
            tabPaneArr={tabPaneArr()}
            currTab={currTab}
            onChangeTab={onChangeTab}
            currRoomID={currRoom?.room.roomID}
            setCurrRoom={setCurrRoom}
            showIsOnline={currTab === '1'}
            showLastMsg={currTab === '1'}
            showUnReadCount={currTab === '1'}
            checkedList={checkedList}
            onCheckRoom={onCheckRoom}
            controlArea={
              isMonitorType ? null : (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {currTab === '0' && (
                    <BatchDeactivated
                      checkedList={checkedList}
                      indeterminate={indeterminate}
                      onCheckAllChange={onCheckAllChange}
                      checkAll={checkAll}
                      batchDeactivated={batchDeactivated}
                    />
                  )}
                  <ContactToMember
                    contactToMember={contactToMember}
                    contactLdg={contactLdg}
                  />
                </div>
              )
            }
          />
        </Col>
        <Col span={17}>
          <RoomDetailSection
            key={currRoom?.room.roomID || 0}
            currRoom={currRoom}
            currTab={currTab}
            setCurrRoom={setCurrRoom}
            onChangeTab={onChangeTab}
            fetchMyConsHandle={fetchMyConsHandle}
          />
        </Col>
      </Row>
    </>
  );
};

export default ConsultationChat;
