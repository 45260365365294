import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscription } from '@apollo/client';
import { MessageOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'dayjs';
import { wsStatusHandle } from '@/plugin/ws';
import { Typography, Divider } from 'antd';
import { setRecMsg, setWsStatusInfo } from '@/store/subscribe';
import { useQueryApi, apiType } from '@/hook/useApi';
import useAuthRoutes from '@/hook/useAuthRoutes';
import useMenuList from '@/hook/useMenuList';
import useRedPoint from '@/hook/useRedPoint';
import useNotification from '@/hook/useNotification';
import useAlertMessage from '@/hook/useAlertMessage';
import useBeep from '@/hook/useBeep';
import useMe from '@/hook/useMe';
import useCrypto from '@/hook/useCrypto';
import useRecMessage from '@/hook/useRecMessage';
import {
  getDeviceId,
  getToken,
  ReceiveMsgContentType,
  RoomStatusKeys,
  AccountTypeKeys,
} from '@/utils';

const {
  theme: { primary },
} = require('@/plugin/theme');

let timeoutSecID = null;
let carouselPlayInterID = null;
let hasPlayTimeoutID = null;

export default function GlobalAdmin({ children, setRoute }) {
  const { meInfo, loginInfo } = useSelector(({ auth, global }) => ({
    ...auth,
    ...global,
  }));
  const { currentInRoomID } = useSelector(({ room }) => room);
  const dispatch = useDispatch();
  const { initRedPointNotify } = useRedPoint();
  const { onNotify, onWarningNotify } = useNotification();

  const { fetchMenuHandle } = useMenuList();
  const { fetchMeHandler, isMonitorType } = useMe();

  /**
   * ws 狀態
   */
  const { wsStatusNotify } = useAlertMessage();
  const { wsStatusInfo } = useSelector(({ subscribe }) => subscribe);
  useEffect(() => {
    wsStatusNotify(wsStatusInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsStatusInfo]);

  /**
   * 音效處理
   */
  const {
    beepEnabled,
    beepAudioSrc,
    fetchBeepFile,
    fetchBeepEnabled,
    playPromise,
  } = useBeep();
  const [beep, setBeep] = useState(null);
  const lazyPlay = () => {
    if (hasPlayTimeoutID) return;
    playPromise(beep);
    hasPlayTimeoutID = setInterval(function () {
      clearInterval(hasPlayTimeoutID);
      hasPlayTimeoutID = null;
    }, 3000);
  };
  useEffect(() => {
    if (beepAudioSrc) {
      setBeep(new Audio(beepAudioSrc));
      if (timeoutSecID || carouselPlayInterID) {
        clearTimeoutInterval();
        setUnReadRoom([]);
      }
    }
  }, [beepAudioSrc]);

  /**
   * 未讀房間處理 (有未讀房就會啟動計時輪播音效
   */
  const [unReadRoom, setUnReadRoom] = useState([]);

  // 新增未讀房間
  const addUnReadRoom = recMsg => {
    const { roomID, contentType } = recMsg;
    const carouselType = [
      ReceiveMsgContentType['Text'],
      ReceiveMsgContentType['File'],
      ReceiveMsgContentType['Voice'],
    ];
    if (!carouselType.includes(contentType)) return;
    // 排除當前＆已存在的
    if (currentInRoomID !== roomID && !unReadRoom.includes(roomID))
      setUnReadRoom(pre => [...pre, roomID]);
  };

  // 移除未讀房間 (進入未讀房)
  const removeUnReadRoom = (roomID = 0) => {
    if (!unReadRoom.includes(roomID)) return;
    setUnReadRoom(pre => pre.filter(p => p !== roomID));
  };

  useEffect(() => {
    currentInRoomID && removeUnReadRoom(currentInRoomID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInRoomID]);

  /**
   * 未讀 計時輪播音效處理
   */
  // 輪播處理
  const carouselPlayHandle = (sec = 3500) => {
    carouselPlayInterID = setInterval(() => {
      playPromise(beep);
    }, sec);
  };
  // 計時 30 秒後執行輪播
  const countSec = (sec = 30000) => {
    timeoutSecID = window.setTimeout(() => {
      carouselPlayHandle();
    }, sec);
  };
  // 清除計時器
  const clearTimeoutInterval = () => {
    window.clearTimeout(timeoutSecID);
    window.clearInterval(carouselPlayInterID);
    timeoutSecID = null;
    carouselPlayInterID = null;
  };
  // 新增 || 清除計時器
  useEffect(() => {
    if (unReadRoom.length && !timeoutSecID && !carouselPlayInterID) countSec();
    if (!unReadRoom.length && (timeoutSecID || carouselPlayInterID)) {
      clearTimeoutInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unReadRoom]);

  /**
   * ws 連線
   */
  const [skip, setSkip] = useState(true);
  const { data: receiveData } = useSubscription(apiType.RECEIVE_MSG, {
    skip,
    variables: { userAuth: { token: getToken(), deviceUID: getDeviceId() } },
  });
  useEffect(() => {
    if (!receiveData?.receiveMessage) return;
    const { receiveMessage } = receiveData;
    // console.log('receiveMessage', receiveMessage);
    dispatch(setRecMsg(receiveMessage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveData]);

  /**
   * ws 訊息處理
   */
  const { fetchData: fetchUserData } = useQueryApi(apiType.GET_USER);
  const fetchUserDataHandle = async id => {
    const { data, error } = await fetchUserData({
      filter: { user: { id }, spinachUserIDs: [], tagIDs: [], roleIDs: [] },
    });
    return error ? {} : data.getUser;
  };
  const userInfoRender = ({ username, userID, roomID }) => {
    return (
      <>
        <Typography.Text strong>
          {username}(#{userID})
        </Typography.Text>
        <Divider type='vertical' />
        <Typography.Text strong>房間ID{roomID}</Typography.Text>
      </>
    );
  };
  const { decryptStrHandle } = useCrypto(loginInfo.token);
  const recMsgCommonType = async recMsg => {
    if (isMonitorType) return; // 監察員不處理 (不跳 notify, 不放提示音)
    const {
      from: { userID },
      text,
      roomID,
      contentType,
    } = recMsg;

    if (userID === meInfo.id) return;
    const { username, accountType } = await fetchUserDataHandle(userID);
    let showName =
      !username && accountType === AccountTypeKeys['Tourist']
        ? '遊客'
        : username;

    const handleTypeKeys = {
      [ReceiveMsgContentType['Text']]: {
        icon: <MessageOutlined style={{ color: primary }} />,
        message: userInfoRender({ username: showName, userID, roomID }),
        description: (
          <span>
            {text?.isEncrypt ? decryptStrHandle(text?.text) : text?.text}
          </span>
        ),
      },
      [ReceiveMsgContentType['Voice']]: {
        icon: <MessageOutlined style={{ color: primary }} />,
        message: userInfoRender({ username: showName, userID, roomID }),
        description: <span>{'传送音讯'}</span>,
      },
      [ReceiveMsgContentType['File']]: {
        icon: <MessageOutlined style={{ color: primary }} />,
        message: userInfoRender({ username: showName, userID, roomID }),
        description: <span>{'传送图档'}</span>,
      },
      [ReceiveMsgContentType['Invite']]: {
        icon: <InfoCircleOutlined style={{ color: primary }} />,
        message: '收到邀请',
        description: userInfoRender({ username: showName, userID, roomID }),
      },
      [ReceiveMsgContentType['Join']]: {
        icon: <InfoCircleOutlined style={{ color: primary }} />,
        message: (
          <>
            <Typography.Text strong>
              {showName}(#{userID}) 加入房間(#{roomID})
            </Typography.Text>
          </>
        ),
      },
    };
    if (!handleTypeKeys[contentType]) {
      console.log('no mapping msg ', recMsg);
      return;
    }
    if (beepEnabled) {
      addUnReadRoom(recMsg);
      lazyPlay(); // 音效
    }
    onNotify({ ...handleTypeKeys[contentType] });
  };
  const recRoomUpdateMsg = async recMsg => {
    const {
      from: { userID },
      roomUpdate: { roomID, consultingDetail },
    } = recMsg;
    if (userID === meInfo.id) return;

    // 諮詢單處理
    if (consultingDetail) {
      const { status, ownerUserID, deactivatedUserID } = consultingDetail;
      if (status === RoomStatusKeys['Processing']) return;

      // 新的咨询单
      if (status === RoomStatusKeys['Active']) {
        onNotify({
          icon: <InfoCircleOutlined style={{ color: primary }} />,
          message: '有新的咨询单',
          description: userInfoRender({
            username: '游客',
            userID: ownerUserID,
            roomID,
          }),
        });
      }

      // 封存諮詢單
      if (status === RoomStatusKeys['Deactivated']) {
        removeUnReadRoom(roomID);
        const { username } = await fetchUserDataHandle(deactivatedUserID);
        onNotify({
          icon: <InfoCircleOutlined style={{ color: primary }} />,
          message: (
            <>
              <Typography.Text strong>
                {username}(#{deactivatedUserID}) 封存咨询单(#{roomID})
              </Typography.Text>
            </>
          ),
          description: '可至「历史咨询纪录」查看',
        });
      }

      initRedPointNotify();
    }
  };
  const recBizSysMsg = ({ businessSystemNotification }) => {
    onNotify({
      icon: <InfoCircleOutlined style={{ color: primary }} />,
      message: '业务系统通知',
      description: businessSystemNotification?.message || '',
    });
  };

  useRecMessage({
    msgCommonType: recMsgCommonType,
    roomUpdate: recRoomUpdateMsg,
    bizSystem: recBizSysMsg,
  });

  /**
   * 允許登入時間提示處理
   */
  const allowLoginNotifyHandle = useCallback(
    ({ allowLoginEndAt = 0 }) => {
      if (!allowLoginEndAt) return;
      const allowMoment = moment.unix(allowLoginEndAt);
      const todayMoment = moment();
      const overDay = Math.ceil(allowMoment.diff(todayMoment, 'days', true));
      if (overDay > 10) return;
      let description =
        overDay === 1 ? (
          <Typography.Text type='danger'>
            授权时间最后1日,请尽速联系窗口
          </Typography.Text>
        ) : (
          `授权时间剩下 ${overDay} 天,请尽速联系窗口`
        );
      onWarningNotify({ message: `时间快到啰!!`, description });
    },
    [onWarningNotify],
  );
  useEffect(() => {
    if (meInfo.setting) allowLoginNotifyHandle(meInfo.setting);
  }, [meInfo, allowLoginNotifyHandle]);

  /**
   * 權限頁面處理;
   */
  useAuthRoutes();

  /**
   * init
   */
  const wsStatusCallBack = ({ status, data }) => {
    dispatch(setWsStatusInfo({ status, data }));
  };
  useEffect(() => {
    // 必要
    fetchMenuHandle();
    fetchMeHandler();

    // ws
    setSkip(false);
    wsStatusHandle(wsStatusCallBack);

    // 其他
    initRedPointNotify();
    fetchBeepFile();
    fetchBeepEnabled();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}
