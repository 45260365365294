import React from 'react';
import { Typography, Spin } from 'antd';
import PropTypes from 'prop-types';
import { Box } from '@WRAP_COMP';

const AdminHeader = ({
  title = '',
  titleLevel = 4,
  subtitle = '',
  cusSubtitle = null,
  btnGroup = null,
  loading = false,
}) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      bgColor='white'
      p='2'>
      <div>
        <Spin spinning={loading}>
          <Typography.Title level={titleLevel} style={{ marginBottom: '0px' }}>
            {title}
          </Typography.Title>
          {cusSubtitle || <Typography.Text>{subtitle}</Typography.Text>}
        </Spin>
      </div>
      {btnGroup}
    </Box>
  );
};

AdminHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btnGroup: PropTypes.node,
};
export default AdminHeader;
