import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useAlertMessage from '@/hook/useAlertMessage';
import { adminContainer, adminLogin } from '@/router/admin';

export const isDefaultKey = key => ['', '403', '404'].includes(key);
export const initRoutes = isLogin => (isLogin ? adminContainer : adminLogin);
export const authMenu = adminContainer[0].children;

export default function useAuthRoutes() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const { onErrorMsg } = useAlertMessage();
  const navigate = useNavigate();

  /**
   * 預設導到第一個權限頁面
   */
  const toDefaultAuthPage = useCallback(keys => {
    let pathname = '/';
    for (let i = 0; i < authMenu.length; i++) {
      if (!isDefaultKey(authMenu[i].path) && keys.includes(authMenu[i].path)) {
        if (authMenu[i].children) {
          const children = authMenu[i].children.find(c =>
            keys.includes(c.path),
          );
          pathname = children?.path
            ? `/${authMenu[i].path}/${children?.path}`
            : '/';
          break;
        } else {
          pathname = `/${authMenu[i].path}`;
          break;
        }
      }
    }
    return pathname;
  }, []);

  const { pathname } = useLocation();
  const routerAuthHandle = useCallback(
    (pathname, authRoutesKey) => {
      if (pathname === '/') {
        navigate(toDefaultAuthPage(authRoutesKey));
        return;
      }
      const pathArr = pathname.split('/');
      pathArr.shift();
      const isAllow = pathArr.every(path =>
        path ? isDefaultKey(path) || authRoutesKey.includes(path) : true,
      );
      if (isAllow) return;
      onErrorMsg('抱歉，找不到此页面');
    },
    [navigate, onErrorMsg, toDefaultAuthPage],
  );

  useEffect(() => {
    routerAuthHandle(pathname, authRoutesKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, authRoutesKey]);

  return { adminContainer };
}
