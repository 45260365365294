import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '@WRAP_COMP';
import { Table as ATable, Card } from 'antd';

const Table = styled(ATable)`
  .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  .ant-table-tbody > tr > td {
    white-space: nowrap;
  }
`;

const DataTable = ({
  rowKey = 'id',
  columns = [],
  dataSource = [],
  pagination = false,
  onChangePage = () => {},
  title = '',
  loading = false,
  children = null,
  rowSelection = null,
  scroll = {},
  footer = null,
  tableProps = {},
  ...props
}) => {
  const pageHandle = p => ({
    pageSize: p.perPage,
    current: p.page,
    total: p.total,
    showTotal: total => `共 ${total} 条`,
  });
  const onChange = ({ current, pageSize }) => {
    onChangePage({ page: current, perPage: pageSize });
  };
  return (
    <Card title={title} {...props}>
      {children && (
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          mb='3'>
          {children}
        </Box>
      )}
      <Table
        columns={columns}
        rowKey={rowKey}
        dataSource={dataSource}
        pagination={pagination && pageHandle(pagination)}
        onChange={onChange}
        loading={loading}
        rowSelection={rowSelection}
        scroll={{ scrollToFirstRowOnChange: true, x: true, ...scroll }}
        footer={footer}
        {...tableProps}
      />
    </Card>
  );
};

DataTable.propTypes = {
  rowKey: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  children: PropTypes.node,
  pagination: PropTypes.object,
  onChangePage: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  rowSelection: PropTypes.object,
  scroll: PropTypes.object,
  footer: PropTypes.func,
  tableProps: PropTypes.object,
};

export default DataTable;
