import React, { useState, useCallback } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, List, Typography, Space } from 'antd';
import { Box, Button } from '@WRAP_COMP';
import useRecMessage from '@HOOK/useRecMessage';
import { formatDateTime, setStorage, getStorage } from '@UTILS';
const getStorageHandle = () => getStorage('businessSystemMsgLog') || [];

function ReceiveMsgLogDrawer() {
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState(() => getStorageHandle());
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const bizSystemHandle = useCallback(
    ({ businessSystemNotification }) => {
      let source = [...dataSource];
      if (source.length === 20) source.pop();
      source = [businessSystemNotification, ...source];
      setDataSource(source);
      try {
        setStorage('businessSystemMsgLog', source);
      } catch (error) {
        console.log('bizSystemHandle error', error);
      }
    },
    [dataSource],
  );

  useRecMessage({
    bizSystem: useCallback(arg => bizSystemHandle(arg), [bizSystemHandle]),
  });

  return (
    <>
      <Box zIndex='10' position='fixed' top='20%' right='0'>
        <Button
          variant='secondary'
          onClick={showDrawer}
          icon={<MenuOutlined />}
        />
      </Box>
      <Drawer
        width='500'
        title='业务系统通知'
        visible={visible}
        onClose={onClose}>
        <List
          itemLayout='vertical'
          size='small'
          dataSource={dataSource}
          renderItem={item => (
            <List.Item>
              <Space direction='vertical'>
                <Typography.Text>{item.message}</Typography.Text>
                <Typography.Text type='secondary'>
                  {formatDateTime(item.createdAt)}
                </Typography.Text>
              </Space>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
}

export default ReceiveMsgLogDrawer;
