import React from 'react';
import ChatRoomHeader from '@/components/ChatRoomHeader/ClientHeader';
import MsgWrap from '@/components/Msg/MsgWrap';
import MsgText from '@/components/Msg/MsgText';
import RoomInputBar from '@/components/ChatRoomInputBar';
import CSRoomContentWrap from '@/components/ChatRoomContent/ContentWrap';
import Carousel from '@/components/ChatRoomContent/Carousel';

import { Box } from '@/plugin/wrap-comp';
import FullBg from './FullBg';
import Container from './Container';

const timestamp = Date.now();
function RoomDemo({
  headerData = {},
  contentData = {},
  contentNode = null,
  bannerData = {},
}) {
  return (
    <FullBg
      bgImgUrl={contentData.backgroundImageURL}
      bgColor={contentData.backgroundColor}>
      <Container
        headerLogoURL={headerData.isEnabled ? headerData.logoURL : ''}
        headerBgColor={headerData.backgroundColor}
        bannerImgURL={bannerData.isEnabled ? bannerData.imageURL : ''}
        bannerLinkURL={bannerData.linkUrl}
        chatRoomHeader={<ChatRoomHeader title='客服咨询' />}
        chatRoomContent={
          <CSRoomContentWrap>
            <Box
              height='100%'
              overflow='scroll'
              bgColor={contentData.boxBackgroundColor || 'gray-1'}
              backgroundImage={`url(${contentData.boxBackgroundImageURL})`}
              backgroundSize='cover'
              backgroundPosition='center'
              backgroundRepeat='no-repeat'>
              {contentNode ? (
                <>{contentNode}</>
              ) : (
                <Box
                  height='100%'
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-end'>
                  <Box p={3}>
                    <MsgWrap
                      timestamp={timestamp}
                      right={false}
                      bg='secondary'
                      isShowMsgStatus={false}
                      username='遊客'
                      userID='456'>
                      <MsgText text='为什么充值没收到款项？' />
                    </MsgWrap>
                    <MsgWrap
                      timestamp={timestamp}
                      right={true}
                      bg='primary'
                      isRead={true}
                      username='客服'
                      userID='123'>
                      <MsgText text='充值没收到款项，劳烦请提供充值订单号以及时间来加速替您查询' />
                    </MsgWrap>
                  </Box>
                  <RoomInputBar isClient={true} showUploadBtn={false} />
                </Box>
              )}
            </Box>
          </CSRoomContentWrap>
        }
        chatRoomContentCourse={
          <Carousel
            position={headerData.contentLogoPosition}
            logoList={
              headerData.contentLogoEnabled ? headerData.contentLogoURL : []
            }
          />
        }
      />
    </FullBg>
  );
}

export default RoomDemo;
