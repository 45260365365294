import React, { useState, useRef } from 'react';
import { Box } from '@WRAP_COMP';
import { EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Input, Space, Checkbox } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import useAlertMessage from '@/hook/useAlertMessage';
import { CSFormFieldType, cloneDeep } from '@/utils';
import QsOption from './QsOption';

function QsItem({
  originData = null,
  updateData = () => {},
  deleteData = () => {},
}) {
  const [isEdit, setIsEdit] = useState(false);
  const labelEl = useRef(null);
  const { onErrorMsg } = useAlertMessage();

  // 標題
  const onChangeLabel = () => {
    let tempData = cloneDeep(originData);
    tempData.label = labelEl.current.state.value;
    setIsEdit(false);
    updateData(tempData);
  };

  // 必填
  const onChangeRequire = checked => {
    let tempData = cloneDeep(originData);
    tempData.rule.require = checked.target.checked;
    updateData(tempData);
  };

  // 新增選項
  const addContentOption = () => {
    let tempData = cloneDeep(originData);
    tempData.content.items = [
      ...tempData.content.items,
      { label: '选项', value: false },
    ];
    updateData(tempData);
  };

  // 更新選項
  const updateContentOption = (idx, option) => {
    let tempData = cloneDeep(originData);
    let tempOption = cloneDeep(option);
    tempData.content.items[idx] = tempOption;
    updateData(tempData);
  };

  // 刪除選項
  const deleteContentOption = idx => {
    if (originData.content.items.length === 1) {
      onErrorMsg('选项类最少保留一个子选项');
      return;
    }
    let tempData = cloneDeep(originData);
    tempData.content.items = tempData.content.items.filter(
      (el, ii) => ii !== idx,
    );
    updateData(tempData);
  };

  const multipleOptionsRender = data => {
    const options = data?.content?.items || [];
    return (
      <Box bgColor='gray-2' px='2'>
        {options.map((op, idx) => (
          <QsOption
            key={idx}
            originData={op}
            updateData={option => updateContentOption(idx, option)}
            deleteData={() => deleteContentOption(idx)}
          />
        ))}
        <Button
          variant='outline-secondary'
          type='dashed'
          size='small'
          block
          onClick={addContentOption}
          style={{ margin: '8px 0px' }}>
          + 添加選項
        </Button>
      </Box>
    );
  };

  return (
    <Box bgColor='secondary-light' mb='2' py='2' px='3'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        {isEdit ? (
          <Input
            ref={labelEl}
            placeholder='請輸入標題'
            defaultValue={originData.label}
            onPressEnter={onChangeLabel}
            onBlur={onChangeLabel}
          />
        ) : (
          <>{originData.label}</>
        )}
        <Space size={0}>
          {isEdit ? (
            <Button
              type='text'
              icon={<SaveOutlined />}
              onClick={onChangeLabel}
            />
          ) : (
            <Button
              type='text'
              icon={<EditOutlined />}
              onClick={() => setIsEdit(pre => !pre)}
            />
          )}
          <Button type='text' icon={<DeleteOutlined />} onClick={deleteData} />
          <Checkbox
            checked={originData.rule.require}
            onChange={checked => onChangeRequire(checked)}
            style={{ whiteSpace: 'nowrap', marginLeft: '8px' }}>
            必填
          </Checkbox>
        </Space>
      </Box>
      {(originData.type === CSFormFieldType['Radio'] ||
        originData.type === CSFormFieldType['Checkbox']) &&
        multipleOptionsRender(originData)}
    </Box>
  );
}

export default QsItem;
