import React, { useEffect, useState } from 'react';
import { Drawer as ADrawer, Form, Switch, Input } from 'antd';
import { Button } from '@/plugin/wrap-comp';

const defaultForm = {
  afterSendSec: 0,
  source: '',
  isEnabled: false,
};

function Drawer({
  visible = false,
  detailData = null,
  loading = false,
  setVisible = () => {},
  create = () => {},
  update = () => {},
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();

  const closeDrawer = async () => {
    await form.resetFields();
    setVisible(false);
  };

  const submitHandle = async fieldsObj => {
    let fields = {
      ...fieldsObj,
      isEnabled: fieldsObj.isEnabled ? 'Yes' : 'No',
    };
    isEdit
      ? await update({
          filter: { hostsDeny: { id: detailData.id } },
          in: { ...fields },
        })
      : await create({ in: { ...fields } });
    await closeDrawer();
  };

  useEffect(() => {
    const initDrawer = async () => {
      if (detailData) {
        setIsEdit(true);
        await form.setFieldsValue({
          ...detailData,
          isEnabled: detailData.isEnabled === 'Yes',
        });
      } else {
        setIsEdit(false);
        await form.setFieldsValue({ ...defaultForm });
      }
    };
    visible && initDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, detailData]);

  return (
    <>
      <ADrawer
        visible={visible}
        onClose={closeDrawer}
        width={500}
        title={isEdit ? '编辑黑名单' : '新增黑名单'}>
        <Form form={form} onFinish={submitHandle}>
          <Form.Item name='isEnabled' valuePropName='checked' label='启用状态'>
            <Switch checkedChildren='启用' unCheckedChildren='停用' />
          </Form.Item>
          <Form.Item
            name='ipAddress'
            label='IP'
            rules={[{ required: true, message: '必填' }]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item name='remark' label='备注'>
            <Input placeholder='请输入' />
          </Form.Item>
          <Button variant='primary' htmlType='submit' loading={loading}>
            提交
          </Button>
        </Form>
      </ADrawer>
    </>
  );
}

export default Drawer;
