import React from 'react';
import { Column } from '@ant-design/charts';
import { Card, Empty, Row, Col, Statistic, Skeleton } from 'antd';
import {
  UserAddOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import { useActiveList } from '@/hook/useList';
import QnsTooltip from '@/components/QnsTooltip';
import CustomerServiceTable from '@/components/Table/CSReportTable';
import {
  setDateRanges,
  toUnix,
  formatTime,
  secondsToHms,
  AccountTypeKeys,
} from '@/utils';

const { theme } = require('@/plugin/theme');
const todayDateTime = setDateRanges(0);
const defaultTodayFilter = {
  greaterThanEqual: toUnix(todayDateTime.start),
  lessThanEqual: toUnix(todayDateTime.end),
};

const consultingRoomServiceOriginFilter = {
  baseFilter: {
    sortField: 'date',
    sortType: 'DESC',
    rangeField: 'date',
    rangeType: 'ByDateTime',
    ...defaultTodayFilter,
  },
};
const customerServiceOriginFilter = {
  baseFilter: {
    sortField: 'id',
    sortType: 'DESC',
  },
  user: { accountType: AccountTypeKeys['CustomerService'] },
  ...defaultTodayFilter,
};

const columnItemType = [
  { key: 'countOfCommonUser', alias: '会员咨询' },
  { key: 'countOfTouristUser', alias: '访客咨询' },
];

const config = {
  isStack: true,
  xField: 'dateTime',
  yField: 'value',
  seriesField: 'type',
  label: {
    position: 'middle',
    layout: [
      { type: 'interval-adjust-position' },
      { type: 'interval-hide-overlap' },
      { type: 'adjust-color' },
    ],
  },
};

const totalDataFields = [
  {
    key: 'createConsultationCountOfCommonUserTotal',
    title: () => (
      <QnsTooltip title='进线会员' tip='筛选日期不重复的点选咨询客服的会员数' />
    ),
    value: 0,
    prefix: () => <UserAddOutlined />,
  },
  {
    key: 'createConsultationCountTotal',
    title: () => (
      <QnsTooltip title='新建咨询房总数' tip='筛选日期内加总的新建咨询房数' />
    ),
    value: 0,
    prefix: () => <UserSwitchOutlined />,
  },
  {
    key: 'validConsultationCountTotal',
    title: () => (
      <QnsTooltip title='有效咨询总数' tip='筛选日期内加总的有效咨询数' />
    ),
    value: 0,
    prefix: () => <SwapOutlined />,
  },
  {
    key: 'averageResponseSecondTotal',
    title: () => (
      <QnsTooltip
        title='平均首次回应时长'
        tip='篩選日期內全有效諮詢房用戶發出首次對話後,「首位客服人員」的回應速度之平均時間'
      />
    ),
    value: 0,
    prefix: () => <CommentOutlined />,
    formatter: v => secondsToHms(v),
  },
  {
    key: 'averageConversationSecondTotal',
    title: () => (
      <QnsTooltip
        title='平均对话时长'
        tip='筛选日期内全有效咨询房用户发出首次对话到客服人员封存该咨询房之平均时间'
      />
    ),
    value: 0,
    prefix: () => <CommentOutlined />,
    formatter: v => secondsToHms(v),
  },
];

const hourInterval = (() => {
  let hours = [];
  for (let h = 0; h < 24; h++) {
    hours.push(h < 10 ? `0${h}:00` : `${h}:00`);
  }
  return hours;
})();

const Dashboard = () => {
  // 圖表 > 今日咨询量与咨询来源
  const {
    data: { columnList = [], isColumnEmpty = true },
    loading: columnLoading,
    error,
  } = useActiveList({
    api: 'REPORT_CONSULTING_ROOM',
    cusFilter: { filter: { ...defaultTodayFilter, intervalSeconds: 3600 } },
    handleData: ({ listConsultationRoomSummary }) => {
      const list = listConsultationRoomSummary.consultationRoomSummaries || [];
      let columnList = [];
      let isColumnEmpty = true;
      hourInterval.forEach(h => {
        const existIdx = list.findIndex(l => formatTime(l.date) === h);

        columnItemType.forEach(t => {
          if (existIdx >= 0) {
            isColumnEmpty = false;
            columnList.push({
              dateTime: h,
              type: t.alias,
              value: list[existIdx][t.key],
            });
          } else {
            columnList.push({ dateTime: h, type: t.alias, value: 0 });
          }
        });
      });
      return { columnList, isColumnEmpty };
    },
  });

  // 今日客服統計
  const {
    data: { list: totalData = [] },
    loading: roomTotalLoading,
  } = useActiveList({
    api: 'REPORT_CONSULTING_ROOM_SERVICE',
    originFilter: { ...consultingRoomServiceOriginFilter },
    handleData: ({ listRoomDailySummary }) => {
      totalDataFields.forEach(
        el => (el.value = listRoomDailySummary.meta[el.key]),
      );
      return { list: [...totalDataFields] };
    },
  });

  // 今日客服個人數據
  const {
    data: { list, total },
    loading: tableLoading,
    pagination,
    handleChangePage,
  } = useActiveList({
    api: 'REPORT_CUSTOMER_SERVICE',
    originFilter: { ...customerServiceOriginFilter },
    handleData: ({ listCustomerServiceSummary }) => {
      const { customerServiceSummaries = [], meta } =
        listCustomerServiceSummary;
      return {
        list: customerServiceSummaries.map(el => ({
          ...el,
          id: el.user.id,
          username: el.user.username,
        })),
        total: meta?.total || 0,
      };
    },
  });

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Card title='今日咨询量与咨询来源'>
            {columnLoading && <Skeleton />}
            {columnLoading || (
              <>
                {isColumnEmpty || error ? (
                  <Empty description={<>暂无数据</>} />
                ) : (
                  <Column {...config} data={columnList} />
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={12} style={{ marginTop: '12px' }}>
        <Col span={4}>
          <Card title='今日客服统计'>
            {roomTotalLoading && <Skeleton />}
            {roomTotalLoading ||
              totalData.map(el => (
                <Statistic
                  key={el.key}
                  valueStyle={{ color: theme.primary }}
                  title={el.title()}
                  value={el.value}
                  prefix={el.prefix()}
                  formatter={el.formatter}
                  style={{ marginBottom: '12px' }}
                />
              ))}
          </Card>
        </Col>
        <Col span={20}>
          <CustomerServiceTable
            title='今日客服个人数据'
            dataSource={list}
            loading={tableLoading}
            pagination={{ ...pagination, total }}
            onChangePage={handleChangePage}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
