import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutationApi, apiType } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import RoleList from './List';
import { AccountTypeKeys, cloneDeep } from '@/utils';
import useAlertMessage from '@/hook/useAlertMessage';

/**
 * 使用者角色列表, 更新&刪除 角色
 * @param {*} param0
 * @param {*} param0.userID
 * @param {*} param0.accountType 要拉的 role list type
 */
const UserRole = ({ userID, accountType }) => {
  const { onSuccessMsg } = useAlertMessage();
  const [dataSource, setDataSource] = useState([]);
  const userRolesId = userRoles => userRoles.map(item => item.role.id);
  const dataHandle = (roleList = [], userRoles = []) => {
    let list = cloneDeep(roleList);
    list.forEach(item => {
      const checked = userRolesId(userRoles).includes(item.id);
      item.checked = checked;
      return item;
    });
    setDataSource(list);
  };
  const { loading, refetch } = useActiveList({
    api: 'LIST_USER_ROLE',
    cusFilter: {
      userRoles: { userRole: { userID } },
      listRole: { role: { supportAccountType: accountType } },
    },
    options: {
      onCompleted: ({ listRole, listUserRole }) =>
        dataHandle(listRole.roles, listUserRole.userRoles),
    },
  });

  const completedConfig = {
    onCompleted: () => {
      refetch();
      onSuccessMsg();
    },
  };
  const [createRole, { loading: createLoading }] = useMutationApi(
    apiType.CREATE_USER_ROLE,
    completedConfig,
  );
  const [deleteRole, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_USER_ROLE,
    completedConfig,
  );
  const changeRoleHandle = (checked, roleID) => {
    if (checked) {
      const isAdmin = accountType === AccountTypeKeys['Manager'] ? 'Yes' : 'No';
      createRole({ in: { roleID, userID, isAdmin } });
    } else {
      deleteRole({ filter: { userRole: { userID, roleID } } });
    }
  };

  return (
    <RoleList
      dataSource={dataSource}
      loading={loading || deleteLoading || createLoading}
      changeRole={changeRoleHandle}
    />
  );
};

UserRole.propTypes = {
  userID: PropTypes.number.isRequired,
};
export default UserRole;
