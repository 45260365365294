import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVisibleDrawer } from '@/store/global';
import { Divider, Button } from 'antd';
import TabsScrollBlock from '@/components/TabsScrollBlock';
import SpinachInfo from '@/components/User/SpinachInfo';
import CSForm from '@/components/ChatRoomContent/CSForm';
import RoomDetailDesc from '@/components/CSRoomAdmin/RoomDetailDesc';
import RoomUserList from '@/components/CSRoomAdmin/RoomUserList';
import { AccountTypeKeys } from '@/utils';

const INFO_TABS = [
  { key: 'roomInfo', tab: '房间资讯' },
  { key: 'csForm', tab: '询前表单' },
];

function InfoTabsBlock({
  room = null,
  ownerUserData = null,
  roomUsersMap = {},
}) {
  const dispatch = useDispatch();
  const openUserDetailDrawer = () => {
    dispatch(setVisibleDrawer({ name: 'UserDetailDrawer', visible: true }));
  };

  const [currTabKey, setCurrInfoTabKey] = useState(INFO_TABS[0].key);
  const tabContentRender = {
    roomInfo: () => (
      <>
        <RoomDetailDesc {...room} />
        {ownerUserData?.accountType !== AccountTypeKeys['Tourist'] && (
          <>
            <Divider orientation='right'>会员资讯</Divider>
            <SpinachInfo userID={ownerUserData?.id || 0}></SpinachInfo>
            <Button
              type='link'
              onClick={openUserDetailDrawer}
              style={{ padding: '0px' }}>
              更多資訊
            </Button>
          </>
        )}
        <RoomUserList
          roomType={room.type}
          userRooms={room.userRooms}
          userInvitations={room.userInvitations}
          roomUsersMap={roomUsersMap}
        />
      </>
    ),
    csForm: () => (
      <CSForm formDataList={room?.formData || []} isReadType={true} />
    ),
  };

  return (
    <>
      <TabsScrollBlock
        tabPaneArr={INFO_TABS}
        onChangeTab={tab => setCurrInfoTabKey(tab)}
        wrapProps={{ py: 0 }}>
        {room && ownerUserData && tabContentRender[currTabKey]()}
      </TabsScrollBlock>
    </>
  );
}

export default InfoTabsBlock;
