import React, { useState } from 'react';
import { Collapse, List, Popover, Form, Input, InputNumber } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { useMutationApi, useQueryApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import QusListForm from './QusListForm';

function QusMgm() {
  const [qsList, setQsList] = useState([]);
  const { onSuccessMsg } = useAlertMessage();

  const { fetchData, loading } = useQueryApi(apiType.LIST_CS_QUEUE_QUS);
  const fetchQsList = async v => {
    const { error, data } = await fetchData();
    if (error) return;
    setQsList(data.listConsultingRoomQuestion.consultingRoomQuestions);
  };
  const onChange = k => {
    if (k.length) fetchQsList();
  };

  /**
   * 新增
   */
  const [createdForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = v => {
    v || createdForm.resetFields();
    setVisible(v);
  };
  const [createQs, { loading: createdQsLdg }] = useMutationApi(
    apiType.CREATED_CS_QUEUE_QUS,
  );
  const createQsHandle = async fields => {
    const { error } = await createQs({ in: { ...fields } });
    if (error) return;
    handleVisibleChange(false);
    onSuccessMsg();
    fetchQsList();
  };
  const createdFormRender = () => {
    return (
      <Form
        form={createdForm}
        onFinish={createQsHandle}
        style={{ minWidth: '200px' }}>
        <Form.Item
          name='question'
          rules={[{ required: true, message: '必填' }]}
          style={{ marginBottom: '12px' }}>
          <Input placeholder='请输入咨询问题' />
        </Form.Item>
        <Form.Item
          name='maxProcessingCount'
          rules={[{ required: true, message: '必填' }]}
          style={{ marginBottom: '12px' }}>
          <InputNumber
            placeholder='请输入问题最大数量'
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Button
          htmlType='submit'
          variant='outline-primary'
          size='small'
          loading={createdQsLdg}>
          确认
        </Button>
      </Form>
    );
  };

  /**
   * 編輯
   */
  const [updateQs, { loading: updateQsLdg }] = useMutationApi(
    apiType.UPDATE_CS_QUEUE_QUS,
  );
  const updateQsHandle = async ({ id, ...fields }) => {
    const { error } = await updateQs({
      filter: { consultingRoomQuestion: { id } },
      in: { ...fields },
    });
    if (error) return;
    onSuccessMsg();
    fetchQsList();
  };

  /**
   * 刪除
   */
  const [deleteQs, { loading: deleteQsLdg }] = useMutationApi(
    apiType.DELETE_CS_QUEUE_QUS,
  );
  const deleteQsHandle = async id => {
    const { error } = await deleteQs({
      filter: { consultingRoomQuestion: { id } },
    });
    if (error) return;
    onSuccessMsg();
    fetchQsList();
  };

  return (
    <Collapse bordered={false} onChange={onChange}>
      <Collapse.Panel
        key='1'
        showArrow={false}
        header={
          <Button variant='outline-primary' block size='small' type='dashed'>
            进阶设定
          </Button>
        }>
        <>
          <Popover
            content={createdFormRender}
            trigger='click'
            visible={visible}
            onVisibleChange={handleVisibleChange}>
            <Button type='primary' size='small'>
              + 新增問題
            </Button>
          </Popover>
          <List
            dataSource={qsList}
            loading={loading || updateQsLdg}
            renderItem={item => (
              <List.Item>
                <QusListForm
                  qusData={{ ...item }}
                  updateQsHandle={updateQsHandle}
                  deleteQsHandle={deleteQsHandle}
                  deleteLoading={deleteQsLdg}
                />
              </List.Item>
            )}
          />
        </>
      </Collapse.Panel>
    </Collapse>
  );
}

export default QusMgm;
