import React from 'react';
import PropTypes from 'prop-types';
import { Typography, List, Switch, Space, Spin } from 'antd';

const RoleList = ({ loading = false, dataSource = [], changeRole }) => {
  return (
    <Spin spinning={loading}>
      <List
        dataSource={dataSource}
        renderItem={item => (
          <List.Item>
            <Space>
              <Switch
                key='isCheck'
                checkedChildren='啟用'
                unCheckedChildren='停用'
                checked={item.checked}
                onChange={e => changeRole(e, item.id)}
              />
              <Typography.Text strong>{item.name}</Typography.Text>
            </Space>
          </List.Item>
        )}></List>
    </Spin>
  );
};

RoleList.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.array,
  changeRole: PropTypes.func.isRequired,
};

export default RoleList;
