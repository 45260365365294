import React, { useState, useEffect } from 'react';
import { Input, Form, Switch, Drawer as ADrawer } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import AvatarUpload from '@/components/Avatar/AvatarUpload';

const defaultForm = { name: '', isDisplayEnabled: true };
function Drawer({
  visible,
  setVisible,
  loading,
  detailData = null,
  createdSVS,
  updateSVS,
}) {
  const [svsForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [avatarURL, setAvatarURL] = useState('');

  const submitHandle = async fields => {
    if (file) fields.avatarContent = file;
    let variables = {
      in: {
        ...fields,
        isDisplayEnabled: fields.isDisplayEnabled ? 'Yes' : 'No',
      },
    };
    if (isEdit) {
      variables = {
        ...variables,
        filter: { contactCustomerService: { id: detailData.id } },
      };
      await updateSVS(variables);
    } else {
      await createdSVS(variables);
    }
    await closeDrawer();
  };

  const closeDrawer = async () => {
    await svsForm.resetFields();
    setFile(null);
    setAvatarURL('');
    setVisible(false);
  };

  useEffect(() => {
    const initForm = async () => {
      setIsEdit(detailData ? true : false);
      setAvatarURL(detailData ? detailData.avatarURL : '');
      const formData = detailData
        ? {
            ...detailData,
            isDisplayEnabled: detailData.isDisplayEnabled === 'Yes',
          }
        : defaultForm;
      await svsForm.setFieldsValue(formData);
    };
    visible && initForm();
  }, [detailData, svsForm, visible]);

  return (
    <ADrawer
      visible={visible}
      title={isEdit ? '編輯' : '新增'}
      onClose={closeDrawer}
      width={450}>
      <AvatarUpload
        avatarURL={avatarURL}
        setUploadFile={file => setFile(file)}
        isEdit={true}
      />
      <Form
        form={svsForm}
        layout='vertical'
        onFinish={submitHandle}
        style={{ marginTop: '28px' }}>
        <Form.Item
          name='name'
          label='客服名称'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入' />
        </Form.Item>
        <Form.Item
          name='isDisplayEnabled'
          valuePropName='checked'
          label='启用状态'>
          <Switch checkedChildren='启用' unCheckedChildren='停用' />
        </Form.Item>
        <Button variant='primary' htmlType='submit' loading={loading}>
          提交
        </Button>
      </Form>
    </ADrawer>
  );
}

export default Drawer;
