import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Modal, Table, Button, Form, Input, Space, Row, Radio } from 'antd';
import { useQueryApi, apiType } from '@/hook/useApi';
import { Fragment } from 'react';
import SearchBar from '@/components/SearchBar';
import { PayType as PayTypeMap } from '@/utils';
import Text from 'antd/lib/typography/Text';
import { Toast } from 'antd-mobile';

function PaymentListModal(
  { roomID, amount, payType, onSubmit = () => {}, onSubmitManual = () => {} },
  ref,
) {
  const currSelectedRow = useRef({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [form] = Form.useForm();

  const { fetchData, loading: listLoading } = useQueryApi(
    apiType.listPaymentChannel,
  );

  const [currentPayType, setCurrentPayType] = useState(PayTypeMap.bank.key);
  const listPaymentChannel = async ({ roomID, amount, payType, search }) => {
    const res = await fetchData({
      in: {
        roomID,
        amount,
        payType,
      },
    });
    const channels = res?.data?.listPaymentChannel?.channels || [];
    const filteredChannels = channels.filter(channel => {
      if (!search) return true;
      const arr = Object.keys(search).reduce((acc, key) => {
        if (search[key]) {
          const tester = new RegExp(search[key], 'ig');
          acc.push(tester.test(channel[key]));
        } else {
          acc.push(true);
        }
        return acc;
      }, []);
      return !arr.includes(false);
    });

    setPaymentList(filteredChannels);
  };

  const onFinish = async search => {
    listPaymentChannel({ roomID, amount, payType, search });
  };

  const open = () => {
    setOpenModal(true);
    listPaymentChannel({ roomID, amount, payType });
  };

  const onCancel = () => {
    setOpenModal(false);
    currSelectedRow.current = {};
  };

  useImperativeHandle(ref, () => ({
    open,
    onCancel,
  }));

  const searchFields = {
    bank: [
      { name: 'name', type: 'text', label: '开户名' },
      { name: 'alias', type: 'text', label: '开户银行' },
    ],
    alipay: [
      { name: 'payeeID', type: 'text', label: 'ID' },
      { name: 'name', type: 'text', label: '开户名' },
      { name: 'account', type: 'text', label: '支付宝账号' },
    ],
  };

  const columns = {
    bank: [
      { key: 'payeeID', title: 'ID', dataIndex: 'payeeID' },
      { key: 'name', title: '开户名', dataIndex: 'name' },
      { key: 'account', title: '银行卡号', dataIndex: 'account' },
      { key: 'alias', title: '开户银行', dataIndex: 'alias' },
      {
        key: 'amount',
        title: '实际清算提款金额',
        dataIndex: 'amount',
        render: amount => `${amount / 100}元`,
      },
    ],
    alipay: [
      { key: 'payeeID', title: 'ID', dataIndex: 'payeeID' },
      { key: 'name', title: '开户名', dataIndex: 'name' },
      { key: 'account', title: '支付宝账号', dataIndex: 'account' },
      {
        key: 'amount',
        title: '实际清算提款金额',
        dataIndex: 'amount',
        render: amount => `${amount / 100}元`,
      },
    ],
  };

  return (
    <>
      <Button type='primary' loading={loading} onClick={() => open()}>
        拆解订单
      </Button>
      <Modal
        width={800}
        title={
          <div>
            拆解订单
            <Radio.Group
              onChange={e => setCurrentPayType(e.target.value)}
              value={currentPayType}
              style={{ marginLeft: '20px' }} // 可选：调整间距
            >
              <Radio value={PayTypeMap.bank.key}>{PayTypeMap.bank.label}</Radio>
              <Radio value={PayTypeMap.alipay.key}>
                {PayTypeMap.alipay.label}
              </Radio>
            </Radio.Group>
          </div>
        }
        visible={openModal}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}>
        <Space direction='vertical'>
          <Space direction='vertical'>
            <SearchBar
              fields={searchFields[currentPayType]}
              onFinish={onFinish}
              loading={listLoading}
            />
            <Form
              form={form}
              name='control-hooks'
              initialValues={{ innerAmount: amount }}
              style={{ maxWidth: 600 }}>
              <Form.Item
                name='timeExpired'
                label='有效时间'
                rules={[{ required: true, message: '必填' }]}>
                <Input
                  style={{
                    width: '250px',
                  }}
                  addonAfter='秒'
                />
              </Form.Item>
            </Form>
            <Table
              loading={listLoading}
              rowSelection={{
                type: 'radio',
                onChange: (_, selectedRows) => {
                  console.log(selectedRows, 'selectedRows');
                  currSelectedRow.current = selectedRows[0];
                },
              }}
              columns={columns[currentPayType] || []}
              dataSource={
                paymentList.filter(
                  item => item.payType === `sub_${currentPayType}`,
                ) || []
              }
              pagination={false}
              rowKey='payeeID'
            />
            <Row justify='end'>
              <Button
                type='primary'
                loading={loading}
                onClick={async () => {
                  if (Object.keys(currSelectedRow.current).length === 0) {
                    Toast.show({ content: '请选择需要拆分的订单' });
                    return;
                  }

                  const time = await form.validateFields();
                  console.log(currSelectedRow.current, time.timeExpired);
                  Modal.confirm({
                    title: null,
                    icon: null,
                    content:
                      currSelectedRow.current?.payType === 'sub_bank' ? (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                          }}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>开户名</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.name}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>银行卡号</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.account}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>开户银行</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.alias}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>实际清算提款金额</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.amount}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>有效时间</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {time.timeExpired}秒
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                          }}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>开户名</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.name}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>支付宝账号</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.account}
                            </Text>
                          </div>

                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>实际清算提款金额</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {currSelectedRow.current?.amount}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <Text style={{ flex: 1 }}>有效时间</Text>
                            <Text style={{ flex: 1, textAlign: 'right' }}>
                              {time.timeExpired}秒
                            </Text>
                          </div>
                        </div>
                      ),
                    cancelText: '取消',
                    okText: '确认',
                    onOk: async () => {
                      setLoading(true);
                      await onSubmit({
                        ...currSelectedRow.current,
                        time: Number(time.timeExpired),
                      });
                      setLoading(false);
                      onCancel();
                    },
                  });
                }}>
                确认
              </Button>
            </Row>
          </Space>
        </Space>
      </Modal>
    </>
  );
}

export default forwardRef(PaymentListModal);
