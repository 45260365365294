import { useCallback } from 'react';
import { notification } from 'antd';

const useNotification = () => {
  const handleNotify = useCallback(
    ({ message = '新訊息', description = '', icon = null } = {}) => {
      const settingObj = { message, description, icon };
      notification.destroy();
      notification.open(settingObj);
    },
    [],
  );

  const handleNotifyType = useCallback((type = 'warning', content = {}) => {
    // notification.destroy();
    notification[type]({ ...content });
  }, []);

  return {
    onNotify: useCallback((...arg) => handleNotify(...arg), [handleNotify]),
    onWarningNotify: useCallback(
      content => handleNotifyType('warning', content),
      [handleNotifyType],
    ),
    onErrorNotify: useCallback(
      content => handleNotifyType('error', content),
      [handleNotifyType],
    ),
  };
};

export default useNotification;
