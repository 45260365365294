import React from 'react';
import DataTable from '@/components/Table/DataTable';
import QnsTooltip from '@/components/QnsTooltip';
import { formatDate, commaFormat, secondsToHms } from '@/utils';

const ConsultingRoomTable = ({
  children,
  dataSource,
  loading,
  onChangePage,
  ...props
}) => {
  const columns = [
    {
      title: '日期',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      render: c => formatDate(c),
    },
    {
      title: () => (
        <QnsTooltip title='进线会员' tip='当日点选咨询客服的不重复会员数' />
      ),
      dataIndex: 'createConsultationCountOfCommonUser',
      key: 'createConsultationCountOfCommonUser',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='进线游客'
          tip='当日点选咨询客服进线数的不重复游客数'
        />
      ),
      dataIndex: 'createConsultationCountOfTourist',
      key: 'createConsultationCountOfTourist',
      render: r => commaFormat(r),
    },
    {
      title: () => <QnsTooltip title='新建咨询房' tip='當日新建的諮詢房數' />,
      dataIndex: 'createConsultationCount',
      key: 'createConsultationCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='有效咨询数'
          tip='當日新建的諮詢房中用戶有進行實際諮詢(會員與客服對話)的房數,若一間房有兩個以上客服對話也只算1次'
        />
      ),
      dataIndex: 'validConsultationCount',
      key: 'validConsultationCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='平均首次回应时间'
          tip='當日全有效諮詢房用戶發出首次對話後,首位客服人員的回應速度之平均時間(客服發出對話時間-用戶發起首次對話時間)'
        />
      ),
      dataIndex: 'averageResponseSecond',
      key: 'averageResponseSecond',
      render: r => secondsToHms(r),
    },
    {
      title: () => (
        <QnsTooltip
          title='平均对话时长'
          tip='當日全有效諮詢房用戶發出首次對話到客服人員封存該諮詢房之平均時間(客服封存該諮詢房-用戶發起首次對話時間)'
        />
      ),
      dataIndex: 'averageConversationSecond',
      key: 'averageConversationSecond',
      render: r => secondsToHms(r),
    },
    {
      title: () => <QnsTooltip title='FAQ点击数' tip='有点击FAQ的房数' />,
      dataIndex: 'faqCount',
      key: 'faqCount',
      render: r => commaFormat(r),
    },
    {
      title: () => (
        <QnsTooltip title='FAQ点击无效对话' tip='有点击FAQ的无效对话房数' />
      ),
      dataIndex: 'invalidFaqCount',
      key: 'invalidFaqCount',
      render: r => commaFormat(r),
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        rowKey='date'
        dataSource={dataSource}
        loading={loading}
        onChangePage={onChangePage}
        {...props}>
        {children}
      </DataTable>
    </div>
  );
};

export default ConsultingRoomTable;
