import React from 'react';
import PropTypes from 'prop-types';
import AvatarFetch from '@/components/Avatar/AvatarFetch';
import { Typography } from 'antd';

const AvatarWithText = React.memo(({ avatarURL, username, size = 'small' }) => {
  return (
    <>
      <AvatarFetch avatarURL={avatarURL} size={size} />
      <Typography.Text strong style={{ marginLeft: '8px' }}>
        {username}
      </Typography.Text>
    </>
  );
});

AvatarWithText.propTypes = {
  avatarURL: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  size: PropTypes.string,
};
export default AvatarWithText;
