import { useState, useCallback } from 'react';
import { useActiveList } from '@/hook/useList';

/**
 * 域名 OriginList
 */
function useOrigin({ originPagination = { perPage: 999 }, options = {} } = {}) {
  /**
   * 域名搜尋 list
   */
  const [originSelectList, setOriginSelectList] = useState([]);
  const selectListHandle = list => {
    let tempList = [];
    list.forEach(el => tempList.push({ value: el.id, label: el.origin }));
    setOriginSelectList(tempList);
  };

  /**
   * 域名 tableList
   */
  const [originTableList, setOriginTableList] = useState([]);
  const [originTotal, setOriginTotal] = useState(0);
  const { loading, pagination, refetch, handleChangePage } = useActiveList({
    api: 'LIST_CS_ROOM_ORIGIN',
    originPagination,
    options: {
      onCompleted: ({ listConsultingRoomOrigin }) => {
        selectListHandle(listConsultingRoomOrigin.consultingRoomOrigins || []);
        setOriginTableList([...listConsultingRoomOrigin.consultingRoomOrigins]);
        setOriginTotal(() => listConsultingRoomOrigin?.meta?.total || 0);
      },
      ...options,
    },
  });

  /**
   * 訪問域名 顯示 remark
   */
  const accessDomainTextFormate = useCallback(
    accessDomain => {
      try {
        if (!accessDomain) return '-';
        let remark = '-';
        let origin = '-';
        const obj = originTableList.find(({ origin }) => {
          const idexEnd =
            origin.indexOf(':') < 0 ? origin.length : origin.indexOf(':');
          return origin.substring(0, idexEnd) === accessDomain;
        });
        obj && (remark = obj.remark);
        obj && (origin = obj.origin);
        return `${origin} (${remark})`;
      } catch (error) {
        console.log('accessDomainTextFormate error', error);
      }
    },
    [originTableList],
  );

  return {
    loading,
    pagination,
    originSelectList,
    originTableList,
    originTotal,
    refetch,
    handleChangePage,
    accessDomainTextFormate,
  };
}

export default useOrigin;
