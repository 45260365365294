import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined, EditTwoTone } from '@ant-design/icons';
import { useActiveList } from '@/hook/useList';
import { useMutationApi, apiType } from '@/hook/useApi';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import AvatarFetch from '@/components/Avatar/AvatarFetch';
import { EnableKey, formatDateTime, EnableDisplay } from '@/utils';
import { Button, Space, Switch } from 'antd';
import Drawer from './Drawer';

const originFilter = {
  contactCustomerService: {
    name: '',
    isDisplayEnabled: EnableKey['Unknown'],
  },
};

const searchFields = [
  { name: 'name', type: 'text', label: '客服名称', placeholder: '请输入' },
  {
    name: 'isDisplayEnabled',
    type: 'select',
    label: '启用状态',
    optionsObj: EnableDisplay,
  },
];

export default function ContactCustomerService() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const {
    data: { list, total },
    loading,
    pagination,
    refetch,
    handleSearchFilter,
    handleChangePage,
  } = useActiveList({
    api: 'LIST_CONTACT_CUS_SVS',
    originFilter: { ...originFilter },
    handleData: ({ listContactCustomerService }) => ({
      list: listContactCustomerService.contactCustomerServices || [],
      total: listContactCustomerService.meta?.total || 0,
    }),
  });

  const onSearchHandle = search => {
    handleSearchFilter({
      contactCustomerService: {
        ...originFilter.contactCustomerService,
        ...search,
      },
    });
  };

  const callback = { onCompleted: refetch };

  // 刪除
  const [deleteSVS, { loading: deleteLdg }] = useMutationApi(
    apiType.DELETE_CONTACT_CUS_SVS,
    callback,
  );

  // 新增 編輯
  const [createdSVS, { loading: createdLdg }] = useMutationApi(
    apiType.CREATED_CONTACT_CUS_SVS,
    callback,
  );
  const [updateSVS, { loading: updateLdg }] = useMutationApi(
    apiType.UPDATE_CONTACT_CUS_SVS,
    callback,
  );

  const onChangeEnabled = (checked, record) => {
    updateSVS({
      in: { name: record.name, isDisplayEnabled: checked ? 'Yes' : 'No' },
      filter: { contactCustomerService: { id: record.id } },
    });
  };

  const [detailSource, setDetailSource] = useState(null);
  const [visible, setVisible] = useState(false);
  const setVisibleHandle = (visible, record = null) => {
    setDetailSource(record);
    setVisible(visible);
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      fixed: 'left',
    },
    {
      title: '头像',
      dataIndex: 'avatarURL',
      key: 'avatarURL',
      render: avatarURL => <AvatarFetch avatarURL={avatarURL} />,
    },
    {
      title: '客服名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '启用状态',
      dataIndex: 'isDisplayEnabled',
      key: 'isDisplayEnabled',
      render: (h, record) => (
        <Switch
          checkedChildren='启用'
          unCheckedChildren='停用'
          checked={h === 'Yes'}
          disabled={
            h === 'Unknown' ||
            updateLdg ||
            !authRoutesKey.includes('API_ContactCustomerService_Update')
          }
          onChange={checked => onChangeEnabled(checked, record)}
        />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: h => formatDateTime(h),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: record => (
        <Space>
          {authRoutesKey.includes('API_ContactCustomerService_Update') && (
            <Button
              onClick={() => setVisibleHandle(true, record)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {authRoutesKey.includes('API_ContactCustomerService_Delete') && (
            <BtnDeleteConfirm
              handleOk={() =>
                deleteSVS({
                  filter: { contactCustomerService: { id: record.id } },
                })
              }
              loading={deleteLdg}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={originFilter.contactCustomerService}
        onFinish={onSearchHandle}
      />
      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => setVisibleHandle(true)}>
          新建联系人客服
        </Button>
      </DataTable>
      <Drawer
        visible={visible}
        setVisible={setVisibleHandle}
        refetch={refetch}
        detailData={detailSource}
        loading={createdLdg || updateLdg}
        createdSVS={createdSVS}
        updateSVS={updateSVS}
      />
    </PageWrap>
  );
}
