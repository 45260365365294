import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { Box } from '@WRAP_COMP';
import { setRecMsg } from '@/store/subscribe';
import useRecMessage from '@HOOK/useRecMessage';
import Header from './Header';
import RoomInfo from './RoomInfo';
import MsgStatus from './MsgStatus';

const rotate = keyframes`
  0%   {background-color: white;}
  50%  {background-color: #D5DFEA;}
  100% {background-color: white;}
`;
const animationStyles = css`
  animation: ${rotate} 0.8s linear;
`;

const RoomCardStyled = styled(Box)`
  cursor: pointer;
  ${({ isNewActiveRoom }) => isNewActiveRoom && animationStyles};
`;

const RoomCard = ({
  room = null,
  userData = null,
  showUnReadCount = true,
  unRead = 0,
  isActive = false,
  isPinned = false,
  isWaitFirstResponse = false,
  isTimeoutResponse = false,
  isNewActiveRoom = false, // 新進諮詢室樣式
  showIsOnline = false,
  showLastMsg = false,
  tags = null,
}) => {
  const dispatch = useDispatch();
  const { userID, avatarURL, username, isOnline } = userData;
  const { roomID, ipAddress, country, administrativeArea, lastMessage } = room;

  /**
   * 監聽
   *  */
  useRecMessage({
    roomID,
    returnCallback: recMsg => {
      if (recMsg?.roomID === roomID) dispatch(setRecMsg(null));
    },
  });

  return (
    <RoomCardStyled
      bgColor={isActive ? 'gray-1' : 'white'}
      borderBottom={'1px solid'}
      borderCr={'gray-2'}
      isNewActiveRoom={isNewActiveRoom}>
      <Box py={3} px={2}>
        <Header
          roomID={roomID}
          showIsOnline={showIsOnline}
          isOnline={isOnline === 'Yes'}
          avatarURL={avatarURL}
          username={username}
          userID={userID}
          tags={tags}
        />
        <RoomInfo
          roomID={roomID}
          ipAddress={ipAddress}
          country={country}
          administrativeArea={administrativeArea}
          isPinned={isPinned}
        />
        <MsgStatus
          roomID={roomID}
          lastMessage={lastMessage}
          showLastMsg={showLastMsg}
          isWaitFirst={isWaitFirstResponse}
          isTimeout={isTimeoutResponse}
          showUnReadCount={showUnReadCount}
          unRead={unRead}
        />
      </Box>
    </RoomCardStyled>
  );
};

RoomCard.propTypes = {
  room: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  showUnReadCount: PropTypes.bool,
  unRead: PropTypes.number,
  isActive: PropTypes.bool,
  isPinned: PropTypes.bool,
  isWaitFirstResponse: PropTypes.bool,
  isTimeoutResponse: PropTypes.bool,
  isNewActiveRoom: PropTypes.bool,
  showIsOnline: PropTypes.bool,
  showLastMsg: PropTypes.bool,
  tags: PropTypes.node,
};
export default RoomCard;
