const bankData = {
  ICBC: {
    name: '中国工商银行',
    pc_domain: 'http://www.icbc.com.cn/icbc/',
    app_download: 'http://m.icbc.com.cn/ICBC/kfssjyxkh9/3.htm?qrcode=1',
    sub: 0,
  },
  CCB: {
    name: '中国建设银行',
    pc_domain: 'http://www.ccb.com',
    app_download: 'http://group.ccb.com/cn/v3/head_content/mbsapp.html',
    color: 'blue',
    sub: 0,
  },
  ABC: {
    name: '中国农业银行',
    pc_domain: 'http://www.abchina.com',
    app_download:
      'http://mobile.abchina.com/download/clientDownload/zh_CN/MB_Index.aspx',
    sub: 0,
  },
  BOC: {
    name: '中国银行',
    pc_domain: 'http://www.boc.cn',
    app_download:
      'https://ebsnew.boc.cn/bocphone/VuePhone/tools/downloadByQrcode/index.html',
    color: 'red',
    sub: 0,
  },
  COMM: {
    name: '交通银行',
    pc_domain: 'http://www.bankcomm.com',
    app_download: 'http://app.bankcomm.com/?t=1',
    color: 'blue',
    sub: 0,
  },
  CMB: {
    name: '招商银行',
    pc_domain: 'http://www.cmbchina.com',
    app_download: 'http://m.cmbchina.com/app',
    sub: 0,
  },
  CITIC: {
    name: '中信银行',
    pc_domain: 'http://www.citicbank.com',
    app_download: 'http://go.citicbank.com/dl',
    sub: 0,
  },
  SPDB: {
    name: '上海浦东发展银行',
    pc_domain: 'https://ebank.spdb.com.cn',
    app_download: 'https://wap.spdb.com.cn/ewap/app',
    color: 'blue',
    sub: 0,
  },
  CIB: {
    name: '兴业银行',
    pc_domain: 'https://www.cib.com.cn',
    app_download: 'https://z.cib.com.cn/public/about-cib',
    color: 'blue',
    sub: 0,
  },
  CMBC: {
    name: '中国民生银行',
    pc_domain: 'http://www.cmbc.com.cn',
    app_download: 'https://m1.cmbc.com.cn/wap',
    color: 'blue',
    sub: 0,
  },
  BJBANK: {
    name: '北京银行',
    pc_domain: 'http://www.bankofbeijing.com.cn',
    app_download: 'http://www.95526.mobi/',
    sub: 0,
  },
  SRCB: {
    name: '深圳农村商业银行',
    pc_domain: 'http://www.4001961200.com',
    app_download: 'http://a.app.qq.com/o/simple.jsp?pkgname=com.csii.sns.ui',
    color: 'yellow',
    sub: 1,
  },
  BGB: {
    name: '广西北部湾银行',
    pc_domain: 'http://www.bankofbbg.com',
    app_download:
      'https://mbank.bankofbbg.com/app_pmb/page/wcb/downloadApp/AppDownLoad.html',
    color: 'blue',
    sub: 0,
  },
  SHRCB: {
    name: '上海农村商业银行',
    pc_domain: 'http://www.srcb.com',
    app_download: 'http://mbank.srcb.com/',
    color: 'yellow',
    sub: 1,
  },
  WHCCB: {
    name: '威海市商业银行',
    pc_domain: 'http://www.whccb.com',
    app_download: 'http://www.whccb.com/publish/whccb/20483/index.html',
    color: 'blue',
    sub: 1,
  },
  BOZK: {
    name: '周口银行',
    pc_domain: '',
    app_download: '',
    sub: 0,
  },
  KORLABANK: {
    name: '库尔勒市商业银行',
    pc_domain: 'http://www.xjkccb.cn',
    app_download: 'http://www.xjkccb.cn',
    sub: 1,
  },
  SPABANK: {
    name: '平安银行',
    pc_domain: 'http://bank.pingan.com',
    app_download:
      'http://bfiles.pingan.com.cn/brcp/pkg/bfiles/download?downapp_id=AM001000287',
    color: 'yellow',
    sub: 0,
  },
  SDEB: {
    name: '顺德农商银行',
    pc_domain: 'http://www.sdebank.com',
    app_download:
      'http://www.sdebank.com/cms/S101_21/ebank/perbank/m/index.html',
    color: 'blue',
    sub: 1,
  },
  HURCB: {
    name: '湖北省农村信用社',
    pc_domain: 'https://ebank.hb96568.com/',
    app_download: 'http://124.127.254.34/5200/newwap/',
    sub: 1,
  },
  WRCB: {
    name: '无锡农村商业银行',
    pc_domain: 'http://www.wrcb.com.cn',
    app_download: 'http://a.app.qq.com/o/simple.jsp?pkgname=com.csii.wrcb',
    sub: 1,
  },
  BOCY: {
    name: '朝阳银行',
    pc_domain: 'http://www.cycb.com',
    app_download:
      'http://www.cycb.com/data/tosend/resource/upload/ChaoYang_develop.apk',
    color: 'blue',
    sub: 0,
  },
  CZBANK: {
    name: '浙商银行',
    pc_domain: 'http://www.czbank.com/czbank/',
    app_download: 'http://m.czbank.com/',
    sub: 0,
  },
  HDBANK: {
    name: '邯郸银行',
    pc_domain: 'http://hdcb.cn/hdyh/index.html',
    app_download: 'https://mobile.hdcb.cn/wechatbank/main.html#appD/appD/appD',
    color: 'blue',
    sub: 0,
  },
  BOD: {
    name: '东莞银行',
    pc_domain: 'http://www.dongguanbank.cn',
    app_download: 'http://download.dongguanbank.cn/html/download_source.html',
    sub: 0,
  },
  ZYCBANK: {
    name: '遵义市商业银行',
    pc_domain: '',
    app_download: '',
    color: 'green',
    sub: 1,
  },
  SXCB: {
    name: '绍兴银行',
    pc_domain: 'http://www.sxccb.com',
    app_download: 'https://ebank.sxccb.com/mobileqr/index.html',
    color: 'blue',
    sub: 0,
  },
  GZRCU: {
    name: '贵州省农村信用社',
    pc_domain: 'http://www.gznxbank.com',
    app_download:
      'http://app.download.nongxinyin.com:666/download/7000/index.html',
    sub: 1,
  },
  ZJKCCB: {
    name: '张家口市商业银行',
    pc_domain: 'www.zjkccb.com',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.zjkccb.mbank&fromcase=40003',
    color: 'blue',
    sub: 1,
  },
  BOJZ: {
    name: '锦州银行',
    pc_domain: 'https://www.jinzhoubank.com',
    app_download:
      'http://www.jinzhoubank.com/jzbank/EBank/MobileBanking/2328.html',
    sub: 0,
  },
  BOP: {
    name: '平顶山银行',
    pc_domain: 'http://www.pdscb.com',
    app_download: 'https://ebank.pdsb.cn/htmls/qrcode.html',
    sub: 0,
  },
  HKB: {
    name: '汉口银行',
    pc_domain: 'http://www.hkbchina.com',
    app_download: 'http://www.hkbchina.com/portal/zh_CN/home/sjyhdl/2224.html',
    color: 'blue',
    sub: 0,
  },
  NXRCU: {
    name: '宁夏黄河农村商业银行',
    pc_domain: '',
    app_download: '',
    sub: 1,
  },
  NYNB: {
    name: '广东南粤银行',
    pc_domain: 'http://www.gdnybank.com',
    app_download: 'http://www.gdnybank.com/wap/gzsjsjyhiiod.html',
    sub: 0,
  },
  GRCB: {
    name: '广州农商银行',
    pc_domain: 'http://www.grcbank.com',
    app_download:
      'https://mbank.grcbank.com/ydyh/page/download/downloadApp.html',
    color: 'yellow',
    sub: 1,
  },
  BOSZ: {
    name: '苏州银行',
    pc_domain: 'http://www.suzhoubank.com',
    app_download: 'http://a.app.qq.com/o/simple.jsp?pkgname=com.yt.sz.szbank',
    color: 'green',
    sub: 0,
  },
  HZCB: {
    name: '杭州银行',
    pc_domain: 'http://www.hccb.com.cn',
    app_download: 'http://www.hzbank.com.cn/mobile/wallet.html',
    color: 'blue',
    sub: 0,
  },
  HSBK: {
    name: '衡水银行',
    pc_domain: 'http://www.hengshuibank.com',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.hsbank.personal&fromcase=40003',
    color: 'yellow',
    sub: 0,
  },
  HBC: {
    name: '湖北银行',
    pc_domain: 'http://www.hubeibank.cn',
    app_download: 'https://ebank.hubeibank.cn/perbank/mobileupload/mobile.html',
    sub: 0,
  },
  JXBANK: {
    name: '嘉兴银行',
    pc_domain: 'http://www.jxccb.com',
    app_download:
      'https://a.app.qq.com/o/simple.jsp?pkgname=com.csii.jiaxing.mobilebank&fromcase=40003',
    color: 'green',
    sub: 0,
  },
  HRXJB: {
    name: '华融湘江银行',
    pc_domain: 'http://www.hrxjbank.com.cn',
    app_download: 'https://wbank.hrxjbank.com.cn/wxbank/PhoneAppDownload.do',
    sub: 0,
  },
  BODD: {
    name: '丹东银行',
    pc_domain: 'http://dandongbank.com',
    app_download: 'http://dandongbank.com/ddyh/index/mobilebankdown/index.html',
    sub: 0,
  },
  AYCB: {
    name: '安阳银行',
    pc_domain: '',
    app_download: '',
    color: 'yellow',
    sub: 0,
  },
  EGBANK: {
    name: '恒丰银行',
    pc_domain: 'http://www.hfbank.com.cn',
    app_download: 'http://mbs-download.hfbank.com.cn/?mb_dataType=5',
    color: 'blue',
    sub: 0,
  },
  CDB: {
    name: '国家开发银行',
    pc_domain: 'http://www.cdb.com.cn',
    app_download: 'http://www.cdb.com.cn',
    sub: 0,
  },
  TCRCB: {
    name: '江苏太仓农村商业银行',
    pc_domain: 'http://www.tcrcb.com',
    app_download: 'http://www.tcrcb.com/tcrcb/488987/489099/503522/index.html',
    color: 'blue',
    sub: 1,
  },
  NJCB: {
    name: '南京银行',
    pc_domain: 'http://www.njcb.com.cn',
    app_download: 'http://wap.njcb.com.cn/',
    sub: 0,
  },
  ZZBANK: {
    name: '郑州银行',
    pc_domain: 'http://www.zzbank.cn',
    app_download: 'http://www.zzbank.cn/ebank/sjyh/201711/t20171122_533.html',
    color: 'yellow',
    sub: 0,
  },
  DYCB: {
    name: '德阳商业银行',
    pc_domain: '',
    app_download: '',
    sub: 1,
  },
  YBCCB: {
    name: '宜宾市商业银行',
    pc_domain: 'http://www.ybccb.com',
    app_download:
      'https://ebank.ybccb.com/pweb/AppDownloadPre.do?_locale=zh_CN&BankId=9999',
    color: 'blue',
    sub: 1,
  },
  SCRCU: {
    name: '四川省农村信用',
    pc_domain: 'https://www.scrcu.com',
    app_download: 'http://www.scrcu.com/public/hzf.html',
    color: 'green',
    sub: 1,
  },
  KLB: {
    name: '昆仑银行',
    pc_domain: 'http://www.klb.cn',
    app_download: 'http://www.klb.cn/eportal/ui?pageId=489579',
    sub: 0,
  },
  LSBANK: {
    name: '莱商银行',
    pc_domain: 'http://www.lsbankchina.com',
    app_download:
      'http://www.lsbankchina.com/publish/lsbankchina/20429/index.html',
    sub: 0,
  },
  YDRCB: {
    name: '尧都农商行',
    pc_domain: 'http://www.ydnsh.com',
    app_download: 'http://yaodu.ucsmy.com/e/App/AppDownload',
    color: 'green',
    sub: 1,
  },
  CCQTGB: {
    name: '重庆三峡银行',
    pc_domain: 'http://www.ccqtgb.com',
    app_download: 'http://www.ccqtgb.com/html/info/12126.html',
    color: 'blue',
    sub: 0,
  },
  FDB: {
    name: '富滇银行',
    pc_domain: 'http://www.fudian-bank.com',
    app_download:
      'https://ebank.fudian-bank.com/mobileChannel/download/index.html',
    sub: 0,
  },
  JSRCU: {
    name: '江苏省农村信用联合社',
    pc_domain: 'http://www.jsnx.net/cn/',
    app_download: 'http://www.js96008.com/S101_21/jsnxsj/index.htm',
    sub: 1,
  },
  JNBANK: {
    name: '济宁银行',
    pc_domain: 'http://www.jn-bank.com',
    app_download: 'http://www.jn-bank.com/publish/jnbank/25036/index.html',
    color: 'blue',
    sub: 0,
  },
  JINCHB: {
    name: '晋城银行JCBANK',
    pc_domain: 'http://www.jccbank.com.cn',
    app_download: 'https://ebank.jinchengbank.com/Guide/mobile.html',
    sub: 0,
  },
  FXCB: {
    name: '阜新银行',
    pc_domain: 'http://www.fuxinbank.com',
    app_download:
      'https://mybank.fuxinbank.com/mibank-port-war/shareController/download',
    color: 'blue',
    sub: 0,
  },
  WHRCB: {
    name: '武汉农村商业银行',
    pc_domain: 'http://www.whrcbank.com',
    app_download:
      'http://app.download.nongxinyin.com:666/download/7700/html/1.html',
    sub: 1,
  },
  HBYCBANK: {
    name: '湖北银行宜昌分行',
    pc_domain: 'http://www.hubeibank.cn',
    app_download: 'https://ebank.hubeibank.cn/perbank/mobileupload/mobile.html',
    sub: 0,
  },
  TZCB: {
    name: '台州银行',
    pc_domain: 'http://www.tzbank.com',
    app_download: 'http://down.tzbank.com:8080/download/TZBank.Simple.apk',
    color: 'blue',
    sub: 0,
  },
  TACCB: {
    name: '泰安市商业银行',
    pc_domain: 'http://www.taccb.com.cn',
    app_download: 'http://www.taccb.com.cn/publish/taccb/10936/index.html',
    sub: 1,
  },
  XCYH: {
    name: '许昌银行',
    pc_domain: '',
    app_download: '',
    color: 'blue',
    sub: 0,
  },
  CEB: {
    name: '中国光大银行',
    pc_domain: 'https://www.cebbank.com',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.cebbank.mobile.cemb&fromcase=40003',
    color: 'yellow',
    sub: 0,
  },
  NXBANK: {
    name: '宁夏银行',
    pc_domain: 'http://www.ycccb.com',
    app_download: 'http://www.ycccb.com/bankofnx/wljr/sjyx84/azzn/index.html',
    sub: 0,
  },
  HSBANK: {
    name: '徽商银行',
    pc_domain: 'http://www.hsbank.com.cn',
    app_download: 'http://www.hsbank.com.cn/Channel/665009',
    sub: 0,
  },
  JJBANK: {
    name: '九江银行',
    pc_domain: 'http://www.jjccb.com',
    app_download: 'http://www.jjccb.com',
    sub: 0,
  },
  NHQS: {
    name: '农信银清算中心',
    pc_domain: '',
    app_download: '',
    color: 'green',
    sub: 0,
  },
  MTBANK: {
    name: '浙江民泰商业银行',
    pc_domain: 'http://www.mintaibank.com',
    app_download:
      'http://www.mintaibank.com/mintaibank/zh_CN/ebank/sjyh/mobile.html',
    sub: 1,
  },
  LANGFB: {
    name: '廊坊银行',
    pc_domain: 'http://www.lccb.com.cn',
    app_download: 'https://mbank.lccb.com.cn/mbank/download/index.jsp',
    color: 'blue',
    sub: 0,
  },
  ASCB: {
    name: '鞍山银行',
    pc_domain: 'http://www.bankofas.com',
    app_download: 'http://www.bankofas.com/asbank/dzyh/index.html',
    sub: 0,
  },
  KSRB: {
    name: '昆山农村商业银行',
    pc_domain: 'http://www.ksrcb.cn',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.yitong.ksrcb.mbank.android',
    sub: 1,
  },
  YXCCB: {
    name: '玉溪市商业银行',
    pc_domain: 'http://www.ynhtbank.com',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.csii.yuxi.mobilebank',
    sub: 1,
  },
  DLB: {
    name: '大连银行',
    pc_domain: 'http://www.bankofdl.com/home/pc/index.shtml',
    app_download:
      'http://www.bankofdl.com/home/pc/common/c361943/c362070/20190125/1093304.shtml',
    sub: 0,
  },
  DRCBCL: {
    name: '东莞农村商业银行',
    pc_domain: 'https://www.drcbank.com',
    app_download: 'https://wap.drcbank.com/',
    sub: 1,
  },
  GCB: {
    name: '广州银行',
    pc_domain: 'http://www.gzcb.com.cn/i_index.shtml',
    app_download: 'http://www.gzcb.com.cn/article/wyzl/xz.shtml',
    sub: 0,
  },
  NBBANK: {
    name: '宁波银行',
    pc_domain: 'http://www.nbcb.cn',
    app_download: 'http://www.nbcb.com.cn/electronic_banking/mobile_banking/',
    color: 'yellow',
    sub: 0,
  },
  BOYK: {
    name: '营口银行',
    pc_domain: 'http://www.bankofyk.com',
    app_download: 'http://www.bankofyk.com/plus/view.php?aid=7138',
    color: 'blue',
    sub: 0,
  },
  SXRCCU: {
    name: '陕西信合',
    pc_domain: 'http://www.sxnxs.com',
    app_download: 'https://ibank.96262.com/icap-ibank/page/index/001/main.html',
    color: 'green',
    sub: 0,
  },
  GLBANK: {
    name: '桂林银行',
    pc_domain: 'https://www.guilinbank.com.cn',
    app_download:
      'https://e.guilinbank.com.cn/newUI/T_Z_NOLOGIN_WAP.hm?menuflag=800',
    color: 'blue',
    sub: 0,
  },
  BOQH: {
    name: '青海银行',
    pc_domain: 'http://www.bankqh.com',
    app_download: 'http://2bai.co/966672',
    sub: 0,
  },
  CDRCB: {
    name: '成都农商银行',
    pc_domain: 'http://www.cdrcb.com',
    app_download: 'http://www.cdrcb.com/uploadrar/opt/download.htm',
    color: 'green',
    sub: 1,
  },
  QDCCB: {
    name: '青岛银行',
    pc_domain: 'http://www.qdccb.com',
    app_download: 'http://www.qdccb.com/dzyx/sjyx/ywjs/iosxz/index.shtml',
    sub: 0,
  },
  HKBEA: {
    name: '东亚银行',
    pc_domain: 'https://www.hkbea.com.cn',
    app_download: 'https://www.hkbea.com.cn/footer/MobileBanking/',
    sub: 0,
  },
  HBHSBANK: {
    name: '湖北银行黄石分行',
    pc_domain: 'http://www.hubeibank.cn',
    app_download: 'https://ebank.hubeibank.cn/perbank/mobileupload/mobile.html',
    sub: 0,
  },
  WZCB: {
    name: '温州银行',
    pc_domain: 'http://www.wzcb.com.cn',
    app_download: 'http://www.wzcb.com.cn/ebank/view/menu_item_id/165',
    color: 'yellow',
    sub: 0,
  },
  TRCB: {
    name: '天津农商银行',
    pc_domain: 'http://www.trcbank.com.cn',
    app_download: 'http://www.trcbank.com.cn/2014/1-9/10570752812.html',
    color: 'yellow',
    sub: 1,
  },
  QLBANK: {
    name: '齐鲁银行',
    pc_domain: 'http://www.qlbchina.com',
    app_download: 'http://wap.qlbchina.com/',
    color: 'blue',
    sub: 0,
  },
  GDRCC: {
    name: '广东省农村信用社联合社',
    pc_domain: 'http://www.gdrcu.com',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.csii.gdnx.mobilebank&g_f=991653',
    color: 'yellow',
    sub: 1,
  },
  ZJTLCB: {
    name: '浙江泰隆商业银行',
    pc_domain: 'http://www.zjtlcb.com',
    app_download: 'https://ebank.zjtlcb.com/perbank/html/other/wxshare.htm',
    color: 'yellow',
    sub: 1,
  },
  GZB: {
    name: '赣州银行',
    pc_domain: 'http://www.bankgz.com',
    app_download: 'http://d.eqxiu.com/s/AIvEYeEr',
    sub: 0,
  },
  GYCB: {
    name: '贵阳市商业银行',
    pc_domain: 'https://www.bankgy.cn',
    app_download:
      'https://www.bankgy.cn/portal/zh_CN/upload/Attachment/sjyhxzpage/pageA.html',
    sub: 1,
  },
  CQBANK: {
    name: '重庆银行',
    pc_domain: 'http://www.cqcbank.com',
    app_download: 'http://www.cqcbank.com/portal/zh_CN/bank/download_cz.html',
    color: 'green',
    sub: 0,
  },
  DAQINGB: {
    name: '龙江银行',
    pc_domain: 'https://www.lj-bank.com',
    app_download: 'https://www.lj-bank.com/phone-ljbank/download.htm',
    color: 'yellow',
    sub: 0,
  },
  CGNB: {
    name: '南充市商业银行',
    pc_domain: 'https://e.tf.cn',
    app_download:
      'http://ebank.cgnb.cn:8093/resource/ws/spread/downAppUrl?from=message&isappinstalled=0',
    color: 'black',
    sub: 1,
  },
  SCCB: {
    name: '三门峡银行',
    pc_domain: 'http://www.zybank.com.cn',
    app_download:
      'https://wbs.zyebank.com/pweixin/weixin/htmls/AppShareDown/index.html',
    color: 'blue',
    sub: 0,
  },
  CSRCB: {
    name: '常熟农村商业银行',
    pc_domain: 'http://www.csrcbank.com',
    app_download: 'https://wx.csrcbank.com/mobiledownload/download.php',
    sub: 1,
  },
  SHBANK: {
    name: '上海银行',
    pc_domain: 'http://www.bosc.cn',
    app_download: 'http://www.bosc.cn/zh/dzyh/dzyh_grsjyh/16269.shtml',
    color: 'blue',
    sub: 0,
  },
  JLBANK: {
    name: '吉林银行',
    pc_domain: 'http://www.jlbank.com.cn',
    app_download: 'http://www.jlbank.com.cn/d/jlbank.html',
    sub: 0,
  },
  CZRCB: {
    name: '常州农村信用联社',
    pc_domain: '',
    app_download: '',
    color: 'green',
    sub: 1,
  },
  BANKWF: {
    name: '潍坊银行',
    pc_domain: 'http://www.wfccb.com',
    app_download: 'http://www.bankwf.com/publish/wfccb/10899/index.html',
    sub: 0,
  },
  ZRCBANK: {
    name: '张家港农村商业银行',
    pc_domain: 'http://www.zrcbank.com',
    app_download: 'http://dl.zrcbank.com/',
    color: 'yellow',
    sub: 1,
  },
  FJHXBC: {
    name: '福建海峡银行',
    pc_domain: 'http://www.fjhxbank.com',
    app_download: 'https://mbank.fjhxbank.com/wxbank/PhoneDownAfter.do',
    color: 'blue',
    sub: 0,
  },
  ZJNX: {
    name: '浙江省农村信用社联合社',
    pc_domain: 'http://www.zj96596.com',
    app_download:
      'https://www.zj96596.com:688/perbank/mobile_APP/download/download.html',
    color: 'green',
    sub: 1,
  },
  LZYH: {
    name: '兰州银行',
    pc_domain: 'https://www.lzbank.com',
    app_download: 'http://sns.lzbank.com/lzbankweb/download.jsp',
    color: 'blue',
    sub: 0,
  },
  JSB: {
    name: '晋商银行',
    pc_domain: 'http://www.jshbank.com',
    app_download: 'http://www.jshbank.com/jsyh/syejlm/dzhdcxyz/1379.html',
    sub: 0,
  },
  BOHAIB: {
    name: '渤海银行',
    pc_domain: 'http://www.cbhb.com.cn',
    app_download: 'http://www.cbhb.com.cn',
    color: 'blue',
    sub: 0,
  },
  CZCB: {
    name: '浙江稠州商业银行',
    pc_domain: 'http://www.czcb.com.cn',
    app_download: 'http://www.czcb.com.cn/sjyh/index.shtml',
    sub: 1,
  },
  YQCCB: {
    name: '阳泉银行',
    pc_domain: 'https://www.yqccb.com',
    app_download: 'https://mb.yqccb.com/download/download.html',
    color: 'blue',
    sub: 0,
  },
  SJBANK: {
    name: '盛京银行',
    pc_domain: 'http://www.shengjingbank.com.cn',
    app_download: 'https://mobile.shengjingbank.com.cn:7443/',
    sub: 0,
  },
  XABANK: {
    name: '西安银行',
    pc_domain: 'https://www.xacbank.com',
    app_download:
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.yitong.xian.mbank&fromcase=40003',
    color: 'blue',
    sub: 0,
  },
  BSB: {
    name: '包商银行',
    pc_domain: 'http://www.bsb.com.cn',
    app_download: 'http://a.app.qq.com/o/simple.jsp?pkgname=cn.com.bsb.mbank',
    color: 'yellow',
    sub: 0,
  },
  JSBANK: {
    name: '江苏银行',
    pc_domain: 'http://www.jsbchina.cn',
    app_download: 'http://m.jsbchina.cn/',
    color: 'yellow',
    sub: 0,
  },
  FSCB: {
    name: '抚顺银行',
    pc_domain: 'https://www.bankoffs.com.cn',
    app_download: 'https://www.bankoffs.com.cn/ebank',
    sub: 0,
  },
  HNRCU: {
    name: '河南省农村信用',
    pc_domain: 'https://ebank.hnnx.com',
    app_download: 'https://ebank.hnnx.com',
    color: 'green',
    sub: 1,
  },
  XTB: {
    name: '邢台银行',
    pc_domain: 'http://www.xtbank.com',
    app_download: 'http://www.xtbank.com/xtbank/xwzx91/rjxz/308844/index.html',
    color: 'blue',
    sub: 0,
  },
  HXBANK: {
    name: '华夏银行',
    pc_domain: 'http://www.hxb.com.cn/index.shtml',
    app_download: 'http://www.hxb.com.cn/jrhx/khfw/xzzq/index.shtml',
    sub: 0,
  },
  HNRCC: {
    name: '湖南省农村信用社',
    pc_domain: 'https://ebank.hnnx.com',
    app_download: 'https://mbank.hnnx.com/per/page/01/0111/0111.html',
    sub: 1,
  },
  DYCCB: {
    name: '东营市商业银行',
    pc_domain: 'http://www.dyccb.net',
    app_download: 'http://www.dyccb.net/publish/dyccb/20401/index.html',
    sub: 1,
  },
  ORBANK: {
    name: '鄂尔多斯银行',
    pc_domain: 'http://www.ordosbank.com',
    app_download:
      'https://ebank.ordosbank.com/pbank/app/resource/common/ocx/867/erdsbank-app.html',
    sub: 0,
  },
  BJRCB: {
    name: '北京农村商业银行',
    pc_domain: 'http://www.bjrcb.com',
    app_download: 'https://mbank2.bjrcb.com/mbs/download/download.html',
    sub: 1,
  },
  XYBANK: {
    name: '信阳银行',
    pc_domain: '',
    app_download: '',
    sub: 0,
  },
  ZGCCB: {
    name: '自贡市商业银行',
    pc_domain: 'http://www.zgbank.com.cn',
    app_download:
      'http://www.zgbank.com.cn/bencandy.php?fid-179-id-2750-page-1.htm',
    color: 'yellow',
    sub: 1,
  },
  CDCB: {
    name: '成都银行',
    pc_domain: 'http://www.bocd.com.cn',
    app_download: 'http://www.bocd.com.cn',
    sub: 0,
  },
  HANABANK: {
    name: '韩亚银行',
    pc_domain: 'http://www.hanabank.cn',
    app_download: 'http://www.hanabank.cn',
    color: 'green',
    sub: 0,
  },
  LYBANK: {
    name: '洛阳银行',
    pc_domain: 'http://www.bankofluoyang.com.cn',
    app_download: 'http://www.bankofluoyang.com.cn/ebankbusiness.jsp?id=222',
    color: 'blue',
    sub: 0,
  },
  GDB: {
    name: '广东发展银行',
    pc_domain: 'http://www.cgbchina.com.cn',
    app_download: 'http://3g.cgbchina.com.cn/',
    sub: 0,
  },
  ZBCB: {
    name: '齐商银行',
    pc_domain: 'http://www.qsbank.cc',
    app_download:
      'http://www.qsbank.cc/publish/qsbank/personal/ebank/mobilebank/29205/29370/index.html',
    sub: 0,
  },
  CBKF: {
    name: '开封市商业银行',
    pc_domain: '',
    app_download: '',
    color: 'blue',
    sub: 1,
  },
  H3CB: {
    name: '内蒙古银行',
    pc_domain: 'http://www.boimc.com.cn',
    app_download: 'http://www.boimc.com/ebank/phone/337.html',
    sub: 0,
  },
  CRCBANK: {
    name: '重庆农村商业银行',
    pc_domain: 'https://www.cqrcb.com',
    app_download: 'https://s.wcd.im/v/27nh4Z6t/?qr',
    sub: 1,
  },
  SZSBK: {
    name: '石嘴山银行',
    pc_domain: 'http://www.szsccb.com',
    app_download: 'http://www.szsccb.com/website/bank/sjyh_apk/index.html',
    color: 'yellow',
    sub: 0,
  },
  DZBANK: {
    name: '德州银行',
    pc_domain: 'http://www.dzbchina.com',
    app_download: 'http://www.dzbchina.com/dezhou.html',
    sub: 0,
  },
  SRBANK: {
    name: '上饶银行',
    pc_domain: 'http://www.srbank.cn',
    app_download: 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.srbank',
    color: 'yellow',
    sub: 0,
  },
  LSCCB: {
    name: '乐山市商业银行',
    pc_domain: 'http://www.lsccb.com',
    app_download: 'http://www.lsccb.com',
    sub: 1,
  },
  JXRCU: {
    name: '江西省农村信用',
    pc_domain: 'http://www.jxnxs.com',
    app_download: 'https://mob.962268.com/ewmdownload/download.html',
    color: 'green',
    sub: 1,
  },
  JZBANK: {
    name: '晋中市商业银行',
    pc_domain: 'http://www.jzbank.com',
    app_download: 'http://www.jzbank.com/wzgl/sjyhapp/',
    sub: 1,
  },
  HZCCB: {
    name: '湖州市商业银行',
    pc_domain: 'http://www.hzccb.net',
    app_download: 'http://www.hzccb.net',
    sub: 1,
  },
  NHB: {
    name: '南海农村信用联社',
    pc_domain: 'http://www.nanhaibank.com',
    app_download: 'http://www.nanhaibank.com/mobibank/download.html',
    sub: 1,
  },
  XXBANK: {
    name: '新乡银行',
    pc_domain: '',
    app_download: '',
    sub: 0,
  },
  JRCB: {
    name: '江苏江阴农村商业银行',
    pc_domain: 'http://www.jybank.com.cn',
    app_download: 'https://wx.jybank.com.cn/pwxweb/AppDownloadTwo.do',
    color: 'green',
    sub: 1,
  },
  YNRCC: {
    name: '云南省农村信用社',
    pc_domain: 'http://www.ynrcc.com',
    app_download: 'https://ebank.ynrcc.com/pweb/MobileBankDownLoad',
    color: 'green',
    sub: 1,
  },
  GXRCU: {
    name: '广西省农村信用',
    pc_domain: 'http://www.gx966888.com',
    app_download:
      'http://wxbank.nongxinyin.com/weixinServer/htmlShow/610001/download/bankDownloadBoth.html',
    color: 'green',
    sub: 1,
  },
  PSBC: {
    name: '中国邮政储蓄银行',
    pc_domain: 'http://www.psbc.com',
    app_download: 'http://phone.psbc.com/',
    color: 'green',
    sub: 0,
  },
  BZMD: {
    name: '驻马店银行',
    pc_domain: '',
    app_download: '',
    color: 'yellow',
    sub: 0,
  },
  ARCU: {
    name: '安徽省农村信用社',
    pc_domain: 'http://eweb.ahrcu.com',
    app_download: 'http://eweb.ahrcu.com/CN/xzzq/sjkhdxz/index.html',
    sub: 1,
  },
  GSRCU: {
    name: '甘肃省农村信用',
    pc_domain: 'https://ebank.gsrcu.com',
    app_download: 'https://ebank.gsrcu.com',
    color: 'green',
    sub: 1,
  },
  LYCB: {
    name: '辽阳市商业银行',
    pc_domain: 'http://www.bankofliaoyang.net',
    app_download:
      'http://www.bankofliaoyang.net/lybank/netbank/shoujibank/index.html',
    sub: 1,
  },
  JLRCU: {
    name: '吉林农信',
    pc_domain: 'https://jlnls.com',
    app_download: 'http://wap.jlnls.com/',
    color: 'yellow',
    sub: 0,
  },
  URMQCCB: {
    name: '乌鲁木齐市商业银行',
    pc_domain: 'http://www.uccb.com.cn',
    app_download: 'http://www.uccb.com.cn/phone.aspx',
    color: 'blue',
    sub: 1,
  },
  XLBANK: {
    name: '中山小榄村镇银行',
    pc_domain: 'http://www.jycbank.com',
    app_download: 'http://www.jjccb.com/portal/zh_CN/mbank/index.html',
    sub: 0,
  },
  CSCB: {
    name: '长沙银行',
    pc_domain: 'http://www.cscb.cn',
    app_download: 'http://www.cscb.cn',
    sub: 0,
  },
  JHBANK: {
    name: '金华银行',
    pc_domain: 'http://www.jhccb.com.cn',
    app_download: 'https://wx.jhccb.com.cn/pwxweb/DownLoadApp.do',
    color: 'yellow',
    sub: 0,
  },
  BHB: {
    name: '河北银行',
    pc_domain: 'http://www.hebbank.com',
    app_download:
      'http://www.hebbank.com/hbbank/gryw/_301291/_301299/index.html',
    color: 'blue',
    sub: 0,
  },
  NBYZ: {
    name: '鄞州银行',
    pc_domain: 'http://www.beeb.com.cn',
    app_download: 'http://ebank.beeb.com.cn/mobile',
    color: 'yellow',
    sub: 0,
  },
  LSBC: {
    name: '临商银行',
    pc_domain: 'http://www.lsbchina.com',
    app_download: 'http://www.lsbchina.com/publish/lsbchina/20687/index.html',
    sub: 0,
  },
  BOCD: {
    name: '承德银行',
    pc_domain: 'http://www.chengdebank.com',
    app_download:
      'https://m.chengdebank.com/netBank/page/download/download.html',
    sub: 0,
  },
  SDRCU: {
    name: '山东农信',
    pc_domain: 'http://www.sdnxs.com',
    app_download: 'https://mbc.sdrcu.com/mbcper/download.html',
    sub: 1,
  },
  NCB: {
    name: '南昌银行',
    pc_domain: 'http://www.jx-bank.com',
    app_download:
      'http://www.jx-bank.com/nccbank/zh_CN/eBank/downLoad/grwy/2078.html',
    color: 'blue',
    sub: 0,
  },
  TCCB: {
    name: '天津银行',
    pc_domain: 'http://www.bank-of-tianjin.com.cn',
    app_download:
      'http://www.bank-of-tianjin.com.cn/wlyx/sjyx/sjyxkhdb/index.shtml#5',
    color: 'blue',
    sub: 0,
  },
  WJRCB: {
    name: '吴江农商银行',
    pc_domain: 'http://www.wjrcb.com',
    app_download:
      'https://www.wjrcb.com/wbank/wbank_2/weProtal/index.html?OpenId=1&Page=weProtal#?/downloadBank',
    sub: 1,
  },
  CBBQS: {
    name: '城市商业银行资金清算中心',
    pc_domain: 'http://www.ccfccb.cn',
    app_download: 'http://www.ccfccb.cn',
    sub: 1,
  },
  HBRCU: {
    name: '河北省农村信用社',
    pc_domain: 'http://www.hebnx.com',
    app_download: 'http://www.hebnx.com/mbank/',
    color: 'green',
    sub: 1,
  },
  CSCRCB: {
    name: '长沙农村商业银行',
    pc_domain: 'http://www.crcbbank.com/',
    app_download: '',
    sub: 1,
  },
  ZYBANK: {
    name: '中原银行',
    pc_domain: 'http://www.zybank.com.cn',
    app_download: '',
    color: 'blue',
    sub: 0,
  },
  FJNX: {
    name: '福建省农村信用社联合社',
    pc_domain: 'http://www.fjnx.com.cn/',
    app_download: '',
    sub: 1,
  },
  HBA: {
    name: '海南银行',
    pc_domain: 'http://www.hnbankchina.com.cn/',
    app_download: '',
    color: 'blue',
  },
  GDRC: {
    name: '广东茂名农村商业银行',
    pc_domain: '',
    app_download: '',
    color: 'blue',
  },
  CRB: {
    name: '华润银行',
    pc_domain: '',
    app_download: '',
    color: 'blue',
  },
  NRC: {
    name: '南海农商银行',
    pc_domain: '',
    app_download: '',
  },
  FRCB: {
    name: '佛山农村商业银行',
    pc_domain: '',
    app_download: '',
  },
  LZCCB: {
    name: '柳州銀行',
    pc_domain: '',
    app_download: '',
  },
  BOW: {
    name: '乌海银行',
    pc_domain: '',
    app_download: '',
  },
  LNRCC: {
    name: '辽宁省农村信用社',
    pc_domain: '',
    app_download: '',
  },
  ZJB: {
    name: '紫金农商银行',
    pc_domain: '',
    app_download: '',
  },
  SXB: {
    name: '山西银行',
    pc_domain: '',
    app_download: '',
  },
  SRCU: {
    name: '山西农村信用社',
    pc_domain: '',
    app_download: '',
  },
  JNMSRB: {
    name: '集宁蒙商村镇银行',
    pc_domain: '',
    app_download: '',
  },
  IMGRCC: {
    name: '内蒙古农村信用社',
    pc_domain: '',
    app_download: '',
  },
  JSRCB: {
    name: '江苏农村商业银行',
    pc_domain: '',
    app_download: '',
  },
  SYRCB: {
    name: '沈阳农商银行',
    pc_domain: '',
    app_download: '',
  },
  ZJRCB: {
    name: '浙江农村商业银行',
    pc_domain: '',
    app_download: '',
    sub: 1,
  },
  JXB: {
    name: '江西银行',
    pc_domain: '',
    app_download: '',
  },
  NYCB: {
    name: '河南南阳村镇银行',
    pc_domain: '',
    app_download: '',
  },
  CAB: {
    name: '长安银行',
    pc_domain: '',
    app_download: '',
  },
  BOQZ: {
    name: '泉州银行',
    pc_domain: '',
    app_download: '',
  },
  JXRCB: {
    name: '江西农商银行',
    pc_domain: '',
    app_download: '',
  },
  CSXB: {
    name: '湖南三湘银行',
    pc_domain: '',
    app_download: '',
  },
};

const useBankData = () => {
  return Object.keys(bankData).map(bank_code => {
    return {
      value: bank_code,
      label: bankData[bank_code].name,
    };
  });
};

export default useBankData;
