import React from 'react';
import { Button, Checkbox, Modal } from 'antd';

function BatchDeactivated({
  checkedList = [],
  indeterminate = false,
  onCheckAllChange = () => {},
  checkAll = false,
  batchDeactivated = () => {},
}) {
  const _onClick = () => {
    if (checkedList.length) {
      Modal.confirm({
        title: '确认批量封存？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await batchDeactivated({ roomIDs: checkedList });
        },
      });
    }
  };
  return (
    <div>
      <Checkbox
        indeterminate={indeterminate} // false 沒樣式
        onChange={onCheckAllChange}
        checked={checkAll}>
        全选
      </Checkbox>
      <Button type='primary' ghost size='small' onClick={_onClick}>
        批量封存
      </Button>
    </div>
  );
}

export default BatchDeactivated;
