import React from 'react';
import PropTypes from 'prop-types';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { DatePicker, Form } from 'antd';
import { datePickerRanges } from '@/utils';

const DateRangePicker = ({
  name = 'date',
  label = '',
  showTime = false,
  isAddDate = false,
  formProps = {},
  ...props
}) => {
  return (
    <Form.Item label={label} name={name} {...formProps}>
      <DatePicker.RangePicker
        locale={locale}
        allowClear={false}
        ranges={datePickerRanges(isAddDate)}
        showTime={showTime}
        {...props}
      />
    </Form.Item>
  );
};

DateRangePicker.propTypes = {
  label: PropTypes.string,
  showTime: PropTypes.bool,
};

export default DateRangePicker;
