import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { EditTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import {
  Typography,
  List,
  Space,
  Form,
  Input,
  Button,
  Divider,
  Spin,
} from 'antd';
import { formatDateTime } from '@/utils';

const WhiteList = ({
  loading = false,
  deleteLoading = false,
  dataSource = [],
  createWhite,
  updateWhite,
  deleteWhite,
}) => {
  const [ipEdit, setIpEdit] = useState(false);
  const [curEditId, setCurEditId] = useState(false);
  const inputEl = useRef(null);
  const [whiteForm] = Form.useForm();

  const changeIpEditType = (type, id) => {
    setIpEdit(type);
    setCurEditId(id);
    if (!type) {
      const ipAddress = inputEl.current.state.value;
      updateWhite(id, ipAddress);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={whiteForm}
        layout='inline'
        onFinish={filed => createWhite(filed, whiteForm)}>
        <Form.Item
          name='ipAddress'
          rules={[{ required: true, message: '必填' }]}>
          <Input placeholder='請輸入 IP 地址' />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            提交
          </Button>
        </Form.Item>
      </Form>

      <Divider />

      <List
        dataSource={dataSource}
        renderItem={item => (
          <List.Item
            actions={[
              <Button
                key='edit'
                type='link'
                icon={
                  ipEdit && curEditId === item.id ? (
                    <CheckCircleTwoTone />
                  ) : (
                    <EditTwoTone />
                  )
                }
                onClick={() => changeIpEditType(!ipEdit, item.id)}
              />,
              <BtnDeleteConfirm
                key='delete'
                handleOk={() => deleteWhite(item.id)}
                loading={deleteLoading}
                placement='topRight'
              />,
            ]}>
            <Space direction='vertical'>
              {ipEdit && curEditId === item.id ? (
                <Input
                  ref={inputEl}
                  type='text'
                  defaultValue={item.ipAddress}
                  onPressEnter={() => changeIpEditType(!ipEdit, item.id)}
                />
              ) : (
                <Typography.Text strong>{item.ipAddress}</Typography.Text>
              )}
              <Typography.Text type='secondary'>
                建立時間：{formatDateTime(item.createdAt)}
              </Typography.Text>
            </Space>
          </List.Item>
        )}
      />
    </Spin>
  );
};

WhiteList.propTypes = {
  loading: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  dataSource: PropTypes.array,
  createWhite: PropTypes.func.isRequired,
  updateWhite: PropTypes.func.isRequired,
  deleteWhite: PropTypes.func.isRequired,
};

export default WhiteList;
