import { useState } from 'react';
import { useActiveList } from '@/hook/useList';

/**
 * 商戶管理
 */
function useMerchant({ options = {}, originPagination = {} } = {}) {
  /**
   * 域名搜尋 list
   */
  const [merchantSelectList, setMerchantSelectList] = useState([]);
  const selectListHandle = list => {
    let tempList = [];
    list.forEach(({ id, key, merchantType }) => {
      tempList.push({ value: id, label: key, merchantType });
    });
    setMerchantSelectList(tempList);
  };

  /**
   * table list
   */
  const [merchantTableList, setMerchantTableList] = useState([]);
  const [merchantTableTotal, setMerchantTableTotal] = useState(0);
  const { loading, pagination, refetch, handleChangePage } = useActiveList({
    api: 'LIST_MERCHANT',
    originFilter: {},
    originPagination,
    options: {
      onCompleted: ({ listMerchant }) => {
        selectListHandle(listMerchant.merchants || []);
        setMerchantTableList([...(listMerchant.merchants || [])]);
        setMerchantTableTotal(() => listMerchant?.meta?.total || 0);
      },
      ...options,
    },
  });

  return {
    loading,
    pagination,
    merchantTableList,
    merchantTableTotal,
    merchantSelectList,
    refetch,
    handleChangePage,
  };
}

export default useMerchant;
