/**
 *  客服(諮詢房)統計數據
 */
import React, { useMemo } from 'react';
import {
  UserAddOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import { Statistic, Card, Skeleton } from 'antd';
import { Row, Col } from '@/plugin/wrap-comp';
import { useActiveList } from '@/hook/useList';
import useMerchant from '@/hook/useMerchant';
import SearchBar from '@/components/SearchBar';
import QnsTooltip from '@/components/QnsTooltip';
import { setDateRanges, formatSearchDate, secondsToHms } from '@/utils';
import ConsultingRoomTable from '@/components/Table/ConsultingRoomTable';
import { Bar } from '@ant-design/charts';

const { theme } = require('@/plugin/theme');
const defaultDateTime = setDateRanges(1);
const defaultSearch = {
  date: [defaultDateTime.start, defaultDateTime.end], // 日期套件要用的預設格式
};

const roomServiceOriginFilter = {
  roomServiceFilter: {
    baseFilter: {
      sortField: 'date',
      sortType: 'DESC',
      rangeField: 'date',
      rangeType: 'ByDateTime',
      greaterThanEqual: formatSearchDate(defaultSearch.date)[0],
      lessThanEqual: formatSearchDate(defaultSearch.date)[1],
    },
  },
  roomAreaFilter: {
    baseFilter: {
      createdAtGTE: formatSearchDate(defaultSearch.date)[0],
      createdAtLTE: formatSearchDate(defaultSearch.date)[1],
    },
  },
};

const config = {
  xField: 'count',
  yField: 'administrativeArea',
  legend: { position: 'top-left' },
  barBackground: { style: { fill: 'rgba(0,0,0,0.1)' } },
  interactions: [
    {
      type: 'active-region',
      enable: false,
    },
  ],
};

export default function ReportConsultingRoomMgm() {
  const { merchantSelectList, loading: merchantLoading } = useMerchant({
    options: { fetchPolicy: 'cache-first' },
  });
  const searchFields = useMemo(
    () => [
      { name: 'date', type: 'dateRange', label: '统计时间' },
      {
        name: 'merchantID',
        type: 'select',
        label: '所属商户号',
        options: merchantSelectList.map(({ value, label }) => ({
          value,
          label,
        })),
      },
    ],
    [merchantSelectList],
  );

  // 處理總數顯示
  const totalDataFields = [
    {
      key: 'createConsultationCountOfCommonUserTotal',
      title: () => (
        <QnsTooltip
          title='进线会员'
          tip='筛选日期不重复的点选咨询客服的会员数'
        />
      ),
      value: 0,
      prefix: () => <UserAddOutlined />,
    },
    {
      key: 'createConsultationCountTotal',
      title: () => (
        <QnsTooltip title='新建咨询房总数' tip='筛选日期内加总的新建咨询房数' />
      ),
      value: 0,
      prefix: () => <UserSwitchOutlined />,
    },
    {
      key: 'validConsultationCountTotal',
      title: () => (
        <QnsTooltip title='有效咨询总数' tip='筛选日期内加总的有效咨询数' />
      ),
      value: 0,
      prefix: () => <SwapOutlined />,
    },
    {
      key: 'averageResponseSecondTotal',
      title: () => (
        <QnsTooltip
          title='平均首次回应时长'
          tip='篩選日期內全有效諮詢房用戶發出首次對話後,「首位客服人員」的回應速度之平均時間'
        />
      ),
      value: 0,
      prefix: () => <CommentOutlined />,
      formatter: v => secondsToHms(v),
    },
    {
      key: 'averageConversationSecondTotal',
      title: () => (
        <QnsTooltip
          title='平均对话时长'
          tip='筛选日期内全有效咨询房用户发出首次对话到客服人员封存该咨询房之平均时间'
        />
      ),
      value: 0,
      prefix: () => <CommentOutlined />,
      formatter: v => secondsToHms(v),
    },
  ];
  const {
    data: { list, total, areaData, totalData },
    loading,
    error,
    handleSearchFilter,
    handleChangePage,
  } = useActiveList({
    api: 'REPORT_CONSULTING_ROOM_SERVICE_AREA',
    cusFilter: { ...roomServiceOriginFilter },
    handleData: ({ listRoomDailySummary, statisticsConsultationRoomArea }) => {
      return {
        list: listRoomDailySummary?.roomDailySummaries || [],
        total: listRoomDailySummary?.meta?.total || 0,
        areaData: statisticsConsultationRoomArea.statistics || [],
        totalData: totalDataFields.map(el => {
          el.value = listRoomDailySummary.meta[el.key];
          return el;
        }),
      };
    },
  });

  const onFinish = ({ date, merchantID }) => {
    handleSearchFilter({
      // 客服统计数据
      roomServiceFilter: {
        merchantID,
        baseFilter: {
          ...roomServiceOriginFilter.roomServiceFilter.baseFilter,
          greaterThanEqual: formatSearchDate(date)[0],
          lessThanEqual: formatSearchDate(date)[1],
        },
      },
      // 访客即时分布排行
      roomAreaFilter: {
        ...(merchantID && { consultingRoom: { merchantID } }),
        baseFilter: {
          createdAtGTE: formatSearchDate(date)[0],
          createdAtLTE: formatSearchDate(date)[1],
        },
      },
    });
  };

  return (
    <>
      <SearchBar
        fields={searchFields}
        defaultSearch={defaultSearch}
        onFinish={onFinish}
        defaultCollapsed={false}
        loading={merchantLoading}
      />

      {/* 統計 */}
      <Row gutter={12}>
        {(loading || error) && <Skeleton />}
        {loading ||
          error ||
          totalData.map(el => (
            <Col key={el.key} flex={1} mb={3}>
              <Card>
                <Statistic
                  valueStyle={{ color: theme.primary }}
                  title={el.title()}
                  value={el.value}
                  prefix={el.prefix()}
                  formatter={el.formatter}
                />
              </Card>
            </Col>
          ))}
      </Row>
      <Row gutter={12}>
        <Col span={16}>
          <ConsultingRoomTable
            dataSource={list}
            loading={loading}
            onChangePage={handleChangePage}
            title='客服统计数据'
            footer={() => <div style={{ textAlign: 'end' }}>共 {total} 条</div>}
          />
        </Col>
        <Col span={8}>
          <Card title='访客即时分布排行'>
            {loading && <Skeleton />}
            {loading || <Bar {...config} data={areaData} />}
          </Card>
        </Col>
      </Row>
    </>
  );
}
