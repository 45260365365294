import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import BasicForm from '@/components/User/BasicForm';
import SpinachInfo from '@/components/User/SpinachInfo';
import LoginInfoList from '@/components/User/LoginInfoList';
import DeviceList from '@/components/User/DeviceList';
import SettingForm from '@/components/User/SettingForm';
import FriendList from '@/components/User/FriendList';
import RoleList from '@/components/User/RoleList';
import WhiteList from '@/components/User/WhiteList';
import { AccountTypeKeys } from '@/utils';

function UserDetailTabs({
  user,
  isEdit,
  tabPosition = 'left',
  basicFormProps,
}) {
  const TabsData = ({ accountType = '' }) => {
    const BASIC_INFO = {
      key: 'BasicSetting',
      title: '基本资讯',
      content: () => (
        <BasicForm userID={user.id} isEdit={isEdit} {...basicFormProps} />
      ),
    };
    const SPINACH_INFO = {
      key: 'SpinachInfo',
      title: '平台資訊',
      content: () => <SpinachInfo userID={user.id} />,
    };
    const LOGIN_INFO_LIST = {
      key: 'LoginInfoList',
      title: '登入资讯',
      content: () => <LoginInfoList userID={user.id} />,
    };
    const USER_SETTING = {
      key: 'UserSetting',
      title: '用户设置',
      content: () => <SettingForm isEdit={isEdit} userID={user.id} />,
    };
    const ROLE_LIST = {
      key: 'RoleList',
      title: '角色设置',
      content: () => (
        <RoleList
          userID={user.id}
          isEdit={isEdit}
          accountType={user.accountType}
        />
      ),
    };
    const DEVICE_LIST = {
      key: 'DeviceList',
      title: '装置设置',
      content: () => <DeviceList isEdit={isEdit} userID={user.id} />,
    };
    const WHITE_LIST = {
      key: 'WhiteList',
      title: '白名单设置',
      content: () => <WhiteList isEdit={isEdit} userID={user.id} />,
    };
    const FRIEND_LIST = {
      key: 'FriendList',
      title: '好友管理',
      content: () => <FriendList isEdit={isEdit} userID={user.id} />,
    };

    const accountTypeRender = {
      [AccountTypeKeys['Manager']]: () => [
        BASIC_INFO,
        LOGIN_INFO_LIST,
        ROLE_LIST,
        DEVICE_LIST,
        WHITE_LIST,
      ],
      [AccountTypeKeys['Monitor']]: () => [
        BASIC_INFO,
        LOGIN_INFO_LIST,
        ROLE_LIST,
        DEVICE_LIST,
        WHITE_LIST,
      ],
      [AccountTypeKeys['CustomerService']]: () => [
        BASIC_INFO,
        LOGIN_INFO_LIST,
        ROLE_LIST,
        DEVICE_LIST,
        WHITE_LIST,
      ],
      [AccountTypeKeys['CommonUser']]: () => [
        BASIC_INFO,
        SPINACH_INFO,
        LOGIN_INFO_LIST,
        USER_SETTING,
        ROLE_LIST,
        DEVICE_LIST,
        WHITE_LIST,
        FRIEND_LIST,
      ],
      [AccountTypeKeys['Tourist']]: () => [BASIC_INFO, DEVICE_LIST],
    };
    return accountTypeRender[accountType]
      ? accountTypeRender[accountType]()
      : accountTypeRender[AccountTypeKeys['Tourist']]();
  };

  return (
    <Tabs tabPosition={tabPosition} type='line' defaultActiveKey='BasicSetting'>
      {user &&
        TabsData(user).map(item => (
          <Tabs.TabPane tab={item.title} key={item.key}>
            {item.content(item.title)}
          </Tabs.TabPane>
        ))}
    </Tabs>
  );
}

UserDetailTabs.propTypes = {
  user: PropTypes.object,
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.user?.id === nextProps.user?.id;
};
export default React.memo(UserDetailTabs, areEqual);
