import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const AvatarWrap = ({ avatarURL = '', text = '', ...props }) => {
  const firstUppCase = str => str.slice(0, 1).toUpperCase();
  return (
    <Avatar src={avatarURL} icon={text ? null : <UserOutlined />} {...props}>
      {text && firstUppCase(text)}
    </Avatar>
  );
};

export default AvatarWrap;
