import React, { useState } from 'react';
import { PlusOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import useOrigin from '@/hook/useOrigin';
import useMerchant from '@/hook/useMerchant';
import useMe from '@/hook/useMe';
import { useMutationApi, apiType } from '@/hook/useApi';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import OriginSettingDrawer from './OriginSettingDrawer';
import { formatDateTime } from '@/utils';
import { useSelector } from 'react-redux';

function OriginSetting() {
  const { isSystemType } = useMe();
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const {
    loading,
    pagination,
    originTableList,
    originTotal,
    refetch,
    handleChangePage,
  } = useOrigin({ originPagination: { perPage: 10 } });

  const {
    merchantTableList,
    merchantSelectList,
    loading: merchantLdg,
  } = useMerchant();

  const onCompletedCallback = { onCompleted: () => refetch() };

  // 新增
  const [createOrigin, { loading: createdLoading }] = useMutationApi(
    apiType.CREATE_CS_ROOM_ORIGIN,
    onCompletedCallback,
  );

  // 編輯
  const [updateOrigin, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_CS_ROOM_ORIGIN,
    onCompletedCallback,
  );

  // 刪除
  const [deleteOrigin, { loading: deleteLoading }] = useMutationApi(
    apiType.DELETE_CS_ROOM_ORIGIN,
    onCompletedCallback,
  );

  const [visible, setVisible] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const openDrawer = (data = null) => {
    setDetailData(data);
    setVisible(true);
  };

  const columns = () => {
    let columns = [
      {
        title: '#ID',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        fixed: 'left',
      },
      {
        title: '域名',
        dataIndex: 'origin',
        key: 'origin',
      },
      {
        title: '商戶号',
        dataIndex: 'merchantID',
        key: 'merchantID',
        render: id => {
          return <>{merchantTableList.find(mr => mr.id === id)?.key}</>;
        },
      },
      {
        title: '配置客服',
        dataIndex: 'customerServices',
        key: 'customerServices',
        render: list => (
          <>
            {list.map(ll => (
              <div key={ll.id}>
                <Typography.Text>
                  {ll.username} #{ll.id}
                </Typography.Text>
              </div>
            ))}
          </>
        ),
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '建立时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: h => formatDateTime(h),
      },
    ];
      columns.push({
        title: '动作',
        key: 'action',
        width: '10%',
        fixed: 'right',
        render: record =>
          record.id === 1 ? null : (
            <Space>
              {(isSystemType || authRoutesKey.includes('API_ConsultingRoomQuestion_Update')) && (
                <Button
                  onClick={() => openDrawer(record)}
                  type='link'
                  icon={<EditTwoTone />}></Button>
              )}
              {(isSystemType || authRoutesKey.includes('API_ConsultingRoomQuestion_Delete')) && (
                <BtnDeleteConfirm
                  handleOk={() =>
                    deleteOrigin({
                      filter: { consultingRoomOrigin: { id: record.id } },
                    })
                  }
                  loading={deleteLoading}
                />
              )}
            </Space>
          ),
      });
    return columns;
  };

  return (
    <>
      <DataTable
        columns={columns()}
        dataSource={originTableList}
        loading={loading}
        bordered={false}
        bodyStyle={{ padding: '0px' }}
        pagination={{ ...pagination, total: originTotal }}
        onChangePage={handleChangePage}>
        {/* 新建 */}
        {(isSystemType || authRoutesKey.includes('API_ConsultingRoomQuestion_Create')) && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openDrawer()}>
            新建访问域名
          </Button>
        )}
      </DataTable>
      <OriginSettingDrawer
        visible={visible}
        setVisible={setVisible}
        merchantSelectList={merchantSelectList}
        detailData={detailData}
        createOrigin={createOrigin}
        updateOrigin={updateOrigin}
        loading={merchantLdg || createdLoading || updateLoading}
      />
    </>
  );
}

export default OriginSetting;
