import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined, EditTwoTone, TagOutlined } from '@ant-design/icons';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import { useActiveList } from '@/hook/useList';
import useAlertMessage from '@/hook/useAlertMessage';
import { Button, Space, Tag, Badge } from 'antd';
import PageWrap from '@/components/PageWrap';
import SearchBar from '@/components/SearchBar';
import DataTable from '@/components/Table/DataTable';
import BtnDeleteConfirm from '@/components/Button/BtnDeleteConfirm';
import { EnableDisplay } from '@/utils';
import Drawer from './Drawer';

const originFilter = {
  tag: { isEnable: 'Unknown', name: '' },
};
const searchFields = [
  { name: 'name', type: 'text', label: '标签名称' },
  {
    name: 'isEnable',
    type: 'select',
    label: '启用状态',
    optionsObj: EnableDisplay,
  },
];

const MemberTagMgm = () => {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const {
    data: { list, total },
    loading,
    pagination,
    refetch,
    handleSearchFilter,
    handleChangePage,
  } = useActiveList({
    api: 'LIST_TAGS',
    originFilter: { ...originFilter },
    handleData: ({ listTag }) => ({
      list: listTag.tags || [],
      total: listTag?.meta?.total || 0,
    }),
  });

  const onFinish = search => {
    let searchParams = { tag: { ...originFilter.tag, ...search } };
    handleSearchFilter(searchParams);
  };

  const [detailSource, setDetailSource] = useState({});
  const { onErrorMsg } = useAlertMessage();
  const { fetchData: fetchDetail } = useQueryApi(apiType.GET_TAG);
  const [detailLdg, setDetailLdg] = useState(false);
  const fetchDetailHandel = async id => {
    setDetailLdg(true);
    const { data, error } = await fetchDetail({ filter: { tag: { id } } });
    setDetailLdg(false);
    if (error) {
      onErrorMsg(error);
    } else {
      setDetailSource({ ...data });
      openDrawer(true, true);
    }
  };

  // 刪除
  const [deleteTag, { loading: deleteLdg }] = useMutationApi(
    apiType.DELETE_TAG,
  );
  const deleteTagHandler = async id => {
    const [err] = await deleteTag({ filter: { tag: { id } } });
    if (!err) refetch();
  };

  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const openDrawer = (open, openType) => {
    setVisible(open);
    setIsEdit(() => (open ? openType : false));
  };

  // table columns
  const columns = [
    { title: '#ID', dataIndex: 'id', key: 'id', width: '5%', fixed: 'left' },
    {
      title: '標籤',
      dataIndex: 'name',
      key: 'name',
      render: (n, r) => (
        <Tag icon={<TagOutlined />} color={r.RGBHex}>
          {n}
        </Tag>
      ),
    },
    {
      title: '启用状态',
      dataIndex: 'isEnable',
      key: 'isEnable',
      width: '10%',
      render: h => (
        <Badge color={EnableDisplay[h].color} text={EnableDisplay[h].text} />
      ),
    },
    {
      title: '动作',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: ({ id }) => (
        <Space>
          {authRoutesKey.includes('API_Member_Tag_Update') && (
            <Button
              onClick={() => fetchDetailHandel(id)}
              type='link'
              icon={<EditTwoTone />}
            />
          )}
          {authRoutesKey.includes('API_Member_Tag_Delete') && (
            <BtnDeleteConfirm
              handleOk={() => deleteTagHandler(id)}
              loading={deleteLdg}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageWrap>
      <SearchBar
        fields={searchFields}
        defaultSearch={originFilter.tag}
        onFinish={onFinish}
      />

      <DataTable
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChangePage={handleChangePage}>
        {/* 新建 */}
        {authRoutesKey.includes('API_Member_Tag_Create') && (
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openDrawer(true, false)}>
            新建标签
          </Button>
        )}
      </DataTable>

      {/* 新增 編輯 */}
      <Drawer
        openDrawer={openDrawer}
        visible={visible}
        isEdit={isEdit}
        refetch={refetch}
        loading={detailLdg}
        detailData={detailSource}
      />
    </PageWrap>
  );
};

export default MemberTagMgm;
