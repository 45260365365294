import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import PageWrap from '@/components/PageWrap';
import OriginSetting from './OriginSetting';
import MerchantSetting from './MerchantSetting';

export default function OriginMgm() {
  const { authRoutesKey } = useSelector(({ auth }) => auth);
  const tabsData = () => {
    let data = [];
    if (authRoutesKey.includes('API_ConsultingRoomOrigin_ALL'))
      data.push({
        key: 'originSetting',
        tab: '访问域名设置',
        content: () => <OriginSetting />,
      });
    if (authRoutesKey.includes('API_SpinachPlatformSetting_ALL'))
      data.push({
        key: 'merchantSetting',
        tab: '商戶列表设置',
        content: () => <MerchantSetting />,
      });
    return data;
  };

  return (
    <PageWrap bgColor='white' p='3'>
      <Tabs defaultActiveKey={tabsData()[0]?.key} type='card'>
        {tabsData().map(tab => (
          <Tabs.TabPane tab={tab.tab} key={tab.key}>
            {tab.content()}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </PageWrap>
  );
}
